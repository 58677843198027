import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import TextField from "#components/utils/TextField";
import Autocomplete from "#components/utils/Autocomplete";
import AddButton from "#components/utils/AddButton";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import productConstants from "#constants/product";

const CostPrice = ({
  isRenderingFromCatalogEdit,
  costPriceWithDiscount,
  selectedProduct,
  setIsCostPriceSectionActive,
  isCostPriceSectionActive,
  sourceIsNotHopStack,
  costPriceInputFieldValue,
  onChange,
  isDisabledCostPriceField,
  costPriceBreakdown,
  updateComponentQuantity,
  onRemoveCostPriceSalesPrice,
  costPrice,
  onCostPriceChange,
  onAddCostPrice,
  onRemoveCostPrice,
  addBreakdownCost,
}) => {
  const displayCostPrice = (
    Number(costPriceWithDiscount || selectedProduct?.costPrice?.total) > 0
      ? Number(costPriceWithDiscount || selectedProduct?.costPrice?.total)
      : 0
  ).toFixed(2);

  return (
    <>
      <div className="mb-4 flex-col gap-1">
        <div className="text-base font-semibold text-454A4F">
          Cost Price Info
        </div>
        <div className="text-sm font-normal">
          Context text on cost pricing goes here...
        </div>
      </div>
      <div
        className={`mb-4 w-full rounded-md border-2 border-B3BFCA p-4 duration-500`}>
        <div
          className={`flex justify-between ${isRenderingFromCatalogEdit ? "mb-2" : ""}`}>
          <div>
            <div>
              <div className="text-sm font-normal">Total Cost Price</div>
              <div className="text-xl font-semibold">{`$${displayCostPrice}`}</div>
            </div>
          </div>
          <div
            className="my-auto mr-2 cursor-pointer text-xl"
            onClick={() =>
              setIsCostPriceSectionActive(!isCostPriceSectionActive)
            }>
            {isCostPriceSectionActive ? (
              <ChevronUpIcon className="h-6 w-6" />
            ) : (
              <ChevronDownIcon className="h-6 w-6" />
            )}
          </div>
        </div>
        {isCostPriceSectionActive && (
          <div
            className={`${sourceIsNotHopStack ? "pointer-events-none" : ""}`}>
            <div className="flex flex-wrap">
              <div
                className={`${!isRenderingFromCatalogEdit ? "my-4" : "mb-4"} flex gap-4`}>
                <div>
                  {!isRenderingFromCatalogEdit ? (
                    <TextField
                      type="number"
                      id="costPrice"
                      label="Cost Price"
                      placeholder=" "
                      value={costPriceInputFieldValue}
                      onChange={(e) => {
                        onChange({
                          target: {
                            name: "total",
                            value: e.target.value,
                            type: "costPrice",
                          },
                        });
                      }}
                      name="costPrice"
                      disabled={isDisabledCostPriceField}
                      className={isDisabledCostPriceField ? "bg-white" : ""}
                    />
                  ) : (
                    <div className="flex flex-col">
                      <span className="mb-1 text-sm font-medium text-lightGray">
                        Cost Price{" "}
                        <Tooltip title="Enter the cost price">
                          <InfoCircleOutlined />
                        </Tooltip>
                      </span>
                      <input
                        type="number"
                        className={`"w-[274px] rounded border p-2 ${isDisabledCostPriceField ? "bg-gray-100" : ""}`}
                        value={costPriceInputFieldValue}
                        onChange={(e) => {
                          onChange({
                            target: {
                              name: "total",
                              value: e.target.value,
                              type: "costPrice",
                            },
                          });
                        }}
                        readOnly={isDisabledCostPriceField}
                        disabled={isDisabledCostPriceField}
                      />
                    </div>
                  )}
                </div>
                <div>
                  {!isRenderingFromCatalogEdit ? (
                    <div className="w-44">
                      <Autocomplete
                        options={productConstants?.currencyOption}
                        labelKey="label"
                        valueKey="value"
                        onChange={(e) => {
                          onChange({
                            target: {
                              name: "currency",
                              value: e,
                              type: "costPrice",
                            },
                          });
                        }}
                        value={selectedProduct?.costPrice?.currency || "USD-$"}
                        placeholder={"Currency"}
                        id={`currency`}
                      />
                    </div>
                  ) : (
                    <div>
                      <AutoCompleteSingleSelect
                        options={productConstants?.currencyOption}
                        value={selectedProduct?.costPrice?.currency || "USD-$"}
                        onChange={(e) => {
                          onChange({
                            target: {
                              name: "currency",
                              value: e,
                              type: "costPrice",
                            },
                          });
                        }}
                        labelKey="label"
                        valueKey="value"
                        placeholder="Select an option"
                        labelText="Currency"
                        shouldDeselect={false}
                        error=""
                        infoText="Select a currency for cost price"
                        showNotSelectedError={true}
                        parentClasses="flex flex-col w-[274px]"
                        labelClasses="text-sm text-lightGray font-medium"
                        inputClasses="-mt-1"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <div className="flex rounded-md border border-primaryAccent bg-blue-100">
                <span className="mb-2 ml-2 mr-3 mt-[10px] text-primaryAccent">
                  <InformationCircleIcon className="h-6 w-6 text-primaryAccent" />
                </span>
                <div className="py-3 text-sm text-lightGray">
                  Please Note - Adding component breakdowns with charges will
                  sum up to calculate the Cost Price.
                </div>
              </div>
            </div>
            <div>
              <div>
                <div className="mb-[10px] text-sm font-medium text-lightGray">
                  Component Breakdown
                </div>
                {_.keys(costPriceBreakdown).length > 0 && (
                  <div className="flex flex-col gap-4">
                    {_.keys(costPriceBreakdown).map((key, indx) => {
                      const isBreakdownValueObject =
                        _.isObject(costPriceBreakdown[key]) &&
                        costPriceBreakdown[key] !== null;
                      let displayComponentPrice = isBreakdownValueObject
                        ? costPriceBreakdown[key]?.selectedPrice
                        : costPriceBreakdown[key];
                      if (Number(displayComponentPrice) % 1 !== 0)
                        displayComponentPrice = Number(
                          displayComponentPrice || "",
                        ).toFixed(2);

                      return (
                        <div
                          className="flex w-full items-center justify-between"
                          key={`cost-price-breakdown-${indx}`}>
                          <div
                            className="flex w-5/6 justify-between rounded-md border border-[#DDDFE0] p-4 text-base duration-500"
                            key={`cp-key-val-${indx}`}>
                            <div className="flex w-full items-center space-x-4">
                              <div
                                className={
                                  "w-[380px] overflow-hidden text-ellipsis whitespace-nowrap text-[#111827]"
                                }>
                                {isBreakdownValueObject
                                  ? costPriceBreakdown[key]?.componentName
                                  : key}
                              </div>
                            </div>
                            <div className="flex w-[352px] gap-4">
                              {isBreakdownValueObject && (
                                <>
                                  <div className="flex w-40 items-center space-x-2">
                                    <div>Quantity:</div>
                                    <input
                                      type="number"
                                      className="w-20 rounded border"
                                      value={
                                        costPriceBreakdown[key]
                                          .selectedQuantity || 0
                                      }
                                      min="0"
                                      onChange={(e) => {
                                        updateComponentQuantity(key, e);
                                      }}
                                    />
                                  </div>
                                  <div className="flex w-48 items-center">
                                    UOM: {costPriceBreakdown[key]?.uom}
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="flex w-[130px] text-base">
                              <div className="w-[90px] rounded-l border p-2">
                                {displayComponentPrice}
                              </div>
                              <div className="flex w-[40px] items-center justify-center rounded-r border">
                                $
                              </div>
                            </div>
                          </div>
                          <div className="ml-4 mt-1 w-1/4">
                            <div
                              className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                              onClick={() => {
                                onRemoveCostPriceSalesPrice(key, "costPrice");
                              }}>
                              Remove
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {costPrice.length > 0 &&
                  costPrice.map((costPriceObject, indx) => (
                    <div className="mt-4 w-full" key={`cp-input-${indx}`}>
                      <div className="flex w-3/4 items-center space-x-4">
                        {!isRenderingFromCatalogEdit ? (
                          <>
                            <TextField
                              type="text"
                              id="key"
                              label="Cost Name"
                              placeholder=" "
                              onChange={(e) => onCostPriceChange(e, indx)}
                              value={costPriceObject.key}
                              name="key"
                            />
                            <TextField
                              type="number"
                              id="value"
                              label="Cost"
                              placeholder=" "
                              onChange={(e) => {
                                onCostPriceChange(e, indx);
                              }}
                              value={costPriceObject.value}
                              name="value"
                            />
                          </>
                        ) : (
                          <>
                            <div className="flex flex-col">
                              <span className="mb-1 text-sm font-medium text-lightGray">
                                Cost Name{" "}
                                <Tooltip title="Enter the cost name">
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </span>
                              <input
                                type="text"
                                id="key"
                                className={`"w-[274px] rounded border p-2`}
                                onChange={(e) => onCostPriceChange(e, indx)}
                                value={costPriceObject.key}
                                name="key"
                              />
                            </div>
                            <div className="flex flex-col">
                              <span className="mb-1 text-sm font-medium text-lightGray">
                                Cost{" "}
                                <Tooltip title="Enter the cost">
                                  <InfoCircleOutlined />
                                </Tooltip>
                              </span>
                              <input
                                type="number"
                                id="value"
                                className={`"w-[274px] rounded border p-2`}
                                value={costPriceObject.value}
                                onChange={(e) => {
                                  onCostPriceChange(e, indx);
                                }}
                                name="value"
                              />
                            </div>
                          </>
                        )}
                        <div
                          className={`cursor-pointer pl-2 text-lg font-bold text-primaryAccent ${isRenderingFromCatalogEdit ? "mt-6" : ""}`}
                          onClick={() => onAddCostPrice(costPriceObject, indx)}>
                          Add
                        </div>
                        <div
                          className={`cursor-pointer pl-2 text-lg font-bold text-red-500 ${isRenderingFromCatalogEdit ? "mt-6" : ""}`}
                          onClick={() => onRemoveCostPrice(indx)}>
                          Remove
                        </div>
                      </div>
                    </div>
                  ))}
                <div className="mt-2">
                  {!isRenderingFromCatalogEdit ? (
                    <AddButton
                      text="Add Cost Component"
                      onClick={addBreakdownCost}
                    />
                  ) : (
                    <div
                      className="cursor-pointer text-sm font-semibold leading-6 tracking-normal text-blueBackground"
                      onClick={addBreakdownCost}>
                      <u>Add Cost Component</u>
                    </div>
                  )}
                </div>
              </div>
              <div
                className="mt-4 flex w-full items-center justify-between"
                key={`cost-price-breakdown-discount`}>
                <div
                  className="flex w-5/6 justify-between rounded-md border border-[#DDDFE0] p-4 text-base duration-500"
                  key={`cp-key-val-discount`}>
                  <div className="flex w-full items-center space-x-4">
                    <div
                      className={
                        "w-[380px] overflow-hidden text-ellipsis whitespace-nowrap text-[#111827]"
                      }>
                      Discount
                    </div>
                  </div>
                  <div className="flex w-[130px] text-base">
                    <input
                      type="number"
                      className="w-[90px] rounded-l border p-2"
                      value={selectedProduct?.costPrice?.discount || ""}
                      onChange={(e) => {
                        onChange({
                          target: {
                            name: "discount",
                            value: e.target.value,
                            type: "costPrice",
                          },
                        });
                      }}
                      name="discount"
                    />
                    <div className="flex w-[40px] items-center justify-center rounded-r border">
                      $
                    </div>
                  </div>
                </div>
                <div className="ml-4 mt-1 w-1/4"></div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CostPrice;
