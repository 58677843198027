import React from "react";
import noDataAvailableImage from "../../static/images/noDataAvailable.png";

const NoNotificationsDataAvailable = ({ message, width = "15%" }) => {
  return (
    <div className="my-4 flex w-full flex-col items-center justify-center py-20">
      <img
        src={noDataAvailableImage}
        alt="No User Roles"
        className="mb-1"
        style={{ width: width }}
      />
      <span className="text-base font-semibold text-gray-600">{message}</span>
    </div>
  );
};
export default NoNotificationsDataAvailable;
