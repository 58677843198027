import { createApiThunk } from "#redux/ReduxUtils/graphqlApi";

import {
  START_AMAZON_INBOUND_PLAN_CREATION,
  CONFIRM_PACKING_OPTIONS,
  SET_PACKING_INFORMATION,
  CANCEL_AMAZON_INBOUND_PLAN,
  SCAN_PICKER_BARCODE,
  CONFIRM_PLACEMENT_OPTIONS,
  CONFIRM_DELIVERY_WINDOW_OPTIONS,
  CONFIRM_TRANSPORTATION_OPTIONS,
  REGENERATE_TRANSPORTATION_OPTIONS,
  CONFIRM_PACKING_COMPLETED,
  SCAN_PACKER_BARCODE,
  CONFIRM_PACK_ITEM,
  UPDATE_SHIPMENT_TRACKING_INFORMATION,
  PACK_ITEMS,
  UNPACK_ITEM,
  SET_PACKER_BOXES,
  UNPACK_BOX,
  DUPLICATE_BOX,
  SET_PICKER_BOXES,
  DROP_ITEMS,
} from "#mutations/index";
import {
  GET_ERRORS_IN_INBOUND_PLAN,
  GET_PACKING_OPTIONS,
  GET_BOX_TYPES,
  GET_PLACEMENTS_OPTIONS,
  GET_TRANSPORTATION_OPTIONS,
  GET_DELIVERY_WINDOW_OPTIONS,
  LIST_SHIPMENTS,
} from "#queries/index";

export const startAmazonInboundPlanCreation = createApiThunk(
  "fbaPacking/startAmazonInboundPlanCreation",
  START_AMAZON_INBOUND_PLAN_CREATION,
  (data) => data.startAmazonInboundPlanCreation,
);

export const confirmPackingOptions = createApiThunk(
  "fbaPacking/confirmPackingOptions",
  CONFIRM_PACKING_OPTIONS,
  (data) => data.confirmPackingOption,
);

export const setPackingInformation = createApiThunk(
  "fbaPacking/setPackingInformation",
  SET_PACKING_INFORMATION,
  (data) => data.setPackingInformation,
);

export const getErrorsInInboundPlan = createApiThunk(
  "fbaPacking/getErrorsInInboundPlan",
  GET_ERRORS_IN_INBOUND_PLAN,
  (data) => data.getErrors,
);

export const getPackingOptions = createApiThunk(
  "fbaPacking/getPackingOptions",
  GET_PACKING_OPTIONS,
  (data) => data.getPackingOptions,
);

export const cancelAmazonInboundPlan = createApiThunk(
  "fbaPacking/cancelAmazonInboundPlan",
  CANCEL_AMAZON_INBOUND_PLAN,
  (data) => data.cancelAmazonInboundPlan,
);

export const getBoxTypes = createApiThunk(
  "fbaPacking/getBoxTypes",
  GET_BOX_TYPES,
  (data) => data.getBoxTypes,
);

export const scanBarcode = createApiThunk(
  "fbaPacking/scanBarcode",
  SCAN_PACKER_BARCODE,
  (data) => data.scanPackerBarcode,
);

export const getPlacementsOptions = createApiThunk(
  "fbaPacking/getPlacementsOptions",
  GET_PLACEMENTS_OPTIONS,
  (data) => data.getPlacementOptions,
);

export const getTransportationOptions = createApiThunk(
  "fbaPacking/getTransportationOptions",
  GET_TRANSPORTATION_OPTIONS,
  (data) => data.getTransportationOptions,
);

export const getDeliveryWindowOptions = createApiThunk(
  "fbaPacking/getDeliveryWindowOptions",
  GET_DELIVERY_WINDOW_OPTIONS,
  (data) => data.getDeliveryWindowOptions,
);

export const confirmPlacementOption = createApiThunk(
  "fbaPacking/confirmPlacementOption",
  CONFIRM_PLACEMENT_OPTIONS,
  (data) => data.confirmPlacementOption,
);

export const confirmDeliveryWindowOptions = createApiThunk(
  "fbaPacking/confirmDeliveryWindowOptions",
  CONFIRM_DELIVERY_WINDOW_OPTIONS,
  (data) => data.confirmDeliveryWindowOptions,
);

export const confirmTransportationOptions = createApiThunk(
  "fbaPacking/confirmTransportationOptions",
  CONFIRM_TRANSPORTATION_OPTIONS,
  (data) => data.confirmTransportationOptions,
);

export const listShipments = createApiThunk(
  "fbaPacking/listShipments",
  LIST_SHIPMENTS,
  (data) => data.listShipments,
);

export const regenerateTransportationOptions = createApiThunk(
  "fbaPacking/regenerateTransportationOptions",
  REGENERATE_TRANSPORTATION_OPTIONS,
  (data) => data.regenerateTransportationOptions,
);

export const confirmPackingCompleted = createApiThunk(
  "fbaPacking/confirmPackingCompleted",
  CONFIRM_PACKING_COMPLETED,
  (data) => data.confirmPackingCompleted,
);

export const confirmPackItem = createApiThunk(
  "fbaPacking/confirmPackItem",
  CONFIRM_PACK_ITEM,
  (data) => data.confirmPackItem,
);

export const updateShipmentTrackingInformation = createApiThunk(
  "fbaPacking/updateShipmentTrackingInformation",
  UPDATE_SHIPMENT_TRACKING_INFORMATION,
  (data) => data.updateShipmentTrackingInformation,
);

export const packItems = createApiThunk(
  "fbaPacking/packItems",
  PACK_ITEMS,
  (data) => data.packItems,
);

export const unPackItems = createApiThunk(
  "fbaPacking/unpackItems",
  UNPACK_ITEM,
  (data) => data.unpackItems,
);

export const setPackerBoxes = createApiThunk(
  "fbaPacking/setPackerBoxes",
  SET_PACKER_BOXES,
  (data) => data.setPackerBoxes,
);

export const unPackBox = createApiThunk(
  "fbaPacking/unpackBox",
  UNPACK_BOX,
  (data) => data.unpackBox,
);

export const duplicateBox = createApiThunk(
  "fbaPacking/duplicateBox",
  DUPLICATE_BOX,
  (data) => data.duplicateBox,
);

export const dropItems = createApiThunk(
  "fbaPacking/dropItems",
  DROP_ITEMS,
  (data) => data.dropItems,
);
