import React from "react";
import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import JsBarcode from "jsbarcode";
import QRCode from "react-qr-code";
import ReactDOM from "react-dom";

// NOTE: WE HAVE CHANGED FROM CANVAS LABEL PRINTING TO GENERATING THE BARCODE USING JSBARCODE AND THEN USING JSPDF TO GENERATE THE PDF
export const SUPPORTED_SIZES_WITHOUT_CANVAS = [
  "2x1",
  "3x1",
  "2.25x1.25",
  "2.25x1.57",
];

const LPN_LABELS = ["4x6-LPN"];

const LPN_LABEL_CONSTANTS = {
  PAGE: {
    WIDTH: 6, // inches
    HEIGHT: 4, // inches
  },
  QR_CODE: {
    WIDTH: 140, // pixels
    HEIGHT: 140, // pixels
    SIZE_IN_INCHES: 2,
    X_OFFSET_DIVISOR: 2.2, // Controls horizontal centering
    Y_POSITION: 0,
  },
  TEXT_POSITIONS: {
    CODE_Y: 1.8,
    NESTED_FORM_FACTOR_Y: 2.1,
    SKU_Y: 2.4,
    DESCRIPTION_START_Y: 2.8,
    DESCRIPTION_LINE_HEIGHT: 0.35,
    CUSTOMER_Y: 3.4,
    DETAILS_START_Y: 3.6,
    DETAILS_LINE_HEIGHT: 0.2,
  },
  FONT_SIZES: {
    CODE: 14,
    SKU: 18,
    DESCRIPTION: 18,
    CUSTOMER: 14,
    DETAILS: 10,
  },
  MAX_WIDTHS: {
    DESCRIPTION: 5, // inches
    DETAILS: 5, // inches
  },
  MAX_LINES: {
    DESCRIPTION: 2,
    DETAILS: 3,
  },
};

export const printCanvasLabelPdf = async (
  code,
  noOfCopies = 1,
  message = "Printing label",
  printer = null,
  dimensions = "2x1",
  appState = null,
  print = true,
  text1,
  text2,
) => {
  if (!code) {
    return appState && appState.setAlert("Could not print", "error", 5000);
  }

  if (SUPPORTED_SIZES_WITHOUT_CANVAS.includes(dimensions)) {
    // Generate a unique ID for internal use
    const uniqueId = `barcode_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;

    // Create a hidden container if it doesn't exist
    let container = document.getElementById("hidden_barcode_container");
    if (!container) {
      container = document.createElement("div");
      container.id = "hidden_barcode_container";
      container.style.display = "none";
      document.body.appendChild(container);
    }

    // Create the canvas element
    let canvas = document.createElement("canvas");
    canvas.id = uniqueId;
    container.appendChild(canvas);

    try {
      // Generate the barcode
      JsBarcode(`#${uniqueId}`, code, {
        format: "code128",
        width: 2,
        height: 100,
        displayValue: false,
      });
    } catch (error) {
      container.removeChild(canvas);
      return (
        appState && appState.setAlert("Error generating barcode", "error", 5000)
      );
    }

    const {
      width,
      height,
      defaultFontSize,
      fontSizeWithAdditionalText,
      defaulltBarcodeRatio,
      barcodeRatioWithAdditionalText,
      horizontalBorder,
      verticalBorder,
      textTruncateLength,
    } = dimensionVariables[dimensions];

    const fontSize = text1 ? fontSizeWithAdditionalText : defaultFontSize;
    const barcodeRatio = text1
      ? barcodeRatioWithAdditionalText
      : defaulltBarcodeRatio;

    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [width, height],
    });

    let vCursor = verticalBorder;
    let hCursor = horizontalBorder;
    let barcodesizeH = width - 2 * horizontalBorder;
    let barcodesizeV = barcodesizeH / barcodeRatio;

    // Add the barcode image to the PDF
    doc.addImage(
      canvas.toDataURL(),
      "PNG",
      hCursor,
      vCursor,
      barcodesizeH,
      barcodesizeV,
    );
    doc.setFontSize(fontSize);
    const linespace = 3 / 100;
    vCursor = vCursor + barcodesizeV + fontSize / 100;
    doc.text(code, width / 2, vCursor, { align: "center" });
    if (text1) {
      vCursor = vCursor + linespace * 2 + fontSize / 100;
      doc.text(truncate(text1, textTruncateLength), horizontalBorder, vCursor);
    }
    if (text2) {
      vCursor = vCursor + linespace + fontSize / 100;
      doc.text(truncate(text2, textTruncateLength), horizontalBorder, vCursor);
    }

    // Clean up
    container.removeChild(canvas);

    // @ts-ignore
    if (print === false) {
      doc.save(`${code}.pdf`);
      return appState && appState.removeLoading();
    } else {
      const out = doc.output("datauristring");
      const cpj = new JSPM.ClientPrintJob();
      let myPrinter;
      if (printer) {
        myPrinter = new JSPM.InstalledPrinter(printer);
      } else {
        myPrinter = new JSPM.DefaultPrinter();
      }
      cpj.clientPrinter = myPrinter;
      for (let i = 0; i < noOfCopies; i++) {
        const myFile = new JSPM.PrintFilePDF(
          out,
          JSPM.FileSourceType.URL,
          `${code}_${i}.pdf`,
          1,
        );
        cpj.files.push(myFile);
      }
      cpj.sendToClient();
      return appState && appState.removeLoading();
    }
  }

  if (LPN_LABELS.includes(dimensions)) {
    const doc = new jsPDF({
      orientation: "landscape",
      unit: "in",
      format: [LPN_LABEL_CONSTANTS.PAGE.WIDTH, LPN_LABEL_CONSTANTS.PAGE.HEIGHT],
    });

    const qrElement = document.getElementById(`qrcode_${code}`);
    qrElement.style.width = `${LPN_LABEL_CONSTANTS.QR_CODE.WIDTH}px`;
    qrElement.style.height = `${LPN_LABEL_CONSTANTS.QR_CODE.HEIGHT}px`;
    qrElement.style.backgroundColor = "white";

    try {
      const dataUrl = await htmlToImage.toPng(qrElement, {
        quality: 1,
        backgroundColor: "#ffffff",
        pixelRatio: 2,
        cacheBust: true,
      });

      if (!dataUrl || dataUrl === "data:,") {
        throw new Error("Invalid QR code image generated");
      }

      const xPos =
        (LPN_LABEL_CONSTANTS.PAGE.WIDTH -
          LPN_LABEL_CONSTANTS.QR_CODE.SIZE_IN_INCHES) /
        LPN_LABEL_CONSTANTS.QR_CODE.X_OFFSET_DIVISOR;

      doc.addImage(
        dataUrl,
        "PNG",
        xPos,
        LPN_LABEL_CONSTANTS.QR_CODE.Y_POSITION,
        LPN_LABEL_CONSTANTS.QR_CODE.SIZE_IN_INCHES,
        LPN_LABEL_CONSTANTS.QR_CODE.SIZE_IN_INCHES,
      );

      doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.CODE);
      doc.text(
        code,
        LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
        LPN_LABEL_CONSTANTS.TEXT_POSITIONS.CODE_Y,
        { align: "center" },
      );

      if (text1?.nestedFormFactor) {
        doc.text(
          text1.nestedFormFactor,
          LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
          LPN_LABEL_CONSTANTS.TEXT_POSITIONS.NESTED_FORM_FACTOR_Y,
          { align: "center" },
        );
      }
      if (text1?.includeTrackingDetails) {
        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.SKU);
        doc.setFont("arial", "bold");
        if (text1.sku) {
          doc.text(
            text1.sku,
            LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
            LPN_LABEL_CONSTANTS.TEXT_POSITIONS.SKU_Y,
            { align: "center" },
          );
        }

        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.DESCRIPTION);
        if (text1.name) {
          const lines = doc.splitTextToSize(
            text1.name,
            LPN_LABEL_CONSTANTS.MAX_WIDTHS.DESCRIPTION,
          );
          const textLines = lines.slice(
            0,
            LPN_LABEL_CONSTANTS.MAX_LINES.DESCRIPTION,
          );
          textLines.forEach((line, index) => {
            doc.text(
              line,
              LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
              LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DESCRIPTION_START_Y +
                index *
                  LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DESCRIPTION_LINE_HEIGHT,
              { align: "center" },
            );
          });
        }

        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.CUSTOMER);
        doc.setFont("arial", "normal");

        if (text1.customer) {
          doc.text(
            text1.customer,
            LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
            LPN_LABEL_CONSTANTS.TEXT_POSITIONS.CUSTOMER_Y,
            { align: "center" },
          );
        }

        // Volume and Alcohol (adjust position based on Client position)
        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.DETAILS);
        const stringToDisplay = [];

        if (text1.lotId) {
          stringToDisplay.push(`Lot ID: ${text1.lotId}`);
        }
        if (text1.bestByDate) {
          stringToDisplay.push(`Expiry Date: ${text1.bestByDate}`);
        }
        if (text1.serialNumber) {
          stringToDisplay.push(`Serial #: ${text1.serialNumber}`);
        }
        if (stringToDisplay.length > 0) {
          const lines = doc.splitTextToSize(
            stringToDisplay.join(" | "),
            LPN_LABEL_CONSTANTS.MAX_WIDTHS.DETAILS,
          );
          const textLines = lines.slice(
            0,
            LPN_LABEL_CONSTANTS.MAX_LINES.DETAILS,
          );
          textLines.forEach((line, index) => {
            doc.text(
              line,
              LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
              LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DETAILS_START_Y +
                index * LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DETAILS_LINE_HEIGHT,
              { align: "center" },
            );
          });
        }
      }

      // Handle print or save
      if (print === false) {
        doc.save(`${code}.pdf`);
        return appState && appState.removeLoading();
      } else {
        const out = doc.output("datauristring");
        const cpj = new JSPM.ClientPrintJob();
        let myPrinter = printer
          ? new JSPM.InstalledPrinter(printer)
          : new JSPM.DefaultPrinter();

        cpj.clientPrinter = myPrinter;
        for (let i = 0; i < noOfCopies; i++) {
          const myFile = new JSPM.PrintFilePDF(
            out,
            JSPM.FileSourceType.URL,
            `${code}_${i}.pdf`,
            1,
          );
          cpj.files.push(myFile);
        }
        cpj.sendToClient();
        return appState && appState.removeLoading();
      }
    } catch (error) {
      console.error("LPN label generation error:", error);
      return (
        appState &&
        appState.setAlert(
          `Error generating QR code: ${error.message}`,
          "error",
          5000,
        )
      );
    }
  }

  try {
    let widthOfPdf = parseInt(dimensions.split("x")[0]);
    let heightOfPdf = parseInt(dimensions.split("x")[1]);
    const dataUrl = await htmlToImage
      .toPng(document.getElementById(code), {
        quality: 1,
        cacheBust: true,
      })
      .catch((error) => {
        console.error("htmlToImage error:", error);
        throw error;
      });

    // Validate data URL
    if (!dataUrl || !dataUrl.startsWith("data:image/png")) {
      throw new Error("Invalid PNG data generated");
    }

    const pdf = new jsPDF({
      orientation: widthOfPdf > heightOfPdf ? "landscape" : "portrait",
      unit: "in",
      format: [widthOfPdf, heightOfPdf],
    });
    var width = pdf.internal.pageSize.getWidth();
    var height = pdf.internal.pageSize.getHeight();
    pdf.addImage(dataUrl, "PNG", 0, 0, width, height);

    if (print === false) {
      pdf.save(`${code}.pdf`);
      appState && appState.removeLoading();
      return;
    }
    const out = pdf.output("datauristring");
    const cpj = new JSPM.ClientPrintJob();
    let myPrinter;
    if (printer) {
      myPrinter = new JSPM.InstalledPrinter(printer);
    } else {
      myPrinter = new JSPM.DefaultPrinter();
    }
    cpj.clientPrinter = myPrinter;
    for (let i = 0; i < noOfCopies; i++) {
      const myFile = new JSPM.PrintFilePDF(
        out,
        JSPM.FileSourceType.URL,
        `${code}_${i}.pdf`,
        1,
      );
      cpj.files.push(myFile);
    }
    cpj.sendToClient();
  } catch (err) {
    throw err;
  }
};

// NOTE: THESE ARE THE VARIOUS SETTINGS VARIABLES FOR THE DIFFERENT LABEL SIZES SUPPORTED BY THE JSBARCODE PRINTING METHOD INTRODUCED ABOVE
const dimensionVariables = {
  "2.25x1.25": {
    width: 2.25,
    height: 1.25,
    defaultFontSize: 14,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 3,
    barcodeRatioWithAdditionalText: 4,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "2x1": {
    width: 2,
    height: 1,
    defaultFontSize: 12,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 3,
    barcodeRatioWithAdditionalText: 4,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "3x1": {
    width: 3,
    height: 1,
    defaultFontSize: 14,
    fontSizeWithAdditionalText: 10,
    defaulltBarcodeRatio: 5,
    barcodeRatioWithAdditionalText: 6,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
  "2.25x1.57": {
    width: 2.25,
    height: 1.57,
    defaultFontSize: 12,
    fontSizeWithAdditionalText: 12,
    defaulltBarcodeRatio: 2,
    barcodeRatioWithAdditionalText: 3,
    horizontalBorder: 0.125,
    verticalBorder: 0.125,
    textTruncateLength: 30,
  },
};

const truncate = (str, maxLength) => {
  return str && str.length > maxLength
    ? str.substring(0, maxLength) + "..."
    : str;
};
