import { useEffect, useState, useContext } from "react";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import {
  GET_NOTIFICATIONS_ENTITIES,
  GET_NOTIFICATIONS_PREFERENCES,
  GET_ENTITY_TYPES,
} from "#queries";
import { SAVE_NOTIFICATION_PREFERENCES } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import {
  ChevronRightIcon,
  InformationCircleIcon,
  MailIcon,
} from "@heroicons/react/outline";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import CustomSwitch from "#newUiComponents/commons/CustomSwitch";
import { Tooltip } from "antd";
import { twMerge } from "tailwind-merge";
import { capitalizeLabel } from "#utils/helper-functions";
import CheckBox from "#newUiComponents/commons/CheckBox";
import NoNotificationsDataAvailable from "#newUiComponents/notificationV2/NoNotificationsDataAvailable";

const DELIVERY_CHANNEL_ENUM = {
  EMAIL: "Email",
};

export const ALERT_VISIBILITY_IN_5000_MS = 5000;

const NotificationPreferencesDashboard = ({}) => {
  const appState = useContext(AppStateContext);
  const auth = useContext(AuthContext);
  const notificationEventsByEntitiesQuery = useQuery(
    GET_NOTIFICATIONS_ENTITIES,
  );
  const notificationPreferencesQuery = useQuery(GET_NOTIFICATIONS_PREFERENCES);
  const saveNotificationsPreferencesQuery = useQuery(
    SAVE_NOTIFICATION_PREFERENCES,
  );
  const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
  const [notificationPreferences, setNotificationPreferences] = useState(null);
  const [selectedNotificationPreferences, setSelectedNotificationPreferences] =
    useState(null);
  const [entityTypeId, setEntityTypeId] = useState(null);

  const handleChange = (event, subcategoryIndex, eventIndex = null) => {
    setSelectedNotificationPreferences((prev) => {
      const updatedPreferences = { ...prev };
      const updatedSubcategories = [...updatedPreferences.subcategories];

      if (eventIndex !== null) {
        const updatedEvents = [
          ...updatedSubcategories[subcategoryIndex].events,
        ];
        updatedEvents[eventIndex].isEnabled =
          !updatedEvents[eventIndex].isEnabled;

        const allEventsEnabled = updatedEvents.every(
          (event) => event.isEnabled,
        );
        updatedSubcategories[subcategoryIndex].events = updatedEvents;
        updatedSubcategories[subcategoryIndex].isEnabled = allEventsEnabled;
      } else {
        const isSubcategoryEnabled =
          !updatedSubcategories[subcategoryIndex].isEnabled;
        updatedSubcategories[subcategoryIndex].isEnabled = isSubcategoryEnabled;

        updatedSubcategories[subcategoryIndex].events = updatedSubcategories[
          subcategoryIndex
        ].events.map((event) => ({
          ...event,
          isEnabled: isSubcategoryEnabled,
        }));
      }

      updatedPreferences.subcategories = updatedSubcategories;
      return updatedPreferences;
    });
  };

  const fetchNotificationsByEntities = async () => {
    try {
      const payload = { role: auth?.user?.role };

      appState.setLoading();

      const NotificationEventsByEntitiesResponse =
        await notificationEventsByEntitiesQuery.fetchData(payload);

      if (NotificationEventsByEntitiesResponse?.error) {
        setNotificationPreferences(null);
        return;
      }

      setNotificationPreferences(
        NotificationEventsByEntitiesResponse?.data
          ?.listNotificationEventsByEntities?.entities ?? [],
      );
    } catch (error) {
      setNotificationPreferences(null);
    } finally {
      appState.removeLoading();
    }
  };

  const fetchNotificationsPreferences = async (notification) => {
    if (notification) {
      try {
        appState.setLoading();
        const notificationPreferencesResponse =
          await notificationPreferencesQuery.fetchData();

        if (notificationPreferencesResponse?.error) {
          setSelectedNotificationPreferences(notification);
          return;
        }

        if (notificationPreferencesResponse?.data) {
          const entities =
            notificationPreferencesResponse?.data?.listNotificationPreferences
              ?.entities ?? [];

          setSelectedNotificationPreferences(
            optimizeNotificationPreferences(notification, entities) || null,
          );
        }
      } catch (error) {
      } finally {
        appState.removeLoading();
      }
    }
  };

  const optimizeNotificationPreferences = (notification, entities) => {
    const updateTemplate = (event, selectedEvent) =>
      event.template?.map((templateData) => {
        const selectedDeliveryChannel =
          selectedEvent?.channels?.find(
            (channel) =>
              channel?.templateId === templateData?.id &&
              channel?.channelName === templateData?.deliveryChannel,
          ) ?? null;
        return {
          ...templateData,
          messageRecipient:
            templateData?.deliveryChannel === DELIVERY_CHANNEL_ENUM?.EMAIL
              ? selectedDeliveryChannel?.messageRecipient || auth?.user?.email
              : null,
        };
      });

    const updateEvents = (subcategory) =>
      subcategory.events.map((event) => {
        const selectedEvent = entities?.find(
          (entity) => entity?.eventId === event?.id,
        );
        const template = updateTemplate(event, selectedEvent);

        return {
          ...event,
          isEnabled: selectedEvent?.isEnabled || false,
          template: template || [],
        };
      });

    const updateSubcategories = (notification) =>
      notification.subcategories.map((subcategory) => {
        const updatedEvents = updateEvents(subcategory);
        const isEnabledAll = updatedEvents.every((event) => event.isEnabled);

        return {
          ...subcategory,
          events: updatedEvents,
          isEnabled: isEnabledAll,
        };
      });

    return {
      ...notification,
      subcategories: updateSubcategories(notification),
    };
  };

  const handleSaveNotificationPreferencesRequest = async () => {
    if (
      selectedNotificationPreferences &&
      selectedNotificationPreferences?.subcategories
    ) {
      const requestNotificationPreferencesInput = {
        preferences: selectedNotificationPreferences?.subcategories?.flatMap(
          (subcategory) =>
            subcategory?.events?.map((event) => ({
              eventId: event?.id,
              channels: event?.template?.map((template) => ({
                templateId: template?.id,
                channelName: template?.deliveryChannel,
                messageRecipient: template?.messageRecipient,
              })),
              isEnabled: event?.isEnabled,
            })),
        ),
        entityTypeId: entityTypeId,
      };

      if (requestNotificationPreferencesInput) {
        appState.setLoading();
        const saveNotificationsPreferencesResponse =
          await saveNotificationsPreferencesQuery.fetchData({
            saveNotificationPreferencesInput:
              requestNotificationPreferencesInput,
          });
        appState.removeLoading();
        if (saveNotificationsPreferencesResponse.error) {
          if (
            saveNotificationsPreferencesResponse?.error &&
            saveNotificationsPreferencesResponse?.error?.message
          ) {
            appState.setAlert(
              saveNotificationsPreferencesResponse?.error?.message,
              "error",
              ALERT_VISIBILITY_IN_5000_MS,
            );
            return false;
          }
        } else if (saveNotificationsPreferencesResponse.data) {
          appState.setAlert(
            saveNotificationsPreferencesResponse.data
              ?.saveNotificationPreferences.message,
            "success",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          setSelectedNotificationPreferences(null);
          fetchNotificationsByEntities();
        }
      }
    }
  };

  const fetchEntityTypes = async () => {
    if (!auth?.user?.role) return;

    try {
      const payload = {
        filters: {
          entityParent: ["USER_ROLE"],
          name: [auth.user.role],
        },
      };

      appState.setLoading();

      const entityTypesResponse = await entityTypesQuery.fetchData(payload);

      if (
        entityTypesResponse?.error ||
        !entityTypesResponse?.data?.entityTypes?.length
      ) {
        setEntityTypeId(null);
        return;
      }

      const entityTypeId = entityTypesResponse.data.entityTypes[0]?.id ?? null;
      setEntityTypeId(entityTypeId);
    } catch (error) {
      setEntityTypeId(null);
    } finally {
      appState.removeLoading();
    }
  };

  useEffect(() => {
    fetchNotificationsByEntities();
    fetchEntityTypes();
  }, []);

  if (notificationPreferences?.length === 0) {
    return (
      <NoNotificationsDataAvailable
        width="25%"
        message="No preferences have been assigned"
      />
    );
  }

  return (
    <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-0 font-inter">
      <main>
        {!selectedNotificationPreferences && (
          <div className="mt-4 h-full w-full space-y-4 px-0">
            {notificationPreferences &&
              notificationPreferences.map((notification, index) => (
                <div
                  className="flex w-full flex-1 flex-row gap-4 px-0"
                  key={"Notify" + index}>
                  <div
                    onClick={() => fetchNotificationsPreferences(notification)}
                    className="flex w-4/5 cursor-pointer items-center rounded-lg border px-6 py-6 shadow-sm transition hover:shadow-md">
                    <div className="flex flex-1 items-center justify-between">
                      <div>
                        <h3 className="text-lg font-semibold">
                          {notification?.category}
                        </h3>
                      </div>
                    </div>
                    <div className="ml-4 font-semibold">
                      <ChevronRightIcon className="h-4 w-4" />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        )}
        {selectedNotificationPreferences && (
          <div className="mt-4 h-full w-full space-y-4 px-0">
            <div className="flex items-center">
              <div className="flex-1">
                <HeaderWithArrow
                  headerTitle={`${selectedNotificationPreferences ? selectedNotificationPreferences?.category : ""}`}
                  description=""
                  isArrow={selectedNotificationPreferences ? true : false}
                  arrowAction={() => {
                    setSelectedNotificationPreferences(null);
                    fetchNotificationsByEntities();
                  }}
                  isLearnMore={false}
                  titleClasses="text-lg font-semibold"
                />
              </div>
            </div>

            {selectedNotificationPreferences &&
              selectedNotificationPreferences?.subcategories && (
                <div className="!mt-0 w-full space-y-8">
                  {selectedNotificationPreferences?.subcategories?.map(
                    (parentEventData, rootIndex) => (
                      <div
                        key={"Sub Notify" + rootIndex}
                        className="w-full pr-4">
                        <div className="mb-4 flex items-center justify-between font-semibold text-black">
                          <span className="text-base font-medium">
                            {capitalizeLabel(parentEventData?.subcategory)}
                          </span>
                          <div className="flex items-center justify-center">
                            <div onClick={(event) => event.stopPropagation()}>
                              <CustomSwitch
                                leftSideText=""
                                rightSideText={`${parentEventData?.isEnabled ? "Disable All Notification" : "Enable All Notification"}`}
                                checked={parentEventData?.isEnabled}
                                onChange={(value) => {
                                  handleChange(
                                    {
                                      target: {
                                        name: "isEnabled",
                                        value: value,
                                        type: "checkbox",
                                      },
                                    },
                                    rootIndex,
                                  );
                                }}
                                labelClasses="text-gray-700 font-medium mr-2 text-sm"
                                switchClasses="w-10 h-6"
                                handleClasses="w-4 h-4"
                                rightColor="bg-primaryAccent"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="space-y-6">
                          {parentEventData?.events &&
                            parentEventData?.events?.map(
                              (childEventData, index) => (
                                <div
                                  key={childEventData?.key}
                                  className="w-full border-t px-0 pb-0 pt-2 text-gray-600">
                                  <div className="flex flex-col items-start justify-start space-y-4">
                                    <div className="flex w-full items-center justify-between font-semibold text-black">
                                      <div className="flex flex-col items-start justify-start">
                                        <span className="text-sm font-normal">
                                          {childEventData?.key}
                                        </span>
                                        <span className="mt-2 text-sm font-light text-gray-400">
                                          {childEventData?.description}
                                        </span>
                                      </div>
                                      <div className="flex items-center justify-center">
                                        <div
                                          onClick={(event) =>
                                            event.stopPropagation()
                                          }>
                                          <CustomSwitch
                                            leftSideText=""
                                            rightSideText={`${childEventData?.isEnabled ? "Disable Notification" : "Enable Notification"}`}
                                            checked={childEventData?.isEnabled}
                                            onChange={(value) => {
                                              handleChange(
                                                {
                                                  target: {
                                                    name: "isEnabled",
                                                    value: value,
                                                    type: "checkbox",
                                                  },
                                                },
                                                rootIndex,
                                                index,
                                              );
                                            }}
                                            labelClasses="text-gray-700 font-medium mr-2 text-sm"
                                            switchClasses="w-10 h-6"
                                            handleClasses="w-4 h-4"
                                            rightColor="bg-primaryAccent"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="w-full space-y-4">
                                      <div className="flex flex-col items-center">
                                        <div className="mb-2 flex w-full items-center text-sm text-gray-700">
                                          Additional Delivery Channels{" "}
                                          <Tooltip title="Additional Delivery Channels">
                                            <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                                          </Tooltip>
                                        </div>
                                        <div className="flex w-full gap-4">
                                          {childEventData &&
                                            childEventData?.template?.map(
                                              (templateData, index) => {
                                                if (
                                                  templateData?.deliveryChannel ===
                                                  DELIVERY_CHANNEL_ENUM?.EMAIL
                                                ) {
                                                  return (
                                                    <button
                                                      key={index + "Channel"}
                                                      className={twMerge(
                                                        `flex cursor-pointer items-center justify-center space-x-2 rounded-md border px-4 py-2 text-sm text-gray-900 ${
                                                          templateData?.messageRecipient
                                                            ? "border-primaryAccent bg-blue-50"
                                                            : "border-gray-300"
                                                        }`,
                                                      )}
                                                      onClick={() => {}}>
                                                      <CheckBox
                                                        role="checkbox"
                                                        name="channel"
                                                        value={true}
                                                        checked={
                                                          templateData?.messageRecipient
                                                            ? true
                                                            : false
                                                        }
                                                        className="h-4 w-4"
                                                      />
                                                      <span className="flex flex-row items-center">
                                                        <MailIcon className="mr-2 h-5 w-5 text-gray-900" />
                                                        <span>Email</span>
                                                      </span>
                                                    </button>
                                                  );
                                                }
                                                return null;
                                              },
                                            )}
                                        </div>
                                      </div>
                                      {childEventData &&
                                        childEventData?.template?.map(
                                          (templateData) => {
                                            if (
                                              templateData?.deliveryChannel ===
                                              DELIVERY_CHANNEL_ENUM?.EMAIL
                                            ) {
                                              return (
                                                <div
                                                  className="flex flex-row items-center"
                                                  key={
                                                    index + "messageRecipient"
                                                  }>
                                                  <MailIcon className="mr-2 h-6 w-6 text-gray-500" />
                                                  <span className="rounded-md bg-gray-50 px-2 py-1 text-sm font-normal text-gray-500">
                                                    {
                                                      templateData?.messageRecipient
                                                    }
                                                  </span>
                                                </div>
                                              );
                                            }
                                            return null;
                                          },
                                        )}
                                    </div>
                                  </div>
                                </div>
                              ),
                            )}
                        </div>
                      </div>
                    ),
                  )}
                </div>
              )}
          </div>
        )}
      </main>
      {selectedNotificationPreferences && (
        <footer className="mt-auto w-full flex-shrink-0 px-4">
          <div className="mt-4 flex w-full items-center justify-end gap-4 space-x-4 bg-white">
            <button
              onClick={handleSaveNotificationPreferencesRequest}
              className={twMerge(
                `rounded-md px-4 py-2 text-sm font-semibold text-white ${
                  false
                    ? "pointer-events-none cursor-not-allowed border bg-gray-400"
                    : "cursor-pointer bg-primaryAccent"
                }`,
              )}>
              Save Changes
            </button>
          </div>
        </footer>
      )}
    </div>
  );
};

export default NotificationPreferencesDashboard;
