import React, { useState } from "react";
import NewModal from "#newUiComponents/commons/NewModal";
import CustomTable from "#newUiComponents/commons/CustomTable";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  PrinterIcon,
  DownloadIcon,
} from "@heroicons/react/outline";
import SlideOverPanel from "#components/common/SlideOverPanel";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import CustomNotification from "#newUiComponents/commons/CustomNotification";

const FbaOrderDetailsModal = ({
  isOpen,
  onClose,
  orderId,
  shipments,
  setOpen,
}) => {
  const [expandedOrder, setExpandedOrder] = useState(null);
  const notify = CustomNotification();

  const handleGetFormattedAddress = (address) => {
    if (!address) return "";

    const parts = [
      address.line1,
      address.line2,
      address.city,
      address.state,
      address.zip,
      address.country,
    ].filter((part) => part && part.trim() !== "");

    return parts.join(", ");
  };

  const handlePrint = async (urls, type = "shipping") => {
    try {
      if (!urls || (Array.isArray(urls) && urls.length === 0)) {
        notify.error("No labels available to print");
        return;
      }

      // Convert single URL to array for consistent handling
      const urlArray = Array.isArray(urls) ? urls : [urls];

      // Function to print a single PDF
      const printPdf = async (url) => {
        const response = await fetch(url);
        const blob = await response.blob();
        const pdfUrl = URL.createObjectURL(blob);

        const printWindow = window.open(pdfUrl);

        printWindow.onload = () => {
          printWindow.print();
          // Clean up after printing
          printWindow.onafterprint = () => {
            URL.revokeObjectURL(pdfUrl);
            printWindow.close();
          };
        };
      };

      // Print each PDF sequentially
      for (const url of urlArray) {
        await printPdf(url);
      }

      notify.success(
        `${type === "shipping" ? "Shipping" : "Box"} label${urlArray.length > 1 ? "s" : ""} sent to printer`,
      );
    } catch (error) {
      console.error("Print error:", error);
      notify.error(
        `Failed to print ${type} label${Array.isArray(urls) && urls.length > 1 ? "s" : ""}`,
      );
    }
  };

  const handleDownload = async (urls, type = "shipping") => {
    try {
      if (!urls || (Array.isArray(urls) && urls.length === 0)) {
        notify.error("No labels available to download");
        return;
      }

      const urlArray = Array.isArray(urls) ? urls : [urls];

      for (const [index, url] of urlArray.entries()) {
        const link = document.createElement("a");
        link.href = url;
        link.download = `${type}_label_${index + 1}.pdf`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }

      notify.success(
        `${type === "shipping" ? "Shipping" : "Box"} label${urlArray.length > 1 ? "s" : ""} downloaded`,
      );
    } catch (error) {
      console.error("Download error:", error);
      notify.error(
        `Failed to download ${type} label${Array.isArray(urls) && urls.length > 1 ? "s" : ""}`,
      );
    }
  };

  const columns = [
    {
      key: "shipmentId",
      title: "Shipment IDs",
      render: (value) => <span>{value}</span>,
    },
    {
      key: "destinationAddress",
      title: "Destination Address",
      render: (_, row) => (
        <span>{handleGetFormattedAddress(row.shipToAddress)}</span>
      ),
    },
    {
      key: "carrier",
      title: "Carrier",
      render: (value) => <span>{value || "-"}</span>,
    },
    {
      key: "status",
      title: "Status",
      render: (value) => (
        <span className="rounded-full bg-green-100 px-2 py-1 capitalize text-green-600">
          {(value || "")?.toLowerCase()}
        </span>
      ),
    },
    {
      key: "boxLabels",
      title: "Box Labels",
      render: (value) => (
        <div className="flex items-center gap-2">
          <PrinterIcon
            className="hover:text-primaryAccent-dark h-5 w-5 cursor-pointer text-primaryAccent"
            onClick={() => handlePrint(value, "box")}
          />
          <DownloadIcon
            className="hover:text-primaryAccent-dark h-5 w-5 cursor-pointer text-primaryAccent"
            onClick={() => handleDownload(value, "box")}
          />
        </div>
      ),
    },
    {
      key: "shippingLabel",
      title: "Shipping Label",
      render: (value) => (
        <div className="flex items-center gap-2">
          <PrinterIcon
            className="hover:text-primaryAccent-dark h-5 w-5 cursor-pointer text-primaryAccent"
            onClick={() => handlePrint(value, "shipping")}
          />
          <DownloadIcon
            className="hover:text-primaryAccent-dark h-5 w-5 cursor-pointer text-primaryAccent"
            onClick={() => handleDownload(value, "shipping")}
          />
        </div>
      ),
    },
  ];

  return (
    <SlideOverPanel
      open={isOpen}
      setOpen={(open) => setOpen(open)}
      containerStyle={"max-w-6xl"}
      isCrossIconVisible={false}
      title={
        <HeaderWithArrow
          headerTitle="Orders in the Inbound Plan"
          description="Clicking on an Order ID will redirect you to the Order Details page within Order Management for more information."
          isLearnMore={false}
          arrowAction={() => setOpen(false)}
          isArrow
          mainClasses="mb-0"
        />
      }>
      {" "}
      {/* Increased width to accommodate new columns */}
      <div className="p-4 font-inter">
        <p className="mb-4 text-sm">Orders: {orderId?.length || 0}</p>
        {orderId?.map((order) => (
          <div key={order} className="mb-4 rounded border border-gray-200">
            <div
              className="flex cursor-pointer items-center justify-between p-4"
              onClick={() =>
                setExpandedOrder(expandedOrder === order ? null : order)
              }>
              <div>
                <p className="text-base font-medium">{order}</p>
                <p className="text-sm text-gray-500">
                  Total no. of shipments: {shipments?.length || 0}
                </p>
              </div>
              {expandedOrder === order ? (
                <ChevronUpIcon className="h-5 w-5 text-gray-500" />
              ) : (
                <ChevronDownIcon className="h-5 w-5 text-gray-500" />
              )}
            </div>

            {expandedOrder === order && (
              <div className="border-t p-4">
                <CustomTable
                  columns={columns}
                  data={shipments || []}
                  isSearchable={false}
                  isFilters={false}
                  isPagination={false}
                />
              </div>
            )}
          </div>
        ))}
      </div>
    </SlideOverPanel>
  );
};

export default FbaOrderDetailsModal;
