import { useState, useEffect, useContext } from "react";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import AutocompleteSingleSelectDropdown from "#components/utils/AutocompleteSingleSelectDropdown";
import { handlePositiveValue } from "#utils/helper-functions";
import ProductVariants from "#components/products/ProductVariants";

const CatalogProductInfoForm = ({
  product,
  setProduct,
  productCategories,
  saveProduct,
  onChange,
  validateCostPriseSalesPrice,
  getProductVariantComponents,
  allVariantComponents,
}) => {
  const appState = useContext(AppStateContext);
  const [componentsCost, setComponentsCost] = useState([]);
  const sourceIsNotHopStack =
    product?.id && (product?.source || "").toLowerCase() !== "hopstack";

  const costPriceBreakdown = _.omit(product?.costPrice, [
    "total",
    "currency",
    "discount",
  ]);

  const totalCostValue =
    _.values(costPriceBreakdown).reduce((initVal, nxtVal) => {
      if (_.isObject(nxtVal) && nxtVal !== null)
        return initVal + Number(nxtVal?.selectedPrice) * 100;
      else return initVal + Number(nxtVal) * 100;
    }, 0) / 100;

  let costPriceWithDiscount = totalCostValue || product?.costPrice?.total;
  if (product?.costPrice?.discount) {
    costPriceWithDiscount =
      (Number(costPriceWithDiscount * 100) -
        Number(product.costPrice.discount * 100)) /
      100;
  }

  const removeComponentVariant = (id, key) => {
    onChange({
      target: {
        name: id,
        value: "delete",
        type: key,
      },
    });
  };

  const addComponentLevelCost = () => {
    const componentsCostData = [...componentsCost];
    componentsCostData.push({});
    setComponentsCost(componentsCostData);
  };

  const onRemoveComponentCost = (index) => {
    const componentsCostData = [...componentsCost];
    componentsCostData.splice(index, 1);
    setComponentsCost(componentsCostData);
  };

  const addComponentCostInCostPrice = (componentCostObject, index) => {
    const selectedComponentCost = { ...componentCostObject };
    if (!_.isObject(product?.costPrice?.[selectedComponentCost?.id])) {
      if (selectedComponentCost?.selectedQuantity > 0) {
        selectedComponentCost.selectedPrice =
          ((Number(selectedComponentCost?.price || "") /
            Number(selectedComponentCost?.quantity || "")) *
            Number(selectedComponentCost?.selectedQuantity || "")) /
          100;
        onChange({
          target: {
            name: selectedComponentCost.id,
            value: selectedComponentCost,
            type: "costPrice",
          },
        });
        onRemoveComponentCost(index);
      } else {
        appState.setAlert(
          "Please enter quantity greater than zero",
          "error",
          5000,
        );
      }
    }
  };

  const getSelectedProductVariantComponent = () => {
    if (product?.costPrice) {
      const variantComponent = [];
      for (const costPriceKey in product?.costPrice) {
        if (
          product?.costPrice[costPriceKey] !== null &&
          _.isObject(product?.costPrice[costPriceKey])
        ) {
          variantComponent.push(product?.costPrice[costPriceKey]);
        }
      }
      if (variantComponent.length > 0) {
        return variantComponent.map((componentObject, indx) => {
          return (
            <div
              className="flex w-full items-center justify-between"
              key={`component-varient-${indx}`}>
              <div
                className="mt-4 flex w-5/6 justify-between rounded-md border border-[#DDDFE0] p-4 text-base duration-500"
                key={`cp-key-val-${indx}`}>
                <div className="flex w-full items-center justify-between space-x-4">
                  <div className="w-[500px]">
                    {componentObject?.componentName}
                  </div>
                  <div className="w-36">
                    Quantity: {componentObject?.selectedQuantity}
                  </div>
                  <div className="w-40">UOM: {componentObject?.uom}</div>
                </div>
              </div>
              <div className="ml-4 mt-4 w-1/4">
                <div
                  className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                  onClick={() => {
                    removeComponentVariant(componentObject?.id, "costPrice");
                  }}>
                  Remove
                </div>
              </div>
            </div>
          );
        });
      }
    }
  };

  const changeComponentValue = (index, key, value, onChangeType = "") => {
    const componentsCostData = [...componentsCost];
    if (onChangeType === "component") {
      const componentDetails = allVariantComponents.find(
        (componentObject) => componentObject.id === value,
      );
      componentsCostData[index] = {
        ...componentDetails,
        selectedQuantity: componentDetails?.quantity,
        selectedPrice: componentDetails?.price,
      };
    } else componentsCostData[index][key] = value;
    setComponentsCost(componentsCostData);
  };

  useEffect(() => {
    if (costPriceWithDiscount > 0 && !product?.costPrice?.currency) {
      onChange({
        target: {
          name: "currency",
          value: "USD-$",
          type: "costPrice",
        },
      });
    }
  }, [costPriceWithDiscount]);

  useEffect(() => {
    if (product?.salesPrice?.length > 0) {
      let productSalesPrice = [...product.salesPrice];
      productSalesPrice = productSalesPrice.map((salesPriceObject, index) => {
        const modifiedSalesPriceObject = { ...salesPriceObject };
        if (costPriceWithDiscount > 0) {
          modifiedSalesPriceObject.valueObj = {
            ...modifiedSalesPriceObject?.valueObj,
            "Cost Summation": costPriceWithDiscount,
            total: 0,
          };
        } else {
          if (
            _.has(modifiedSalesPriceObject.valueObj, [
              "Cost Summation",
              "total",
            ])
          ) {
            modifiedSalesPriceObject.valueObj = _.omit(
              modifiedSalesPriceObject.valueObj,
              ["Cost Summation", "total"],
            );
          } else {
            modifiedSalesPriceObject.valueObj = _.omit(
              modifiedSalesPriceObject.valueObj,
              ["Cost Summation"],
            );
          }
        }
        return {
          ...modifiedSalesPriceObject,
        };
      });
      setProduct({
        ...product,
        salesPrice: [...productSalesPrice],
      });
    }
  }, [costPriceWithDiscount]);

  return (
    <div
      className={`relative ${product?.typeOfProduct === "BUNDLE" ? "h-full" : ""}`}>
      <div
        className={`flex-1 flex-col ${product?.typeOfProduct === "BUNDLE" ? "h-full" : "h-[calc(100%-96px)]"}`}>
        <div>
          <div className="flex flex-1">
            <div className="flex-1 bg-white">
              <div className="flex w-full">
                <div className="catalog-textfield-wrapper">
                  <label className="catalog-textfield-label">Length</label>
                  <input
                    className="catalog-textfield"
                    type="number"
                    value={product.attributes?.length}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        attributes: {
                          ...(product?.attributes || {}),
                          length: handlePositiveValue(e.target.value, "float"),
                        },
                      })
                    }
                  />
                </div>
                <div className="catalog-textfield-wrapper">
                  <label className="catalog-textfield-label">Width</label>
                  <input
                    className="catalog-textfield"
                    type="number"
                    value={product.attributes?.width}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        attributes: {
                          ...(product?.attributes || {}),
                          width: handlePositiveValue(e.target.value, "float"),
                        },
                      })
                    }
                  />
                </div>
                <div className="catalog-textfield-wrapper">
                  <label className="catalog-textfield-label">Height</label>
                  <input
                    className="catalog-textfield"
                    type="number"
                    value={product.attributes?.height}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        attributes: {
                          ...(product?.attributes || {}),
                          height: handlePositiveValue(e.target.value, "float"),
                        },
                      })
                    }
                  />
                </div>
                <div className="catalog-textfield-wrapper">
                  <label className="catalog-textfield-label">Unit</label>
                  <AutocompleteSingleSelectDropdown
                    options={[
                      {
                        name: "Inches",
                      },
                      {
                        name: "Centimeters",
                      },
                      {
                        name: "Meters",
                      },
                      {
                        name: "Feet",
                      },
                    ]}
                    labelKey={"name"}
                    valueKey={"name"}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        attributes: {
                          ...(product?.attributes || {}),
                          dimensionsUnit: e,
                        },
                      })
                    }
                    value={product.attributes?.dimensionsUnit}
                    showCheckedIndicator={false}
                  />
                </div>
              </div>
              <div className="flex w-full">
                <div className="catalog-textfield-wrapper">
                  <label className="catalog-textfield-label">Weight</label>
                  <input
                    className="catalog-textfield"
                    type="number"
                    value={product.attributes?.weight}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        attributes: {
                          ...(product?.attributes || {}),
                          weight: handlePositiveValue(e.target.value, "float"),
                        },
                      })
                    }
                  />
                </div>
                <div className="catalog-textfield-wrapper">
                  <label className="catalog-textfield-label">Unit</label>
                  <AutocompleteSingleSelectDropdown
                    options={[
                      { name: "Pounds" },
                      { name: "Kilograms" },
                      { name: "Ounces" },
                      { name: "Litres" },
                    ]}
                    labelKey={"name"}
                    valueKey={"name"}
                    onChange={(e) =>
                      setProduct({
                        ...product,
                        attributes: {
                          ...(product?.attributes || {}),
                          weightMeasure: e,
                        },
                      })
                    }
                    value={product.attributes?.weightMeasure}
                    showCheckedIndicator={false}
                  />
                </div>
              </div>
            </div>
          </div>
          {product?.typeOfProduct !== "BUNDLE" && (
            <ProductVariants
              sourceIsNotHopStack={sourceIsNotHopStack}
              allVariantComponents={allVariantComponents}
              getProductVariantComponents={getProductVariantComponents}
              selectedProduct={product}
              onChange={onChange}
              isRenderingFromCatalogs={true}
            />
          )}
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end" }}
        className="border-grey-300 sticky bottom-0 z-10 -mx-6 h-24 gap-4 border-t bg-white">
        <button
          onClick={() => {
            setProduct(null);
          }}
          className="mb-4 mt-6 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border-[1px] border-primaryAccent bg-white p-2 font-sans text-lg font-medium text-primaryAccent hover:border-[#4096ff] hover:text-[#4096ff]">
          Cancel
        </button>
        <button
          type="primary"
          onClick={validateCostPriseSalesPrice}
          className="mb-4 mr-6 mt-6 flex h-14 w-32 cursor-pointer items-center justify-center rounded-md border bg-primaryAccent p-2 font-sans text-lg font-medium text-white hover:bg-[#4096ff] hover:text-white">
          Save
        </button>
      </div>
    </div>
  );
};

export default CatalogProductInfoForm;
