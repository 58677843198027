import React, { useState, useRef, useEffect } from "react";
import { Transition } from "@headlessui/react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  XIcon,
} from "@heroicons/react/outline";
import PropTypes from "prop-types";

const CustomMultiSelect = ({
  options,
  selectedValues,
  onChange,
  placeholder = "Select Options",
  maxDisplayTags = 2,
  className = "",
  CustomOptionRender = null,
  CustomTagRender = null,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredOptions = searchTerm
    ? options.filter((option) =>
        option.name.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    : options;

  const handleOptionClick = (option) => {
    const isSelected = selectedValues.includes(option.id);
    const newValues = isSelected
      ? selectedValues.filter((v) => v !== option.id)
      : [...selectedValues, option.id];
    onChange(newValues.map((v) => ({ id: v, name: v })));
  };

  const handleRemoveTag = (valueToRemove) => {
    const newValues = selectedValues.filter((v) => v !== valueToRemove);
    onChange(newValues.map((v) => ({ id: v, name: v })));
  };

  return (
    <div className={`relative ${className}`} ref={dropdownRef}>
      <div
        className="relative flex cursor-pointer items-center justify-between rounded border border-gray-300 px-3 py-2"
        onClick={() => setIsOpen(!isOpen)}>
        <div className="flex flex-wrap items-center gap-2">
          {selectedValues?.length > 0 ? (
            <>
              {selectedValues.slice(0, maxDisplayTags).map((value) =>
                CustomTagRender ? (
                  <CustomTagRender
                    key={value}
                    value={value}
                    onRemove={() => handleRemoveTag(value)}
                  />
                ) : (
                  <span
                    key={value}
                    className="flex items-center gap-1 rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">
                    {value}
                    <XIcon
                      className="h-4 w-4 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveTag(value);
                      }}
                    />
                  </span>
                ),
              )}
              {selectedValues.length > maxDisplayTags && (
                <span className="rounded-full bg-primaryAccent px-2 py-1 text-sm text-white">
                  +{selectedValues.length - maxDisplayTags}
                </span>
              )}
            </>
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>
        <span>
          {isOpen ? (
            <ChevronUpIcon className="h-5 w-5 text-gray-500" />
          ) : (
            <ChevronDownIcon className="h-5 w-5 text-gray-500" />
          )}
        </span>
      </div>

      <Transition
        show={isOpen}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
        className="absolute z-50 mt-2 w-full origin-top rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="max-h-60 overflow-y-auto">
          <div className="sticky top-0 bg-white p-2">
            <input
              type="text"
              placeholder="Search..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full rounded border border-gray-300 px-3 py-2 text-sm focus:border-primaryAccent focus:outline-none focus:ring-1 focus:ring-primaryAccent"
              onClick={(e) => e.stopPropagation()}
            />
          </div>
          <div className="py-1">
            {filteredOptions.map((option) => (
              <div
                key={option.id}
                className="flex cursor-pointer items-center px-4 py-2 hover:bg-gray-100"
                onClick={() => handleOptionClick(option)}>
                <input
                  type="checkbox"
                  className="mr-3 h-4 w-4 cursor-pointer rounded border-gray-300 text-primaryAccent checked:bg-primaryAccent checked:hover:bg-primaryAccent focus:ring-primaryAccent"
                  checked={selectedValues.includes(option.id)}
                  readOnly
                />
                {CustomOptionRender ? (
                  <CustomOptionRender option={option} />
                ) : (
                  <span className="text-sm">{option.name}</span>
                )}
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </div>
  );
};

CustomMultiSelect.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  maxDisplayTags: PropTypes.number,
  className: PropTypes.string,
  CustomOptionRender: PropTypes.func,
  CustomTagRender: PropTypes.func,
};

export default CustomMultiSelect;
