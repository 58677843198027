import Modal from "#components/utils/Modal";
import { Tab } from "@headlessui/react";
import { PlusIcon, TrashIcon } from "@heroicons/react/solid";
import { useContext } from "react";
import { AppStateContext } from "#contexts/appState";
import TextField from "#components/utils/TextField";
import { isFeatherRiverColdTenant } from "#utils/tenantCheck";
import Dropdown from "#components/utils/Dropdown";

const OrderLineItemsTable = ({ orderLineItems, onWeightChange }) => {
  return (
    <table className="min-w-full divide-y divide-gray-200">
      <thead className="bg-gray-50">
        <tr>
          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
            SKU
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
            Qty
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
            Lot ID
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
            Expiry Date
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
            LPN
          </th>
          <th className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500">
            Weight(Lbs)
          </th>
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200 bg-white">
        {orderLineItems.map((item, index) => (
          <tr key={index}>
            <td className="whitespace-nowrap px-6 py-4">{item.sku}</td>
            <td className="whitespace-nowrap px-6 py-4">
              {item.availableQuantity || item.quantity} {item.formFactor}
            </td>
            <td className="whitespace-nowrap px-6 py-4">{item.lotId || "-"}</td>
            <td className="whitespace-nowrap px-6 py-4">
              {item.bestByDate || "-"}
            </td>
            <td className="whitespace-nowrap px-6 py-4">
              {item.nestedFormFactorId || "-"}
            </td>
            <td className="whitespace-nowrap px-6 py-4">
              <input
                type="number"
                value={item.weight}
                onChange={(e) => {
                  if (
                    isNaN(parseInt(e.target.value)) === false &&
                    parseFloat(e.target.value) >= 0
                  ) {
                    onWeightChange(index, e.target.value);
                  } else {
                    onWeightChange(index, "");
                  }
                }}
                className="w-24 rounded border border-gray-600 px-2 py-1"
                min="0"
                step="0.01"
                onWheel={(e) => e.currentTarget.blur()}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const HandlingUnitManager = ({
  handlingUnits,
  orderLineItems,
  onHandlingUnitsChange,
}) => {
  const appState = useContext(AppStateContext);

  const addHandlingUnit = () => {
    onHandlingUnitsChange([
      ...handlingUnits,
      { type: "Pallet", items: [], weight: "", isPredefined: false },
    ]);
  };

  const addItemToHandlingUnit = (unitIndex, item, quantity) => {
    const currentAllocated = handlingUnits
      .flatMap((u) => u.items)
      .filter((i) => i.sku === item.sku)
      .reduce((acc, i) => acc + i.allocatedQuantity, 0);

    if (currentAllocated + quantity > item.quantity) {
      appState.setAlert(
        `Cannot add ${quantity} units. Only ${item.quantity - currentAllocated} units available for ${item.sku}`,
        "error",
        5000,
      );
      return;
    }

    const newHandlingUnits = [...handlingUnits];
    newHandlingUnits[unitIndex].items.push({
      ...item,
      allocatedQuantity: quantity,
    });
    onHandlingUnitsChange(newHandlingUnits);
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <h3 className="text-lg font-medium">Handling Units</h3>
        <button
          onClick={addHandlingUnit}
          className="inline-flex items-center rounded bg-blue-600 px-3 py-2 text-sm text-white">
          <PlusIcon className="mr-2 h-4 w-4" />
          Add Handling Unit
        </button>
      </div>

      {handlingUnits.map((unit, unitIndex) => (
        <div key={unitIndex} className="rounded border p-4">
          <div className="mb-4 flex items-center justify-between">
            <div className="flex items-center gap-4">
              <h4 className="font-medium">
                {unit.isPredefined
                  ? `Pallet - ${unit.items[0]?.sku}`
                  : `Handling Unit ${unitIndex + 1}`}
              </h4>
              {!unit.isPredefined && (
                <>
                  <select
                    value={unit.type}
                    onChange={(e) => {
                      const newUnits = [...handlingUnits];
                      newUnits[unitIndex].type = e.target.value;
                      onHandlingUnitsChange(newUnits);
                    }}
                    className="rounded border px-2 py-1">
                    <option value="Pallet">Pallet</option>
                    <option value="Carton">Carton</option>
                    <option value="Case">Case</option>
                  </select>
                  <button
                    onClick={() => {
                      const newUnits = [...handlingUnits];
                      newUnits.splice(unitIndex, 1);
                      onHandlingUnitsChange(newUnits);
                    }}
                    className="text-gray-500 hover:text-red-600">
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </>
              )}
            </div>
            <input
              type="number"
              placeholder="Weight (lbs)"
              value={unit.weight}
              onChange={(e) => {
                if (e.target.value < 0) {
                  e.target.value = "";
                  return;
                }
                const newUnits = [...handlingUnits];
                newUnits[unitIndex].weight = e.target.value;
                onHandlingUnitsChange(newUnits);
              }}
              className="w-32 rounded border px-2 py-1"
              min="0"
              step="0.01"
            />
          </div>

          {!unit.isPredefined && (
            <>
              <div className="mt-2 flex gap-2">
                <select
                  className="w-full rounded border p-2"
                  onChange={(e) => {
                    const itemIndex = e.target.value;
                    if (itemIndex) {
                      const selectedItem = orderLineItems[parseInt(itemIndex)];
                      const remainingQty =
                        selectedItem.quantity -
                        handlingUnits
                          .flatMap((u) => u.items)
                          .filter((i) => i.sku === selectedItem.sku)
                          .reduce((acc, i) => acc + i.allocatedQuantity, 0);

                      if (remainingQty > 0) {
                        const quantityInput = document.getElementById(
                          `quantity-input-${unitIndex}`,
                        );
                        quantityInput.max = remainingQty;
                        quantityInput.value = Math.min(1, remainingQty);
                        quantityInput.dataset.itemIndex = itemIndex;
                      }
                    }
                  }}>
                  <option value="">Select item...</option>
                  {orderLineItems
                    .filter((item) => item.formFactor !== "Pallet")
                    .map((item, idx) => {
                      const remainingQty =
                        item.quantity -
                        handlingUnits
                          .flatMap((u) => u.items)
                          .filter((i) => i.sku === item.sku)
                          .reduce((acc, i) => acc + i.allocatedQuantity, 0);
                      if (remainingQty > 0) {
                        return (
                          <option key={idx} value={idx}>
                            {item.sku} - {remainingQty} {item.formFactor}{" "}
                            available
                          </option>
                        );
                      }
                      return null;
                    })}
                </select>
                <input
                  id={`quantity-input-${unitIndex}`}
                  type="number"
                  className="w-32 rounded border px-2 py-1"
                  min="1"
                  placeholder="Qty"
                  onChange={(e) => {
                    if (e.target.value < 0) {
                      e.target.value = "";
                    }
                  }}
                />
                <button
                  onClick={() => {
                    const quantityInput = document.getElementById(
                      `quantity-input-${unitIndex}`,
                    );
                    const itemIndex = parseInt(quantityInput.dataset.itemIndex);
                    const quantity = parseInt(quantityInput.value);

                    if (!isNaN(itemIndex) && !isNaN(quantity) && quantity > 0) {
                      addItemToHandlingUnit(
                        unitIndex,
                        orderLineItems[itemIndex],
                        quantity,
                      );
                      quantityInput.value = "";
                    }
                  }}
                  className="rounded bg-blue-600 px-3 py-1 text-white">
                  Add
                </button>
              </div>
            </>
          )}

          {unit.items.length > 0 && (
            <div className="mt-4">
              <table className="min-w-full divide-y divide-gray-200">
                <thead>
                  <tr>
                    <th className="px-4 py-2 text-left">SKU</th>
                    <th className="px-4 py-2 text-left">Quantity</th>
                    <th className="px-4 py-2 text-left">Form Factor</th>
                    {!unit.isPredefined && (
                      <th className="px-4 py-2 text-left">Actions</th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {unit.items.map((item, itemIndex) => (
                    <tr key={itemIndex}>
                      <td className="px-4 py-2">{item.sku}</td>
                      <td className="px-4 py-2">{item.allocatedQuantity}</td>
                      <td className="px-4 py-2">{item.formFactor}</td>
                      {!unit.isPredefined && (
                        <td className="px-4 py-2">
                          <button
                            onClick={() => {
                              const newUnits = [...handlingUnits];
                              newUnits[unitIndex].items.splice(itemIndex, 1);
                              onHandlingUnitsChange(newUnits);
                            }}
                            className="text-red-600">
                            <TrashIcon className="h-4 w-4" />
                          </button>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const BasicInfoForm = ({ bolBuildData, setBolBuildData }) => {
  const appState = useContext(AppStateContext);
  return (
    <div className="space-y-4">
      <div>
        <TextField
          type="text"
          id="name"
          label="Carrier"
          placeholder=" "
          onChange={(e) =>
            setBolBuildData({
              ...bolBuildData,
              carrier: e.target.value,
            })
          }
          value={bolBuildData?.carrier}
          name="carrier"
        />
      </div>
      <div>
        <TextField
          type="text"
          id="bolNumber"
          label="BOL Number"
          placeholder=" "
          onChange={(e) =>
            setBolBuildData({
              ...bolBuildData,
              bolNumber: e.target.value,
            })
          }
          value={bolBuildData?.bolNumber}
          name="bolNumber"
        />
      </div>
      <div>
        <TextField
          type="text"
          id="bolSealNumber"
          label="Seal #"
          placeholder=" "
          onChange={(e) =>
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                bolSealNumber: e.target.value,
              },
            })
          }
          value={bolBuildData?.bolData?.bolSealNumber}
          name="bolSealNumber"
        />
      </div>
      {isFeatherRiverColdTenant(appState.tenant.subdomain) && (
        <>
          <div>
            <TextField
              type="text"
              id="temperature"
              label="Temperature"
              placeholder=" "
              onChange={(e) => {
                setBolBuildData({
                  ...bolBuildData,
                  bolData: {
                    ...bolBuildData.bolData,
                    temperature: e.target.value,
                  },
                });
              }}
              value={bolBuildData?.bolData?.temperature}
              name="temperature"
              key="temperature"
            />
          </div>
          <div>
            <TextField
              type="text"
              id="recorder"
              label="Recorder"
              placeholder=" "
              onChange={(e) => {
                setBolBuildData({
                  ...bolBuildData,
                  bolData: {
                    ...bolBuildData.bolData,
                    recorder: e.target.value,
                  },
                });
              }}
              value={bolBuildData?.bolData?.recorder}
              name="recorder"
              key="recorder"
            />
          </div>
        </>
      )}
      <div>
        <TextField
          type="text"
          id="scacCode"
          label="SCAC Code"
          placeholder=" "
          onChange={(e) => {
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                scacCode: e.target.value,
              },
            });
          }}
          value={bolBuildData?.bolData?.scacCode}
          name="scacCode"
        />
      </div>
      <div>
        <TextField
          type="text"
          id="trailerNumber"
          label="Trailer Number"
          placeholder=" "
          onChange={(e) =>
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                trailerNumber: e.target.value,
              },
            })
          }
          value={bolBuildData?.bolData?.trailerNumber}
          name="trailerNumber"
        />
      </div>
      <div>
        <TextField
          type="date"
          id="dateOfShipping"
          label="Date of Shipping"
          placeholder=" "
          onChange={(e) =>
            setBolBuildData({
              ...bolBuildData,
              dateOfShipping: e.target.value,
            })
          }
          value={bolBuildData?.dateOfShipping}
          name="dateOfShipping"
        />
      </div>
    </div>
  );
};

const MiscInfoForm = ({ bolBuildData, setBolBuildData }) => {
  const feeTermsOptions = ["Collect", "Prepaid", "Customer check acceptable"];

  const trailerLoadedOptions = ["By shipper", "By driver"];

  const freightCountedOptions = [
    "By shipper",
    "By driver/pallets said to contain",
    "By driver/pieces",
  ];

  const freightChargeOptions = ["Prepaid", "Collect", "3rd Party"];

  return (
    <div className="space-y-4">
      <div>
        <TextField
          type="number"
          id="codAmount"
          label="COD Amount"
          placeholder=" "
          onChange={(e) =>
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                codAmount: e.target.value,
              },
            })
          }
          value={bolBuildData?.bolData?.codAmount}
          min="0"
          step="0.01"
        />
      </div>
      <div>
        <Dropdown
          placeholder="Fee Terms"
          list={feeTermsOptions.map((option) => ({
            name: option,
          }))}
          labelKey="name"
          valueKey="name"
          selectedValue={bolBuildData?.bolData?.feeTerms}
          setSelected={(value) =>
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                feeTerms: value,
              },
            })
          }
        />
      </div>
      <div>
        <Dropdown
          placeholder="Trailer Loaded By"
          list={trailerLoadedOptions.map((option) => ({
            name: option,
          }))}
          labelKey="name"
          valueKey="name"
          selectedValue={bolBuildData?.bolData?.trailerLoadedBy}
          setSelected={(value) =>
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                trailerLoadedBy: value,
              },
            })
          }
        />
      </div>
      <div>
        <Dropdown
          placeholder="Freight Counted By"
          list={freightCountedOptions.map((option) => ({
            name: option,
            value: option,
          }))}
          labelKey="name"
          valueKey="name"
          selectedValue={bolBuildData?.bolData?.freightCountedBy}
          setSelected={(value) =>
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                freightCountedBy: value,
              },
            })
          }
        />
      </div>
      <div>
        <Dropdown
          placeholder="Freight Charge Terms"
          list={freightChargeOptions.map((option) => ({
            name: option,
            value: option,
          }))}
          labelKey="name"
          valueKey="name"
          selectedValue={bolBuildData?.bolData?.freightChargeTerms}
          setSelected={(value) =>
            setBolBuildData({
              ...bolBuildData,
              bolData: {
                ...bolBuildData.bolData,
                freightChargeTerms: value,
              },
            })
          }
        />
      </div>
    </div>
  );
};

const BolBuildForm = ({ bolBuildData, setBolBuildData, submitBolData }) => {
  const handleWeightChange = (index, newWeight) => {
    const updatedLineItems = [...bolBuildData.bolData.orderLineItems];
    updatedLineItems[index] = {
      ...updatedLineItems[index],
      weight: newWeight,
    };
    setBolBuildData({
      ...bolBuildData,
      bolData: {
        ...bolBuildData.bolData,
        orderLineItems: updatedLineItems,
      },
    });
  };

  const handleHandlingUnitsChange = (newHandlingUnits) => {
    setBolBuildData({
      ...bolBuildData,
      bolData: {
        ...bolBuildData.bolData,
        handlingUnits: newHandlingUnits,
      },
    });
  };

  const tabs = [
    {
      name: "Basic Info",
      content: (
        <BasicInfoForm
          bolBuildData={bolBuildData}
          setBolBuildData={setBolBuildData}
        />
      ),
    },
    {
      name: "Line Items",
      content: (
        <OrderLineItemsTable
          orderLineItems={bolBuildData.bolData.orderLineItems}
          onWeightChange={handleWeightChange}
        />
      ),
    },
    {
      name: "Handling Units",
      content: (
        <HandlingUnitManager
          handlingUnits={bolBuildData.bolData.handlingUnits}
          onHandlingUnitsChange={handleHandlingUnitsChange}
          setBolBuildData={setBolBuildData}
          orderLineItems={bolBuildData.bolData.orderLineItems}
        />
      ),
    },
    {
      name: "Misc Info",
      content: (
        <MiscInfoForm
          bolBuildData={bolBuildData}
          setBolBuildData={setBolBuildData}
        />
      ),
    },
  ];

  return (
    <Modal
      title={`BOL Build for ${bolBuildData.orderId}`}
      negativeAction={() => setBolBuildData(null)}
      positiveAction={() => submitBolData(bolBuildData)}
      id="orderFormModal">
      <Tab.Group>
        <Tab.List className="flex space-x-1 border-b border-gray-200">
          {tabs.map((tab) => (
            <Tab
              key={tab.name}
              className={({ selected }) =>
                `px-4 py-2.5 text-lg font-medium leading-5 focus:outline-none ${
                  selected
                    ? "border-b-2 border-deactivatedTextColor text-deactivatedTextColor"
                    : "text-gray-500 hover:text-gray-700"
                }`
              }>
              {tab.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-4">
          {tabs.map((tab) => (
            <Tab.Panel
              key={tab.name}
              className="rounded-xl p-3 focus:outline-none">
              {tab.content}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </Modal>
  );
};

export default BolBuildForm;
