export const inboundFormInitState = {
  client: "",
  warehouse: "",
  marketPlace: "",
  sellerId: "",
  shippingMode: "",
};
export const inboundFormEnums = {
  CLIENT: "client",
  WAREHOUSE: "warehouse",
  MARKETPLACE: "marketPlace",
  SELLER_ID: "sellerId",
  SHIPPING_MODE: "shippingMode",
};

export const fbaPackingTabsEnums = {
  PACKING_OPTIONS: "Packing Options",
  BOX_INFORMATION: "Box Information",
  PLACEMENT_OPTIONS: "Placement Options",
  DELIVERY_WINDOW: "Delivery Window",
  LABEL_GENERATION: "Label Generation",
};

export const fbaPackingLtlFlowTabsEnums = {
  PLACEMENT_OPTIONS: "Placement Options",
  BOX_INFORMATION: "Box Information",
  TRANSPORTATION_OPTIONS: "Transportation Options",
  DELIVERY_WINDOW: "Delivery Window",
  LABEL_GENERATION: "Label Generation",
};

export const planStatusesEnums = {
  ACTIVE: { key: "ACTIVE", label: "Active" },
  VOIDED: { key: "VOIDED", label: "Voided" },
  SHIPPED: { key: "SHIPPED", label: "Shipped" },
  DRAFT: { key: "DRAFT", label: "Draft" },
  ELIGIBILITY_CHECK_PENDING: {
    key: "ELIGIBILITY_CHECK_PENDING",
    label: "Eligibility Check pending",
  },
  ELIGIBILITY_CHECK_COMPLETED: {
    key: "ELIGIBILITY_CHECK_COMPLETED",
    label: "Elibilibility Check completed",
  },
  REQUESTED_FOR_VOID: {
    key: "REQUESTED_FOR_VOID",
    label: "Requested for Void",
  },
};

export const inboundFormOptionsEnums = {
  WAREHOUSES_OPTIONS: "warehousesOptions",
  CLIENTS_OPTIONS: "clientsOptions",
  MARKET_PLACE_OPTIONS: "marketplaceOptions",
  SELLER_ID_OPTIONS: "sellerIdOptions",
  SHIPPING_MODE_OPTIONS: "shippingModeOptions",
};

export const fbaStringEnums = {
  AMAZON: "Amazon",
};

export const SEARCH_DEBOUNCE_TIME_IN_MS = 100;
export const LIST_INBOUND_PLANS_TIME_IN_MS = 100;
export const POLLING_INTERVAL = 5000;

export const handleModifyInboundForm = (originalValue, field, value) => {
  if (field)
    return {
      ...originalValue,
      [field]: value,
    };
  else return originalValue;
};

export const handleGetAmazonIntegrations = (integrations) => {
  return (integrations || [])?.filter(
    (integration) => integration.integrationType === fbaStringEnums.AMAZON,
  );
};

export const handleFilterMarketPlaces = (sellerId, integrations) => {
  const integration = integrations?.find(
    (integration) => integration.sellerId === sellerId,
  );
  if (integration) {
    return integration.marketplaces;
  } else if (integrations.length) return integrations[0]?.marketplaces;
  else return [];
};

export const fbaInboundOptions = (
  warehouses,
  customers,
  marketPlaces,
  sellers,
  shippingModes,
) => ({
  warehousesOptions: handleCreateOptions(warehouses || [], "name", "id"),
  clientsOptions: handleCreateOptions(customers || [], "name", "id"),
  marketplaceOptions: handleCreateOptions(marketPlaces || [], "name", "id"),
  sellerIdOptions: handleCreateOptions(sellers || [], "name", "id"),
  shippingModeOptions: handleCreateOptions(shippingModes || [], "mode", "type"),
});
/**
 * Function to generate options for dropdowns
 * @param {Array} data - Array of objects to use for options
 * @param {string} labelKey - Key in objects to use as label
 * @param {string} valueKey - Key in objects to use as value
 * @returns {Array} Array of options with label and value keys
 */

// creating options for Single Select dropdown

export const handleCreateOptions = (data = [], labelKey, valueKey) => {
  return Array.isArray(data)
    ? data?.map((item) => ({
        label: item[labelKey],
        value: item[valueKey],
      }))
    : [];
};

export const INBOUND_PLAN_COLUMN_KEYS = Object.freeze({
  INBOUND_PLAN_ID: "inboundPlanId",
  AMAZON_INBOUND_PLAN_ID: "amazonInboundPlanId",
  CREATION_DATE: "creationDate",
  STATUS: "status",
  TOTAL_PRODUCTS: "totalProducts",
  ACTIONS: "actions",
});

export const dummyData = {
  marketplaceOptions: [
    { country: "Amazon US", code: "amazon_us" },
    { country: "Amazon Canada", code: "amazon_ca" },
    { country: "Amazon UK", code: "amazon_uk" },
    { country: "Amazon Germany", code: "amazon_de" },
    { country: "Amazon France", code: "amazon_fr" },
  ],
  sellerIdOptions: [
    { name: "Seller 001", id: "seller_001" },
    { name: "Seller 002", id: "seller_002" },
    { name: "Seller 003", id: "seller_003" },
    { name: "Seller 004", id: "seller_004" },
  ],
  shippingModeOptions: [
    { mode: "Air", type: "air" },
    { mode: "Sea", type: "sea" },
    { mode: "Road", type: "road" },
  ],
};

export const handleGetStatusConfig = () => {
  return {
    ACTIVE: {
      dotColor: "green",
      badgeColor: "green",
      bgColor: "#DCFCE7",
      textColor: "#166534",
    },
    VOIDED: {
      dotColor: "red",
      badgeColor: "red",
      bgColor: "#FEE2E2",
      textColor: "#991B1B",
    },
    SHIPPED: {
      dotColor: "blue",
      badgeColor: "blue",
      bgColor: "#DBEAFE",
      textColor: "#1E40AF",
    },
    DRAFT: {
      dotColor: "gray",
      badgeColor: "gray",
      bgColor: "#F3F4F6",
      textColor: "#374151",
    },
    ELIGIBILITY_CHECK_PENDING: {
      dotColor: "yellow",
      badgeColor: "yellow",
      bgColor: "#FEF9C3",
      textColor: "#854D0E",
    },
    ELIGIBILITY_CHECK_COMPLETED: {
      dotColor: "blue",
      badgeColor: "blue",
      bgColor: "#DBEAFE",
      textColor: "#1E40AF",
    },
    CANCELLED: {
      dotColor: "red",
      badgeColor: "red",
      bgColor: "#FEE2E2",
      textColor: "#991B1B",
    },
  };
};

export const updateInboundPlanVariablesInitState = {
  updateInboundPlanInput: {
    id: null,
    inboundPlanDetails: {
      amazonInboundPlanId: null,
      deliveryWindowOptions: [
        {
          availabilityType: null,
          deliveryWindowOptionId: null,
          endDate: null,
          startDate: null,
          validUntil: null,
        },
      ],
      packingOptions: [
        {
          discounts: {
            description: null,
            target: null,
            type: null,
            value: {
              amount: null,
              code: null,
            },
          },
          packingGroups: null,
          packingOptionId: null,
          status: null,
        },
      ],
      placementOptions: [
        {
          discounts: [
            {
              description: null,
              target: null,
              type: null,
              value: {
                amount: null,
                code: null,
              },
            },
          ],
          expiration: null,
          placementOptionId: null,
          shipmentIds: null,
          fees: [
            {
              description: null,
              target: null,
              type: null,
              value: {
                amount: null,
                code: null,
              },
            },
          ],
          status: null,
        },
      ],
      transportationOptions: [
        {
          carrier: {
            alphaCode: null,
            name: null,
          },
          carrierAppointment: {
            endTime: null,
            startTime: null,
          },
          preconditions: null,
          qoute: {
            cost: {
              amount: null,
              code: null,
            },
          },
          shipmentId: null,
          shippingSolution: null,
          transportOptionId: null,
        },
      ],
      selectedDeliveryWindowOption: {
        availabilityType: null,
        deliveryWindowOptionId: null,
        endDate: null,
        startDate: null,
        validUntil: null,
      },
      selectedPackingOption: {
        discounts: {
          description: null,
          target: null,
          type: null,
          value: {
            amount: null,
            code: null,
          },
        },
        packingGroups: null,
        packingOptionId: null,
        status: null,
      },
      selectedPlacementOption: {
        discounts: [
          {
            description: null,
            target: null,
            type: null,
            value: {
              amount: null,
              code: null,
            },
          },
        ],
        expiration: null,
        placementOptionId: null,
        status: null,
        shipmentIds: null,
        fees: [
          {
            description: null,
            target: null,
            type: null,
            value: {
              amount: null,
              code: null,
            },
          },
        ],
      },
      selectedTransportationOption: {
        carrier: {
          alphaCode: null,
          name: null,
        },
        carrierAppointment: {
          endTime: null,
          startTime: null,
        },
        preconditions: null,
        shipmentId: null,
        shippingSolution: null,
        transportOptionId: null,
        qoute: {
          cost: {
            amount: null,
            code: null,
          },
        },
      },
    },
    status: null,
    orders: null,
    items: [
      {
        asin: null,
        fnsku: null,
        lotId: null,
        price: null,
        productId: null,
        quantity: null,
        sku: null,
        uniqueIdentifier: null,
      },
    ],
  },
};
