import React, { useState, useEffect } from "react";
import { formateInitials, formatCount } from "#utils/helper-functions";
import { Avatar } from "antd";
import { twMerge } from "tailwind-merge";
import NoNotificationsDataAvailable from "#newUiComponents/notificationV2/NoNotificationsDataAvailable";

export const SystemNotificationEventsUserRoles = ({
  userRoleIds,
  entityUserRolesWithCount,
}) => {
  const [selectedUserRoles, setSelectedUserRoles] = useState([]);

  useEffect(() => {
    if (userRoleIds) {
      setSelectedUserRoles(userRoleIds);
    }
  }, [userRoleIds]);
  if (!selectedUserRoles || selectedUserRoles.length === 0) {
    return (
      <NoNotificationsDataAvailable
        width="15%"
        message="No User Roles Assigned"
      />
    );
  }

  const roleMap = new Map(
    entityUserRolesWithCount.map((roles) => [roles.id, roles]),
  );

  return (
    <div className="mb-2 mt-4">
      <div className="flex h-44 gap-4 space-y-1 overflow-y-auto">
        <div className="w-full space-y-2 p-0">
          {selectedUserRoles.map((role, subIndex) => {
            const roleID = role?.id || role;
            const filteredRole = roleMap.get(roleID);

            if (!filteredRole) return null;

            return (
              <div
                key={subIndex}
                className={twMerge(
                  `bg-white py-2 ${subIndex !== selectedUserRoles.length - 1 ? "border-b-2" : ""}`,
                )}>
                <div className="flex flex-row items-center justify-start space-x-2">
                  <Avatar
                    style={{
                      backgroundColor: "#F1F5F9",
                      color: "#374151",
                    }}>
                    {formateInitials(filteredRole?.name)}
                  </Avatar>
                  <div className="flex flex-col items-start justify-start">
                    <p className="text-sm text-gray-700">
                      {filteredRole?.name}
                    </p>
                    <p className="text-xs text-gray-300">
                      {formatCount(filteredRole?.userCount)} Users
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
