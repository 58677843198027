import React, { createContext, useContext, useState, useEffect } from "react";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";

const FeatureFlagsContext = createContext();

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const FeatureFlagsProvider = ({ children }) => {
  const [ldFlags, setLdFlags] = useState({
    isBranded: undefined,
    storageManagementEnabled: undefined,
    salesPortalEnabled: undefined,
    isBundlingWorkflowEnabled: undefined,
    batchSettingsEnabled: undefined,
    lotIdSelectionEnabled: undefined,
    fbaCasePackEnabled: undefined,
    bolUploadEnabled: undefined,
    fbaSkuLabelGeneration: undefined,
    shippingWorkflowDesktopEnabled: undefined,
    csvPackingListDownloadEnabled: undefined,
    transactionHistoryReportEnabled: undefined,
    billingModuleEnabled: undefined,
    lpnSelectionEnabled: undefined,
    cogEnabled: undefined,
    marketplaceImport: undefined,
    associateUsernameInputEnabled: undefined,
    fba2DBarcodeEnabled: undefined,
    defineBundlesOnConsignment: undefined,
    productLinkageAllowed: undefined,
    warehouseBulkUpload: undefined,
    enhancedSearchUx: undefined,
    newRateshoppingUx: undefined,
    fbaV1Enabled: undefined,
    notificationV2Enabled: undefined,
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const ldClient = useLDClient();
  const flags = useFlags();

  const fetchFlags = async () => {
    if (ldClient) {
      const {
        isBranded,
        hierarchicalStorageManagementEnabled: storageManagementEnabled,
        salesPortalEnabled,
        batchSettingsEnabled,
        bundlingWorkflow: isBundlingWorkflowEnabled,
        lotIdSelectionEnabled,
        fbaCasePackEnabled,
        bolUploadEnabled,
        fbaSkuLabelGeneration,
        shippingWorkflowDesktopEnabled,
        csvPackingListDownloadEnabled,
        transactionHistoryReportEnabled,
        billingModuleEnabled,
        lpnSelectionEnabled,
        cogEnabled,
        marketplaceImport,
        associateUsernameInputEnabled,
        fba2DBarcodeEnabled,
        defineBundlesOnConsignment,
        productLinkageAllowed,
        warehouseBulkUpload,
        enhancedSearchUx,
        newRateshoppingUx,
        fbaV1Enabled,
        notificationV2Enabled,
      } = flags;

      setLdFlags({
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
        isBundlingWorkflowEnabled,
        batchSettingsEnabled,
        lotIdSelectionEnabled,
        fbaCasePackEnabled,
        bolUploadEnabled,
        fbaSkuLabelGeneration,
        shippingWorkflowDesktopEnabled,
        csvPackingListDownloadEnabled,
        transactionHistoryReportEnabled,
        billingModuleEnabled,
        lpnSelectionEnabled,
        cogEnabled,
        marketplaceImport,
        associateUsernameInputEnabled,
        fba2DBarcodeEnabled,
        defineBundlesOnConsignment,
        productLinkageAllowed,
        warehouseBulkUpload,
        enhancedSearchUx,
        newRateshoppingUx,
        fbaV1Enabled,
        notificationV2Enabled,
      });
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    fetchFlags();
  }, [ldClient, flags]);

  useEffect(() => {
    if (ldClient) {
      ldClient.on("change", fetchFlags);
    }

    return () => {
      if (ldClient) {
        ldClient.off("change", fetchFlags);
      }
    };
  }, [ldClient, fetchFlags]);

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  return (
    <FeatureFlagsContext.Provider value={ldFlags}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};
