import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import React from "react";
import { handleModifyInboundForm, inboundFormEnums } from "./FbaUtils";
import { Skeleton } from "antd";
import CustomAlert from "#newUiComponents/commons/CustomAlert";

const InboundForm = ({
  inboundForm,
  setInboundForm,
  formData,
  fbaIntegrations,
  isFilterOnly,
  isIntegrationsLoading,
}) => {
  const renderIntegrationFields = () => {
    if (isIntegrationsLoading) {
      return (
        <>
          <div className="mb-4 flex w-60 flex-col">
            <div className="mb-2 text-base text-gray-500">Seller Id</div>
            <Skeleton.Input active size="large" className="h-[38px] w-full" />
          </div>
          <div className="mb-4 flex w-60 flex-col">
            <div className="mb-2 text-base text-gray-500">Marketplace</div>
            <Skeleton.Input active size="large" className="h-[38px] w-full" />
          </div>
        </>
      );
    }

    if (
      !fbaIntegrations?.length &&
      inboundForm.warehouse &&
      inboundForm.client
    ) {
      return (
        <CustomAlert
          message="Amazon integration not found for this client. Please set up
          integration first."
          type="error"
          id="fbaIntegrationsNotFoundAlert"
        />
      );
    }

    if (!isFilterOnly && fbaIntegrations && fbaIntegrations.length) {
      return (
        <>
          <AutoCompleteSingleSelect
            options={formData?.sellerIdOptions}
            value={inboundForm?.sellerId}
            onChange={(value) =>
              setInboundForm(
                handleModifyInboundForm(
                  inboundForm,
                  inboundFormEnums.SELLER_ID,
                  value,
                ),
              )
            }
            labelKey="label"
            valueKey="value"
            placeholder="Select Seller Id"
            labelText="Seller Id"
            required={false}
            disabled={false}
            infoText="Select a Seller ID"
            error={""}
            showNotSelectedError={false}
            parentClasses="flex flex-col w-60 mb-4"
            labelClasses="text-base text-gray-500"
          />
          <AutoCompleteSingleSelect
            options={formData?.marketplaceOptions}
            value={inboundForm?.marketPlace}
            onChange={(value) =>
              setInboundForm(
                handleModifyInboundForm(
                  inboundForm,
                  inboundFormEnums.MARKETPLACE,
                  value,
                ),
              )
            }
            labelKey="label"
            valueKey="value"
            placeholder="Select Marketplace"
            labelText="Marketplace"
            required={false}
            disabled={false}
            infoText="Select a Marketplace"
            error={""}
            showNotSelectedError={false}
            parentClasses="flex flex-col w-60 mb-4"
            labelClasses="text-base text-gray-500"
          />
        </>
      );
    }

    return null;
  };

  return (
    <div className="flex flex-wrap items-center gap-6">
      <AutoCompleteSingleSelect
        options={formData?.warehousesOptions}
        value={inboundForm?.warehouse}
        onChange={(value) =>
          setInboundForm(
            handleModifyInboundForm(
              inboundForm,
              inboundFormEnums.WAREHOUSE,
              value,
            ),
          )
        }
        labelKey="label"
        valueKey="value"
        placeholder="Select Warehouse"
        labelText="Warehouse"
        required={false}
        disabled={false}
        infoText="Select a Warehouse"
        error={""}
        showNotSelectedError={false}
        parentClasses="flex flex-col w-60 mb-4"
        labelClasses="text-base text-gray-500"
      />
      <AutoCompleteSingleSelect
        options={formData?.clientsOptions}
        value={inboundForm?.client}
        onChange={(value) =>
          setInboundForm(
            handleModifyInboundForm(
              inboundForm,
              inboundFormEnums.CLIENT,
              value,
            ),
          )
        }
        labelKey="label"
        valueKey="value"
        placeholder="Select Client"
        labelText="Client"
        required={false}
        disabled={false}
        infoText="Select a Client"
        error={""}
        showNotSelectedError={false}
        parentClasses="flex flex-col w-60 mb-4"
        labelClasses="text-base text-gray-500"
      />

      {!isFilterOnly && renderIntegrationFields()}
    </div>
  );
};

export default InboundForm;
