import React, { useState } from "react";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import InputBox from "#newUiComponents/commons/InputBox";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import { getCurrencySymbol } from "#utils/getCurrencySymbol";
import CustomAlert from "#newUiComponents/commons/CustomAlert";
import { selectedMethodEnums } from "#components/packer/ConfirmBoxContents";

const ShipmentDetailsAndPreview = ({
  order,
  transportMode,
  setTransportMode,
  boxDetails,
  setShowRateShoppingSlideOver,
  selectedIncoterms,
  setSelectedIncoterms,
  selectedInsuranceProvider,
  setSelectedInsuranceProvider,
  insuredValue,
  setInsuredValue,
  preSelectedCarrierRate,
  selectedMethod,
  setSelectedMethod,
  setTrackingNumber,
  trackingNumber,
  isLabelGenerated,
}) => {
  const INCOTERMS_OPTIONS = [
    { label: "DAP", value: "DAP" },
    { label: "DDP", value: "DDP" },
    { label: "DUP", value: "DUP" },
    { label: "DDU", value: "DDU" },
    { label: "EXW", value: "EXW" },
    { label: "FCA", value: "FCA" },
    { label: "CPT", value: "CPT" },
    { label: "CIP", value: "CIP" },
    { label: "DAT", value: "DAT" },
    { label: "FAS", value: "FAS" },
    { label: "FOB", value: "FOB" },
    { label: "CFR", value: "CFR" },
    { label: "CIF", value: "CIF" },
  ];

  const INSURANCE_PROVIDERS = [
    { label: "Carrier", value: "carrier" },
    { label: "Parcel Guard", value: "parcel_guard" },
  ];

  const { carrier, currency, price, service, estimated_delivery_days } =
    preSelectedCarrierRate;

  const sortedIncotermsOptions = INCOTERMS_OPTIONS.sort((a, b) => {
    const firstOptions = ["DAP", "DDP", "DUP"];
    const aInFirstOptions = firstOptions.indexOf(a.value) !== -1;
    const bInFirstOptions = firstOptions.indexOf(b.value) !== -1;

    if (aInFirstOptions && !bInFirstOptions) {
      return -1;
    }
    if (!aInFirstOptions && bInFirstOptions) {
      return 1;
    }
    return a.label.localeCompare(b.label);
  });

  const productDetailsArray = order.orderProducts || [];
  const totalNoOfProducts = boxDetails
    ?.flatMap((box) => box.items)
    ?.map((item) => item?.quantity || 0)
    .reduce((total, quantity) => total + quantity, 0);

  const totalCost = boxDetails
    ?.flatMap((box) => box.items)
    ?.map((item) => (item?.quantity || 0) * (item?.unitCost || 0))
    .reduce((total, cost) => total + cost, 0);

  return (
    <div className="mb-8">
      {/* Shipment Preview Section */}
      <HeaderWithArrow
        isArrow={false}
        headerTitle="Shipment Preview"
        description=""
      />
      <div className="mb-4">
        <label className="cursor-pointer">
          <input
            type="radio"
            value="SP"
            id="rate-shopping-select"
            className={`mr-4 cursor-pointer text-2C7695 ${isLabelGenerated ? "pointer-events-none text-gray-200" : ""}`}
            checked={selectedMethod === selectedMethodEnums.RATE_SHOPPING}
            onChange={(value) => {
              setSelectedMethod(selectedMethodEnums.RATE_SHOPPING);
            }}
            disabled={isLabelGenerated}
          />
          Rate Shopping
        </label>
        <label style={{ marginLeft: "20px" }} className="cursor-pointer">
          <input
            type="radio"
            value="TrackingId"
            id="tracking-id-select"
            className={`mr-4 cursor-pointer text-2C7695 ${isLabelGenerated ? "pointer-events-none text-gray-200" : ""}`}
            checked={selectedMethod === selectedMethodEnums.TRACKING_ID}
            onChange={(value) => {
              setSelectedMethod(selectedMethodEnums.TRACKING_ID);
            }}
            disabled={isLabelGenerated}
          />
          Tracking ID
        </label>
      </div>
      {selectedMethod && (
        <div className="mb-4 flex items-center gap-2 pt-4">
          <h2 className="h-[19px] w-48 text-left font-inter text-base font-medium leading-[19.36px] text-[#224E73] opacity-100">
            User Entry Section
          </h2>
          <div className="w-full border-t border-gray-300"></div>
        </div>
      )}
      <div>
        {selectedMethod === selectedMethodEnums.RATE_SHOPPING && (
          <>
            <div className="mb-4 grid grid-cols-4 gap-6">
              <div>
                <AutoCompleteSingleSelect
                  labelKey="label"
                  valueKey="value"
                  placeholder="Select an option"
                  labelText="Shipping Mode"
                  showNotSelectedError={true}
                  parentClasses="flex flex-col w-full mb-4 md:w-3/4"
                  labelClasses="text-base text-[#344053]"
                  value={transportMode}
                  shouldDeselect={false}
                  onChange={(value) => {
                    setTransportMode(value);
                  }}
                  options={[
                    { label: "Small Parcel", value: "SP" },
                    { label: "LTL", value: "LTL" },
                  ]}
                  disabled={isLabelGenerated}
                />
              </div>
              <div>
                <AutoCompleteSingleSelect
                  options={sortedIncotermsOptions}
                  value={selectedIncoterms}
                  onChange={(option) => setSelectedIncoterms(option)}
                  labelKey="label"
                  valueKey="value"
                  placeholder="Select an option"
                  labelText="Incoterms"
                  parentClasses="flex flex-col w-full mb-4 md:w-3/4"
                  labelClasses="text-base text-[#344053]"
                  isSort={false}
                  infoText="International Commercial Terms"
                  disabled={isLabelGenerated}
                />
              </div>
              <div>
                <AutoCompleteSingleSelect
                  options={INSURANCE_PROVIDERS}
                  value={selectedInsuranceProvider}
                  onChange={(option) => setSelectedInsuranceProvider(option)}
                  labelKey="label"
                  valueKey="value"
                  placeholder="Select an option"
                  labelText="Insurance Provider"
                  parentClasses="flex flex-col w-full mb-4 md:w-3/4"
                  labelClasses="text-base text-[#344053]"
                />
              </div>
              <div>
                <InputBox
                  label="Insurance Amount"
                  value={insuredValue}
                  onChange={(e) => setInsuredValue(parseFloat(e.target.value))}
                  type="number"
                  placeholder="Enter insurance amount"
                  parentClasses="flex flex-col w-full mb-4 md:w-3/4"
                  labelClasses="text-base text-[#344053]"
                  infoText="Insurance amount will be order value. Only applicable for selected insurance provider."
                />
              </div>
              <div className="flex items-center text-sm">
                {carrier && service && price ? (
                  <p>
                    <span>
                      {[
                        carrier,
                        service,
                        currency &&
                          price &&
                          `${getCurrencySymbol(currency)} ${price}`,
                        estimated_delivery_days &&
                          `${estimated_delivery_days} days`,
                      ]
                        .filter(Boolean)
                        .join(" | ")}
                    </span>{" "}
                    <span
                      onClick={() => {
                        setShowRateShoppingSlideOver(true);
                      }}
                      disabled={isLabelGenerated}
                      className={`ml-3 cursor-pointer text-sm font-semibold text-[#224E73] underline ${isLabelGenerated ? "pointer-events-none text-gray-400" : ""}`}>
                      Edit
                    </span>
                  </p>
                ) : (
                  <button
                    onClick={() => {
                      if (transportMode) setShowRateShoppingSlideOver(true);
                    }}
                    disabled={!transportMode || isLabelGenerated}
                    className={`rounded-md px-4 py-2 text-sm font-semibold transition-all duration-200 ${
                      transportMode && !isLabelGenerated
                        ? "bg-[#224E73] text-white hover:bg-[#1a3d5a] active:bg-[#153147]"
                        : "cursor-not-allowed bg-gray-100 text-gray-400"
                    }`}>
                    Enter rate-shopping details
                  </button>
                )}
              </div>
            </div>
            {selectedIncoterms && (
              <CustomAlert
                id="incotermsInfo"
                type="warning"
                message={
                  "Please note that Incoterms are supported only by specific carriers. Selected Incoterms will be applied if a compatible carrier is chosen."
                }
              />
            )}
            {selectedInsuranceProvider && (
              <CustomAlert
                id="insuranceInfo"
                type="warning"
                message="Please note that Insurance is supported only by specific carriers. Selected Insurance will be applied if a compatible carrier is chosen."
              />
            )}
          </>
        )}
        {selectedMethod === selectedMethodEnums.TRACKING_ID && (
          <div className="flex">
            <div className="mb-4 w-1/3">
              <InputBox
                label={
                  <span>
                    Tracking id
                    {/* <span className="text-gray-400"> (if applicable)</span> */}
                  </span>
                }
                value={trackingNumber}
                placeholder="Enter tracking id"
                name="trackingId"
                labelClasses="text-base text-[#344053]"
                type="text"
                onChange={(e) => {
                  setTrackingNumber(e.target.value);
                }}
                disabled={isLabelGenerated}
              />
            </div>
            <div></div>
          </div>
        )}
      </div>

      {/* Shipment Details Section */}
      <div className="mb-4 flex items-center gap-2">
        <h2 className="h-[19px] w-40 text-left font-inter text-base font-medium leading-[19.36px] text-[#224E73] opacity-100">
          Shipment Details
        </h2>
        <div className="w-full border-t border-gray-300"></div>
      </div>

      <div className="mt-6 grid grid-cols-2 gap-4">
        <div>
          <p className="text-left font-inter text-[17px] leading-[20px] text-[#344053]">
            Recipient’s Address
          </p>
          {order?.shippingAddress?.line1 ||
          order?.shippingAddress?.line2 ||
          order?.shippingAddress?.city ||
          order?.shippingAddress?.state ||
          order?.shippingAddress?.country ||
          order?.shippingAddress?.zip ? (
            <>
              <p className="mt-2 text-base text-[#667084]">
                {order.shippingAddress?.line1} &nbsp;
                {order.shippingAddress?.line2} &nbsp;
                {order.shippingAddress?.city} &nbsp;
              </p>
              <p className="text-base text-[#667084]">
                {order.shippingAddress?.state} &nbsp;
                {order.shippingAddress?.country} &nbsp;
                {order.shippingAddress?.zip}
              </p>
            </>
          ) : (
            <p className="mt-2 text-base text-[#667084]">-</p>
          )}
        </div>
      </div>
      <div className="mt-6 grid min-h-24 grid-cols-2 gap-4">
        <div>
          <p className="text-left font-inter text-[17px] leading-[20px] text-[#344053]">
            No. of Total Products
          </p>
          <p className="mt-2 text-base text-[#667084]">{totalNoOfProducts}</p>
        </div>
        <div>
          <p className="text-left font-inter text-[17px] leading-[20px] text-[#344053]">
            Total Cost
          </p>
          <p className="mt-2 text-base text-[#667084]">${totalCost}</p>
        </div>
      </div>
    </div>
  );
};

export default ShipmentDetailsAndPreview;
