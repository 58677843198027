import React from "react";
import dayjs from "dayjs";

const OrderHeader = ({
  orderId,
  warehouse,
  clientName,
  orderDate,
  orderSource,
  onClickOrderId = () => {},
}) => {
  const formatDate = (dateString) =>
    dateString && dayjs(dateString).isValid()
      ? dayjs(dateString).format("YYYY/MM/DD")
      : "-";

  return (
    <div className="flex w-full items-center bg-white">
      {/* <input type="checkbox" className="mr-4 h-5 w-5 rounded border-gray-300" /> */}
      <div className="grid grid-cols-5 gap-4 text-sm">
        <div>
          <div className="font-medium text-gray-500">Order ID</div>
          <div
            className="cursor-pointer font-medium text-primaryAccent"
            onClick={onClickOrderId}>
            {orderId}
          </div>
        </div>
        {warehouse && (
          <div>
            <div className="font-medium text-gray-500">Warehouse</div>
            <div>{warehouse}</div>
          </div>
        )}
        <div>
          <div className="font-medium text-gray-500">Client</div>
          <div>{clientName}</div>
        </div>
        <div>
          <div className="font-medium text-gray-500">Source</div>
          <div>{orderSource}</div>
        </div>
        <div>
          <div className="font-medium text-gray-500">Date</div>
          <div>{formatDate(orderDate)}</div>
        </div>
      </div>
      {/* <button className="ml-auto">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 text-gray-400"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
          />
        </svg>
      </button> */}
    </div>
  );
};

export default OrderHeader;
