import { useState, useEffect, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_USERS,
  GET_STATIONS,
  GET_USER,
  GET_ENTITY_TYPES,
  INITIATE_RESET_PASSWORD,
  GET_AUDIT,
  GET_USER_FIELDS,
} from "#queries";
import {
  SAVE_USER,
  DELETE_USER,
  SAVE_ENTITY_TYPE,
  DELETE_ENTITY_TYPE,
  BULK_UPLOAD_USER,
} from "#mutations";
import { EntityContext } from "#contexts/entity";
import _ from "lodash";
import { getDefaultNavigationRoutes } from "#components/layout/AdminLayout";
import { AppStateContext } from "#contexts/appState";
import { MasterDataContext } from "#contexts/masterData";
import { AuthContext } from "#contexts/auth";
import { BulkValidationQueryGenerator } from "../../queries/bulkValidationQueryGenerator";
import { useFeatureFlags } from "#contexts/featureFlags";
import { GET_VENDORS } from "#queries/index";

const MAX_NUMBER_OF_USERS = 500;

const withUsersLogic = (WrappedComponent) => {
  return (props) => {
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const [entityTypes, setEntityTypes] = useState([]);
    const auth = useContext(AuthContext);
    const entity = useContext(EntityContext);
    const [showFilters, setShowFilters] = useState(false);
    const appState = useContext(AppStateContext);
    const [selectedUser, setSelectedUser] = useState(null);
    const masterData = useContext(MasterDataContext);
    const stationsQuery = useQuery(GET_STATIONS);
    const usersQuery = useQuery(GET_USERS);
    const allUsersQuery = useQuery(GET_USERS);
    const getUserQuery = useQuery(GET_USER);
    const saveUserQuery = useQuery(SAVE_USER);
    const toggleUserSuspensionQuery = useQuery(SAVE_USER);
    const deleteUserQuery = useQuery(DELETE_USER);
    const getAuditQuery = useQuery(GET_AUDIT);
    const [selectedAudit, setSelectedAudit] = useState(null);
    const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
    const saveEntityTypeQuery = useQuery(SAVE_ENTITY_TYPE);
    const deleteEntityTypeQuery = useQuery(DELETE_ENTITY_TYPE);
    const initiateResetPasswordQuery = useQuery(INITIATE_RESET_PASSWORD);
    const vendorsQuery = useQuery(GET_VENDORS);
    const {
      isBranded,
      storageManagementEnabled,
      salesPortalEnabled,
      fbaV1Enabled,
    } = useFeatureFlags();

    const [permissionOptions, setPermissionOptions] = useState(
      makeDefaultPermissionList(
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
        fbaV1Enabled,
      ),
    );
    const getUserFields = useQuery(GET_USER_FIELDS);
    const uploadBulk = useQuery(BULK_UPLOAD_USER);
    const [dashboardFields, setDashboardFields] = useState(null);
    const [finalError, setFinalError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const bulkUploadValidation = useQuery(
      BulkValidationQueryGenerator({ keyword: "Users" }),
    );
    const [validationResult, setValidationResult] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [vendors, setVendors] = useState([]);
    const [auditUserId, setAuditUserId] = useState(null);

    useEffect(() => {
      loadFirstTimeData();
    }, []);

    const loadFirstTimeData = async () => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        const warehouses = auth.user.warehouses;
        const customers = auth.user.customers;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            5000,
          );
        }
        getUserFields.fetchData();
        const filtersSet = {};

        filtersSet["customers"] = customers;
        filtersSet["warehouses"] = warehouses;
        filtersSet["status"] = null;

        entity.setFilters(filtersSet);
        usersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: filtersSet,
          paginated: false,
          sort: entity.sort,
        });
        allUsersQuery.fetchData({
          perPage: MAX_NUMBER_OF_USERS,
          pageNumber: 1,
          filters: { ...filtersSet, keyword: null },
          paginated: false,
          sort: entity.sort,
        });

        const vendorsData = await vendorsQuery.fetchData({
          filters: {
            classifications: ["Internal", "Third Party"],
          },
        });
        if (vendorsData.data.vendors?.entities?.length > 0) {
          setVendors(vendorsData.data.vendors.entities);
        }
      }
    };

    useEffect(() => {
      if (getUserFields.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }
      if (getUserFields.data) {
        setDashboardFields(getUserFields.data.userFields);
      }
    }, [getUserFields.loading, getUserFields.data, getUserFields.error]);

    useEffect(() => {
      if (uploadBulk.data) {
        usersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSuccessMessage(uploadBulk.data.bulkUploadUsers.message);
      }
      if (uploadBulk.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (uploadBulk.error) {
        appState.removeLoading();
        setFinalError(uploadBulk.error.message);
      }
    }, [uploadBulk.loading, uploadBulk.data, uploadBulk.error]);

    useEffect(() => {
      if (bulkUploadValidation.loading) appState.setLoading();
      else {
        appState.removeLoading();
      }

      if (bulkUploadValidation.data) {
        setValidationResult(
          bulkUploadValidation.data.validateBulkUploadUsers
            .inputValidationErrors,
        );
      }
    }, [
      bulkUploadValidation.loading,
      bulkUploadValidation.data,
      bulkUploadValidation.error,
    ]);

    useEffect(() => {
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      fetchEntityTypes();
    }, []);

    const fetchEntityTypes = () => {
      const filtersSet = { entityParent: ["USER_ROLE"] };
      entityTypesQuery.fetchData({ filters: filtersSet });
    };

    useEffect(() => {
      if (entityTypesQuery.data) {
        setEntityTypes(entityTypesQuery.data.entityTypes);
      }

      if (entityTypesQuery.error) {
        setEntityTypes([]);
      }
    }, [entityTypesQuery.data, entityTypesQuery.error]);

    useEffect(() => {
      if (saveEntityTypeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveEntityTypeQuery.data) {
        appState.hideConfirmation();
        const filtersSet = { entityParent: ["USER_ROLE"] };
        entityTypesQuery.fetchData({ filters: filtersSet });
        setAddCustomEntity(null);
        setSelectedRole(null);
        appState.setAlert(saveEntityTypeQuery.data.saveEntityType.message);
        fetchEntityTypes();
      }

      if (saveEntityTypeQuery.error) {
        appState.setAlert(saveEntityTypeQuery.error.message, "error", 5000);
      }
    }, [
      saveEntityTypeQuery.loading,
      saveEntityTypeQuery.data,
      saveEntityTypeQuery.error,
    ]);

    useEffect(() => {
      if (initiateResetPasswordQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (initiateResetPasswordQuery.data) {
        appState.setAlert(
          initiateResetPasswordQuery.data.initiateResetPassword.message,
          "success",
          5000,
        );
      }

      if (initiateResetPasswordQuery.error) {
        appState.setAlert(
          initiateResetPasswordQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      initiateResetPasswordQuery.loading,
      initiateResetPasswordQuery.data,
      initiateResetPasswordQuery.error,
    ]);

    useEffect(() => {
      if (usersQuery.data) {
        entity.setEntities({
          ...usersQuery.data.users,
          ...usersQuery.variables,
        });
        appState.removeLoading();
      }
    }, [usersQuery.loading, usersQuery.error, usersQuery.data]);

    useEffect(() => {
      if (getUserQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (getUserQuery.data) {
        setSelectedUser(getUserQuery.data.user);
      }
      if (getUserQuery.error) {
        setSelectedUser(null);
      }
    }, [getUserQuery.loading, getUserQuery.data, getUserQuery.error]);

    /* 
    Second parameter has been added to handle the change in permissions object
    for roles type. This function handles it perfectly for userPermissions, but 
    routes permissions use href instead of route and so we need to compare accordingly.
    */
    const getPermissionsObject = (permissions) => {
      const defaultPermissions = makeDefaultPermissionList(
        isBranded,
        storageManagementEnabled,
        salesPortalEnabled,
        fbaV1Enabled,
      );
      const userPermissions = defaultPermissions.map((item) => {
        const permissionObject = permissions.find(
          (element) => element.name === item.name,
        );
        if (item.href && !item.children) {
          if (permissionObject) {
            return {
              ...item,
              readable: permissionObject.readable,
              writable: permissionObject.writable,
            };
          }
          return {
            ...item,
            readable: false,
            writable: false,
          };
        }
        if (item.children?.length) {
          const children = item.children.map((child) => {
            if (permissionObject) {
              const childObject = permissionObject.children.find(
                (element) => element.href === child.href,
              );
              if (childObject) {
                return {
                  ...child,
                  readable: childObject.readable,
                  writable: childObject.writable,
                };
              }
            }
            return {
              ...child,
              readable: false,
              writable: false,
            };
          });
          return { ...item, children };
        }
      });
      return userPermissions;
    };

    useEffect(() => {
      if (saveUserQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveUserQuery.data) {
        appState.hideConfirmation();
        appState.setAlert(saveUserQuery.data.saveUser.message, "success", 5000);
        usersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSelectedUser(null);
      }
      if (saveUserQuery.error) {
        appState.setAlert(saveUserQuery.error.message, "error", 5000);
      }
    }, [saveUserQuery.loading, saveUserQuery.data, saveUserQuery.error]);

    useEffect(() => {
      if (deleteUserQuery.data) {
        appState.hideConfirmation();
        appState.setAlert(
          deleteUserQuery.data.deleteUser.message,
          "success",
          5000,
        );
        usersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSelectedUser(null);
      }
      if (deleteUserQuery.error) {
        appState.setAlert(deleteUserQuery.error.message, "error", 5000);
      }
    }, [deleteUserQuery.loading, deleteUserQuery.data, deleteUserQuery.error]);

    const deleteButtonClicked = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this user?",
        () => {
          deleteUserQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };
    const roleWise = (users, role) => _.filter(users, (e) => e.role === role);

    const onChange = (e) => {
      const user = {
        ...selectedUser,
      };

      user[e.target.name] = e.target.value;
      setSelectedUser(user);
    };

    const onChangeMultiSelect = (field, value) => {
      const user = {
        ...selectedUser,
      };
      user[field] = value;
      setSelectedUser(user);
    };

    const onChangeDropdown = (field, value) => {
      const user = {
        ...selectedUser,
      };

      if (field === "role") {
        if (value === "Add Custom") {
          updateCustomEntityForNewRole();
        }
      }

      user[field] = value;
      setSelectedUser(user);
    };

    const updateCustomEntityForNewRole = () => {
      setPermissionOptions(
        makeDefaultPermissionList(
          isBranded,
          storageManagementEnabled,
          salesPortalEnabled,
          fbaV1Enabled,
        ),
      );
      setAddCustomEntity({
        entityParent: "USER_ROLE",
        name: "",
        attributes: {
          permissionOptions: makeDefaultPermissionList(
            isBranded,
            storageManagementEnabled,
            salesPortalEnabled,
            fbaV1Enabled,
          ),
        },
      });
    };

    const onChangePermission = (route, readable, writable) => {
      let modifiedOptions = permissionOptions.map((item) => {
        if ((!item.href || item.type === "INTRINSIC") && route === item) {
          return {
            ...item,
            children: [...item.children].map((child) => ({
              ...child,
              readable,
              writable,
            })),
          };
        }
        if (item.children?.length) {
          return {
            ...item,
            children: item.children.map((child) => {
              if (child.href === route.href) {
                return { ...child, readable, writable };
              }
              return child;
            }),
          };
        }
        if (item.href === route.href) {
          return { ...item, readable, writable };
        }
        return item;
      });
      setPermissionOptions(modifiedOptions);
      if (addCustomEntity) {
        setAddCustomEntity({
          ...addCustomEntity,
          attributes: {
            ...addCustomEntity.attributes,
            permissionOptions: modifiedOptions,
          },
        });
      }
    };

    const checkPagination = (direction) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < (entity.pageNumber + 1) * entity.perPage) {
        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: entity.filters,
          paginated: true,
          sort: entity.sort,
        };
        return usersQuery.fetchData(vars);
      } else {
        return entity.paginate({ pageNumber: entity.pageNumber + 1 });
      }
    };

    const addBlankUser = () => {
      const blank = {};

      if (entityTypes.length === 0) {
        saveEntityTypeQuery.fetchData({
          entityTypeInput: {
            name: "ASSOCIATE",
            entityParent: "USER_ROLE",
            code: "ASSOCIATE",
          },
        });
      }
      setSelectedUser({
        ...blank,
      });
    };

    const saveUser = () => {
      const user = { ...selectedUser };
      saveUserQuery.fetchData({
        ...user,
      });
    };

    const handleResetUserPassword = (user) => {
      initiateResetPasswordQuery.fetchData({
        email: user.email,
      });
    };

    useEffect(() => {
      if (toggleUserSuspensionQuery.data) {
        appState.hideConfirmation();
        appState.setAlert(
          "User Suspension updated successfully",
          "success",
          5000,
        );
        usersQuery.fetchData({
          perPage: entity.perPage,
          filters: entity.filters,
          paginated: false,
          pageNumber: 1,
          sort: entity.sort,
        });
        setSelectedUser(null);
      }
      if (toggleUserSuspensionQuery.error) {
        appState.setAlert(
          toggleUserSuspensionQuery.error.message,
          "error",
          5000,
        );
      }
    }, [
      toggleUserSuspensionQuery.loading,
      toggleUserSuspensionQuery.data,
      toggleUserSuspensionQuery.error,
    ]);

    const getAudit = (id) => {
      const user = entity.entities.find((item) => item.id === id);

      if (user) {
        setAuditUserId(user.name);
        getAuditQuery.fetchData({ id });
      }
    };

    useEffect(() => {
      const onCompleted = (data) => {
        if (data?.getAudit) {
          setSelectedAudit(data.getAudit);
        }
      };
      const onError = (_) => {
        setSelectedAudit(null);
      };
      if (onCompleted || onError) {
        if (onCompleted && !getAuditQuery.loading && !getAuditQuery.error) {
          onCompleted(getAuditQuery.data);
        } else if (onError && !getAuditQuery.loading && getAuditQuery.error) {
          onError(getAuditQuery.error);
        }
      }
    }, [getAuditQuery.loading, getAuditQuery.data, getAuditQuery.error]);

    const toggleSuspendUserButtonClicked = (user) => {
      toggleUserSuspensionQuery.fetchData({
        ...user,
      });
    };

    /**
     *
     * @param {object} roleType
     * @returns {void}
     * @description Fetches the permission options for the selected role type
     * and sets the permission options state
     */
    const fetchUserRolePermission = (roleType) => {
      if (roleType.attributes?.permissionOptions) {
        setSelectedRole(roleType);
        const permissions = getPermissionsObject(
          roleType.attributes?.permissionOptions,
          "href",
        );
        setPermissionOptions(permissions);
      }
    };

    const submitUserRole = () => {
      const updatedUserRole = {
        id: selectedRole.id,
        entityParent: selectedRole.entityParent,
        name: selectedRole.name,
        attributes: {
          permissionOptions,
        },
      };
      appState.showConfirmation(
        "Confirm",
        "All related users will be affected.",
        () =>
          saveEntityTypeQuery.fetchData({
            entityTypeInput: {
              ...updatedUserRole,
            },
          }),
        appState.hideConfirmation,
      );
    };

    /**
     *
     * @param {string} id
     * @returns {void}
     * @description Delete user role
     * @example
     * deleteUserRole("1234567890")
     */
    const deleteUserRole = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Do you want to delete this role?",
        () => deleteEntityTypeQuery.fetchData({ deleteEntityTypeId: id }),
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (deleteEntityTypeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (deleteEntityTypeQuery.data) {
        appState.hideConfirmation();
        appState.setAlert(deleteEntityTypeQuery.data.deleteEntityType.message);
        usersQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: 1,
          filters: entity.filters,
          paginated: false,
          sort: entity.sort,
        });
        fetchEntityTypes();
      }

      if (deleteEntityTypeQuery.error) {
        appState.setAlert(deleteEntityTypeQuery.error.message, "error", 5000);
      }
    }, [
      deleteEntityTypeQuery.loading,
      deleteEntityTypeQuery.data,
      deleteEntityTypeQuery.error,
    ]);

    return (
      <WrappedComponent
        roleTypes={entityTypes}
        users={entity.displayEntities}
        allUsers={allUsersQuery?.data?.users.entities ?? []}
        isStatsLoading={allUsersQuery.loading}
        masterData={masterData}
        addBlankUser={addBlankUser}
        stations={
          stationsQuery.data ? stationsQuery.data.stations.entities : []
        }
        fetchUser={(id) => getUserQuery.fetchData({ id })}
        resetUserPassword={(email) =>
          initiateResetPasswordQuery.fetchData({ email })
        }
        dashboardFields={dashboardFields}
        saveBulkUpload={(rows) => {
          uploadBulk.fetchData({ rows });
        }}
        errorMessage={finalError}
        successMessage={successMessage}
        selectedUser={selectedUser}
        setSelectedUser={setSelectedUser}
        writable={props.writable}
        saveUser={saveUser}
        roleWise={roleWise}
        onChange={onChange}
        onChangeMultiSelect={onChangeMultiSelect}
        onChangeDropdown={onChangeDropdown}
        deleteButtonClicked={deleteButtonClicked}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        vendors={vendors || []}
        onChangePermission={onChangePermission}
        permissionOptions={permissionOptions}
        fetchUserRolePermission={fetchUserRolePermission}
        selectedRole={selectedRole}
        setSelectedRole={setSelectedRole}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          usersQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
        }}
        submitFilters={() => {
          setShowFilters(false);
          usersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters },
            sort: entity.sort,
          });
          allUsersQuery.fetchData({
            perPage: MAX_NUMBER_OF_USERS,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            paginated: false,
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          usersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            sort: entity.sort,
          });
          allUsersQuery.fetchData({
            perPage: MAX_NUMBER_OF_USERS,
            pageNumber: 1,
            filters: { ...entity.filters, keyword: null },
            paginated: false,
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        onChangeFilter={(field, value, autoSubmit = false) => {
          entity.setFilters({
            ...entity.filters,
            [field]: value,
          });
          if (autoSubmit) {
            usersQuery.fetchData({
              perPage: entity.perPage,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
              },
              sort: entity.sort,
            });
            allUsersQuery.fetchData({
              perPage: MAX_NUMBER_OF_USERS,
              pageNumber: 1,
              filters: {
                ...entity.filters,
                [field]: value,
                keyword: null,
              },
              paginated: false,
              sort: entity.sort,
            });
          }
        }}
        onChangeSearchKeyword={(e) => {
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          });
        }}
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          usersQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
            },
            sort,
          });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        addCustomEntity={addCustomEntity}
        setAddCustomEntity={setAddCustomEntity}
        onSubmitCustomEntity={() =>
          saveEntityTypeQuery.fetchData({
            entityTypeInput: {
              ...addCustomEntity,
              attributes: {
                permissionOptions,
              },
            },
          })
        }
        resetPasswordButtonClicked={handleResetUserPassword}
        toggleSuspendUserButtonClicked={toggleSuspendUserButtonClicked}
        selectedAudit={selectedAudit}
        setSelectedAudit={setSelectedAudit}
        auditUserId={auditUserId}
        setAuditUserId={setAuditUserId}
        getAudit={getAudit}
        validate={(rows) => {
          bulkUploadValidation.fetchData({ rows });
        }}
        validationResult={validationResult}
        submitUserRole={submitUserRole}
        deleteUserRole={deleteUserRole}
        updateCustomEntityForNewRole={updateCustomEntityForNewRole}
      />
    );
  };
};

const makeDefaultPermissionList = (
  isBranded,
  storageManagementEnabled,
  salesPortalEnabled,
  fbaV1Enabled,
) =>
  getDefaultNavigationRoutes({
    isBranded,
    storageManagementEnabled,
    salesPortalEnabled,
    fbaV1Enabled,
  }).map((item) => {
    if (item.children?.length) {
      return {
        ...item,
        children: item.children.map((child) => ({
          ...child,
          readable: true,
          writable: true,
        })),
      };
    }
    return { ...item, readable: true, writable: true };
  });

export default withUsersLogic;
