import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";

const SlideOverPanel = ({
  open,
  setOpen,
  title = "Details",
  children,
  containerStyle = "",
  subTitle = "",
  isCrossIconVisible = true,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-30" onClose={() => setOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0">
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full">
                <Dialog.Panel
                  className={twMerge(
                    `pointer-events-auto relative w-screen max-w-md ${containerStyle}`,
                  )}>
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-500"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-500"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0">
                    <div className="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4"></div>
                  </Transition.Child>
                  <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="relative flex items-center px-2">
                      {title && (
                        <Dialog.Title className="sticky top-0 m-4 mt-6 flex-1 font-inter text-2xl font-semibold text-gray-900">
                          {title}
                          {subTitle && (
                            <>
                              <br />
                              <span className="text-lg font-normal text-[#717679]">
                                {subTitle}
                              </span>
                            </>
                          )}
                        </Dialog.Title>
                      )}
                      {isCrossIconVisible && (
                        <button
                          type="button"
                          className="relative rounded-md text-2xl text-[#111827] hover:text-black focus:outline-none focus:ring-2 focus:ring-white"
                          onClick={() => setOpen(false)}>
                          <span className="absolute -inset-2.5" />
                          <span className="sr-only">Close panel</span>
                          <XIcon className="h-8 w-8" aria-hidden="true" />
                        </button>
                      )}
                    </div>
                    {/* Scrollable Content */}
                    <div className="relative flex-1 overflow-y-auto">
                      {children}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SlideOverPanel;
