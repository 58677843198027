import withStorageManagementLogic from "../components/HOC/withStorageManagementLogic";
import { useState, Fragment } from "react";
import StorageManagementHome from "../components/storageManagement/StorageManagementHome";
import CreateStorageSection from "../components/storageManagement/CreateStorageSection";
import BarcodeView from "../components/storageManagement/BarcodeView";
import { Tab } from "@headlessui/react";

const StorageManagement = ({
  warehouses,
  selectedStorageSection,
  setSelectedStorageSection,
  onChangeStorageSection,
  storageTypes,
  INITIAL_STORAGE_SECTION_STATE,
  locationTree,
  setLocationTree,
  selectedHierarchy,
  setSelectedHierarchy,
  locationTypes,
  onChangeHierarchy,
  onChangeHierarchyLocation,
  submitSaveHierarchy,
  getCurrentHierarchyArray,
  deleteLocation,
  showAlert,
  storageSections,
  setStorageSections,
  submitCreateStorageSection,
  confirmSubmitCreateStorageSection,
  setConfirmSubmitCreateStorageSection,
  deleteStorageSection,
  storageSectionStatusUpdate,
  setStorageSectionStatusUpdate,
  submitStorageSectionStatusUpdate,
  setLoading,
  buildLocationTreeFromStorageSection,
  submitEditStorageSection,
  locationDeletions,
  setLocationDeletions,
  storageSectionToDelete,
  setStorageSectionToDelete,
  currentHierarchyArray,
  selectedLocations,
  setSelectedLocations,
  shouldSubmitEdit,
  setShouldSubmitEdit,
  submitStorageSectionStatusEdit,
  customers,
  reserveLocation,
  searchReservations,
  locationReservations,
  selectedPrints,
  setSelectedPrints,
  printSelectedBarcodes,
  queueMultiplePrints,
  queueSinglePrint,
  printBarcodesForStorageSection,
  onChangeHierarchyError,
  setOnChangeHierarchyError,
  storageSectionsMap,
  LocationStatusEnum,
}) => {
  const [activeTab, setActiveTab] = useState("location");

  const LocationView = selectedStorageSection ? (
    <CreateStorageSection
      selectedStorageSection={selectedStorageSection}
      setSelectedStorageSection={setSelectedStorageSection}
      onChangeStorageSection={onChangeStorageSection}
      storageTypes={storageTypes}
      locationTree={locationTree}
      setLocationTree={setLocationTree}
      selectedHierarchy={selectedHierarchy}
      setSelectedHierarchy={setSelectedHierarchy}
      locationTypes={locationTypes}
      onChangeHierarchy={onChangeHierarchy}
      onChangeHierarchyLocation={onChangeHierarchyLocation}
      submitSaveHierarchy={submitSaveHierarchy}
      getCurrentHierarchyArray={getCurrentHierarchyArray}
      deleteLocation={deleteLocation}
      showAlert={showAlert}
      submitCreateStorageSection={submitCreateStorageSection}
      confirmSubmitCreateStorageSection={confirmSubmitCreateStorageSection}
      setConfirmSubmitCreateStorageSection={
        setConfirmSubmitCreateStorageSection
      }
      submitEditStorageSection={submitEditStorageSection}
      locationDeletions={locationDeletions}
      setLocationDeletions={setLocationDeletions}
      currentHierarchyArray={currentHierarchyArray}
      selectedLocations={selectedLocations}
      setSelectedLocations={setSelectedLocations}
      shouldSubmitEdit={shouldSubmitEdit}
      setShouldSubmitEdit={setShouldSubmitEdit}
      submitStorageSectionStatusEdit={submitStorageSectionStatusEdit}
      customers={customers}
      reserveLocation={reserveLocation}
      searchReservations={searchReservations}
      locationReservations={locationReservations}
      printSelectedBarcodes={printSelectedBarcodes}
      queueMultiplePrints={queueMultiplePrints}
      queueSinglePrint={queueSinglePrint}
      selectedPrints={selectedPrints}
      setSelectedPrints={setSelectedPrints}
      onChangeHierarchyError={onChangeHierarchyError}
      setOnChangeHierarchyError={setOnChangeHierarchyError}
    />
  ) : (
    <StorageManagementHome
      warehouses={warehouses}
      locationTypes={locationTypes}
      selectedStorageSection={selectedStorageSection}
      setSelectedStorageSection={setSelectedStorageSection}
      setLocationTree={setLocationTree}
      INITIAL_STORAGE_SECTION_STATE={INITIAL_STORAGE_SECTION_STATE}
      storageSections={storageSections}
      setStorageSections={setStorageSections}
      deleteStorageSection={deleteStorageSection}
      storageSectionStatusUpdate={storageSectionStatusUpdate}
      setStorageSectionStatusUpdate={setStorageSectionStatusUpdate}
      submitStorageSectionStatusUpdate={submitStorageSectionStatusUpdate}
      setLoading={setLoading}
      buildLocationTreeFromStorageSection={buildLocationTreeFromStorageSection}
      showAlert={showAlert}
      storageSectionToDelete={storageSectionToDelete}
      setStorageSectionToDelete={setStorageSectionToDelete}
      getCurrentHierarchyArray={getCurrentHierarchyArray}
      printBarcodesForStorageSection={printBarcodesForStorageSection}
      printSelectedBarcodes={printSelectedBarcodes}
      selectedPrints={selectedPrints}
      setSelectedPrints={setSelectedPrints}
    />
  );

  return (
    <div className="min-h-screen bg-white px-3 py-5">
      <div className="text-xl font-semibold">Storage Management</div>
      <div className="mt-1 text-sm font-normal text-gray-500">
        Effortlessly replicate your physical space, creating diverse storage
        types and detailed hierarchies for optimal space utilization and
        streamlined operations.
      </div>

      <Tab.Group
        defaultIndex={0}
        onChange={(index) =>
          setActiveTab(index === 0 ? "location" : "barcode")
        }>
        <div>
          <nav className="flex space-x-8" aria-label="Tabs">
            <Tab.List className="-mb-px flex">
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`text-medium whitespace-nowrap border-b-4 px-1 py-4 font-bold ${
                      selected
                        ? "border-primaryAccent text-base text-gray-900"
                        : "border-transparent text-gray-700 hover:border-gray-500 hover:text-gray-900"
                    } `}>
                    Location View
                  </button>
                )}
              </Tab>
              <Tab as={Fragment}>
                {({ selected }) => (
                  <button
                    className={`text-medium ml-8 whitespace-nowrap border-b-4 px-1 py-4 font-bold ${
                      selected
                        ? "border-primaryAccent text-base text-gray-900"
                        : "border-transparent text-gray-700 hover:border-gray-500 hover:text-gray-900"
                    } `}>
                    Barcode View
                  </button>
                )}
              </Tab>
            </Tab.List>
          </nav>
        </div>
        <Tab.Panels>
          <Tab.Panel className="py-4">{LocationView}</Tab.Panel>
          <Tab.Panel className="py-4">
            <BarcodeView
              warehouses={warehouses}
              locationTypes={locationTypes}
              storageSectionsMap={storageSectionsMap}
              queueSinglePrint={queueSinglePrint}
              selectedPrints={selectedPrints}
              setSelectedPrints={setSelectedPrints}
              printSelectedBarcodes={printSelectedBarcodes}
              LocationStatusEnum={LocationStatusEnum}
              showAlert={showAlert}
            />
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default withStorageManagementLogic(StorageManagement);
