import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  usePDF,
  pdf,
  Font,
  View,
} from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import _ from "lodash";

const BolDoc = ({
  id,
  date,
  pickupDate,
  deliveryDate,
  orderNo,
  weight,
  orderLineItems,
  shippingAddress,
  order,
  tenant,
  thirdPartyFreightChargesBill,
  carrier,
  isFeatherRiverColdTenant = false,
}) => {
  const grandTotal = Object.entries(
    orderLineItems.reduce((acc, item) => {
      const formFactor = item.formFactor || "N/A";
      acc[formFactor] = (acc[formFactor] || 0) + item.quantity;
      return acc;
    }, {}),
  ).map(([formFactor, quantity]) => `${quantity} ${formFactor}`);

  return (
    <div className="bg-white p-4 font-verdana" id={id}>
      <div className="mb-4 flex items-start justify-evenly">
        <div className="text-left">Date {date}</div>
        <div className="flex-1 text-center font-bold">
          BILL OF LADING - SHORT FORM - NOT NEGOTIABLE
        </div>
        <div className="text-right">Page 1 of 1</div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black">
          <div className="h-12 w-full items-center justify-center border-b border-black bg-gray-200 pt-2 text-center font-bold text-black">
            SHIP FROM
          </div>
          <div className="h-20 p-2">
            {tenant?.profile?.businessName}
            <br />
            {tenant?.profile?.shippingAddress}
          </div>
        </div>
        <div className="flex-1 border border-black">
          <div className="ml-2 pt-2 font-bold">
            Bill of Lading Number: {order?.bolNumber}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black">
          <div className="h-12 w-full items-center justify-center border-b border-black bg-gray-200 pt-2 text-center font-bold text-black">
            SHIP TO
          </div>
          <div className="h-20 p-2">
            {shippingAddress?.name}
            <br />
            {shippingAddress?.line1} {shippingAddress?.line2}
            <br />
            {shippingAddress?.city}, {shippingAddress?.state}{" "}
            {shippingAddress?.zip}
          </div>
        </div>
        <div className="flex-1 border border-black">
          <div className="ml-2 pt-2 font-bold">Carrier Name: {carrier}</div>
          <div className="ml-2 pt-4 font-bold">
            PICK UP DATE: {pickupDate === "Invalid date" ? "" : pickupDate}
          </div>
          <div className="ml-2 font-bold">
            DELIVER: {deliveryDate === "Invalid date" ? "" : deliveryDate}
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black">
          <div className="h-12 w-full items-center justify-center border-b border-black bg-gray-200 pt-2 text-center font-bold text-black">
            THIRD PARTY FREIGHT CHARGES BILL TO
          </div>
          <div className="h-20 p-2">{thirdPartyFreightChargesBill}</div>
        </div>
        <div className="flex-1 border border-black">
          <div className="ml-2 pt-2 font-bold">
            SCAC: {order?.bolData?.scacCode}
          </div>
          <div className="ml-2 pt-4 font-bold">
            TRAILER: {order?.bolData?.trailerNumber}
          </div>
          <div className="ml-2 pt-4 font-bold">
            SEAL#: {order?.bolData?.bolSealNumber}
          </div>
          {isFeatherRiverColdTenant && (
            <>
              {order?.bolData?.temperature && (
                <div className="ml-2 pt-4 font-bold">
                  TEMPERATURE: {order?.bolData?.temperature}
                </div>
              )}
              {order?.bolData?.recorder && (
                <div className="ml-2 pt-4 font-bold">
                  RECORDER#: {order?.bolData?.recorder}
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black">
          <div className="h-20 p-2">
            <b>SPECIAL INSTRUCTIONS:</b> <br />
            <br />
            <b>Customer Order No: ASN: {orderNo}</b>
          </div>
        </div>
        <div className="flex-1 border border-black">
          <div className="border-b border-black p-2">
            <b>
              Freight Charge Terms (Freight charges are prepaid unless marked
              otherwise):
            </b>
            <br />
            <input
              type="checkbox"
              checked={order?.bolData?.freightChargeTerms === "Prepaid"}
            />{" "}
            Prepaid{" "}
            <input
              type="checkbox"
              className="ml-2"
              checked={order?.bolData?.freightChargeTerms === "Collect"}
            />{" "}
            Collect
            <input
              type="checkbox"
              className="ml-2"
              checked={order?.bolData?.freightChargeTerms === "3rd Party"}
            />{" "}
            3rd Party
          </div>
          <div className="p-2">
            <input type="checkbox" /> Master bill of lading with attached
            underlying bills of lading
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black">
          <div className="h-12 w-full items-center justify-center border-b border-black bg-gray-200 pt-2 text-center font-bold text-black">
            CUSTOMER ORDER INFORMATION
          </div>
          <div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  SKU
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  Name
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  Lot ID
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  Expiry
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  LPN
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  # of Packages
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  Weight
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  Pallet/Slip
                  <br />
                  (circle one)
                </div>
              </div>
            </div>
            {orderLineItems &&
              orderLineItems.map((item, idx) => (
                <div className="flex" key={idx}>
                  <div className="flex w-full justify-evenly">
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {item.sku}
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {item.productName}
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {item.lotId}
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {item.bestByDate}
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {item.nestedFormFactorId}
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {item.quantity} {item.formFactor}
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {item.weight || item?.attributes?.weight || 0} lbs
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      Y | N
                    </div>
                  </div>
                </div>
              ))}

            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  {""}
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  Y | N
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  <b>Grand Total</b>
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  <b>{grandTotal.join(", ")}</b>
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  <b>
                    {_.sumBy(orderLineItems, (i) => parseFloat(i.weight)) ||
                      "-"}{" "}
                    lbs
                  </b>
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black">
          <div className="h-12 w-full items-center justify-center border-b border-black bg-gray-200 pt-2 text-center font-bold text-black">
            CARRIER INFORMATION
          </div>
          <div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  <b>Handling Unit</b>
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  <b>Package</b>
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="block flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  <b>LTL Only</b>
                </div>
              </div>
            </div>
            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex flex-1 justify-evenly">
                  <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                    Qty
                  </div>
                  <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                    Type
                  </div>
                </div>
                <div className="flex flex-1 justify-evenly">
                  <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                    Qty
                  </div>
                  <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                    Type
                  </div>
                </div>
                <div className="flex flex-1 justify-evenly">
                  <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                    Weight
                  </div>
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                  <div className="block">
                    <b>Commodity Description </b>
                  </div>
                  <div className="p-1" style={{ fontSize: 8 }}>
                    Commodities requiring special or additional care or
                    attention in handling or stowing must be so marked and
                    packaged as to ensure safe transportation with ordinary
                    care. See Section 2(e) of NMFC item 360
                  </div>
                </div>
                <div className="flex flex-1 justify-evenly">
                  <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                    NMFC No.
                  </div>
                </div>
                <div className="flex flex-1 justify-evenly">
                  <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                    Class
                  </div>
                </div>
              </div>
            </div>
            {order?.bolData?.handlingUnits &&
              order?.bolData?.handlingUnits.map((handlingUnit, idx) => (
                <div className="flex" key={idx}>
                  <div className="flex w-full justify-evenly">
                    <div className="flex flex-1 justify-evenly">
                      <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                        {handlingUnit.quantity || 1}
                      </div>
                      <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                        {handlingUnit.type}
                      </div>
                    </div>
                    <div className="flex flex-1 justify-evenly">
                      <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                        {_.sumBy(handlingUnit.items || [], "allocatedQuantity")}
                      </div>
                      <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                        {[
                          ...new Set(
                            handlingUnit.items?.map((i) => i.formFactor) || [],
                          ),
                        ]?.join(", ") || "N/A"}
                      </div>
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center">
                      {handlingUnit.weight || "-"} lbs
                    </div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                    <div className="flex-1 items-center justify-center border border-l-0 border-black pb-2 text-center"></div>
                  </div>
                </div>
              ))}

            <div className="flex">
              <div className="flex w-full justify-evenly">
                <div className="flex flex-1 justify-evenly">
                  <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
                  <div className="flex-1 items-center justify-center border border-l-0 border-black text-center">
                    <div className="invisible">Pallet</div>
                  </div>
                </div>
                <div className="flex flex-1 justify-evenly">
                  <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
                  <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
                </div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
                <div className="flex-1 items-center justify-center border border-l-0 border-black text-center"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 flex items-start justify-evenly pt-4">
        <div className="text-md flex-1">
          Where the rate is dependent on value, shippers are required to state
          specifically in writing the agreed or declared value of the property
          as follows: "The agreed or declared value of the property is
          specifically stated by the shipper to be not exceeding _____________
          per ______________.
        </div>
        <div className="flex-1 pl-4">
          <b>COD Amount: ${order?.bolData?.codAmount || "N/A"}</b>
          <br />
          <br />
          <b className="pr-2">Fee Terms:</b>{" "}
          <input
            type="checkbox"
            checked={order?.bolData?.feeTerms === "Collect"}
          />{" "}
          Collect
          <input
            type="checkbox"
            className="ml-2"
            checked={order?.bolData?.feeTerms === "Prepaid"}
          />{" "}
          Prepaid
          <input
            type="checkbox"
            className="ml-2"
            checked={order?.bolData?.feeTerms === "Customer Check Acceptable"}
          />{" "}
          Customer Check Acceptable
          <br />
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black pb-4">
          <div className="w-full items-center justify-center border-b border-black bg-white py-2 text-center text-sm font-bold text-black">
            Note: Liability Information for loss or damage in this shipment may
            be applicable. See 49 USC § 14706(c)(1)(A) and (B).
          </div>
          <div className="flex">
            <div className="flex-1 p-2 text-sm">
              Received, subject to individually determined rates or contracts
              that have been agreed upon in writing between the carrier and
              shipper, if applicable, otherwise to the rates, classifications,
              and rules that have been established by the carrier and are
              available to the shipper, on request, and to all applicable state
              and federal regulations.
            </div>
            <div className="flex-1">
              The carrier shall not make delivery of this shipment without
              payment of charges and all other lawful fees.
              <br />
              <b style={{ verticalAlign: -10 }}>
                Consignee Signature _______________
              </b>
            </div>
          </div>
        </div>
      </div>
      <div className="flex">
        <div className="flex-1 border border-black">
          <div className="flex">
            <div className="flex-1 p-2 font-bold">
              Shipper Signature / Date
              <br />
              <br />
              __________________________________
              <br />
              <span className="text-xs font-normal">
                This is to certify that the above named materials are properly
                classified, packaged, marked, and labeled, and are in proper
                condition for transportation according to the applicable
                regulations of the DOT.
              </span>
            </div>
            <div className="flex-1 border-l border-r border-black p-2">
              <b>Trailer Loaded:</b>
              <br />
              <input
                type="checkbox"
                checked={order?.bolData?.trailerLoadedBy === "By shipper"}
              />{" "}
              By Shipper
              <br />
              <input
                type="checkbox"
                checked={order?.bolData?.trailerLoadedBy === "By driver"}
              />{" "}
              By driver
              <br />
            </div>
            <div className="flex-1 border-l border-r border-black p-2">
              <b>Freight Counted:</b>
              <br />
              <input
                type="checkbox"
                checked={order?.bolData?.freightCountedBy === "By Shipper"}
              />{" "}
              By Shipper
              <br />
              <input
                type="checkbox"
                checked={
                  order?.bolData?.freightCountedBy ===
                  "By driver/pallets said to contain"
                }
              />{" "}
              By driver/pallets said to contain
              <br />
              <input
                type="checkbox"
                checked={
                  order?.bolData?.freightCountedBy === "By driver/pieces"
                }
              />{" "}
              By driver/pieces
              <br />
            </div>
            <div className="flex-1 p-1 font-bold">
              Carrier Signature / Pickup Date
              <br />
              <br />
              __________________________________
              <br />
              <span className="text-sm font-normal">
                Carrier acknowledges receipt of packages and required placards.
                Carrier certifies emergency response information was made
                available and/or carrier has the DOT emergency response
                guidebook or equivalent documentation in the vehicle. Property
                described above is received in good order, except as noted.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BolDoc;
