import { useState } from "react";
import { XIcon } from "@heroicons/react/solid";
import ReactTooltip from "react-tooltip";
import {
  findNodeByIdRecursive,
  findParentNodeById,
  deselectAllChildren,
  convertData,
} from "./EnhancedCategoriesSelect";
import { mapSelectedValuesToNames } from "./EnhancedCategoriesSelect";

// TODO :- make this component more generic
const SelectedFilters = ({
  filters,
  filter,
  maxCount,
  onChangeFilter,
  isClickable = true,
}) => {
  const [showAllFilters, setShowAllFilters] = useState(false);
  const isCategoryOrCustomer =
    filter.filterName === "category" ||
    filter.filterName === "selectedCustomers";

  const handleFilterClick = (filterValue) => {
    if (isCategoryOrCustomer) {
      onChangeFilter(
        filter.filterName,
        deselectCategory(
          filterValue.id,
          filters[filter.filterName],
          filter.options,
        ),
        true,
      );
    } else {
      onChangeFilter(
        filter.filterName,
        filters[filter.filterName].filter((item) => item !== filterValue)
          .length > 0
          ? filters[filter.filterName].filter((item) => item !== filterValue)
          : null,
        true,
      );
    }
  };

  // only for product categories

  let fullStringValuesForCategories = [];
  let filtersData = [];

  if (isCategoryOrCustomer && filters[filter.filterName] && filter.options) {
    fullStringValuesForCategories = mapSelectedValuesToNames(
      filters[filter.filterName],
      filter.options,
      true,
    );
    filtersData = mapSelectedValuesToNames(
      filters[filter.filterName],
      filter.options,
    )?.map((value, index) => {
      return [value, fullStringValuesForCategories[index]]; // [shortString, fullString] ==> [value, tooltip]
    });
  }

  return (
    <div className="p-2 text-sm">
      <div className="flex flex-wrap gap-2">
        {filters[filter.filterName] &&
          filters[filter.filterName]
            .slice(0, maxCount)
            .map((filterValue, idx) => (
              <div
                className="flex max-w-xs items-center truncate rounded-full border border-gray-300 bg-white px-3 py-1"
                key={idx}
                data-tip={
                  isCategoryOrCustomer ? filtersData?.[idx]?.[1] : filterValue
                }
                data-for={
                  isCategoryOrCustomer
                    ? `tooltip-${filterValue.id}`
                    : `tooltip-${filterValue}+${idx}`
                }>
                <span className="truncate">
                  {(isCategoryOrCustomer
                    ? filtersData?.[idx]?.[0]
                    : filterValue) || null}
                </span>
                {isClickable && (
                  <span
                    className="ml-2 cursor-pointer"
                    onClick={() => handleFilterClick(filterValue)}>
                    <XIcon className="h-4 w-4 text-gray-600" />
                  </span>
                )}
                <ReactTooltip
                  id={
                    isCategoryOrCustomer
                      ? `tooltip-${filterValue.id}`
                      : `tooltip-${filterValue}+${idx}`
                  }
                  place="top"
                  type="dark"
                  effect="solid"
                />
              </div>
            ))}
        {showAllFilters &&
          filters[filter.filterName] &&
          filters[filter.filterName].slice(maxCount).map((filterValue, idx) => (
            <div
              className="flex max-w-xs items-center truncate rounded-full border border-gray-300 bg-white px-3 py-1"
              key={idx}
              data-tip={
                isCategoryOrCustomer
                  ? filtersData?.[maxCount + idx]?.[1]
                  : filterValue
              }
              data-for={
                isCategoryOrCustomer
                  ? `tooltip-${filterValue.id}`
                  : `tooltip-${filterValue}+${idx}`
              }>
              <span className="truncate">
                {(isCategoryOrCustomer
                  ? filtersData?.[maxCount + idx]?.[0]
                  : filterValue) || null}
              </span>
              {isClickable && (
                <span
                  className="ml-2 cursor-pointer"
                  onClick={() => handleFilterClick(filterValue)}>
                  <XIcon className="h-4 w-4 text-gray-600" />
                </span>
              )}
              <ReactTooltip
                id={
                  isCategoryOrCustomer
                    ? `tooltip-${filterValue.id}`
                    : `tooltip-${filterValue}+${idx}`
                }
                place="top"
                type="dark"
                effect="solid"
              />
            </div>
          ))}
        {filters[filter.filterName] &&
          filters[filter.filterName].length > maxCount && (
            <div
              className="flex h-8 w-8 cursor-pointer items-center justify-center rounded-full border border-gray-300 bg-white"
              onClick={() => setShowAllFilters(!showAllFilters)}>
              <span className="text-xs">
                {showAllFilters
                  ? "less"
                  : `+${filters[filter.filterName].length - maxCount}`}
              </span>
            </div>
          )}
      </div>
    </div>
  );
};

const deselectCategory = (deselectedCategoryId, selectedValues, data) => {
  // Find the deselected category
  const deselectedNode = findNodeByIdRecursive(
    convertData(data)[0],
    deselectedCategoryId,
  );
  if (!deselectedNode) return selectedValues;

  // Deselect the category and its children
  let newValues = [...selectedValues];
  newValues = deselectAllChildren(deselectedNode, newValues);

  // Check and deselect parents if necessary
  let currentNode = deselectedNode;
  while (currentNode) {
    const parentNode = findParentNodeById(convertData(data)[0], currentNode.id);
    if (parentNode) {
      const allChildrenDeselected = Object.values(parentNode.children).every(
        (child) => !newValues.some((item) => item.id === child.id),
      );
      if (allChildrenDeselected) {
        newValues = newValues.filter((item) => item.id !== parentNode.id);
      }
    }
    currentNode = parentNode;
  }

  return newValues;
};

export default SelectedFilters;
