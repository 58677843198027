import {
  BookmarkIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  FilterIcon,
  SearchIcon,
  SwitchHorizontalIcon,
  XCircleIcon,
  XIcon,
  PencilIcon,
  TrashIcon,
  ShareIcon,
} from "@heroicons/react/outline";
import { useEffect, useState, useContext, useRef } from "react";
import { Slider } from "antd";
import { pluralize } from "../../../utils/helper-functions";
import noFilterIcon from "#static/images/nofilter.png";
import SectionTabs from "./SectionTabs";
import CatalogMainContent from "./CatalogMainContent";
import AutocompleteMultiSelectDropdown from "#components/utils/AutocompleteMultiSelectDropdown";
import SaveNewBookmark from "./SaveNewBookmark";
import { useQuery } from "#hooks/useQuery";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { GET_CATALOGS, GET_PRODUCTS } from "#queries";
import { UPSERT_CATALOG, DELETE_CATALOG } from "#mutations";
import SlideOverPanel from "#components/common/SlideOverPanel";
import ManageCatalogForm from "#components/catalogs/ManageCatalogForm";
import EnhancedCategoriesSelect from "./EnhancedCategoriesSelect";
import { AppStateContext } from "#contexts/appState";
import SelectedFilters from "./SelectedFilters";
import CatalogProductActions from "./CatalogProductActions";

const ALERT_TIMEOUT_IN_MS = 5000;

const INITIAL_SALES_PRICE_RANGE = {
  from: 0,
  to: 10000,
};

export const initialCatalog = {
  name: "",
  products: [],
  displayProducts: [],
};

const ManageCatalog = ({
  products,
  total,
  filters,
  onChangeFilter,
  submitFilters,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  productTags,
  getCatalog,
  fetchedCatalog,
  setFetchedCatalog,
  pageSavedFilters,
  applyBookmarkFilters,
  saveBookmark,
  setFilterQueryOperator,
  filterQueryOperator,
  productCategories,
  filteringCriteria,
  clearFilters,
  loadFirstTimeData,
  setExportListHeaders,
  handleScroll,
  getSpecificProduct,
  setFilters,
  customers,
  parentProducts,
  customerList,
  salesPriceRange,
  fetchSalesPriceMinMaxValue,
}) => {
  const actionsOnBookmark = (bookmark) => {
    return [
      {
        name: "Remove",
        value: "delete",
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => {
          setCurrentBookmark(null);
          saveBookmark(bookmark, true);
        },
      },
    ];
  };

  const actionOnCatalog = (catalog) => {
    return [
      {
        name: "Share Catalog",
        value: "edit",
        icon: <ShareIcon className="h-6 w-6" />,
        onClick: () => {},
      },
      {
        name: "Edit Catalog",
        value: "edit",
        icon: <PencilIcon className="h-6 w-6" />,
        onClick: () => fetchCatalog(catalog?.id),
      },
      {
        name: "Delete Catalog",
        value: "delete",
        icon: <TrashIcon className="h-6 w-6" />,
        onClick: () => openCatalogDeleteConfirmation(catalog),
      },
    ];
  };
  const upsertCatalogQuery = useQuery(UPSERT_CATALOG);
  const deleteCatalogQuery = useQuery(DELETE_CATALOG);
  const [componentAlert, setComponentAlert] = useState(null);
  const getCatalogsQuery = useQuery(GET_CATALOGS);
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const catalogs = useRef([]);
  const [filteredCatalogs, setFilteredCatalogs] = useState([]);
  const [savingNewBookmark, setSavingNewBookmark] = useState(null);
  const [showFilterSelection, setShowFilterSelection] = useState(false);
  const [showBookmarkSelection, setShowBookmarkSelection] = useState(false);
  const [currentBookmark, setCurrentBookmark] = useState(null);
  const [showCatalogSelection, setShowCatalogSelection] = useState(false);
  const [showLeftSidebar, setShowLeftSidebar] = useState(true);
  const [addNewCatalog, setAddNewCatalog] = useState(null);
  const [productQueryPageNumber, setProductQueryPageNumber] = useState(1);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [isProductsLoading, setIsProductsLoading] = useState(false);
  const [deleteCatalogId, setDeleteCatalogId] = useState();
  const [priceRange, setPriceRange] = useState(null);
  const [isDisableGoBtn, setIsDisableGoBtn] = useState(true);
  const [visibleSliderTooltip, setVisibleSliderTooltip] = useState(true);
  const appState = useContext(AppStateContext);
  const filterBoxRef = useRef(null);
  const bookMarkBoxRef = useRef(null);
  const catalogFilterContainerRef = useRef(null);
  const salesPriceRangeElementRef = useRef(null);

  useEffect(() => {
    fetchInitialData();
  }, []);

  const fetchCatalog = async (catalogId) => {
    try {
      const response = await getCatalogsQuery.fetchData({
        perPage: 25,
        pageNumber: 1,
        filters: {
          catalogId: catalogId,
        },
      });

      if (response?.data) {
        setAddNewCatalog({
          id: response?.data?.catalogs?.entities?.[0]?.id,
          name: response?.data?.catalogs?.entities?.[0]?.name,
          displayProducts: response?.data?.catalogs?.entities?.[0]?.products,
          products: response?.data?.catalogs?.entities?.[0]?.products || [],
        });
      }
    } catch (e) {}
  };

  const fetchCatalogProducts = async (
    pageNo = 1,
    productIds = [],
    category = [],
    keyword = "",
    searchFields = [],
  ) => {
    setIsProductsLoading(true);
    try {
      const response = await getProductsQuery.fetchData({
        perPage: 25,
        pageNumber: pageNo,
        filters: {
          id: productIds,
          category: category,
          keyword: keyword,
          searchFields: searchFields,
        },
      });
      if (response?.data) {
        const existCatalogData = { ...addNewCatalog };
        setAddNewCatalog({
          ...existCatalogData,
          displayProducts: [
            ...(existCatalogData?.displayProducts || []),
            ...(response.data?.products?.entities || []),
          ],
          parentProducts: [
            ...(existCatalogData?.parentProducts || []),
            ...(response.data?.products?.parentProducts || []),
          ],
          total: response.data?.products?.total,
        });
      }
    } catch (err) {
      console.log("error while loading products from catalog management ", err);
    } finally {
      setIsProductsLoading(false);
    }
  };

  const fetchInitialData = async () => {
    const catalogsResponse = await getCatalogsQuery.fetchData();

    if (catalogsResponse.data) {
      catalogs.current = catalogsResponse.data.catalogs.entities;
      setFilteredCatalogs(catalogsResponse.data.catalogs.entities);
    }
  };

  const isElementHidden = () => {
    const rect = salesPriceRangeElementRef.current?.getBoundingClientRect();
    const containerRect =
      catalogFilterContainerRef.current?.getBoundingClientRect();

    const isHidden =
      rect?.top >= containerRect?.bottom || rect?.bottom <= containerRect?.top;
    if (isHidden) {
      if (visibleSliderTooltip) setVisibleSliderTooltip(false);
    }
  };

  useEffect(() => {
    if (filters && Object.keys(filters).length > 0) {
      const setFilters = [];
      Object.keys(filters).forEach((key) => {
        if (
          filters[key] &&
          (filters[key].length > 0 ||
            (key === "salesPriceRange" &&
              Object.keys(filters[key])?.length > 0))
        ) {
          const filter = filteringCriteria.find((f) => f.filterName === key);
          // || key === "catalog";
          if (filter) {
            setFilters.push(filter);
          }
        }
      });
      setSelectedFilters(setFilters);
    }
  }, [filters, filteringCriteria]);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleFilterCriteriaClick = (criteria) => {
    if (criteria.filterName === "salesPriceRange")
      setVisibleSliderTooltip(true);
    setShowFilterSelection(false);
    selectedFilters.push(criteria);
  };

  const handleCatalogSelection = (catalog) => {
    setShowCatalogSelection(false);
    if (catalog) {
      onChangeFilter("catalog", [catalog], true);
    } else {
      onChangeFilter("catalog", null, true);
    }
  };

  const handleSavedFilterClick = (savedFilter) => {
    setCurrentBookmark(savedFilter);
    setShowBookmarkSelection(false);

    if (savedFilter && Object.keys(savedFilter.filters).length > 0) {
      applyBookmarkFilters(savedFilter);
    } else {
      clearFilters();
    }
  };

  const saveBookmarkHandler = () => {
    saveBookmark(savingNewBookmark).then((bookmarkResponse) => {
      if (bookmarkResponse?.data) setSavingNewBookmark(null);
    });
  };

  const handleCreateCatalogClick = () => {
    setAddNewCatalog(initialCatalog);
  };

  const fetchCatalogProductDetails = async (productIds) => {
    try {
      const response = await getProductsQuery.fetchData({
        perPage: productIds.length,
        pageNumber: 1,
        filters: {
          id: productIds,
        },
      });
      if (response?.data) {
        setAddNewCatalog({
          ...addNewCatalog,
          products: [...(response.data?.products?.entities || [])],
          displayProducts: [...(response.data?.products?.entities || [])],
        });
      }
    } catch (e) {}
  };

  useDebouncedEffect(
    () => {
      if (priceRange) {
        if (
          priceRange?.from &&
          (priceRange?.from < salesPriceRange?.from ||
            priceRange?.from > salesPriceRange?.to)
        ) {
          setIsDisableGoBtn(true);
          appState.setAlert(
            `You can not enter minimum value less than ${salesPriceRange?.from} and greater than ${salesPriceRange?.to}`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        } else if (
          priceRange?.to &&
          (priceRange?.to > salesPriceRange?.to ||
            priceRange?.to < salesPriceRange?.from)
        ) {
          setIsDisableGoBtn(true);
          appState.setAlert(
            `You can not enter maximum value greater than ${salesPriceRange?.to} and less than ${salesPriceRange?.from}`,
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
        } else if (
          (!priceRange?.from && !priceRange?.to) ||
          (priceRange?.from &&
            priceRange?.to &&
            priceRange.from > priceRange.to)
        ) {
          setIsDisableGoBtn(true);
        } else setIsDisableGoBtn(false);
      }
    },
    500,
    [priceRange],
  );

  useEffect(() => {
    if (addNewCatalog?.id) {
      if (addNewCatalog?.products?.length > 0) {
        if (
          addNewCatalog.products.every(
            (productValue) => typeof productValue !== "object",
          )
        ) {
          fetchCatalogProductDetails(addNewCatalog.products);
        }
      }
    }
  }, [addNewCatalog]);

  useEffect(() => {
    if (showFilterSelection) {
      setShowBookmarkSelection(false);
      setShowCatalogSelection(false);
    }
  }, [showFilterSelection]);

  useEffect(() => {
    if (showBookmarkSelection) {
      setShowFilterSelection(false);
      setShowCatalogSelection(false);
    }
  }, [showBookmarkSelection]);

  useEffect(() => {
    if (showCatalogSelection) {
      setShowBookmarkSelection(false);
      setShowFilterSelection(false);
    }
  }, [showCatalogSelection]);

  const [filterCriteriaTabs, setFilterCriteriaTabs] = useState([
    {
      name: "Any Criteria",
      current: filterQueryOperator !== "$and",
      tooltip: "Products that match any of the selected criteria",
      value: "$or",
    },
    {
      name: "All Criteria",
      current: filterQueryOperator === "$and",
      tooltip: "Products that match all of the selected criteria",
      value: "$and",
    },
  ]);

  useEffect(() => {
    if (filterQueryOperator === "$and" || filterQueryOperator === "$or") {
      onChangeFilter(null, null, true, "onChangeQueryOperator");
    }
  }, [filterQueryOperator]);

  useEffect(() => {
    if (filterCriteriaTabs) {
      const selectedTab = filterCriteriaTabs.find((tab) => tab.current);
      setFilterQueryOperator(selectedTab.value);
    }
  }, [filterCriteriaTabs]);

  useEffect(() => {
    if (currentBookmark && currentBookmark.queryOperator) {
      const selectedTab = filterCriteriaTabs.find(
        (tab) => tab.value === currentBookmark.queryOperator,
      );
      setFilterCriteriaTabs(
        filterCriteriaTabs.map((tab) => {
          tab.current = tab.name === selectedTab.name;
          return tab;
        }),
      );
    }
  }, [currentBookmark]);

  const trySaveBookmark = () => {
    if (selectedFilters.length > 0) {
      if (!currentBookmark) {
        const bookmark = {
          name: "New Bookmark",
          filters,
        };
        setCurrentBookmark(bookmark);
        setSavingNewBookmark(bookmark);
      } else {
        saveBookmark(currentBookmark);
      }
    }
  };

  const confirmDeleteCatalog = async (catalogId) => {
    appState.setLoading();
    const deleteCatalogResponse = await deleteCatalogQuery.fetchData({
      id: catalogId,
    });
    appState.removeLoading();
    if (deleteCatalogResponse?.data) {
      if (deleteCatalogResponse.data?.deleteCatalog?.message) {
        appState.setAlert(
          deleteCatalogResponse.data.deleteCatalog.message,
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        fetchInitialData();
        onChangeFilter("catalog", null, true);
      } else {
        appState.setAlert(
          deleteCatalogResponse.data?.deleteCatalog?.error ||
            "Failed to delete catalog.",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    } else if (deleteCatalogResponse.error) {
      appState.setAlert(
        deleteCatalogResponse.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  };

  const submitCatalog = async () => {
    if (addNewCatalog?.name && (addNewCatalog.name || "").trim() !== "") {
      const catalogProducts = (addNewCatalog?.products || [])?.map(
        (productObject) => productObject?.id,
      );
      appState.setLoading();
      const response = await upsertCatalogQuery.fetchData({
        id: addNewCatalog?.id,
        name: addNewCatalog?.name,
        products: catalogProducts,
      });
      appState.removeLoading();
      if (response.data) {
        setAddNewCatalog(null);
        setSelectedCategories([]);
        fetchInitialData();
        appState.setDismissableAlert(
          addNewCatalog?.id
            ? "Catalog has been updated successfully"
            : "New Catalog has been created successfully",
          "success",
          ALERT_TIMEOUT_IN_MS,
        );
        if (addNewCatalog?.id)
          onChangeFilter("catalog", [addNewCatalog.name], true);
      }
      if (response.error) {
        setComponentAlert({
          msg: response.error.message,
          alertType: "error",
        });
      }
    } else {
      setComponentAlert({
        msg: "Please enter a valid catalog name",
        alertType: "error",
      });
    }
  };

  const getAllSubcategories = (baseCategory, options) => {
    const basePattern = `${baseCategory}/`;
    return options.filter((option) => option.name.startsWith(basePattern));
  };

  const handleCategorySelection = (currentValues, addedValues, options) => {
    let updatedValues = [...currentValues];

    addedValues.forEach((value) => {
      if (!updatedValues.includes(value)) {
        updatedValues.push(value);
      }
      const subcategories = getAllSubcategories(value, options);
      subcategories.forEach((sub) => {
        if (!updatedValues.includes(sub.name)) {
          updatedValues.push(sub.name);
        }
      });
    });

    return updatedValues;
  };

  const handleCategoryDeselection = (currentValues, removedValues, options) => {
    return currentValues.filter(
      (value) =>
        !removedValues.some(
          (removedValue) =>
            value === removedValue || value.startsWith(`${removedValue}/`),
        ),
    );
  };

  const openCatalogDeleteConfirmation = (catalog) => {
    appState.showNewConfirmation(
      "Delete Catalog",
      <span>
        Are you sure you want to delete{" "}
        <span className="font-semibold text-primaryAccent">
          {catalog?.name}
        </span>
        ? This action cannot be undone.
      </span>,
      () => confirmDeleteCatalog(catalog?.id),
      () => {},
    );
  };

  const handleClickOutsideOfSalesPrice = (event) => {
    if (
      salesPriceRangeElementRef.current &&
      !salesPriceRangeElementRef.current.contains(event.target)
    ) {
      if (visibleSliderTooltip) {
        setVisibleSliderTooltip(false);
      }
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterBoxRef.current && !filterBoxRef.current.contains(event.target))
        setShowFilterSelection(false);
      if (
        bookMarkBoxRef.current &&
        !bookMarkBoxRef.current.contains(event.target)
      )
        setShowBookmarkSelection(false);
    };

    if (showFilterSelection || showBookmarkSelection) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showFilterSelection, showBookmarkSelection]);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutsideOfSalesPrice);
    return () => {
      document.removeEventListener("mousedown", handleClickOutsideOfSalesPrice);
    };
  }, []);

  return (
    <>
      {addNewCatalog && (
        <SlideOverPanel
          open={true}
          setOpen={setAddNewCatalog}
          title={!addNewCatalog?.id ? "Create Catalog" : "Update Catalog"}
          containerStyle={"max-w-7xl"}
          subTitle="Manage catalog name and associated products">
          <ManageCatalogForm
            catalog={addNewCatalog}
            setCatalog={setAddNewCatalog}
            submitCatalog={submitCatalog}
            productCategories={productCategories}
            componentAlert={componentAlert}
            setComponentAlert={setComponentAlert}
            fetchCatalogProducts={fetchCatalogProducts}
            setSelectedCategories={setSelectedCategories}
            selectedCategories={selectedCategories}
            isProductsLoading={isProductsLoading}
            productQueryPageNumber={productQueryPageNumber}
            setProductQueryPageNumber={setProductQueryPageNumber}
            customerList={customerList}
          />
        </SlideOverPanel>
      )}
      <div className="mt-4 flex space-x-4">
        <div
          className={`hs-catalog-sidebar h-[72vh] transition-transform duration-500 ease-in-out hs-catalog-sidebar-${showLeftSidebar} ${
            showLeftSidebar ? "translate-x-0" : "-translate-x-full"
          }`}>
          <div
            className={`hs-sidebar-section-action absolute -top-3 bg-white transition-transform duration-500 ease-in-out ${
              showLeftSidebar ? "rotate-0" : "rotate-180"
            }`}
            style={{ right: "-1.15rem" }}
            onClick={() => setShowLeftSidebar((prevState) => !prevState)}>
            <SwitchHorizontalIcon className="h-5 w-5" />
          </div>
          {showLeftSidebar && (
            <div className="h-full p-4">
              <div className="text-lg text-[#8A8B8E]">
                Catalog ({catalogs.current.length})
              </div>
              <div className="relative">
                <div className="flex items-center justify-between text-xl text-[#111827]">
                  <div>
                    {filters?.catalog?.[0] || "No Catalog"}{" "}
                    {showCatalogSelection ? (
                      <ChevronUpIcon
                        className="h-8 w-8"
                        onClick={() =>
                          setShowCatalogSelection((prevState) => !prevState)
                        }
                      />
                    ) : (
                      <ChevronDownIcon
                        className="h-8 w-8"
                        onClick={() =>
                          setShowCatalogSelection((prevState) => !prevState)
                        }
                      />
                    )}
                  </div>
                  {filters?.catalog?.[0] && (
                    <CatalogProductActions
                      actionsOnProduct={actionOnCatalog}
                      product={catalogs.current.find(
                        (catalog) =>
                          catalog.name.toLowerCase() ===
                          (filters?.catalog?.[0] || "").toLowerCase(),
                      )}
                    />
                  )}
                </div>
                {showCatalogSelection && (
                  <div className="absolute z-40 mt-2 h-[57vh] w-96 rounded-lg border bg-white p-4 text-lg font-semibold">
                    <div>
                      <div className="relative mt-2 rounded-md shadow-sm">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                          <SearchIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </div>
                        <input
                          type="search"
                          name="search"
                          id="search"
                          className="block w-full rounded-md border-0 py-1.5 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                          placeholder="Search"
                          onChange={(e) =>
                            setFilteredCatalogs((prev) => {
                              return catalogs.current.filter((catalog) =>
                                catalog.name
                                  .toLowerCase()
                                  .includes(e.target.value.toLowerCase()),
                              );
                            })
                          }
                        />
                      </div>
                    </div>
                    <div
                      className="overflow-y-auto"
                      style={{ height: "calc(57vh - 140px)" }}>
                      {filteredCatalogs &&
                        filteredCatalogs.map((catalog, index) => (
                          <div
                            key={index}
                            className="mt-2 flex cursor-pointer p-2"
                            onClick={() =>
                              handleCatalogSelection(catalog.name)
                            }>
                            <div className="flex-1 text-lg font-normal">
                              {catalog.name}
                            </div>
                            <div className="ml-2 text-lg font-light text-gray-500">
                              {pluralize(catalog.products?.length, "Product")}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div>
                      <div className="flex justify-between">
                        <button
                          className="mt-4 rounded-lg py-2 pr-6 text-lg text-primaryAccent"
                          onClick={handleCreateCatalogClick}>
                          + Create Catalog
                        </button>

                        <button
                          className="mt-4 rounded-lg py-2 pr-6 text-lg text-primaryAccent"
                          onClick={() => handleCatalogSelection(null)}>
                          Clear
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div className="mt-2 flex items-center justify-center space-x-4">
                <div className="hs-sidebar-section-title flex-1 text-[#111827]">
                  Filter
                </div>
                <div className="relative">
                  <div
                    className={`hs-sidebar-section-action relative ${
                      showBookmarkSelection &&
                      "hs-sidebar-section-action-active"
                    }`}
                    onClick={() =>
                      setShowBookmarkSelection((prevState) => !prevState)
                    }>
                    <BookmarkIcon className="h-5 w-5" />
                  </div>
                  {showBookmarkSelection && (
                    <div
                      ref={bookMarkBoxRef}
                      className="absolute z-10 mt-2 w-96 rounded-lg border bg-white p-4 text-lg font-semibold shadow-2xl">
                      <div className="flex items-center">
                        <div>Bookmarks</div>
                        <div>
                          <button
                            className="rounded-lg py-2 pr-6 text-lg text-primaryAccent"
                            onClick={() => handleSavedFilterClick(null)}>
                            (Clear)
                          </button>
                        </div>
                      </div>
                      <div>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <div className="max-h-[35vh] overflow-y-auto">
                        {pageSavedFilters &&
                          pageSavedFilters?.map((savedFilter, index) => (
                            <div
                              key={index}
                              className="mt-2 flex cursor-pointer justify-between p-2">
                              <div className="flex flex-col text-lg font-normal">
                                <span
                                  onClick={() =>
                                    handleSavedFilterClick(savedFilter)
                                  }>
                                  {savedFilter.name}
                                </span>
                                <span className="text-lg font-light text-gray-500">
                                  {pluralize(
                                    Object.keys(savedFilter.filters).length,
                                    "Filter",
                                  )}
                                </span>
                              </div>
                              <CatalogProductActions
                                actionsOnProduct={actionsOnBookmark}
                                product={savedFilter}
                              />
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
                <div className="relative">
                  <div
                    className={`hs-sidebar-section-action relative ${
                      showFilterSelection && "hs-sidebar-section-action-active"
                    }`}
                    onClick={() =>
                      setShowFilterSelection((prevState) => !prevState)
                    }>
                    <FilterIcon className="h-5 w-5" />
                  </div>
                  {showFilterSelection && (
                    <div
                      ref={filterBoxRef}
                      className="absolute z-50 mt-2 w-96 rounded-lg border bg-white p-4 text-lg font-semibold shadow-2xl">
                      <div>Add Filter</div>
                      <div>
                        <div className="relative mt-2 rounded-md shadow-sm">
                          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <SearchIcon
                              className="h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </div>
                          <input
                            type="search"
                            name="search"
                            id="search"
                            className="block w-full rounded-md border-0 py-1.5 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                            placeholder="Search"
                          />
                        </div>
                      </div>
                      <div>
                        {filteringCriteria
                          .filter(
                            (i) =>
                              !selectedFilters.find((f) => f.name === i.name),
                          )
                          .map((criteria, index) => (
                            <div
                              key={index}
                              className="mt-2 flex cursor-pointer p-2"
                              onClick={() =>
                                handleFilterCriteriaClick(criteria)
                              }>
                              <div className="flex-1 text-lg font-normal">
                                {criteria.name}
                              </div>
                              {criteria.filterName !== "salesPriceRange" && (
                                <div className="ml-2 text-lg font-light text-gray-500">
                                  {pluralize(criteria.options.length, "Item")}
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {selectedFilters.length === 0 && (
                <div className="m-auto flex flex-col items-center justify-center pb-5 text-center">
                  <img
                    src={noFilterIcon}
                    className="h-[170px] justify-center"
                  />
                  <div className="mt-4 text-center font-inter text-xl font-semibold text-black">
                    No Filters selected
                  </div>
                  <div className="mt-2 text-center font-inter text-lg font-light text-gray-500">
                    Add filters from the filter icon on top and save it for
                    future usage
                  </div>
                  <button
                    className="mt-4 rounded-lg bg-primaryAccent px-6 py-2 text-white"
                    onClick={() => setShowFilterSelection(true)}>
                    Add Filter
                  </button>
                </div>
              )}
              {selectedFilters.length > 0 && (
                <div
                  ref={catalogFilterContainerRef}
                  style={{ height: "75%" }}
                  className="flex-1 grow overflow-y-auto p-4"
                  onScroll={() => isElementHidden()}>
                  <SectionTabs
                    tabs={filterCriteriaTabs}
                    setTabs={setFilterCriteriaTabs}
                  />
                  <div className="mt-4 space-y-4">
                    {selectedFilters.map((filter, index) => (
                      <div key={index}>
                        {filter.filterName !== "salesPriceRange" && (
                          <div className="text-lg font-normal">
                            {filter.name}
                          </div>
                        )}
                        <div>
                          <div
                            className={`${filter.filterName === "salesPriceRange" ? "justify-between" : ""} flex items-center`}>
                            {filter.filterName === "category" ||
                            filter.filterName === "selectedCustomers" ? (
                              <EnhancedCategoriesSelect
                                filterTitle={
                                  filter.filterName === "selectedCustomers"
                                    ? filter.name
                                    : ""
                                }
                                options={filter.options}
                                preValues={filters[filter.filterName]}
                                onChangeCustomSelect={(values) => {
                                  onChangeFilter(
                                    filter.filterName,
                                    values,
                                    true,
                                  );
                                }}
                              />
                            ) : filter.filterName !== "salesPriceRange" ? (
                              <>
                                {" "}
                                <AutocompleteMultiSelectDropdown
                                  className="tagsFilterDropdown"
                                  key={index}
                                  options={filter.options}
                                  labelKey={"name"}
                                  valueKey={"name"}
                                  values={filters[filter.filterName]}
                                  placeholder={`${
                                    filters[filter.filterName]?.length || 0
                                  } selected`}
                                  onChange={(values) => {
                                    const previousValues =
                                      filters[filter.filterName] || [];
                                    const addedValues = values?.filter(
                                      (x) => !previousValues.includes(x),
                                    );
                                    const removedValues = previousValues.filter(
                                      (x) => !values?.includes(x),
                                    );

                                    if (filter.filterName === "category") {
                                      let updatedValues = [...previousValues];

                                      updatedValues = handleCategoryDeselection(
                                        updatedValues,
                                        removedValues,
                                        filter.options,
                                      );

                                      updatedValues = handleCategorySelection(
                                        updatedValues,
                                        addedValues,
                                        filter.options,
                                      );

                                      onChangeFilter(
                                        filter.filterName,
                                        updatedValues,
                                        true,
                                      );
                                    } else {
                                      onChangeFilter(
                                        filter.filterName,
                                        values && values.length ? values : null,
                                        true,
                                      );
                                    }
                                  }}
                                />
                              </>
                            ) : (
                              <div className="relative w-96">
                                <div className="flex flex-wrap gap-1">
                                  <div className="text-lg font-normal">
                                    {filter.name}
                                  </div>
                                </div>
                                <div>
                                  <div className="mb-11 mt-10">
                                    <div
                                      ref={salesPriceRangeElementRef}
                                      onFocus={() =>
                                        setVisibleSliderTooltip(true)
                                      }
                                      onMouseEnter={() =>
                                        setVisibleSliderTooltip(true)
                                      }>
                                      <Slider
                                        className="sales-price-range"
                                        range
                                        defaultValue={
                                          priceRange?.from || priceRange?.to
                                            ? [
                                                priceRange?.from ||
                                                  salesPriceRange.from ||
                                                  0,
                                                priceRange?.to ||
                                                  salesPriceRange.to ||
                                                  10000,
                                              ]
                                            : salesPriceRange?.to
                                              ? [
                                                  salesPriceRange.from,
                                                  salesPriceRange.to,
                                                ]
                                              : [0, 0]
                                        }
                                        value={
                                          priceRange?.from || priceRange?.to
                                            ? [
                                                priceRange?.from ||
                                                  salesPriceRange.from,
                                                priceRange?.to ||
                                                  salesPriceRange.to,
                                              ]
                                            : [
                                                salesPriceRange.from,
                                                salesPriceRange.to,
                                              ]
                                        }
                                        disabled={false}
                                        styles={{
                                          track: { background: "#224E73" },
                                        }}
                                        step={0.01}
                                        min={salesPriceRange?.from || 0}
                                        max={salesPriceRange?.to || 10000}
                                        tooltip={{
                                          open: true,
                                          formatter: (value) => `$${value}`,
                                          arrow: false,
                                          placement: "bottom",
                                          zIndex: 10,
                                          overlayStyle: {
                                            display: visibleSliderTooltip
                                              ? "block"
                                              : "none",
                                          },
                                        }}
                                        onChange={(newValue) =>
                                          setPriceRange({
                                            from: Number(newValue[0]),
                                            to: Number(newValue[1]),
                                          })
                                        }
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            <XCircleIcon
                              onClick={() => {
                                if (
                                  filter.filterName === "category" ||
                                  filter.filterName === "selectedCustomers"
                                )
                                  onChangeFilter(filter.filterName, [], true);
                                else
                                  onChangeFilter(filter.filterName, null, true);
                                if (filter.filterName === "salesPriceRange") {
                                  setPriceRange(null);
                                  setIsDisableGoBtn(true);
                                }
                                setSelectedFilters(
                                  selectedFilters.filter(
                                    (f) => f.name !== filter.name,
                                  ),
                                );
                              }}
                              className={`h-12 w-12 pl-2 text-[#8C8C8C] ${filter.filterName === "salesPriceRange" ? "mt-[-63px]" : ""}`}
                            />
                          </div>
                          {filter.filterName === "salesPriceRange" && (
                            <div className="flex justify-between text-sm">
                              <input
                                type="number"
                                className="h-7 w-[90px] rounded border p-2"
                                placeholder="Min"
                                value={priceRange?.from || ""}
                                onChange={(e) => {
                                  setPriceRange({
                                    ...priceRange,
                                    from: Number(e.target.value),
                                  });
                                  if (!visibleSliderTooltip)
                                    setVisibleSliderTooltip(true);
                                }}
                                name="min"
                              />
                              <input
                                type="number"
                                className="h-7 w-[90px] rounded border p-2"
                                placeholder="Max"
                                value={priceRange?.to || ""}
                                onChange={(e) => {
                                  setPriceRange({
                                    ...priceRange,
                                    to: Number(e.target.value),
                                  });
                                  if (!visibleSliderTooltip)
                                    setVisibleSliderTooltip(true);
                                }}
                                name="max"
                              />
                              <button
                                className="h-7 w-[50px] rounded bg-primaryAccent px-1 py-1 text-white"
                                onClick={() => {
                                  if (
                                    (priceRange?.from &&
                                      priceRange.from >=
                                        salesPriceRange?.from &&
                                      priceRange.from <= salesPriceRange?.to) ||
                                    (priceRange?.to &&
                                      priceRange.to <= salesPriceRange?.to &&
                                      priceRange.to >= salesPriceRange?.from)
                                  ) {
                                    onChangeFilter(
                                      filter.filterName,
                                      {
                                        from:
                                          priceRange?.from ||
                                          salesPriceRange?.from ||
                                          0,
                                        to:
                                          priceRange?.to ||
                                          salesPriceRange?.to ||
                                          10000,
                                      },
                                      true,
                                    );
                                  }
                                  if (!visibleSliderTooltip)
                                    setVisibleSliderTooltip(true);
                                }}
                                disabled={isDisableGoBtn}
                                style={{
                                  opacity: isDisableGoBtn ? 0.5 : "unset",
                                }}>
                                Go
                              </button>
                            </div>
                          )}
                        </div>
                        {filter.filterName !== "salesPriceRange" && (
                          <SelectedFilters
                            filters={filters}
                            filter={filter}
                            maxCount={4}
                            onChangeFilter={onChangeFilter}
                          />
                        )}
                        {/* <div className="filterWrapperCatalog">
                          <div className="appliedFiltersInnerWrapper">
                            {filters[filter.filterName] &&
                              filters[filter.filterName].map(
                                (filterValue, idx) => (
                                  <div
                                    className="appliedFilterWrapper"
                                    key={idx}
                                    title={filter.name}>
                                    <span className="appliedFilterText">
                                      {filterValue || null}
                                    </span>
                                    <span
                                      className="appliedFilterClose"
                                      onClick={() => {
                                        onChangeFilter(
                                          filter.filterName,
                                          filters[filter.filterName].filter(
                                            (item) => item !== filterValue,
                                          ).length > 0
                                            ? filters[filter.filterName].filter(
                                                (item) => item !== filterValue,
                                              )
                                            : null,
                                          true,
                                        );
                                      }}>
                                      <XIcon className="h-6 w-6" />
                                    </span>
                                  </div>
                                ),
                              )}
                          </div>
                        </div> */}
                      </div>
                    ))}
                  </div>
                  <div className="mt-4 flex justify-center">
                    <button
                      className="w-full rounded-lg bg-primaryAccent px-6 py-2 text-white"
                      onClick={trySaveBookmark}>
                      Save Filters
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <CatalogMainContent
          handleCreateCatalogClick={handleCreateCatalogClick}
          handleScroll={handleScroll}
          clearFilters={clearFilters}
          loadFirstTimeData={loadFirstTimeData}
          products={products}
          total={total}
          filters={filters}
          onChangeFilter={onChangeFilter}
          submitFilters={submitFilters}
          selectProduct={selectProduct}
          selectedProducts={selectedProducts}
          selectAllRows={selectAllRows}
          showLeftSidebar={showLeftSidebar}
          allRowsSelected={allRowsSelected}
          getCatalog={getCatalog}
          fetchedCatalog={fetchedCatalog}
          setFetchedCatalog={setFetchedCatalog}
          productCategories={productCategories}
          setExportListHeaders={setExportListHeaders}
          customers={customers}
          getSpecificProduct={getSpecificProduct}
          parentProducts={parentProducts}
          customerList={customerList}
          fetchSalesPriceMinMaxValue={fetchSalesPriceMinMaxValue}
        />
        {savingNewBookmark && (
          <SaveNewBookmark
            savingNewBookmark={savingNewBookmark}
            setSavingNewBookmark={setSavingNewBookmark}
            saveBookmark={saveBookmarkHandler}
          />
        )}
      </div>
    </>
  );
};

// function deselectNode(selectedData, nodeToDeselect) {
//   // Remove the node to deselect and its children from selectedData
//   selectedData = selectedData.filter(node => !node.startsWith(nodeToDeselect));

//   // Check if the parent nodes are still fully selected
//   const parentNodes = nodeToDeselect.split('/');
//   for (let i = parentNodes.length - 1; i >= 0; i--) {
//     const parentNode = parentNodes.slice(0, i).join('/');
//     if (parentNode && selectedData.some(node => node.startsWith(parentNode + '/'))) {
//       // If any sibling nodes are still selected, break the loop
//       break;
//     } else {
//       // Remove the parent node as it's no longer fully selected
//       selectedData = selectedData.filter(node => node !== parentNode);
//     }
//   }

//   return selectedData;
// }

export default ManageCatalog;
