import { useState, useContext, useEffect } from "react";
import withInwardReceiverLogic from "#components/HOC/withInwardReceiverLogic";
import _ from "lodash";
import MultipleSelectStationForm from "#components/common/MultipleSelectStationForm";
import SelectCustomerCode from "#components/picker/common/SelectCustomerCode";
import ScanOrEnterManually from "#components/common/ScanOrEnterManually";
import DetailedView from "#components/receiver/DetailedView";
import AddLpnToProduct from "#components/receiver/AddLpnToProduct";
import GenerateLpn from "#components/receiver/GenerateLpn";
import ReceiverConsignmentsList from "#components/receiver/ReceiverConsignmentsList";
import ReceiverConsignmentItemsList from "#components/receiver/ReceiverConsignmentItemsList";
import { Tab } from "@headlessui/react";
import BolDoc from "#components/common/BolDoc";
import moment from "moment-timezone";
import FnSkuLabel2 from "#components/common/FnSkuLabel2";
import { AppStateContext } from "#contexts/appState";
import { isFeatherRiverColdTenant } from "#utils/tenantCheck";

const Receiver = ({
  loading,
  currentUser,
  stations,
  onSubmitStation,
  onSelectStation,
  selectedStation,
  codeToPrint,
  currentSku,
  scanBarcode,
  increaseItemQuantity,
  decreaseItemQuantity,
  confirmItem,
  triggerScan,
  currentScan,
  customers,
  submitCustomerCode,
  cancelCurrentCustomerAndTracking,
  addLpnToProduct,
  generateLpn,
  setAddLpnToProduct,
  setGenerateLpn,
  availableInventory,
  setInventorySearchKeyword,
  inventorySearchKeyword,
  submitLpn,
  submitNullValue,
  submitGenerateLpn,
  saveAndPrint,
  cancelItem,
  confirmItemDamaged,
  setCurrentSku,
  consignments,
  checkPagination,
  checkConsignmentById,
  filters,
  setFilters,
  submitFilters,
  consignmentsData,
  currentItems,
  setCurrentItems,
  updateItem,
  removeItem,
  addItem,
  specificConsignment,
  bolDoc,
  tenant,
  statusList,
  currentProduct,
  warehouses,
  selectedWarehouse,
  setSelectedWarehouse,
  printCode,
  prepEnabled,
  confirmPrepItem,
  eligibleConsignments,
  productSkuBinMappings,
  currentParentProduct,
  handleLpnAdded,
  addingFormFactor,
  setAddingFormFactor,
  bulkPrintCodes,
}) => {
  const appState = useContext(AppStateContext);
  const [selectedIndexTop, setSelectedIndexTop] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    setSelectedIndex(0);
  }, [currentScan?.trackingNumber, currentScan?.consignmentId]);

  const renderBolDoc = () => {
    return (
      bolDoc && (
        <div className="fixed" style={{ marginTop: 1000 }}>
          <BolDoc
            id={bolDoc.orderId}
            date={moment().format("MM/DD/YYYY")}
            pickupDate={moment(
              bolDoc.dateOfShipping
                ? bolDoc.dateOfShipping
                : bolDoc.completedDate,
            ).format("MM/DD/YYYY")}
            deliveryDate={moment(
              bolDoc.dateOfShipping
                ? bolDoc.dateOfShipping
                : bolDoc.completedDate,
            ).format("MM/DD/YYYY")}
            orderNo={bolDoc.orderId}
            weight={_.sumBy(bolDoc.orderLineItems, "attributes.weight")}
            orderLineItems={bolDoc.orderLineItems}
            shippingAddress={bolDoc.shippingAddress}
            tenant={tenant}
            order={bolDoc}
            isFeatherRiverColdTenant={isFeatherRiverColdTenant(
              appState?.subdomain,
            )}
          />
        </div>
      )
    );
  };

  if (currentUser && !currentUser.station) {
    return (
      <MultipleSelectStationForm
        title={"Please choose station."}
        selectedStation={selectedStation}
        onSubmit={onSubmitStation}
        stations={stations}
        onChangeDropdown={onSelectStation}
        warehouses={warehouses}
        selectedWarehouse={selectedWarehouse}
        setSelectedWarehouse={setSelectedWarehouse}
      />
    );
  }

  if (!currentScan || !currentScan.customer) {
    return (
      <>
        {renderBolDoc()}
        <SelectCustomerCode
          customers={customers}
          onSubmit={submitCustomerCode}
        />
        {codeToPrint && (
          <div className="z-50" style={{ position: "fixed" }}>
            {codeToPrint.map((code, idx) => (
              <img id={`label-placeholder-${code}`} key={idx} />
            ))}
          </div>
        )}
      </>
    );
  }

  if (generateLpn) {
    // TODO: HOP-6031
    return (
      <GenerateLpn
        formFactors={[
          { name: "Each" },
          { name: "Case" },
          { name: "Carton" },
          { name: "Pallet" },
        ]}
        availableInventory={availableInventory}
        backButtonPress={() => setGenerateLpn(null)}
        inventorySearchKeyword={inventorySearchKeyword}
        setInventorySearchKeyword={setInventorySearchKeyword}
        generateLpn={generateLpn}
        setGenerateLpn={setGenerateLpn}
        submitGenerateLpn={submitGenerateLpn}
        triggerScan={triggerScan}
        saveAndPrint={saveAndPrint}
      />
    );
  }

  if (addLpnToProduct) {
    return (
      <AddLpnToProduct
        availableInventory={availableInventory}
        backButtonPress={() => setAddLpnToProduct(null)}
        inventorySearchKeyword={inventorySearchKeyword}
        setInventorySearchKeyword={setInventorySearchKeyword}
        addLpnToProduct={addLpnToProduct}
        setAddLpnToProduct={setAddLpnToProduct}
        submitLpn={submitLpn}
        triggerScan={triggerScan}
      />
    );
  }

  if (
    currentScan &&
    currentScan.customer &&
    !currentScan.trackingNumber &&
    !currentScan.consignmentId
  ) {
    const tabs = {
      "Detail View": (
        <ScanOrEnterManually
          onSubmit={scanBarcode}
          onSkip={submitNullValue}
          onCancel={cancelCurrentCustomerAndTracking}
        />
      ),
      "List View": (
        <ReceiverConsignmentsList
          consignments={consignments}
          checkConsignmentById={checkConsignmentById}
          filters={filters}
          setFilters={setFilters}
          submitFilters={submitFilters}
          consignmentsData={consignmentsData}
          checkPagination={checkPagination}
          statusList={statusList}
          onSubmit={scanBarcode}
        />
      ),
    };
    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
    return (
      <>
        <div className="left-0 w-full bg-black text-center text-2xl text-white">
          {customers.find((item) => item.id === currentScan.customer)?.name}
        </div>
        {renderBolDoc()}

        <div className="flex-col pt-36">
          <Tab.Group
            selectedIndex={selectedIndexTop}
            onChange={setSelectedIndexTop}>
            <Tab.List className="flex items-center justify-center">
              <div className="space-x-2 rounded-full bg-primaryAccent p-2">
                {tabs &&
                  Object.keys(tabs).map((tab) => (
                    <Tab
                      key={tab}
                      className={({ selected }) =>
                        classNames(
                          "font-regular rounded-full px-6 py-2.5 leading-5",
                          "font-montserrat text-lg focus:outline-none focus:ring-0",
                          selected
                            ? "bg-white font-medium text-primaryAccent"
                            : "rounded-full border border-primaryAccent bg-transparent text-white",
                        )
                      }>
                      {tab}
                    </Tab>
                  ))}
              </div>
            </Tab.List>
            <div className="block">
              <Tab.Panels className="mt-2">
                {tabs &&
                  Object.values(tabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="flex flex-col items-center bg-transparent p-4">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
        {codeToPrint && (
          <div className="z-50" style={{ position: "fixed" }}>
            {codeToPrint.map((code, idx) => (
              <img id={`label-placeholder-${code}`} key={idx} />
            ))}
          </div>
        )}
      </>
    );
  }

  if (!currentSku && !loading) {
    const tabs = {
      "Detail View": (
        <div className="flex">
          <ScanOrEnterManually
            onSubmit={scanBarcode}
            text="Scan an Item Code"
            onCancel={cancelCurrentCustomerAndTracking}
            lpnEnabled={true}
            setAddLpnToProduct={setAddLpnToProduct}
            setGenerateLpn={() => setGenerateLpn({ quantity: 1 })}
            eligibleConsignments={eligibleConsignments}
          />
        </div>
      ),
      "List View": (
        <ReceiverConsignmentItemsList
          consignment={specificConsignment}
          checkConsignmentById={checkConsignmentById}
          scanBarcode={scanBarcode}
          eligibleConsignments={eligibleConsignments}
          tenant={tenant}
        />
      ),
    };
    function classNames(...classes) {
      return classes.filter(Boolean).join(" ");
    }
    return (
      <>
        <div className="left-0 w-full bg-black text-center text-2xl text-white">
          {customers.find((item) => item.id === currentScan.customer)?.name}
          {specificConsignment &&
            specificConsignment.id &&
            ` - ${specificConsignment.orderId}`}
        </div>
        {renderBolDoc()}
        <div className="flex-col pt-36">
          <Tab.Group selectedIndex={selectedIndex} onChange={setSelectedIndex}>
            <Tab.List className="flex items-center justify-center">
              <div className="space-x-2 rounded-full bg-primaryAccent p-2">
                {tabs &&
                  Object.keys(tabs).map((tab) => (
                    <Tab
                      key={tab}
                      className={({ selected }) =>
                        classNames(
                          "font-regular rounded-full px-6 py-2.5 leading-5",
                          "font-montserrat text-lg focus:outline-none focus:ring-0",
                          selected
                            ? "bg-white font-medium text-primaryAccent"
                            : "rounded-full border border-primaryAccent bg-transparent text-white",
                        )
                      }>
                      {tab}
                    </Tab>
                  ))}
              </div>
            </Tab.List>
            <div className="block">
              <Tab.Panels className="mt-2">
                {tabs &&
                  Object.values(tabs).map((tabInner, idx) => (
                    <Tab.Panel
                      key={idx}
                      className="flex flex-col items-center bg-transparent p-4">
                      {tabInner}
                    </Tab.Panel>
                  ))}
              </Tab.Panels>
            </div>
          </Tab.Group>
        </div>
        {codeToPrint && (
          <div className="z-50" style={{ position: "fixed" }}>
            {codeToPrint.map((code, idx) => (
              <img id={`label-placeholder-${code}`} key={idx} />
            ))}
          </div>
        )}
      </>
    );
  }

  if (currentSku) {
    return (
      <>
        <div className="left-0 w-full bg-black text-center text-2xl text-white">
          {customers.find((item) => item.id === currentScan.customer)?.name}
          {specificConsignment &&
            specificConsignment.id &&
            ` - ${specificConsignment.orderId}`}
        </div>
        <div className="mt-10">
          <DetailedView
            currentSku={currentSku}
            confirmItem={confirmItem}
            confirmPrepItem={confirmPrepItem}
            confirmItemDamaged={confirmItemDamaged}
            increaseItemQuantity={increaseItemQuantity}
            decreaseItemQuantity={decreaseItemQuantity}
            cancelItem={cancelItem}
            setCurrentSku={setCurrentSku}
            scanBarcode={scanBarcode}
            currentItems={currentItems}
            setCurrentItems={setCurrentItems}
            updateItem={updateItem}
            removeItem={removeItem}
            addItem={addItem}
            specificConsignment={specificConsignment}
            currentProduct={currentProduct}
            printCode={printCode}
            bulkPrintCodes={bulkPrintCodes}
            prepEnabled={prepEnabled}
            eligibleConsignments={eligibleConsignments}
            tenant={tenant}
            productSkuBinMappings={productSkuBinMappings}
            currentParentProduct={currentParentProduct}
            handleLpnAdded={handleLpnAdded}
            addingFormFactor={addingFormFactor}
            setAddingFormFactor={setAddingFormFactor}
          />
        </div>
        {codeToPrint && (
          <div className="z-50" style={{ position: "fixed" }}>
            {codeToPrint.map((code, idx) => (
              <img
                id={`label-placeholder-${code}`}
                style={{ position: "fixed", top: 10000 }}
                key={idx}
              />
            ))}
          </div>
        )}
        {currentProduct?.fnSku && prepEnabled && (
          <div className="z-50" style={{ position: "fixed", marginTop: 1000 }}>
            <img
              id={`label-placeholder-${currentProduct.fnSku}`}
              style={{ position: "fixed", top: 10000 }}
            />
            <FnSkuLabel2
              id={currentProduct.fnSku}
              value={currentProduct.fnSku}
              text1={truncate(currentProduct.productName)}
              text2={
                currentSku.bestByDate
                  ? `Best by: ${currentSku.bestByDate}`
                  : null
              }
            />
          </div>
        )}

        {renderBolDoc()}
      </>
    );
  }

  return (
    <>
      <div className="left-0 w-full bg-black text-center text-2xl text-white">
        {customers.find((item) => item.id === currentScan.customer)?.name}
      </div>
      {codeToPrint && (
        <div className="z-50" style={{ position: "fixed" }}>
          {codeToPrint.map((code, idx) => (
            <img id={`label-placeholder-${code}`} key={idx} />
          ))}
        </div>
      )}
      {renderBolDoc()}
    </>
  );

  return null;
};

const truncate = (str) => {
  return str && str.length > 30 ? str.substring(0, 30) + "..." : str;
};

export default withInwardReceiverLogic(Receiver);
