import { useEffect, useRef } from "react";

const POLLING_TIMER = 1800000; // 30 min polling interval

const useNotifications = (
  notificationV2Enabled,
  fetchNotifications,
  notification,
) => {
  const autoRefreshInterval = useRef(null);

  const fetchAndSetNotifications = async (filters) => {
    try {
      const response = await fetchNotifications({ filters });
      if (response?.data) {
        const notificationsResponse = response.data.listNotificationsV1 || {};
        const entities = notificationsResponse.entities || [];
        const unreadTotal = entities.filter((n) => n?.isRead === false)?.length;

        notification.setEntities({
          entities,
          total: entities.length,
          unreadTotal,
          paginated: false,
          pageNumber: 0,
          notification: "notificationV2",
          markAsRead: false,
        });
      }
    } catch (error) {
      clearInterval(autoRefreshInterval.current);
    }
  };

  useEffect(() => {
    if (notificationV2Enabled) {
      if (autoRefreshInterval.current) {
        clearInterval(autoRefreshInterval.current);
      }

      fetchAndSetNotifications({ subCategory: "ALL" });

      autoRefreshInterval.current = setInterval(() => {
        fetchAndSetNotifications({ subCategory: "ALL" });
      }, POLLING_TIMER);
    }

    return () => {
      if (autoRefreshInterval.current) {
        clearInterval(autoRefreshInterval.current);
      }
    };
  }, [notificationV2Enabled]);

  useEffect(() => {
    if (notification?.markAsRead) {
      fetchAndSetNotifications({ subCategory: "ALL" });
    }
  }, [notification?.markAsRead]);
};

export default useNotifications;
