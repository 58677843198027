import {
  ChevronDownIcon,
  ChevronRightIcon,
  MinusIcon,
  PlusIcon,
} from "@heroicons/react/outline";
import OrderItem from "./OrderItem";
import PlannedBox from "./PlannedBox";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import BoxTypePopover from "./BoxTypePopover";
import CheckBox from "#newUiComponents/commons/CheckBox";
import { useState } from "react";
import OrderHeader from "./OrderHeader";
import CustomTable from "#newUiComponents/commons/CustomTable";

const PlannedOrder = ({
  order,
  isSelected,
  onSelect,
  onAddBox,
  boxes,
  onDuplicateBox,
  onDeleteBox,
  isExpanded,
  onToggle,
  expandedBoxId,
  onBoxToggle,
  setSelectedOrderForCustomBox,
  setBoxDimensionsDetails,
  setDimensionErrors,
  setIsBoxDimensionsModalOpen,
  onAddItems,
  packedQuantities,
  onEditDimensions,
  onUpdateWeight,
  onSelectBoxType,
  onUpdateItems,
  customers,
  warehouses,
  boxTypes,
}) => {
  const [isBoxPopoverOpen, setIsBoxPopoverOpen] = useState(false);
  const [isOrderItemsExpanded, setIsOrderItemsExpanded] = useState(true);

  const handleBoxSelect = (boxType) => {
    if (boxType.isCustom) {
      setSelectedOrderForCustomBox(order.orderId); // null means bulk addition
      setBoxDimensionsDetails({
        length: "",
        width: "",
        height: "",
      });
      setDimensionErrors({
        length: "",
        width: "",
        height: "",
      });
      setIsBoxDimensionsModalOpen(true);
    } else {
      onAddBox(order.orderId, boxType);
    }
  };

  const columns = [
    {
      key: "sku",
      title: "SKU",
      render: (sku) => (
        <span className="text-sm font-medium text-primaryAccent">{sku}</span>
      ),
    },
    {
      key: "name",
      title: "Name",
      render: (name) => <span className="text-sm text-gray-700">{name}</span>,
    },
    {
      key: "quantity",
      title: "Quantity",
      render: (quantity) => (
        <span className="text-sm font-medium">{quantity}</span>
      ),
    },
    {
      key: "packed",
      title: "Packed",
      render: (packed, record) => {
        const [packedCount, totalCount] = packed.split("/");
        const isPacked = packedCount === totalCount;
        return (
          <span
            className={`text-sm font-medium ${isPacked ? "text-green-600" : "text-orange-500"}`}>
            {packed}
          </span>
        );
      },
    },
  ];

  const data = order.orderDetails.map((item, index) => ({
    key: index,
    sku: item.sku,
    name: item.name,
    quantity: item.quantity,
    packed: `${packedQuantities[item.sku] || 0}/${item.quantity}`,
  }));

  return (
    <div className="mb-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm">
      <div className="mb-3 flex items-center justify-between">
        <div className="flex w-3/4 items-center gap-3">
          <button onClick={onToggle} className="rounded p-1 hover:bg-gray-100">
            {isExpanded ? (
              <ChevronDownIcon className="h-5 w-5 text-gray-500" />
            ) : (
              <ChevronRightIcon className="h-5 w-5 text-gray-500" />
            )}
          </button>
          <CheckBox
            checked={isSelected}
            onChange={(e) => {
              if (e.target.checked) {
                onSelect(order.orderId);
              } else {
                onSelect(order.orderId);
              }
            }}
          />
          <OrderHeader
            orderId={order.orderId}
            orderDate={order?.orderDate}
            warehouse={
              warehouses.find((warehouse) => warehouse.id === order.warehouse)
                ?.name || ""
            }
            clientName={
              customers.find((customer) => customer.id === order.customer)
                ?.name || ""
            }
            orderSource={order.source || ""}
            onClickOrderId={() => onToggle()}
          />

          {/* <h3 className="text-lg font-medium">Order #{order.orderId}</h3> */}
        </div>
        <BoxTypePopover
          onSelectBox={handleBoxSelect}
          setIsOpen={setIsBoxPopoverOpen}
          isOpen={isBoxPopoverOpen}
          boxTypes={boxTypes}
          trigger={
            <PrimaryButton
              height="2.5rem"
              minWidth="7rem"
              maxWidth="20rem"
              variant="primary"
              className="mt-2 text-base font-medium">
              <PlusIcon className="mr-2 h-4 w-4" />
              Add Box
              <ChevronDownIcon className="ml-1 h-4 w-4" />
            </PrimaryButton>
          }
        />
      </div>

      {isExpanded && (
        <>
          <div className="mt-4">
            <button
              onClick={() => setIsOrderItemsExpanded(!isOrderItemsExpanded)}
              className="group flex items-center gap-3 bg-white px-4 py-2.5 transition-all hover:border-gray-300 hover:bg-gray-50 hover:shadow-sm active:bg-gray-100">
              <div className="rounded-full bg-gray-100 p-1.5 transition-colors group-hover:bg-gray-200">
                {isOrderItemsExpanded ? (
                  <ChevronDownIcon className="h-4 w-4 text-gray-600" />
                ) : (
                  <ChevronRightIcon className="h-4 w-4 text-gray-600" />
                )}
              </div>
              <span className="text-sm font-medium text-gray-700">
                Order Items:
              </span>
            </button>
            {isOrderItemsExpanded && (
              <CustomTable
                columns={columns}
                data={data}
                searchPlaceholder="Search by SKU or Name"
                isSearchable={false}
              />
            )}
          </div>

          {boxes.map((box, index) => (
            <PlannedBox
              key={`${order.orderId}-${index}`}
              displayName={box.displayName}
              boxNumber={index + 1}
              dimensions={box}
              onDuplicate={() => onDuplicateBox(order.orderId, index)}
              onDelete={() => onDeleteBox(order.orderId, index)}
              onAddItems={onAddItems}
              items={box.items}
              boxIndex={index}
              orderId={order.orderId}
              onEditDimensions={onEditDimensions}
              onUpdateWeight={onUpdateWeight}
              onSelectBoxType={onSelectBoxType}
              onUpdateItems={onUpdateItems}
              boxTypes={boxTypes}
            />
          ))}
        </>
      )}
    </div>
  );
};

export default PlannedOrder;
