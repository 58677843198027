import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { GET_PRODUCT_VARIANT_COMPONENT } from "#queries";
import { useQuery } from "#hooks/useQuery";
import Checkbox from "#components/utils/Checkbox";
import ArrowUpDownIcon from "#components/common/ArrowUpDownIcon";
import noFilterIcon from "#static/images/nofilter.png";
import CatalogProductActions from "./CatalogProductActions";
import SelectedFilters from "./SelectedFilters";
import PricePopover from "./PricePopover";

const ListViewProducts = ({
  products,
  selectAllRows,
  allRowsSelected,
  selectProduct,
  selectedProducts,
  getCatalog,
  actionsOnProduct,
  isSearchResults,
  productCategories,
  selectAllDisabled,
  isRemoveSelectAllCheckBox,
  showSalesAndCostPrice,
  customerList,
}) => {
  const [sortedOrderOfProducts, setSortedOrderOfProducts] = useState([]);
  const [sortingOrders, setSortingOrders] = useState({});
  const [variantComponentIds, setVariantComponentIds] = useState([]);
  const [allVariantComponents, setAllVariantComponents] = useState([]);

  const productComponentQuery = useQuery(GET_PRODUCT_VARIANT_COMPONENT);

  const fetchProductVariantComponent = async (variantIds) => {
    try {
      const productComponentResponse = await productComponentQuery.fetchData({
        perPage: variantIds.length,
        pageNumber: 1,
        filters: {
          componentId: variantIds,
        },
      });
      if (
        productComponentResponse?.data?.getProductVariantComponent?.entities
          ?.length > 0
      )
        setAllVariantComponents(
          productComponentResponse.data.getProductVariantComponent.entities,
        );
    } catch (e) {
      console.log("Error while fetching product variant component", e);
    }
  };

  useEffect(() => {
    setSortedOrderOfProducts([...products]);
  }, [products]);

  useEffect(() => {
    if (showSalesAndCostPrice && products?.length > 0) {
      let allVariantComponents = products.reduce((initArray, nxtObject) => {
        if (nxtObject?.costPrice) {
          const allVariantComponentsId = _.keys(
            nxtObject?.costPrice?.[0],
          ).filter((key) => _.isObject(nxtObject?.costPrice?.[0]?.[key]));
          if (allVariantComponentsId.length > 0) {
            return [...initArray, ...allVariantComponentsId];
          }
        }
        return initArray;
      }, []);
      if (allVariantComponents.length > 0) {
        allVariantComponents = allVariantComponents.filter(
          (id, index) => index === allVariantComponents.indexOf(id),
        );
        if (!_.isEqual(variantComponentIds, allVariantComponents)) {
          setVariantComponentIds(allVariantComponents);
        }
      }
    }
  }, [products, variantComponentIds]);

  useEffect(() => {
    if (variantComponentIds.length > 0) {
      fetchProductVariantComponent(variantComponentIds);
    }
  }, [variantComponentIds]);

  const handleSortingOfProducts = (type, defaultOrder) => {
    let sortedProducts = [...sortedOrderOfProducts];
    let sortOrder = sortingOrders[type] || defaultOrder;

    switch (type) {
      case "productInfo":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.name?.localeCompare(b.name)
            : b.name?.localeCompare(a.name),
        );
        break;
      case "price":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.pricing?.computedPriceFields?.totalPrice -
              b.pricing?.computedPriceFields?.totalPrice
            : b.pricing?.computedPriceFields?.totalPrice -
              a.pricing?.computedPriceFields?.totalPrice,
        );
        break;
      case "customer":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.customer?.localeCompare(b.customer)
            : b.customer?.localeCompare(a.customer),
        );
        break;
      case "description":
        sortedProducts.sort((a, b) =>
          sortOrder === "asc"
            ? a.description?.localeCompare(b.description)
            : b.description?.localeCompare(a.description),
        );
        break;
      default:
        sortedProducts = [...products];
        break;
    }

    const nextSortOrder = sortOrder === "asc" ? "desc" : "asc";
    setSortingOrders({ ...sortingOrders, [type]: nextSortOrder });

    setSortedOrderOfProducts(sortedProducts);
  };

  return (
    <div
      className={`shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg ${isSearchResults ? "h-[50vh] w-full overflow-x-auto overflow-y-auto" : "h-[55vh] overflow-y-visible"}`}>
      <table className="min-w-full divide-y divide-gray-300 overflow-y-visible">
        <thead className="sticky top-0 z-5 bg-gray-50">
          <tr>
            {!selectAllDisabled && (
              <th
                scope="col"
                className="sticky left-0 bg-gray-50 py-3.5 pl-4 text-left text-lg font-normal text-[#717679] sm:pl-6">
                {!isRemoveSelectAllCheckBox && (
                  <Checkbox
                    role="checkbox"
                    onChange={selectAllRows}
                    name="selectAllRows"
                    checked={allRowsSelected}
                    disabled={!!selectAllDisabled}
                  />
                )}
              </th>
            )}
            <th
              scope="col"
              className={`py-3.5 text-left text-lg font-normal text-[#717679] sm:pl-6 ${selectAllDisabled ? "pl-4" : "-pl-4"}`}>
              <div className="flex items-center space-x-1">
                <span>Product Info</span>
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("productInfo", "asc");
                  }}
                  className="h-5 w-5 cursor-pointer text-gray-700"
                />
              </div>
            </th>
            <th
              scope="col"
              className="py-3.5 pl-6 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Description</span>
                <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-700" />
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("description", "asc");
                  }}
                  className="h-5 w-5 cursor-pointer text-gray-700"
                />
              </div>
            </th>
            <th
              scope="col"
              className="py-3.5 pl-6 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Categories</span>
                <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-700" />
              </div>
            </th>
            <th
              scope="col"
              className="py-3.5 pl-6 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Price</span>
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("price", "asc");
                  }}
                  className="h-5 w-5 cursor-pointer text-gray-700"
                />
              </div>
            </th>
            {showSalesAndCostPrice && (
              <>
                <th
                  scope="col"
                  className="py-3.5 pl-6 text-left text-lg font-normal text-[#717679]">
                  <div className="flex items-center space-x-1">
                    <div className="min-w-[88px]">Cost Price</div>
                  </div>
                </th>
                <th
                  scope="col"
                  className="py-3.5 pl-6 text-left text-lg font-normal text-[#717679]">
                  <div className="flex items-center space-x-1">
                    <div className="min-w-[95px]">Sales Price</div>
                  </div>
                </th>
              </>
            )}
            <th
              scope="col"
              className="py-3.5 pl-6 text-left text-lg font-normal text-[#717679]">
              <div className="flex items-center space-x-1">
                <span>Customer</span>
                <ArrowUpDownIcon
                  onClick={() => {
                    handleSortingOfProducts("customer", "asc");
                  }}
                  className="h-5 w-5 text-gray-700"
                />
              </div>
            </th>
            <th
              scope="col"
              className="py-3.5 pl-6 pr-4 text-left text-lg font-normal text-[#717679]">
              Action
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-gray-200 bg-white">
          {!products.length && (
            <tr>
              <td colSpan={"100%"}>
                <div className="w-full flex-col items-center justify-center p-8 text-center">
                  <img src={noFilterIcon} className="mx-auto w-64" />
                  <div className="mt-4 text-center font-inter text-2xl font-semibold text-black">
                    No products found
                  </div>
                </div>
              </td>
            </tr>
          )}
          {sortedOrderOfProducts?.length > 0 &&
            sortedOrderOfProducts.map((product, rowIndex) => {
              const costPrice = {};
              if (showSalesAndCostPrice && product?.costPrice?.length === 1) {
                for (const costPriceKey in product.costPrice[0]) {
                  if (costPriceKey !== "currency") {
                    let componentPrice;
                    if (!_.isObject(product.costPrice[0][costPriceKey])) {
                      componentPrice = Number(
                        product.costPrice[0][costPriceKey],
                      );
                      if (
                        costPriceKey !== "discount" &&
                        costPriceKey !== "total"
                      ) {
                        costPrice[costPriceKey] = componentPrice / 100;
                      } else costPrice[costPriceKey] = componentPrice;
                    } else {
                      const selectedComponent = allVariantComponents.find(
                        (variantObject) => variantObject.id === costPriceKey,
                      );
                      componentPrice =
                        (Number(selectedComponent?.price || "") /
                          Number(selectedComponent?.quantity || "")) *
                        Number(
                          product.costPrice[0][costPriceKey].quantity || "",
                        );
                      costPrice[selectedComponent?.componentName] =
                        componentPrice / 100;
                    }
                    if (costPriceKey !== "discount") {
                      if (costPriceKey !== "total") {
                        costPrice["total"] =
                          (costPrice["total"] || 0) + componentPrice;
                      }
                    }
                  } else
                    costPrice[costPriceKey] =
                      product.costPrice[0][costPriceKey];
                }
                if (costPrice?.discount) {
                  costPrice.total =
                    (costPrice.total - (costPrice?.discount || 0)) / 100;
                  costPrice.discount = costPrice?.discount / 100;
                } else costPrice.total = Number(costPrice.total || 0) / 100;
              }

              return (
                <tr key={uuidv4()}>
                  {!selectAllDisabled && (
                    <td className="sticky left-0 bg-white px-4 py-4 text-sm font-medium text-gray-900 sm:pl-6">
                      <Checkbox
                        role="checkbox"
                        onChange={(_) => {
                          selectProduct(
                            product.id,
                            false,
                            sortedOrderOfProducts,
                          );
                        }}
                        name="product"
                        value={product.id}
                        checked={selectedProducts?.includes(product.id)}
                        disabled={!!selectAllDisabled}
                      />
                    </td>
                  )}
                  <td
                    className={`overflow-ellipsis py-4 text-sm font-medium text-gray-900 sm:pl-6 ${selectAllDisabled ? "pl-4" : "-pl-4"}`}>
                    <div
                      className="flex cursor-pointer items-center"
                      onClick={() =>
                        !product.isParentProduct && getCatalog(product.id)
                      }>
                      {(product.images?.[0]?.url ||
                        product.images?.[0]?.display_url) && (
                        <div className="h-[66px] w-16 shrink-0">
                          <img
                            className="h-[66px] w-16 rounded"
                            src={
                              product.images?.[0]?.display_url
                                ? product.images?.[0]?.display_url
                                : product.images?.[0]?.url
                            }
                            alt=""
                          />
                        </div>
                      )}
                      <div className="ml-2 flex flex-col gap-1 font-inter">
                        <div className="w-auto max-w-[200px] truncate text-base font-medium text-primaryAccent">
                          {product.name}
                        </div>
                        <div className="w-auto max-w-[200px] truncate font-inter text-sm text-lightGray">
                          SKU: {product.sku}
                        </div>
                        {product?.asin && (
                          <div className="w-auto max-w-[200px] truncate font-inter text-sm text-lightGray">
                            ASIN: {product.asin}
                          </div>
                        )}
                      </div>
                    </div>
                  </td>
                  <td className="my-4 ml-6 line-clamp-3 h-[60px] w-[192px] overflow-hidden text-ellipsis whitespace-normal break-words text-sm text-[#111827]">
                    {product.description}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-6 text-base font-normal text-[#111827]">
                    {/* {product.category
                    ? productCategories?.find((i) => i.id === product.category)
                        ?.name || "-"
                    : "-"} */}
                    <SelectedFilters
                      filters={{
                        category: product.categories,
                      }}
                      filter={{
                        filterName: "category",
                        options: productCategories,
                      }}
                      maxCount={3}
                      onChangeFilter={() => {}}
                      isClickable={false}
                    />
                  </td>
                  <td className="whitespace-nowrap py-4 pl-6 text-lg text-[#111827]">
                    {product.pricing?.computedPriceFields?.totalPrice
                      ? `$${product.pricing?.computedPriceFields?.totalPrice.toFixed(
                          2,
                        )}`
                      : "-"}
                  </td>
                  {showSalesAndCostPrice && (
                    <>
                      <td className="whitespace-nowrap py-4 pl-6 text-base font-medium text-[#111827]">
                        {costPrice?.total ? (
                          <>
                            <PricePopover data={costPrice} type="cost" />
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                      <td className="whitespace-nowrap py-4 pl-6">
                        {product?.salesPrice?.length > 0 ? (
                          <>
                            <PricePopover
                              data={product.salesPrice}
                              type="sales"
                              customerList={customerList}
                            />
                          </>
                        ) : (
                          "-"
                        )}
                      </td>
                    </>
                  )}
                  <td className="relative whitespace-nowrap py-4 pl-6 text-base text-[#111827]">
                    All
                  </td>
                  <td className="whitespace-nowrap py-4 pl-6 pr-4 text-lg text-[#111827]">
                    <CatalogProductActions
                      actionsOnProduct={actionsOnProduct}
                      product={product}
                    />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  );
};

export default ListViewProducts;
