import React, { useContext, useState } from "react";
import CheckBox from "#newUiComponents/commons/CheckBox";
import { PlusIcon, TrashIcon, ChevronDownIcon } from "@heroicons/react/outline";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import NewModal from "#newUiComponents/commons/NewModal";
import { AppStateContext } from "#contexts/appState";
import SlideOverPanel from "#components/common/SlideOverPanel";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import BoxTypePopover from "./BoxTypePopover";
import BoxItemsTable from "./BoxItemsTable";
import BoxDuplicationModal from "./BoxDuplicationModal";
import PlannedOrder from "./PlannedOrder";

const PackPlan = ({
  detailedOrdersForBatching = [],
  customers,
  warehouses,
  boxTypes,
  orderBoxes,
  setOrderBoxes,
  setPickPlanDetails,
  pickPlanDetails,
}) => {
  const notify = CustomNotification();
  const appState = useContext(AppStateContext);

  const [selectedOrders, setSelectedOrders] = useState(new Set());
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [expandedBoxId, setExpandedBoxId] = useState(null);
  const [isBoxDimensionsModalOpen, setIsBoxDimensionsModalOpen] =
    useState(false);
  const [boxDimensionsDetails, setBoxDimensionsDetails] = useState({
    length: "",
    width: "",
    height: "",
  });
  const [dimensionErrors, setDimensionErrors] = useState({
    length: "",
    width: "",
    height: "",
  });
  const [selectedOrderForCustomBox, setSelectedOrderForCustomBox] =
    useState(null);
  const [boxCounts, setBoxCounts] = useState({});

  const [selectedBoxForItems, setSelectedBoxForItems] = useState(null);
  const [boxItems, setBoxItems] = useState({});
  const [
    openManualAddItemsSlideOverPanel,
    setOpenManualAddItemsSlideOverPanel,
  ] = useState(false);
  const [assignedQuantities, setAssignedQuantities] = useState({});
  const [packedQuantities, setPackedQuantities] = useState({});
  const [isBoxPopoverOpen, setIsBoxPopoverOpen] = useState(false);

  const [duplicationModalOpen, setDuplicationModalOpen] = useState(false);
  const [duplicationDetails, setDuplicationDetails] = useState({
    orderId: null,
    boxIndex: null,
    quantity: 1,
  });
  // Sample order data - replace with actual data

  const handleEditDimensions = (orderId, boxIndex) => {
    const box = orderBoxes[orderId][boxIndex];
    setSelectedOrderForCustomBox(`${orderId}-${boxIndex}`);
    setBoxDimensionsDetails({
      length: box.length,
      width: box.width,
      height: box.height,
      weight: box.weight || 0,
    });
    setIsBoxDimensionsModalOpen(true);
  };

  const handleUpdateWeight = (
    orderId,
    boxIndex,
    newWeight,
    isTotal = false,
  ) => {
    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: prev[orderId].map((box, idx) => {
        if (idx === boxIndex) {
          const itemsWeight = box.items.reduce(
            (sum, item) => sum + (item.weight || 0) * item.packQuantity,
            0,
          );

          if (isTotal) {
            // If updating total weight, adjust box weight
            // const newBoxWeight = Math.max(0, newWeight - itemsWeight);
            return {
              ...box,
              totalWeight: newWeight,
            };
          } else {
            // If updating box weight, adjust total weight
            return {
              ...box,
              weight: newWeight,
              totalWeight: newWeight + box.totalWeight,
            };
          }
        }
        return box;
      }),
    }));
  };
  const renumberBoxes = (orderId) => {
    setOrderBoxes((prev) => {
      const boxes = prev[orderId] || [];
      const updatedBoxes = boxes.map((box, index) => {
        const newDisplayName = `Box-${index + 1}`;
        const newName = `${orderId}_${newDisplayName}`;
        return { ...box, name: newName, displayName: newDisplayName };
      });
      return { ...prev, [orderId]: updatedBoxes };
    });
  };

  const getNextBoxName = (orderId) => {
    const currentBoxes = orderBoxes[orderId] || [];
    const nextBoxNumber = currentBoxes.length + 1;
    const displayName = `Box-${nextBoxNumber}`;
    const name = `${orderId}_Box-${nextBoxNumber}`;

    return { name, displayName };
  };

  const handleOrderToggle = (orderId) => {
    setExpandedOrderId(expandedOrderId === orderId ? null : orderId);
    setExpandedBoxId(null); // Close any open boxes when toggling orders
  };

  const handleBoxToggle = (boxId) => {
    setExpandedBoxId(expandedBoxId === boxId ? null : boxId);
  };

  const handleSelectAll = () => {
    if (selectedOrders.size === detailedOrdersForBatching.length) {
      setSelectedOrders(new Set());
    } else {
      setSelectedOrders(
        new Set(detailedOrdersForBatching.map((order) => order.orderId)),
      );
    }
  };

  const handleSelectOrder = (orderId) => {
    const newSelected = new Set(selectedOrders);
    if (newSelected.has(orderId)) {
      newSelected.delete(orderId);
    } else {
      newSelected.add(orderId);
    }
    setSelectedOrders(newSelected);
  };

  const handleAddItemsToBox = (orderId, boxIndex) => {
    const box = orderBoxes[orderId][boxIndex];
    setSelectedBoxForItems({ orderId, boxIndex, box });
    setOpenManualAddItemsSlideOverPanel(true);
  };

  const handleUpdateBoxItems = (orderId, boxIndex, updatedItems) => {
    setOrderBoxes((prev) => {
      const updatedBoxes = prev[orderId].map((box, idx) => {
        if (idx === boxIndex) {
          const newItems = updatedItems.map((item) => ({
            ...item,
            packQuantity: item.editQuantity,
          }));

          const itemsWeight = newItems.reduce(
            (sum, item) => sum + (item.weight || 0) * item.packQuantity,
            0,
          );

          return {
            ...box,
            items: newItems,
            totalWeight: (box.weight || 0) + itemsWeight,
          };
        }
        return box;
      });

      // Rest of the existing code...
      const newPackedQuantities = {};
      updatedBoxes.forEach((box) => {
        box.items.forEach((item) => {
          newPackedQuantities[item.sku] =
            (newPackedQuantities[item.sku] || 0) + item.packQuantity;
        });
      });

      setPackedQuantities((prevPacked) => ({
        ...prevPacked,
        [orderId]: newPackedQuantities,
      }));

      setAssignedQuantities((prevAssigned) => ({
        ...prevAssigned,
        [orderId]: newPackedQuantities,
      }));

      return { ...prev, [orderId]: updatedBoxes };
    });

    notify.success(
      "Box Items Updated",
      `Successfully updated items in Box #${boxIndex + 1} for Order #${orderId}`,
    );
  };

  const handleConfirmAddItems = (quantities) => {
    if (!quantities) {
      setOpenManualAddItemsSlideOverPanel(false);
      setSelectedBoxForItems(null);
      return;
    }

    const { orderId, box, boxIndex } = selectedBoxForItems;
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);

    const updatedItems = order.orderDetails
      .map((item) => {
        const currentAssigned = assignedQuantities[orderId]?.[item.sku] || 0;
        const availableQuantity = item.quantity - currentAssigned;
        const packQuantity = Math.min(
          quantities[item.sku] || 0,
          availableQuantity,
        );
        return {
          ...item,
          packQuantity,
        };
      })
      .filter((item) => item.packQuantity > 0);

    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: prev[orderId].map((b, idx) => {
        if (idx === boxIndex) {
          // Calculate total weight from items
          const itemsWeight = updatedItems.reduce(
            (sum, item) => sum + (item.weight || 0) * item.packQuantity,
            0,
          );

          return {
            ...b,
            items: updatedItems,
            // Add box weight to items weight for total
            totalWeight: (b.totalWeight || 0) + itemsWeight,
          };
        }
        return b;
      }),
    }));

    const newAssignedQuantities = { ...assignedQuantities[orderId] };
    updatedItems.forEach((item) => {
      newAssignedQuantities[item.sku] =
        (newAssignedQuantities[item.sku] || 0) + item.packQuantity;
    });

    setAssignedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    // Update packedQuantities
    setPackedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    setOpenManualAddItemsSlideOverPanel(false);
    setSelectedBoxForItems(null);

    notify.success(
      "Items Added to Box",
      `Successfully added ${updatedItems.length} items to ${box.displayName}`,
    );
  };

  const addBoxWithAllItems = (orderId, boxType) => {
    const { name, displayName } = getNextBoxName(
      orderId,
      boxType.name.replace(" ", ""),
    );
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);

    const items = order.orderDetails.map((item) => ({
      ...item,
      packQuantity: item.quantity,
    }));

    const itemsWeight = items.reduce(
      (sum, item) => sum + (item.weight || 0) * item.packQuantity,
      0,
    );

    const boxWithItems = {
      ...boxType,
      name,
      type: boxType.name || "",
      displayName,
      items,
      weight: boxType.weight || 0,
      totalWeight: (boxType.weight || 0) + itemsWeight,
    };

    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: [...(prev[orderId] || []), boxWithItems],
    }));

    // Update assignedQuantities and packedQuantities
    const newAssignedQuantities = {};
    order.orderDetails.forEach((item) => {
      newAssignedQuantities[item.sku] = item.quantity;
    });

    setAssignedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    setPackedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    notify.success(
      "Box Added Successfully",
      `Added ${boxType.name} with all items to Order #${orderId}`,
    );
  };

  const addEmptyBox = (orderId, boxType) => {
    const { name, displayName } = getNextBoxName(
      orderId,
      boxType.name.replace(" ", ""),
    );
    const boxWithName = {
      ...boxType,
      name,
      displayName,
      type: boxType.name || "",
      items: [],
      weight: boxType.weight || 0,
      totalWeight: boxType.weight || 0,
    };

    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: [...(prev[orderId] || []), boxWithName],
    }));

    notify.success(
      "Box Added Successfully",
      `Added empty ${boxType.name} to Order #${orderId}`,
    );
  };

  const handleAddBox = (orderId, boxType) => {
    // Check if this is the first box for this order
    if (!orderBoxes[orderId] || orderBoxes[orderId].length === 0) {
      appState.showNewConfirmation(
        "Will all Items inside the order fit into 1 box?",
        "",
        () => {
          // If no, just add an empty box
          addEmptyBox(orderId, boxType);
        },
        () => {
          // If yes, add the box and all items
          addBoxWithAllItems(orderId, boxType);
        },
        "Yes, all items can be added into one box",
        "No, Multiple Boxes will be needed",
      );
    } else {
      // If it's not the first box, just add an empty box
      addEmptyBox(orderId, boxType);
    }
  };

  const handleAddBoxToSelected = (boxType) => {
    if (boxType.isCustom) {
      setSelectedOrderForCustomBox(null);
      setBoxDimensionsDetails({
        length: "",
        width: "",
        height: "",
      });
      setIsBoxDimensionsModalOpen(true);
      return;
    }

    // Check if any selected order already has boxes
    const hasExistingBoxes = Array.from(selectedOrders).some(
      (orderId) => orderBoxes[orderId]?.length > 0,
    );

    if (!hasExistingBoxes) {
      appState.showNewConfirmation(
        "Will all Items inside the order fit into 1 box?",
        "",
        () => {
          // If no, add empty boxes

          selectedOrders.forEach((orderId) => addEmptyBox(orderId, boxType));
        },
        () => {
          // If yes, add boxes with all items

          selectedOrders.forEach((orderId) =>
            addBoxWithAllItems(orderId, boxType),
          );
        },
        "Yes, all items can be added into one box",
        "No, Multiple Boxes will be needed",
      );
    } else {
      // If any order already has boxes, just add empty boxes to all selected orders
      selectedOrders.forEach((orderId) => addEmptyBox(orderId, boxType));
    }
  };

  const performDuplication = (quantity) => {
    const { orderId, boxIndex, items, maxDuplicates } = duplicationDetails;
    const originalBox = orderBoxes[orderId][boxIndex];

    const actualQuantity = Math.min(quantity, maxDuplicates);

    const newBoxes = Array.from({ length: actualQuantity }, (_, index) => {
      const { name, displayName } = getNextBoxName(orderId);
      return { ...originalBox, name, displayName, items };
    });

    setOrderBoxes((prev) => ({
      ...prev,
      [orderId]: [...prev[orderId], ...newBoxes],
    }));

    // Update assignedQuantities and packedQuantities
    const newAssignedQuantities = { ...(assignedQuantities[orderId] || {}) };
    newBoxes.forEach((box) => {
      box.items.forEach((item) => {
        newAssignedQuantities[item.sku] =
          (newAssignedQuantities[item.sku] || 0) + item.packQuantity;
      });
    });

    setAssignedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    setPackedQuantities((prev) => ({
      ...prev,
      [orderId]: newAssignedQuantities,
    }));

    renumberBoxes(orderId);

    notify.success(
      "Boxes Duplicated",
      `Duplicated ${actualQuantity} box${actualQuantity > 1 ? "es" : ""} in Order #${orderId}.`,
    );

    setDuplicationModalOpen(false);
  };

  const handleDuplicateBox = (orderId, boxIndex) => {
    const originalBox = orderBoxes[orderId][boxIndex];
    const maxDuplicates = calculateMaxDuplicates(orderId, originalBox.items);

    if (maxDuplicates === 0) {
      notify.info(
        "Box Duplication Not Possible",
        "There are not enough available quantities to duplicate this box.",
      );
      return;
    }

    setDuplicationDetails({
      orderId,
      boxIndex,
      quantity: 1,
      items: originalBox.items,
      maxDuplicates,
    });
    setDuplicationModalOpen(true);
  };
  const handleBoxTypeSelection = (orderId, boxIndex, boxType) => {
    if (boxType.isCustom) {
      handleEditDimensions(orderId, boxIndex);
    } else {
      setOrderBoxes((prev) => ({
        ...prev,
        [orderId]: prev[orderId].map((box, index) =>
          index === boxIndex
            ? { ...box, ...boxType, type: boxType.name || "", name: box.name }
            : box,
        ),
      }));
      notify.success(
        "Box Updated",
        `Updated box type for Order #${orderId}, Box #${boxIndex + 1} to ${boxType.name}`,
      );
    }
  };

  const handleDimensionsSubmit = () => {
    const errors = {};
    let hasErrors = false;

    Object.entries(boxDimensionsDetails).forEach(([dim, value]) => {
      if (value === "" || value < 0) {
        errors[dim] =
          `${dim.charAt(0).toUpperCase() + dim.slice(1)} must be greater than or equal to 0`;
        hasErrors = true;
      }
    });

    if (hasErrors) {
      setDimensionErrors(errors);
      return;
    }

    const customBox = {
      ...boxDimensionsDetails,
      name: "Custom Box",
      isCustom: true,
      type: "Custom Box",
    };

    if (selectedOrderForCustomBox?.includes("-")) {
      const [orderId, boxIndex] = selectedOrderForCustomBox.split("-");
      setOrderBoxes((prev) => ({
        ...prev,
        [orderId]: prev[orderId].map((box, index) => {
          if (index === parseInt(boxIndex)) {
            const itemsWeight = box.items.reduce(
              (sum, item) => sum + (item.weight || 0) * item.packQuantity,
              0,
            );
            return {
              ...box,
              ...customBox,
              totalWeight: (customBox.weight || 0) + itemsWeight,
            };
          }
          return box;
        }),
      }));

      notify.success(
        "Box Updated",
        `Updated box dimensions for Order #${orderId}, Box #${parseInt(boxIndex) + 1}`,
      );
    } else if (selectedOrderForCustomBox) {
      addEmptyBox(selectedOrderForCustomBox, customBox);
    } else {
      selectedOrders.forEach((orderId) => {
        addEmptyBox(orderId, customBox);
      });
    }

    // Reset and close modal
    setIsBoxDimensionsModalOpen(false);
    setBoxDimensionsDetails({
      length: "",
      width: "",
      height: "",
      weight: 0,
    });
    setSelectedOrderForCustomBox(null);
  };

  const calculateMaxDuplicates = (orderId, boxItems) => {
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);
    const currentAssignedQuantities = assignedQuantities[orderId] || {};

    return Math.min(
      ...boxItems.map((item) => {
        const orderItem = order.orderDetails.find((i) => i.sku === item.sku);
        const availableQuantity =
          orderItem.quantity - (currentAssignedQuantities[item.sku] || 0);
        return Math.floor(availableQuantity / item.packQuantity);
      }),
    );
  };

  const handleDeleteBox = (orderId, boxIndex) => {
    setOrderBoxes((prev) => {
      const boxToDelete = prev[orderId][boxIndex];
      const updatedBoxes = prev[orderId].filter(
        (_, index) => index !== boxIndex,
      );

      // Update assignedQuantities and packedQuantities
      const newAssignedQuantities = { ...assignedQuantities[orderId] };
      boxToDelete.items.forEach((item) => {
        newAssignedQuantities[item.sku] = Math.max(
          0,
          (newAssignedQuantities[item.sku] || 0) - item.packQuantity,
        );
      });

      setAssignedQuantities((prev) => ({
        ...prev,
        [orderId]: newAssignedQuantities,
      }));

      setPackedQuantities((prev) => ({
        ...prev,
        [orderId]: newAssignedQuantities,
      }));

      notify.warning(
        "Box Removed",
        `Removed Box ${boxIndex + 1} (${boxToDelete.length}x${boxToDelete.width}x${boxToDelete.height} in) from Order #${orderId}`,
      );

      return {
        ...prev,
        [orderId]: updatedBoxes,
      };
    });

    // Renumber the boxes after deletion
    renumberBoxes(orderId);
  };

  const isAllItemsPacked = () => {
    return detailedOrdersForBatching.every((order) => {
      const orderPackedQuantities = packedQuantities[order.orderId] || {};
      return order.orderDetails.every(
        (item) => (orderPackedQuantities[item.sku] || 0) === item.quantity,
      );
    });
  };

  const handleErasePlan = () => {
    appState.showNewConfirmation(
      "Erase Packing Plan",
      "Are you sure you want to erase the entire packing plan? This action cannot be undone.",
      confirmErasePlan,
      () => {
        // This function will be called if the user clicks "No"
        // We don't need to do anything in this case
      },
      "No",
      "Yes, Erase Plan",
    );
  };

  const confirmErasePlan = () => {
    setOrderBoxes({});
    setAssignedQuantities({});
    setPackedQuantities({});
    setBoxCounts({});
    notify.success(
      "Plan Erased",
      "All boxes have been removed from the orders.",
    );
  };

  const handleCancelPlan = () => {
    // Here you might want to clear the current packing plan or navigate away
    notify.info(
      "Packing Plan Cancelled",
      "The packing plan has been cancelled.",
    );
  };

  return (
    <div className="w-full pb-20 font-inter">
      <div className="mb-6 flex items-center justify-between">
        <div className="flex items-center gap-2">
          <span className="text-gray-500">
            {selectedOrders.size} of {detailedOrdersForBatching.length} orders
            selected
          </span>
        </div>

        <div className="flex gap-3">
          <PrimaryButton
            height="2.5rem"
            minWidth="13rem"
            maxWidth="20rem"
            variant="secondary"
            className="mt-2 text-base font-medium"
            onClick={handleSelectAll}>
            Select All Orders
          </PrimaryButton>

          <BoxTypePopover
            onSelectBox={handleAddBoxToSelected}
            isOpen={isBoxPopoverOpen}
            setIsOpen={setIsBoxPopoverOpen}
            boxTypes={boxTypes}
            trigger={
              <PrimaryButton
                height="2.5rem"
                minWidth="13rem"
                maxWidth="20rem"
                variant="primary"
                className={`mt-2 border-none text-base font-medium ${
                  selectedOrders.size === 0
                    ? "cursor-not-allowed opacity-50"
                    : ""
                }`}
                disabled={selectedOrders.size === 0}>
                <PlusIcon className="mr-2 h-4 w-4" />
                Add Box to Selected
                <ChevronDownIcon className="ml-1 h-4 w-4" />
              </PrimaryButton>
            }
          />

          <PrimaryButton
            height="2.5rem"
            minWidth="7rem"
            maxWidth="20rem"
            variant="primary"
            className="mt-2 text-base font-medium"
            danger
            onClick={handleErasePlan}>
            <TrashIcon className="mr-2 h-4 w-4" />
            <span>Erase Plan</span>
          </PrimaryButton>
        </div>
      </div>

      <SlideOverPanel
        open={openManualAddItemsSlideOverPanel}
        containerStyle="max-w-6xl"
        setOpen={setOpenManualAddItemsSlideOverPanel}
        isCrossIconVisible={false}
        title={
          <HeaderWithArrow
            headerTitle={
              "Add Items to " +
              selectedBoxForItems?.box?.displayName +
              " from order #" +
              selectedBoxForItems?.orderId
            }
            description="Select the required items and enter their quantities to pack into this box. Ensure the quantities match the available stock to maintain accuracy and avoid discrepancies."
            isLearnMore={false}
            arrowAction={() => setOpenManualAddItemsSlideOverPanel(false)}
            isArrow
            mainClasses="mb-0"
          />
        }>
        {selectedBoxForItems && (
          <BoxItemsTable
            items={
              detailedOrdersForBatching.find(
                (o) => o.orderId === selectedBoxForItems.orderId,
              ).orderDetails
            }
            assignedQuantities={
              assignedQuantities[selectedBoxForItems.orderId] || {}
            }
            onConfirm={handleConfirmAddItems}
          />
        )}
      </SlideOverPanel>
      <NewModal
        isOpen={isBoxDimensionsModalOpen}
        onClose={() => {
          setIsBoxDimensionsModalOpen(false);
          setDimensionErrors({
            length: "",
            width: "",
            height: "",
          });
        }}
        title="Enter Custom Box Dimensions"
        maxHeight="600px">
        <div className="py-4 font-inter">
          <div className="flex flex-col gap-4">
            {["length", "width", "height", "weight"].map((dim) => (
              <div key={dim} className="flex flex-col">
                <div className="flex items-center">
                  <label className="w-24 font-medium">
                    {dim.charAt(0).toUpperCase() + dim.slice(1)}{" "}
                    {dim === "weight" ? "(lbs):" : "(in):"}
                  </label>
                  <input
                    type="number"
                    min="0"
                    step={dim === "weight" ? "0.1" : "1"}
                    className={`${dimensionErrors[dim] ? "border-red-500" : "border-gray-300"} ml-2 w-24 rounded-md border px-2 py-1`}
                    value={boxDimensionsDetails[dim]}
                    onChange={(e) => {
                      const value = Number(e.target.value);
                      if (value < 0) {
                        setDimensionErrors((prev) => ({
                          ...prev,
                          [dim]: `${dim.charAt(0).toUpperCase() + dim.slice(1)} cannot be negative`,
                        }));
                        return;
                      }
                      setDimensionErrors((prev) => ({
                        ...prev,
                        [dim]: "",
                      }));
                      setBoxDimensionsDetails((prev) => ({
                        ...prev,
                        [dim]: value,
                      }));
                    }}
                  />
                </div>
                {dimensionErrors[dim] && (
                  <span className="ml-26 mt-1 text-sm text-red-500">
                    {dimensionErrors[dim]}
                  </span>
                )}
              </div>
            ))}
          </div>
          <div className="mt-6 flex justify-end gap-4">
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="secondary"
              onClick={() => {
                setIsBoxDimensionsModalOpen(false);
                setDimensionErrors({
                  length: "",
                  width: "",
                  height: "",
                });
              }}>
              Cancel
            </PrimaryButton>
            <PrimaryButton
              height="2.5rem"
              width="7rem"
              variant="primary"
              onClick={handleDimensionsSubmit}>
              Confirm
            </PrimaryButton>
          </div>
        </div>
      </NewModal>

      <BoxDuplicationModal
        isOpen={duplicationModalOpen}
        onClose={() => setDuplicationModalOpen(false)}
        onConfirm={performDuplication}
        maxDuplicates={duplicationDetails.maxDuplicates}
      />

      <div className="space-y-4">
        {detailedOrdersForBatching.map((order) => (
          <PlannedOrder
            key={order.orderId}
            order={order}
            isSelected={selectedOrders.has(order.orderId)}
            onSelect={handleSelectOrder}
            onAddBox={handleAddBox}
            boxes={orderBoxes[order.orderId] || []}
            onDuplicateBox={handleDuplicateBox}
            onDeleteBox={handleDeleteBox}
            isExpanded={expandedOrderId === order.orderId}
            onToggle={() => handleOrderToggle(order.orderId)}
            expandedBoxId={expandedBoxId}
            onBoxToggle={handleBoxToggle}
            setBoxDimensionsDetails={setBoxDimensionsDetails}
            setDimensionErrors={setDimensionErrors}
            setIsBoxDimensionsModalOpen={setIsBoxDimensionsModalOpen}
            setSelectedOrderForCustomBox={setSelectedOrderForCustomBox}
            handleAddBoxToSelected={handleAddBoxToSelected}
            onAddItems={handleAddItemsToBox}
            packedQuantities={packedQuantities[order.orderId] || {}}
            onEditDimensions={handleEditDimensions}
            onUpdateWeight={handleUpdateWeight}
            onSelectBoxType={handleBoxTypeSelection}
            onUpdateItems={handleUpdateBoxItems}
            customers={customers}
            warehouses={warehouses}
            boxTypes={boxTypes}
          />
        ))}
      </div>

      {/* <div className="fixed bottom-0 left-0 right-0 border-t border-gray-200 bg-white p-4">
        <div className="mx-auto flex max-w-4xl justify-end gap-4">
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="secondary"
            onClick={handleCancelPlan}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="primary"
            onClick={handleConfirmPlan}
            disabled={!isAllItemsPacked()}>
            Confirm Plan
          </PrimaryButton>
        </div>
      </div> */}
    </div>
  );
};

export default PackPlan;
