import _ from "lodash";
import {
  ChevronDownIcon,
  ChevronUpIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import AddButton from "#components/utils/AddButton";
import TextField from "#components/utils/TextField";
import Autocomplete from "#components/utils/Autocomplete";
import MultiSelectAutoComplete from "#components/utils/MultiSelectAutoComplete";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import HollowButton from "#utils/hollowButton";
import productConstants from "#constants/product";
import SelectedSalesChannel from "./SelectedSalesChannel";

const SalesPrice = ({
  sourceIsNotHopStack,
  isRenderingFromCatalogEdit,
  addBreakdownSalesPrice,
  salesPrice,
  selectedProduct,
  expandCollapseSalesPriceSection,
  onChange,
  customerList,
  onReomveSalesPrice,
  defaultSalesPriceComponentKey,
  onSalesPriceChange,
  onAddSalesPrice,
  onRemoveSalesPrice,
  onRemoveCostPriceSalesPrice,
}) => {
  const allSelectedSalesChannel =
    selectedProduct?.salesPrice?.reduce((initArray, spData) => {
      if (spData && spData?.salesChannel && spData?.salesChannel?.length > 0) {
        return [...initArray, ...spData.salesChannel];
      }
      return initArray;
    }, []) || [];

  return (
    <>
      <div className="mb-4 flex justify-between">
        <div className="flex-col gap-1">
          <div className="text-base font-semibold text-454A4F">
            Sale Price Info
          </div>
          <div className="text-sm font-normal">
            Context text on sale pricing goes here...
          </div>
        </div>
        <div className={`${sourceIsNotHopStack ? "pointer-events-none" : ""}`}>
          {!isRenderingFromCatalogEdit ? (
            <AddButton
              text="Add New"
              onClick={() => addBreakdownSalesPrice()}
            />
          ) : (
            <HollowButton
              text="Add New"
              colour={"primaryAccent"}
              onClick={() => addBreakdownSalesPrice()}
              styles="relative flex before:content-['+'] before:text-primaryAccent before:font-semibold before:mr-2"
            />
          )}
        </div>
      </div>
      {salesPrice.length > 0 &&
        salesPrice.map((salesPriceObjectValues, indx) => {
          const salesData = selectedProduct?.salesPrice?.[indx]?.valueObj;
          const salesPriceObject = _.omit(salesData, [
            "total",
            "discount",
            "currency",
          ]);
          const isDisabledSalesPriceField = _.keys(salesPriceObject).length > 0;
          let totalSalesValue;
          if (_.values(salesPriceObject).length > 0) {
            totalSalesValue =
              _.values(salesPriceObject).reduce(
                (initVal, nxtVal) => initVal + Number(nxtVal) * 100,
                0,
              ) / 100;
          }
          let salesPriceFieldValue =
            totalSalesValue ?? (salesData?.total || "");
          if (isDisabledSalesPriceField)
            salesPriceFieldValue = Number(salesPriceFieldValue).toFixed(2);
          let salesPriceWithDiscount = totalSalesValue || salesData?.total;
          if (salesData?.discount) {
            salesPriceWithDiscount =
              (Number(salesPriceWithDiscount * 100) -
                Number(salesData.discount * 100)) /
              100;
          }

          const getUnSelectedSalesChannel = (customerList || []).filter(
            (customerObject) =>
              (
                selectedProduct?.salesPrice?.[indx]?.salesChannel || []
              ).includes(customerObject?.id) ||
              !allSelectedSalesChannel.includes(customerObject?.id),
          );

          return (
            <div className="flex items-center" key={`sp-main-section-${indx}`}>
              <div
                className={`mb-4 w-full rounded-md border-2 border-B3BFCA p-4 duration-500`}>
                <div
                  className={`flex justify-between ${isRenderingFromCatalogEdit ? "mb-2" : ""}`}>
                  <div>
                    <div>
                      <div className="text-sm font-normal">
                        Total Sale Price
                      </div>
                      <div className="text-xl font-semibold">{`$${Number(salesPriceWithDiscount || 0).toFixed(2)}`}</div>
                    </div>
                  </div>
                  <div
                    className="my-auto mr-2 cursor-pointer text-xl"
                    onClick={() => expandCollapseSalesPriceSection(indx)}>
                    {salesPriceObjectValues.active ? (
                      <ChevronUpIcon className="h-6 w-6" />
                    ) : (
                      <ChevronDownIcon className="h-6 w-6" />
                    )}
                  </div>
                </div>
                {salesPriceObjectValues.active && (
                  <div
                    className={`${sourceIsNotHopStack ? "pointer-events-none" : ""}`}>
                    <div>
                      <div className="flex flex-wrap">
                        <div
                          className={`${!isRenderingFromCatalogEdit ? "my-4" : "mb-4"} flex gap-4`}>
                          {!isRenderingFromCatalogEdit ? (
                            <>
                              <TextField
                                type="number"
                                id="salesPrice"
                                label="Sales Price"
                                placeholder=" "
                                value={salesPriceFieldValue}
                                onChange={(e) => {
                                  const currency = {};
                                  if (!salesData?.currency) {
                                    currency["currency"] = "USD-$";
                                  }
                                  onChange({
                                    target: {
                                      name: indx,
                                      value: {
                                        ...selectedProduct?.salesPrice?.[indx],
                                        valueObj: {
                                          ...salesData,
                                          ...currency,
                                          total: e.target.value,
                                        },
                                      },
                                      type: "salesPrice",
                                    },
                                  });
                                }}
                                name="salesPrice"
                                disabled={isDisabledSalesPriceField}
                                className={
                                  isDisabledSalesPriceField ? "bg-white" : ""
                                }
                              />
                              <Autocomplete
                                options={productConstants?.currencyOption}
                                labelKey="label"
                                valueKey="value"
                                onChange={(e) => {
                                  onChange({
                                    target: {
                                      name: indx,
                                      value: {
                                        ...selectedProduct?.salesPrice?.[indx],
                                        valueObj: { ...salesData, currency: e },
                                      },
                                      type: "salesPrice",
                                    },
                                  });
                                }}
                                value={salesData?.currency || "USD-$"}
                                placeholder={"Currency"}
                                id={`currency`}
                              />
                            </>
                          ) : (
                            <>
                              <div className="flex flex-col">
                                <span className="mb-1 text-sm font-medium text-lightGray">
                                  Sales Price{" "}
                                  <Tooltip title="Enter the sales price">
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </span>
                                <input
                                  id="salesPrice"
                                  type="number"
                                  name="salesPrice"
                                  className={`"w-[274px] rounded border p-2 ${isDisabledSalesPriceField ? "bg-gray-100" : ""}`}
                                  value={salesPriceFieldValue}
                                  onChange={(e) => {
                                    const currency = {};
                                    if (!salesData?.currency) {
                                      currency["currency"] = "USD-$";
                                    }
                                    onChange({
                                      target: {
                                        name: indx,
                                        value: {
                                          ...selectedProduct?.salesPrice?.[
                                            indx
                                          ],
                                          valueObj: {
                                            ...salesData,
                                            ...currency,
                                            total: e.target.value,
                                          },
                                        },
                                        type: "salesPrice",
                                      },
                                    });
                                  }}
                                  readOnly={isDisabledSalesPriceField}
                                  disabled={isDisabledSalesPriceField}
                                />
                              </div>
                              <div>
                                <AutoCompleteSingleSelect
                                  options={productConstants?.currencyOption}
                                  value={salesData?.currency || "USD-$"}
                                  onChange={(e) => {
                                    onChange({
                                      target: {
                                        name: indx,
                                        value: {
                                          ...selectedProduct?.salesPrice?.[
                                            indx
                                          ],
                                          valueObj: {
                                            ...salesData,
                                            currency: e,
                                          },
                                        },
                                        type: "salesPrice",
                                      },
                                    });
                                  }}
                                  labelKey="label"
                                  valueKey="value"
                                  placeholder="Select an option"
                                  labelText="Currency"
                                  shouldDeselect={false}
                                  error=""
                                  infoText="Select a currency for sales price"
                                  showNotSelectedError={true}
                                  parentClasses="flex flex-col w-[274px]"
                                  labelClasses="text-sm text-lightGray font-medium"
                                  inputClasses="-mt-1"
                                />
                              </div>
                              <div>
                                <span className="text-sm font-medium text-lightGray">
                                  Select Channels{" "}
                                  <Tooltip title="Select Sales Channels for sales price">
                                    <InfoCircleOutlined />
                                  </Tooltip>
                                </span>
                                <SelectedSalesChannel
                                  customerList={getUnSelectedSalesChannel}
                                  indx={indx}
                                  selectedProduct={selectedProduct}
                                  salesData={salesData}
                                  onChange={onChange}
                                />
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      {!isRenderingFromCatalogEdit && (
                        <div className="mb-4 w-[353px]">
                          <label className="mb-2 block text-left">
                            <span className="pb-4 font-montserrat text-sm font-medium text-2C7695">
                              Select Channels
                            </span>
                          </label>
                          <MultiSelectAutoComplete
                            options={getUnSelectedSalesChannel}
                            labelKey={"name"}
                            valueKey={"id"}
                            setValues={(selectedOptions) => {
                              const currency = {};
                              if (!salesData?.currency) {
                                currency["currency"] = "USD-$";
                              }
                              onChange({
                                target: {
                                  name: indx,
                                  value: {
                                    ...selectedProduct?.salesPrice?.[indx],
                                    salesChannel: [...selectedOptions],
                                    valueObj: {
                                      ...salesData,
                                      ...currency,
                                    },
                                  },
                                  type: "salesPrice",
                                },
                              });
                            }}
                            values={
                              selectedProduct?.salesPrice?.[indx]
                                ?.salesChannel || []
                            }
                            emptyValuesAccountsForAll={
                              (
                                selectedProduct?.salesPrice?.[indx]
                                  ?.salesChannel || []
                              ).length > 0
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="mb-4">
                      <div className="my-4 flex rounded-md border border-primaryAccent bg-blue-100">
                        <span className="mb-2 ml-2 mr-3 mt-[10px] text-primaryAccent">
                          <InformationCircleIcon className="h-6 w-6 text-primaryAccent" />
                        </span>
                        <div className="py-3 text-sm text-lightGray">
                          Please Note - Adding component breakdowns with charges
                          will sum up to calculate the Sales Price.
                        </div>
                      </div>
                    </div>
                    <div>
                      <div
                        className={`w-full rounded-md border border-[#DDDFE0] p-4 duration-500`}>
                        <div className="mb-[10px] text-sm font-medium text-lightGray">
                          Component Breakdown
                        </div>
                        {_.keys(salesPriceObject).length > 0 && (
                          <div className="flex flex-col gap-4">
                            {_.keys(salesPriceObject).map((spKey, spIndx) => {
                              const salesPrice =
                                Number(salesPriceObject[spKey]) % 1 !== 0
                                  ? Number(
                                      salesPriceObject[spKey] || "",
                                    ).toFixed(2)
                                  : salesPriceObject[spKey];

                              return (
                                <div
                                  className="flex w-full items-center justify-between"
                                  key={`sp-breakdown-component-${spIndx}`}>
                                  <div
                                    className="flex w-5/6 justify-between rounded-md border border-[#DDDFE0] p-4 text-base duration-500"
                                    key={`sp-key-val-${indx}`}>
                                    <div className="flex w-full items-center space-x-4">
                                      <div
                                        className={
                                          "w-[380px] overflow-hidden text-ellipsis whitespace-nowrap text-[#111827]"
                                        }>
                                        {spKey}
                                      </div>
                                    </div>
                                    <div className="flex w-[130px] text-base">
                                      <div className="w-[90px] rounded-l border p-2">
                                        {salesPrice}
                                      </div>
                                      <div className="flex w-[40px] items-center justify-center rounded-r border">
                                        $
                                      </div>
                                    </div>
                                  </div>
                                  {spKey.toLowerCase() !== "cost summation" ? (
                                    <div className="ml-4 mt-1 w-1/4">
                                      <div
                                        className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                                        onClick={() => {
                                          onReomveSalesPrice(
                                            spKey,
                                            "salesPrice",
                                            indx,
                                          );
                                        }}>
                                        Remove
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="ml-4 mt-4 w-1/4"></div>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        )}
                        {salesPriceObjectValues?.salesPriceData?.length > 0 &&
                          salesPriceObjectValues.salesPriceData.map(
                            (objectForSalesPrice, dataIndx) => {
                              const isDisabledDefaultCostField =
                                defaultSalesPriceComponentKey.includes(
                                  objectForSalesPrice.key.toLowerCase(),
                                );
                              return (
                                <div
                                  className="mt-4 w-full"
                                  key={`sp-input-${dataIndx}-${indx}`}>
                                  <div className="flex w-3/4 items-center space-x-4">
                                    {!isRenderingFromCatalogEdit ? (
                                      <>
                                        <TextField
                                          type="text"
                                          label="Sale Cost Name"
                                          placeholder=" "
                                          onChange={(e) => {
                                            if (
                                              ![
                                                ...defaultSalesPriceComponentKey,
                                                "cost summation",
                                              ].includes(
                                                (
                                                  e.target.value || ""
                                                ).toLowerCase(),
                                              )
                                            ) {
                                              onSalesPriceChange(
                                                e,
                                                dataIndx,
                                                indx,
                                                "salesPriceData",
                                              );
                                            }
                                          }}
                                          value={objectForSalesPrice.key || ""}
                                          name="key"
                                          disabled={isDisabledDefaultCostField}
                                          className={
                                            isDisabledDefaultCostField
                                              ? "bg-white"
                                              : ""
                                          }
                                        />
                                        <TextField
                                          type="number"
                                          label="Cost"
                                          placeholder=" "
                                          onChange={(e) => {
                                            onSalesPriceChange(
                                              e,
                                              dataIndx,
                                              indx,
                                              "salesPriceData",
                                            );
                                          }}
                                          value={
                                            objectForSalesPrice.value || ""
                                          }
                                          name="value"
                                        />
                                      </>
                                    ) : (
                                      <>
                                        <div className="flex flex-col">
                                          <span className="mb-1 text-sm font-medium text-lightGray">
                                            Sale Cost Name{" "}
                                            <Tooltip title="Enter the sale cost name">
                                              <InfoCircleOutlined />
                                            </Tooltip>
                                          </span>
                                          <input
                                            className={`"w-[274px] rounded border p-2 ${isDisabledDefaultCostField ? "bg-gray-100" : ""}`}
                                            type="text"
                                            id="key"
                                            name="key"
                                            onChange={(e) => {
                                              if (
                                                (
                                                  e.target.value || ""
                                                ).toLowerCase() !==
                                                "cost summation"
                                              ) {
                                                onSalesPriceChange(
                                                  e,
                                                  dataIndx,
                                                  indx,
                                                  "salesPriceData",
                                                );
                                              }
                                            }}
                                            value={
                                              objectForSalesPrice.key || ""
                                            }
                                            readOnly={
                                              isDisabledDefaultCostField
                                            }
                                            disabled={
                                              isDisabledDefaultCostField
                                            }
                                          />
                                        </div>
                                        <div className="flex flex-col">
                                          <span className="mb-1 text-sm font-medium text-lightGray">
                                            Cost{" "}
                                            <Tooltip title="Enter the cost">
                                              <InfoCircleOutlined />
                                            </Tooltip>
                                          </span>
                                          <input
                                            className={`"w-[274px] rounded border p-2`}
                                            type="number"
                                            id="value"
                                            name="value"
                                            value={
                                              objectForSalesPrice.value || ""
                                            }
                                            onChange={(e) => {
                                              onSalesPriceChange(
                                                e,
                                                dataIndx,
                                                indx,
                                                "salesPriceData",
                                              );
                                            }}
                                          />
                                        </div>
                                      </>
                                    )}
                                    <div
                                      className="mt-6 cursor-pointer pl-2 text-lg font-bold text-primaryAccent"
                                      onClick={() =>
                                        onAddSalesPrice(
                                          objectForSalesPrice,
                                          indx,
                                          dataIndx,
                                        )
                                      }>
                                      Add
                                    </div>
                                    <div
                                      className="mt-6 cursor-pointer pl-2 text-lg font-bold text-red-500"
                                      onClick={() =>
                                        onRemoveSalesPrice(
                                          indx,
                                          dataIndx,
                                          "salesPriceData",
                                        )
                                      }>
                                      Remove
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                        <div className="mt-2">
                          {!isRenderingFromCatalogEdit ? (
                            <AddButton
                              text="Add Sales Component"
                              onClick={() =>
                                addBreakdownSalesPrice(indx, "salesPriceData")
                              }
                            />
                          ) : (
                            <div
                              className="cursor-pointer text-sm font-semibold leading-6 tracking-normal text-blueBackground"
                              onClick={() =>
                                addBreakdownSalesPrice(indx, "salesPriceData")
                              }>
                              <u>Add Sales Component</u>
                            </div>
                          )}
                        </div>
                      </div>
                      <div
                        className="mt-4 flex w-full items-center justify-between"
                        key={`sales-price-breakdown-discount`}>
                        <div
                          className="flex w-5/6 justify-between rounded-md border border-[#DDDFE0] p-4 text-base duration-500"
                          key={`sp-key-val-discount`}>
                          <div className="flex w-full items-center space-x-4">
                            <div
                              className={
                                "w-[380px] overflow-hidden text-ellipsis whitespace-nowrap text-[#111827]"
                              }>
                              Discount
                            </div>
                          </div>
                          <div className="flex w-[130px] text-base">
                            <input
                              type="number"
                              className="w-[90px] rounded-l border p-2"
                              value={salesData?.discount || ""}
                              onChange={(e) => {
                                onChange({
                                  target: {
                                    name: indx,
                                    value: {
                                      ...selectedProduct?.salesPrice?.[indx],
                                      valueObj: {
                                        ...salesData,
                                        discount: e.target.value,
                                      },
                                    },
                                    type: "salesPrice",
                                  },
                                });
                              }}
                              name="discount"
                            />
                            <div className="flex w-[40px] items-center justify-center rounded-r border">
                              $
                            </div>
                          </div>
                        </div>
                        <div className="ml-4 mt-1 w-1/4"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div
                className={`mb-4 ml-4 ${sourceIsNotHopStack ? "pointer-events-none" : ""}`}>
                <XIcon
                  className="h-5 w-5 cursor-pointer text-red-600"
                  onClick={() =>
                    onRemoveCostPriceSalesPrice(indx, "salesPrice")
                  }
                />
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SalesPrice;
