import { useState, useContext } from "react";
import _ from "lodash";
import AddButton from "#components/utils/AddButton";
import TextField from "#components/utils/TextField";
import Autocomplete from "#components/utils/Autocomplete";
import { AppStateContext } from "#contexts/appState";

const ProductVariants = ({
  sourceIsNotHopStack,
  allVariantComponents,
  getProductVariantComponents,
  selectedProduct,
  onChange,
  isRenderingFromCatalogs = false,
}) => {
  const [componentsCost, setComponentsCost] = useState([]);
  const appState = useContext(AppStateContext);

  const addComponentLevelCost = () => {
    const componentsCostData = [...componentsCost];
    componentsCostData.push({});
    setComponentsCost(componentsCostData);
  };

  const onRemoveComponentCost = (index) => {
    const componentsCostData = [...componentsCost];
    componentsCostData.splice(index, 1);
    setComponentsCost(componentsCostData);
  };

  const addComponentCostInCostPrice = (componentCostObject, index) => {
    const selectedComponentCost = { ...componentCostObject };
    if (!_.isObject(selectedProduct?.costPrice?.[selectedComponentCost?.id])) {
      if (selectedComponentCost?.selectedQuantity > 0) {
        selectedComponentCost.selectedPrice =
          ((Number(selectedComponentCost?.price || "") /
            Number(selectedComponentCost?.quantity || "")) *
            Number(selectedComponentCost?.selectedQuantity || "")) /
          100;
        onChange({
          target: {
            name: selectedComponentCost.id,
            value: selectedComponentCost,
            type: "costPrice",
          },
        });
        onRemoveComponentCost(index);
      } else {
        appState.setAlert(
          "Please enter quantity greater than zero",
          "error",
          5000,
        );
      }
    }
  };

  const removeComponentVariant = (id, key) => {
    onChange({
      target: {
        name: id,
        value: "delete",
        type: key,
      },
    });
  };

  const changeComponentValue = (index, key, value, onChangeType = "") => {
    const componentsCostData = [...componentsCost];
    if (onChangeType === "component") {
      const componentDetails = allVariantComponents.find(
        (componentObject) => componentObject.id === value,
      );
      componentsCostData[index] = {
        ...componentDetails,
        selectedQuantity: componentDetails?.quantity,
        selectedPrice: componentDetails?.price,
      };
    } else componentsCostData[index][key] = value;
    setComponentsCost(componentsCostData);
  };

  const getSelectedProductVariantComponent = () => {
    if (selectedProduct?.costPrice) {
      const variantComponent = [];
      for (const costPriceKey in selectedProduct?.costPrice) {
        if (
          selectedProduct?.costPrice[costPriceKey] !== null &&
          _.isObject(selectedProduct?.costPrice[costPriceKey])
        ) {
          variantComponent.push(selectedProduct?.costPrice[costPriceKey]);
        }
      }
      if (variantComponent.length > 0) {
        return variantComponent.map((componentObject, indx) => {
          return (
            <div
              className="flex w-full items-center justify-between"
              key={`component-varient-${indx}`}>
              <div
                className="mt-4 flex w-5/6 justify-between rounded-md border border-[#DDDFE0] p-4 text-base duration-500"
                key={`cp-key-val-${indx}`}>
                <div className="flex w-full items-center justify-between space-x-4">
                  <div className="w-[500px]">
                    {componentObject?.componentName}
                  </div>
                  <div className="w-36">
                    Quantity: {componentObject?.selectedQuantity}
                  </div>
                  <div className="w-40">UOM: {componentObject?.uom}</div>
                </div>
              </div>
              <div className="ml-4 mt-4 w-1/4">
                <div
                  className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                  onClick={() => {
                    removeComponentVariant(componentObject?.id, "costPrice");
                  }}>
                  Remove
                </div>
              </div>
            </div>
          );
        });
      }
    }
  };

  return (
    <div className={`${sourceIsNotHopStack ? "pointer-events-none" : ""}`}>
      <div className="catalog-textfield-wrapper">
        <label className="catalog-textfield-label">Product Components</label>
      </div>
      {getSelectedProductVariantComponent()}
      {componentsCost.length > 0 &&
        componentsCost.map((componentCostObject, index) => {
          return (
            <div className="mt-4 w-full" key={`component-input-${index}`}>
              <div className="flex w-5/6 items-center space-x-4">
                <div className="w-5/6">
                  <div
                    className={
                      isRenderingFromCatalogs ? "catalog-textfield-wrapper" : ""
                    }>
                    <label
                      className={
                        isRenderingFromCatalogs
                          ? "catalog-textfield-label"
                          : "mb-2 block text-left"
                      }>
                      Select Component
                    </label>
                    <Autocomplete
                      options={allVariantComponents}
                      labelKey="componentName"
                      valueKey="id"
                      onChange={(selectedValue) => {
                        if (
                          componentsCost.findIndex(
                            (costObject) => costObject?.id === selectedValue,
                          ) === -1
                        )
                          changeComponentValue(
                            index,
                            "id",
                            selectedValue,
                            "component",
                          );
                      }}
                      onKeyDown={_.debounce(getProductVariantComponents, 500)}
                      value={componentCostObject?.id || ""}
                      id={`product_component`}
                    />
                  </div>
                </div>
                <div
                  className={`${isRenderingFromCatalogs ? "mt-1 items-center" : "mt-8"} flex space-x-4`}>
                  {!isRenderingFromCatalogs ? (
                    <div>
                      <TextField
                        type="number"
                        id="quantity"
                        label="Quantity"
                        placeholder="0"
                        onChange={(e) =>
                          changeComponentValue(
                            index,
                            "selectedQuantity",
                            e.target.value,
                          )
                        }
                        value={componentCostObject?.selectedQuantity || ""}
                        name="quantity"
                        disabled={!componentCostObject?.id}
                      />
                    </div>
                  ) : (
                    <div className="catalog-textfield-wrapper">
                      <label className="catalog-textfield-label">
                        Quantity
                      </label>
                      <input
                        className="catalog-textfield !border-primaryAccent"
                        type="number"
                        value={componentCostObject?.selectedQuantity || ""}
                        onChange={(e) =>
                          changeComponentValue(
                            index,
                            "selectedQuantity",
                            e.target.value,
                          )
                        }
                        placeholder="0"
                        name="quantity"
                        disabled={!componentCostObject?.id}
                      />
                    </div>
                  )}
                  {!isRenderingFromCatalogs ? (
                    <div>
                      <TextField
                        type="text"
                        id="key"
                        label="UOM"
                        placeholder=" "
                        value={componentCostObject?.uom || ""}
                        name="key"
                        disabled={true}
                        className={componentCostObject?.uom ? "bg-white" : ""}
                      />
                    </div>
                  ) : (
                    <div className="catalog-textfield-wrapper">
                      <label className="catalog-textfield-label">UOM</label>
                      <input
                        className={`catalog-textfield !border-primaryAccent ${componentCostObject?.uom ? "bg-white" : ""}`}
                        type="text"
                        id="key"
                        value={componentCostObject?.uom || ""}
                        placeholder=" "
                        name="key"
                        disabled={true}
                      />
                    </div>
                  )}
                  <div
                    className={`${isRenderingFromCatalogs ? "mt-5" : "mt-4"} flex space-x-4`}>
                    <div
                      className="cursor-pointer text-lg font-bold text-primaryAccent"
                      onClick={() =>
                        addComponentCostInCostPrice(componentCostObject, index)
                      }>
                      Add
                    </div>
                    <div
                      className="cursor-pointer pl-2 text-lg font-bold text-red-500"
                      onClick={() => onRemoveComponentCost(index)}>
                      Remove
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      <div className="my-4">
        <AddButton
          text="Add Product Component"
          onClick={addComponentLevelCost}
          styles={[isRenderingFromCatalogs ? "bg-primaryAccent" : ""]}
        />
      </div>
    </div>
  );
};

export default ProductVariants;
