import React, { useState, useEffect } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_OUTBOUND_PLANS,
  GET_ORDERS,
  GET_ALL_BATCHES,
} from "#queries/index";
import CustomTable from "#newUiComponents/commons/CustomTable";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import { useContext } from "react";
import { AuthContext } from "#contexts/auth";
import OrderDetailsModal from "./OrderDetailsModal";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import QuickFilters from "#components/common/QuickFilters";
import { EntityContext } from "#contexts/entity";
import NewModal from "#newUiComponents/commons/NewModal";
import moment from "moment-timezone";
import PopoverOnTableAction from "#components/utils/PopoverOnTableAction";
import { DotsVerticalIcon } from "@heroicons/react/solid";

const OutboundPlan = () => {
  const auth = useContext(AuthContext);
  const entity = useContext(EntityContext);
  const notify = CustomNotification();
  const outboundPlansQuery = useQuery(GET_OUTBOUND_PLANS);
  const getOrdersQuery = useQuery(GET_ORDERS);
  const batchesQuery = useQuery(GET_ALL_BATCHES);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [warehouses, setWarehouses] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortConfig, setSortConfig] = useState(null);
  const [tableData, setTableData] = useState({ entities: [], total: 0 });
  const [ordersData, setOrdersData] = useState({});
  const [selectedOrders, setSelectedOrders] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [pickingBatches, setPickingBatches] = useState([]);

  const fetchOrdersData = async (orderIds) => {
    if (!orderIds || orderIds.length === 0) return;

    // Get unique order IDs using Set
    const uniqueOrderIds = [...new Set(orderIds)];

    const response = await getOrdersQuery.fetchData({
      filters: {
        id: uniqueOrderIds,
      },
      paginated: false,
      perPage: uniqueOrderIds.length,
      sort: "-orderDate",
    });

    if (response.data?.orders?.entities) {
      const ordersMap = response.data.orders.entities.reduce((acc, order) => {
        acc[order.id] = order;
        return acc;
      }, {});
      setOrdersData(ordersMap);
    }
  };

  const fetchOutboundPlans = async () => {
    const response = await outboundPlansQuery.fetchData({
      pageNumber: currentPage,
      perPage: pageSize,
      filters: {
        warehouse: entity.filters.warehouse ? entity.filters.warehouse[0] : "",
        customer: entity.filters.customer ? entity.filters.customer[0] : "",
        outboundPlanId: searchTerm,
      },
    });

    if (response.data?.getOutboundPlans) {
      setTableData(response.data.getOutboundPlans);

      const allOrderIds = response.data.getOutboundPlans.entities.reduce(
        (ids, plan) => {
          return [...ids, ...(plan.orders || [])];
        },
        [],
      );

      if (allOrderIds.length > 0) {
        fetchOrdersData(allOrderIds);
      }
    }
  };

  useEffect(() => {
    fetchOutboundPlans();
  }, [currentPage, pageSize, entity.filters, searchTerm]);

  const handleOrderClick = (orders) => {
    if (!orders || orders.length === 0) return;

    // Filter ordersData to only include the selected orders
    const selectedOrdersData = orders.reduce((acc, orderId) => {
      if (ordersData[orderId]) {
        acc[orderId] = ordersData[orderId];
      }
      return acc;
    }, {});

    if (!Object.keys(selectedOrdersData).length) {
      notify.error("No order details found for selected outbound plan.");
      return;
    }
    setSelectedOrders(selectedOrdersData);
    setIsModalOpen(true);
  };

  const handlePickBatchClick = async (row) => {
    if (!row?.outboundPlanId) {
      notify.error("Outbound Plan ID not found.");
      return;
    }

    const pickingBatchResponse = await batchesQuery.fetchData({
      perPage: entity.perPage,
      filters: {
        typeOfBatch: "PICKING",
        outboundPlanId: row.outboundPlanId,
      },
      paginated: false,
      pageNumber: 1,
      sort: entity.sort,
      strictTimeRange: false,
    });

    if (pickingBatchResponse.data?.getAllBatches?.entities) {
      setPickingBatches(pickingBatchResponse.data.getAllBatches.entities);
      setIsModalOpen(true);
    } else if (pickingBatchResponse.error?.message)
      notify.error("Error occured while fetching pick batches.");
  };

  const columns = [
    {
      key: "actions",
      title: "ACTIONS",
      isSort: false,
      render: (_, row) => (
        <PopoverOnTableAction
          icon={<DotsVerticalIcon className="h-6 w-6 text-[#737579]" />}
          panelClassName={
            "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
          }
          customClassName={"py-4"}>
          <div
            className={`p-4 ${row.status === "BATCHING_ATTEMPT_FAILED" ? "cursor-pointer" : "cursor-not-allowed bg-gray-100 text-gray-400"}`}
            onClick={() => {
              if (row.status === "BATCHING_ATTEMPT_FAILED") {
                // retry batching
              }
            }}>
            Retry Batching Attempt
          </div>
        </PopoverOnTableAction>
      ),
    },
    {
      key: "outboundPlanId",
      title: "OUTBOUND PLAN ID",
      isSort: false,
      stickPosition: "left",
      render: (outboundPlanId) => (
        <span className="font-semibold text-primaryAccent">
          {outboundPlanId}
        </span>
      ),
    },
    {
      key: "createdAt",
      title: "CREATED DATE",
      isSort: false,
      render: (value) => moment(value).format("YYYY-MM-DD HH:mm"),
    },
    {
      key: "orders",
      title: "NUMBER OF ORDERS",
      isSort: false,
      render: (orders) => (
        <span
          className="cursor-pointer font-semibold text-primaryAccent"
          onClick={() => handleOrderClick(orders)}>
          {(orders || []).length}
        </span>
      ),
    },
    {
      key: "pickingBatchesCount",
      title: "NUMBER OF PICK BATCHES",
      isSort: false,
      render: (pickingBatchesCount, row) => (
        <span
          className="cursor-pointer font-semibold text-primaryAccent"
          onClick={() => handlePickBatchClick(row)}>
          {pickingBatchesCount}
        </span>
      ),
    },
    {
      key: "status",
      title: "STATUS",
      isSort: false,
      render: (status) => status,
    },
    {
      key: "customer",
      title: "CLIENT(S)",
      isSort: false,
      render: (customer) => (
        <span>
          {(customers || []).find((client) => client.id === customer)?.name}
        </span>
      ),
    },
    {
      key: "warehouse",
      title: "WAREHOUSE",
      isSort: false,
      render: (warehouse) => (
        <span>{(warehouses || []).find((w) => w.id === warehouse)?.name}</span>
      ),
    },
  ];

  useDebouncedEffect(
    () => {
      setCurrentPage(1);
      fetchOutboundPlans();
    },
    500,
    [searchTerm],
  );

  const handleSort = (key, direction) => {
    setSortConfig({ key, direction });
    setCurrentPage(1);
    fetchOutboundPlans();
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
  };

  useEffect(() => {
    if (outboundPlansQuery.error) {
      console.error("Error fetching outbound plans:", outboundPlansQuery.error);
    }
  }, [outboundPlansQuery.error]);

  useEffect(() => {
    if (auth?.user) {
      if (auth?.user?.warehousesList) setWarehouses(auth.user.warehousesList);
      if (auth?.user?.customersList) setCustomers(auth.user.customersList);
    }
  }, [auth]);

  return (
    <div className="p-6 font-inter">
      <div className="mb-4 flex items-center justify-between">
        <HeaderWithArrow
          headerTitle={"Outbound Plans"}
          isArrow={false}
          titleClasses="font-montserrat text-2xl font-medium text-primaryAccent"
        />
      </div>
      {(customers?.length > 1 || warehouses?.length > 1) && (
        <QuickFilters
          warehouseFilterName={"warehouse"}
          customerFilterName={"customer"}
          customers={customers}
          warehouses={warehouses}
          filters={entity.filters}
          onChangeFilter={(field, value) => {
            entity.setFilters({
              ...entity.filters,
              [field]: value,
            });
          }}
        />
      )}

      <CustomTable
        columns={columns}
        data={tableData.entities}
        onSort={handleSort}
        isSearchable={true}
        isFilters={true}
        isPagination={true}
        loadingData={outboundPlansQuery.loading || getOrdersQuery.loading}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageSize={pageSize}
        setPageSize={setPageSize}
        searchTerm={searchTerm}
        onChangeSearchTerm={handleSearch}
        totalDataCount={tableData.total || 0}
        searchPlaceholder="Search outbound plan ID..."
        pageSizeOptions={["10", "20", "50", "100"]}
        noResultsText="No outbound plans found"
      />

      {isModalOpen && selectedOrders && (
        <OrderDetailsModal
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setSelectedOrders(null);
          }}
          orders={selectedOrders}
          customers={customers}
        />
      )}

      {isModalOpen && pickingBatches.length && (
        <PickingBatchTable
          isOpen={isModalOpen}
          onClose={() => {
            setIsModalOpen(false);
            setPickingBatches([]);
          }}
          batches={pickingBatches}
        />
      )}
    </div>
  );
};

const PickingBatchTable = ({ isOpen, onClose, batches }) => {
  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title="Picking Batches"
      maxHeight="80vh"
      minWidth="800px">
      <div className="flex flex-col space-y-4 font-inter">
        {Object.values(batches).map((batch) => (
          <div
            key={batch.id}
            className="rounded-lg border border-gray-200 bg-white shadow-sm">
            <div className="flex items-center justify-between p-4">
              <div className="grid grid-cols-4 gap-4 text-sm">
                <div>
                  <div className="font-medium text-gray-500">Batch ID</div>
                  <div>{batch.id}</div>
                </div>
                <div>
                  <div className="font-medium text-gray-500">Status</div>
                  <div>{batch.status}</div>
                </div>
                <div>
                  <div className="font-medium text-gray-500">Orders</div>
                  <div>{batch.attributes.orderIds?.length}</div>
                </div>
                <div>
                  <div className="font-medium text-gray-500">Created Date</div>
                  <div>{new Date(batch.createdAt).toLocaleDateString()}</div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </NewModal>
  );
};

export default OutboundPlan;
