import { XIcon } from "@heroicons/react/solid";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const BatchPrioritisation = ({
  selectedOrderIds,
  setSelectedOrderIds,
  orders,
  expandOrder,
}) => {
  const deselectOrder = (orderId) => {
    setSelectedOrderIds((prev) => prev.filter((order) => order !== orderId));
  };

  const onDragEnd = (result) => {
    if (result.destination.index === -1) return;
    const selectedProductsCopy = [...selectedOrderIds];
    const [reOrderedProducts] = selectedProductsCopy.splice(
      result.source.index,
      1,
    );
    selectedProductsCopy.splice(result.destination.index, 0, reOrderedProducts);
    setSelectedOrderIds(selectedProductsCopy);
  };

  return (
    <div>
      <div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="Column">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="flex flex-col gap-y-4">
                {selectedOrderIds.map((orderId, index) => (
                  <Draggable key={orderId} draggableId={orderId} index={index}>
                    {(provided) => (
                      <div
                        className="flex items-center justify-between rounded border-2 border-gray-300 p-3"
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        ref={provided.innerRef}
                        style={{
                          ...provided.draggableProps.style,
                          left: "auto", // required to fix the item offset while dragging
                        }}>
                        <div className="text-lg font-semibold text-primaryAccent">
                          <a
                            className="cursor-pointer"
                            onClick={() =>
                              expandOrder(
                                orders?.find((ord) => ord?.id === orderId),
                              )
                            }>
                            {
                              orders?.find((ord) => ord?.id === orderId)
                                ?.orderId
                            }
                          </a>
                        </div>

                        <div className="flex gap-4">
                          <div
                            className="cursor-pointer"
                            onClick={() => deselectOrder(orderId)}>
                            <XIcon className="h-5 w-5 text-gray-500" />
                          </div>

                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="h-5 w-5 text-gray-500">
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                              />
                            </svg>
                          </div>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
};

export default BatchPrioritisation;
