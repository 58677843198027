import _ from "lodash";
import JSPM from "jsprintmanager";
import * as htmlToImage from "html-to-image";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";

export const printBol = async (
  id,
  noOfCopies = 1,
  printer = null,
  download = false,
  appState = null,
) => {
  if (!id) {
    return appState && appState.setAlert("Could not print", "error", 5000);
  }

  try {
    html2canvas(document.getElementById(id), { quality: 1 }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const imgWidth = 210; // in mm
      const pageHeight = 297; // in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      const pdf = new jsPDF({ orientation: "portrait", format: "a4" });
      let position = 10; // give some top padding to first page

      pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position += heightLeft - imgHeight; // top padding for other pages
        pdf.addPage();
        pdf.addImage(imgData, "PNG", 0, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }

      if (download) {
        window.open(pdf.output("bloburl"));
        pdf.save(`${id}.pdf`);
        return;
      } else {
        const out = pdf.output("datauristring");
        // console.log(out);
        // return;
        // pdf.output;
        // canvas.toBlob((blob) => {
        const cpj = new JSPM.ClientPrintJob();
        let myPrinter;
        if (printer) {
          myPrinter = new JSPM.InstalledPrinter(printer);
        } else {
          myPrinter = new JSPM.DefaultPrinter();
        }
        cpj.clientPrinter = myPrinter;
        for (let i = 0; i < noOfCopies; i++) {
          const myFile = new JSPM.PrintFilePDF(
            out,
            JSPM.FileSourceType.URL,
            `${id}_${i}.pdf`,
            1,
          );
          cpj.files.push(myFile);
        }
        cpj.sendToClient();
      }
    });
  } catch (err) {
    throw err;
  }
};
