import { useState, useEffect, useContext } from "react";
import { SearchIcon } from "@heroicons/react/outline";
import { useQuery } from "#hooks/useQuery";
import { GET_PRODUCTS } from "#queries";
import _ from "lodash";
import noSearchResultsIcon from "#static/images/noSearchResults.jpg";
import ListViewProducts from "./ListViewProducts";
import LoadingIndicator from "#components/utils/LoadingIndicator";

export const DEFAULT_SEARCH_FIELDS = [
  {
    name: "SKU",
    value: "sku",
    enabled: false,
  },
  {
    name: "Name",
    value: "name",
    enabled: false,
  },
  {
    name: "Description",
    value: "description",
    enabled: false,
  },
  {
    name: "UPC",
    value: "upc",
    enabled: false,
  },
];

const CatalogEnhancedSearch = (props) => {
  const productsQuery = useQuery(GET_PRODUCTS);
  const [keyword, setKeyword] = useState("");
  const [searchFields, setSearchFields] = useState(DEFAULT_SEARCH_FIELDS);
  const [searchResults, setSearchResults] = useState([]);
  const [parentProducts, setParentProducts] = useState([]);
  const [isProductSearch, setIsProductSearch] = useState(false);

  useEffect(() => {
    if (keyword && keyword.trim() !== "") {
      onChangeProductSearch(keyword);
    }
  }, [searchFields]);
  useEffect(() => {
    if (!props?.editingProduct && searchResults && keyword) {
      onChangeProductSearch(keyword);
    }
  }, [props?.editingProduct]);

  const onChangeProductSearch = async (searchKeyword) => {
    setIsProductSearch(true);
    const response = await productsQuery.fetchData({
      perPage: 25,
      pageNumber: 1,
      filters: {
        keyword: searchKeyword,
        searchFields: searchFields.filter((i) => i.enabled).map((i) => i.value),
      },
      sort: "-createdAt",
    });

    setIsProductSearch(false);

    if (response.data.products) {
      let products = response.data.products.entities.filter(
        (i) => !i.parentProduct,
      );
      const parentProducts = response.data.products.parentProducts || [];
      if (parentProducts.length > 0) {
        products = [
          ...parentProducts.map((i) => ({ ...i, isParentProduct: true })),
          ...products,
        ];
      }
      setParentProducts(parentProducts);
      setSearchResults(products);
    }

    if (response.error) {
      setSearchResults([]);
      setParentProducts([]);
    }
  };
  const debouncedProductSearch = _.debounce(onChangeProductSearch, 500);

  return (
    <div className="relative flex-1 flex-col">
      <div className="relative flex-1 rounded-md shadow-sm">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </div>
        <input
          type="search"
          name="search"
          id="search"
          className="block w-full rounded-md border-0 py-3 pl-10 text-lg text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
          placeholder="Search Products"
          onChange={(e) => {
            setKeyword(e.target.value);
            debouncedProductSearch(e.target.value);
          }}
          value={keyword}
        />
      </div>
      {keyword && keyword.trim() !== "" && (
        <div className="absolute z-20 mt-4 w-full border-2 border-[#DDDFE0] bg-white p-4">
          <div className="flex-col">
            <div className="flex items-center p-2">
              <h2 className="text-xl font-normal text-[#717679]">Criteria:</h2>
              <div className="flex flex-wrap">
                {searchFields.map((field) => (
                  <button
                    key={field.value}
                    className={`m-2 p-2 ${
                      field.enabled
                        ? "bg-primaryAccent text-white"
                        : "bg-[#F1F8FF] text-primaryAccent"
                    } rounded-md text-xl`}
                    onClick={() => {
                      if (searchFields.length === 1) {
                        return;
                      }
                      // DISABLE THE FIELD
                      const updatedFields = searchFields.map((f) => {
                        if (f.value === field.value) {
                          return {
                            ...f,
                            enabled: !field.enabled,
                          };
                        }
                        return f;
                      });
                      setSearchFields(updatedFields);
                    }}>
                    {field.name}
                  </button>
                ))}
              </div>
            </div>
            {isProductSearch && (
              <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
                <LoadingIndicator shouldShowOnPage={false} />
              </div>
            )}
            {(!searchResults || searchResults.length === 0) &&
            !isProductSearch ? (
              <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 text-center">
                <img src={noSearchResultsIcon} className="mx-auto w-48" />
                <div className="mt-4 text-2xl text-black">
                  No Results To Display
                </div>
              </div>
            ) : !isProductSearch ? (
              <ListViewProducts
                {...props}
                parentProducts={parentProducts}
                isSearchResults={true}
                products={searchResults}
                selectAllDisabled={true}
                showSalesAndCostPrice={true}
                customerList={props?.customerList || []}
              />
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

export default CatalogEnhancedSearch;
