import React, { useState } from "react";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";

const FbaShipmentDetails = ({ data, onChangeData }) => {
  const notify = CustomNotification();
  const [expandedShipmentId, setExpandedShipmentId] = useState(null);

  const hasValidLabels = (shipment) => {
    return (
      shipment.boxLabels?.length > 0 &&
      shipment.boxLabels[0]?.trim() !== "" &&
      shipment.shippingLabel?.trim() !== ""
    );
  };

  const downloadFiles = (boxLabels, labelType) => {
    boxLabels.forEach((url, index) => {
      fetch(url)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`Failed to fetch file at ${url}`);
          }
          return response.blob();
        })
        .then((blob) => {
          const link = document.createElement("a");
          const fileURL = URL.createObjectURL(blob);
          link.href = fileURL;
          const filename = `${labelType}_${index + 1}.pdf`;
          link.download = filename;
          link.click();
          URL.revokeObjectURL(fileURL);
        })
        .catch((error) => {
          notify.error(`Error downloading file: ${error.message}`);
        });
    });
  };

  return (
    <div className="p-6">
      {data.map((shipment) => (
        <div
          key={shipment.shipmentId}
          className="border-b pb-4 last:border-b-0">
          <div
            className="flex cursor-pointer items-center justify-between"
            onClick={() =>
              setExpandedShipmentId((prevId) =>
                prevId === shipment.shipmentId ? null : shipment.shipmentId,
              )
            }>
            <div className="flex items-center gap-4">
              <span className="text-gray-500">Shipment ID:</span>
              <span>{shipment.shipmentId}</span>
            </div>
            <div className="flex items-center gap-1 text-gray-500">
              <span>Status: {shipment.status}</span>
              {expandedShipmentId === shipment.shipmentId ? (
                <ChevronUpIcon className="h-5 w-5" />
              ) : (
                <ChevronDownIcon className="h-5 w-5" />
              )}
            </div>
          </div>
          {expandedShipmentId === shipment.shipmentId && (
            <div className="mt-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <span className="text-gray-500">Carrier:</span>
                  {shipment.isCarrierEditDisabled ? (
                    <span>{shipment.carrier}</span> // Show carrier as text if disabled
                  ) : (
                    <input
                      type="text"
                      className="w-full rounded border border-gray-300 p-2"
                      placeholder="Enter carrier name"
                      value={shipment.carrier}
                      onChange={(e) =>
                        onChangeData(
                          shipment.shipmentId,
                          null,
                          "carrier",
                          e.target.value,
                        )
                      }
                    />
                  )}
                </div>
                <div className="flex gap-4">
                  <PrimaryButton
                    height="3rem"
                    minWidth="13rem"
                    maxWidth="20rem"
                    className="text-base font-medium"
                    disabled={
                      !hasValidLabels(shipment) || !shipment.boxLabels?.length
                    }
                    onClick={() => {
                      downloadFiles(shipment.boxLabels, "BoxLabel");
                    }}>
                    Download Box Labels
                  </PrimaryButton>
                  <PrimaryButton
                    height="3rem"
                    minWidth="13rem"
                    maxWidth="20rem"
                    variant="primary"
                    className="text-base font-medium"
                    disabled={
                      !hasValidLabels(shipment) || !shipment.shippingLabel
                    }
                    onClick={() => {
                      downloadFiles([shipment.shippingLabel], "ShippingLabel");
                    }}>
                    Download Shipping Label
                  </PrimaryButton>
                </div>
              </div>
              <div className="mt-4">
                {shipment.boxes.map((box) => (
                  <div
                    key={box.boxId}
                    className="border-b pb-2 last:border-b-0">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center gap-4">
                        <span className="text-gray-500">Box ID:</span>
                        <span>{box.boxId}</span>
                        <span>
                          (
                          {box?.templateName?.replace(/P|B/g, (match) =>
                            match === "P" ? "Group-" : "Box-",
                          )}
                          )
                        </span>
                      </div>
                      <div className="flex items-center gap-4">
                        <span className="text-gray-500">Tracking Number:</span>
                        {shipment.trackingNumbers.find(
                          (t) => t.boxId === box.boxId,
                        )?.isTrackingEditDisabled ? (
                          <span>
                            {shipment.trackingNumbers.find(
                              (t) => t.boxId === box.boxId,
                            )?.trackingId || ""}
                          </span> // Show tracking number as text if disabled
                        ) : (
                          <input
                            type="text"
                            className="w-full rounded border border-gray-300 p-2"
                            placeholder="Enter tracking number"
                            value={
                              shipment.trackingNumbers.find(
                                (t) => t.boxId === box.boxId,
                              )?.trackingId || ""
                            }
                            onChange={(e) =>
                              onChangeData(
                                shipment.shipmentId,
                                box.boxId,
                                "trackingId",
                                e.target.value,
                              )
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FbaShipmentDetails;
