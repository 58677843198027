import _ from "lodash";
import { useEffect, useState, useContext } from "react";
import { useQuery } from "#hooks/useQuery";
import { GET_INVENTORY, GET_SKU } from "#queries";
import { SAVE_BUNDLE_AS_PRODUCT } from "#mutations";
import ModalV2 from "#components/utils/ModalV2";
import { FALLBACK_IMAGE_URL } from "#constants/fallback-image-urls";
import { buildMarketplaceHyperlink } from "#utils/buildMarketplaceHyperlink";
import { AppStateContext } from "#contexts/appState";
import ReactTooltip from "react-tooltip";
import {
  DotsVerticalIcon,
  TrashIcon,
  InformationCircleIcon,
} from "@heroicons/react/outline";
import { Menu } from "@headlessui/react";
import UOMConfigurationV2 from "#components/products/UOMConfigurationV2";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";

import ProductSearch from "./ProductSearch";
import ProductInfo from "./ProductInfo";
import { useFeatureFlags } from "#contexts/featureFlags";

const ALERT_TIMEOUT_IN_MS = 5000;

const bundleDefinitionTypes = Object.freeze({
  BUNDLE: "BUNDLE",
  MULTIPACK: "MULTIPACK",
});

const BundleForm = ({
  selectedProduct,
  isSelectedProductBundle,
  isPrepCenter,
  negativeAction,
  setShowImage,
  multiAccountSupportEnabled,
  selectedCustomer,
  selectedWarehouse,
}) => {
  const { isBundlingWorkflowEnabled } = useFeatureFlags();

  const appState = useContext(AppStateContext);

  const getBundleAsProduct = useQuery(GET_SKU);
  const getBundleProducts = useQuery(GET_INVENTORY);
  const saveBundleAsProduct = useQuery(SAVE_BUNDLE_AS_PRODUCT);

  const [loader, setLoader] = useState(false);
  const [productInformation, setProductInformation] = useState({
    ...selectedProduct,
  });
  const [bundleInformation, setBundleInformation] = useState(null);
  const [bundleDefinitionType, setBundleDefinitionType] = useState(
    bundleDefinitionTypes.BUNDLE,
  );
  const [editingBundle, setEditingBundle] = useState(false);
  const [selectProductForUOMView, setSelectedProductForUOMView] =
    useState(null);

  // If the selected product is a bundle, fetch the constituent products that this bundle holds.
  useEffect(() => {
    // if (isSelectedProductBundle) {
    fetchBundleInformation();
    // }
  }, []);

  // Fetches the bundle information based on the selected product uniqueIdenifier (ASIN for Amazon and SKU for others)
  // Also populates the constituent products (itemDetails).
  const fetchBundleInformation = async () => {
    setLoader(true);
    // Fetch the bundle Information
    const bundleAsProductResponse = await getBundleAsProduct.fetchData({
      id: selectedProduct.id,
    });
    if (bundleAsProductResponse.error) {
      appState.setAlert(
        bundleAsProductResponse.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      return;
    }
    const bundle = bundleAsProductResponse.data.specificInventory;
    if (!bundle || !bundle.products?.length) {
      setLoader(false);
      return;
    }
    // Fetch the constituent products information
    const uniqueProductIds = _.uniqBy(
      bundle.products.map((product) => product.id),
    );
    const bundleProductsResponse = await getBundleProducts.fetchData({
      filters: {
        id: uniqueProductIds,
        showInactive: true,
      },
    });
    if (bundleProductsResponse.error) {
      appState.setAlert(
        bundleProductsResponse.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      return;
    }
    const bundleProducts = await bundleProductsResponse.data.inventory.entities;
    if (bundle.products.length === 1) {
      // Case for Multipack.
      setBundleDefinitionType(bundleDefinitionTypes.MULTIPACK);
    }
    const productMap = _.keyBy(bundleProducts, "id");
    setBundleInformation({
      ...bundle,
      products: bundle.products?.map((product) => {
        if (productMap[product.id]) {
          return {
            ...product,
            itemDetails: productMap[product.id],
          };
        }
        return product;
      }),
    });
    setProductInformation({ ...productInformation });
    setLoader(false);
  };

  // Refresh the bundle items by fetching the updated data from the backend.
  const refreshBundleItems = async () => {
    const uniqueProductIds = _.uniqBy(
      bundleInformation.products.map((product) => product.id),
    );
    const bundleProductsResponse = await getBundleProducts.fetchData({
      filters: {
        id: uniqueProductIds,
        showInactive: true,
      },
    });
    if (bundleProductsResponse.error) {
      appState.setAlert(
        bundleProductsResponse.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      return;
    }
    const bundleProducts = await bundleProductsResponse.data.inventory.entities;
    if (bundleInformation.products.length === 1) {
      // Case for Multipack.
      setBundleDefinitionType(bundleDefinitionTypes.MULTIPACK);
    }
    const productMap = _.keyBy(bundleProducts, "id");
    setBundleInformation({
      ...bundleInformation,
      products: bundleInformation.products?.map((product) => {
        if (productMap[product.id]) {
          return {
            ...product,
            itemDetails: productMap[product.id],
          };
        }
        return product;
      }),
    });
  };

  // Defines the selected product as bundle. Used when we couldn't find an existing bundle for the selected product with the uniqueIdentifier.
  const defineBundle = () => {
    setProductInformation({ ...productInformation });
    // Bundle information to be saved using SAVE_BUNDLE query.
    // Definining with new style bundle
    setBundleInformation({
      ...productInformation,
      products: [],
    });
    setEditingBundle(true);
  };

  const submitBundleChanges = async () => {
    setLoader(true);
    // Bundle needs to contain atleast one product.
    if (
      !bundleInformation.products ||
      bundleInformation.products.length === 0
    ) {
      return appState.setAlert(
        `At least 1 product is needed to save a bundle.`,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
    // Check if all the constituent product quantities are valid
    for (let i = 0; i < bundleInformation.products.length; i++) {
      const product = bundleInformation.products[i];
      if (product.quantity < 0) {
        return appState.setAlert(
          `Please provide valid Quantity. Check Product ${i + 1}.`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
      }
    }

    const products = [];

    // Saving bundle
    bundleInformation.products.forEach((p) => {
      products.push({
        id: p.id,
        uom: p.uom || "Each",
        quantity: parseInt(p.quantity),
      });
    });
    const saveBundleAsProductResponse = await saveBundleAsProduct.fetchData({
      ...bundleInformation,
      products,
    });
    if (saveBundleAsProductResponse.error) {
      setLoader(false);
      return appState.setAlert(
        saveBundleAsProductResponse.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
    appState.setAlert(
      saveBundleAsProductResponse.data.saveBundleAsProduct.message,
    );

    setLoader(false);
    return negativeAction({ operationSuccess: true }); // close the bundle form.
  };

  const upsertBundleItem = (addedProduct) => {
    let currentBundleInformation = { ...bundleInformation };
    if (
      !currentBundleInformation.products ||
      currentBundleInformation.products.length === 0
    ) {
      currentBundleInformation.products = [];
    }

    if (addedProduct.idx >= 0) {
      currentBundleInformation.products[addedProduct.idx] = {
        ...addedProduct,
        idx: null,
      };
    } else {
      let foundProduct = null;
      if (["FBA", "FBM"].includes(addedProduct.source)) {
        foundProduct =
          currentBundleInformation.products.findIndex(
            (product) =>
              product.itemDetails?.asin === addedProduct.itemDetails?.asin,
          ) !== -1;
      } else {
        foundProduct =
          currentBundleInformation.products.findIndex(
            (product) =>
              product.itemDetails?.sku === addedProduct.itemDetails?.sku,
          ) !== -1;
      }
      if (foundProduct) {
        return appState.setAlert(`The product already exists in the bundle.`);
      }
      currentBundleInformation.products.push({
        ...addedProduct,
        quantity: 1,
      });
    }
    setBundleInformation(currentBundleInformation);
  };

  const removeBundleItem = (idx) => {
    let currentBundleInformation = { ...bundleInformation };
    if (
      !currentBundleInformation.products ||
      currentBundleInformation.products.length === 0
    ) {
      return;
    }
    appState.showConfirmation(
      `Remove item from bundle`,
      `Are you sure you want to remove this item from the bundle?`,
      () => {
        currentBundleInformation.products =
          currentBundleInformation.products.filter(
            (item, index) => index !== idx,
          );
        setBundleInformation(currentBundleInformation);
        appState.hideConfirmation();
      },
      appState.hideConfirmation,
      "Confirm",
      "Cancel",
    );
  };

  const changeBundleDefinitionType = (selectedOption) => {
    if (selectedOption === bundleDefinitionType) {
      return;
    }

    if (bundleInformation.products.length === 0) {
      setBundleDefinitionType(selectedOption);
      return;
    }

    appState.showConfirmation(
      `Changing pack type will reset the existing selected products`,
      `Are you sure you want to change the pack type?`,
      () => {
        setBundleDefinitionType(selectedOption);
        setBundleInformation({ ...bundleInformation, products: [] });
        appState.hideConfirmation();
      },
      appState.hideConfirmation,
      "Confirm",
      "Cancel",
    );
  };

  // No Bundle Defined Modal
  if (!bundleInformation) {
    return (
      <ModalV2
        title={`Bundle Info`}
        noPadding={true}
        onClose={negativeAction}
        xIconClicked={negativeAction}
        minWidth={"1062px"}
        minHeight={"648px"}
        height={"648px"}>
        {loader ? (
          <div className="flex h-full w-full flex-col items-center justify-center rounded border bg-white">
            <div className="h-10 w-10 animate-spin rounded-full border-t-4 border-solid border-hyperlinkColor"></div>
          </div>
        ) : (
          <div className="flex h-full flex-col items-center justify-center">
            <img
              style={{ width: "154px", height: "144px" }}
              alt={""}
              src="https://hopstack-pub.s3.amazonaws.com/icons/Searching.png"
            />
            <p className="mt-2 text-base font-semibold">No Information Found</p>
            <p className="my-2 w-537 text-center text-xs font-normal">
              Add constituent products and other info to mark the product as a
              Bundle/Multipack/Kit
            </p>
            <button
              className="h-40 w-143 rounded bg-hyperlinkColor text-white"
              onClick={defineBundle}>
              Define Bundle
            </button>
          </div>
        )}
      </ModalV2>
    );
  }

  // Bundle Preview Modal
  if (!editingBundle) {
    return (
      <>
        <ModalV2
          title={`Bundle Info`}
          noPadding={true}
          onClose={negativeAction}
          xIconClicked={negativeAction}
          minWidth={"1062px"}
          height={"648px"}>
          <div className="flex h-full flex-col px-6 pb-4">
            {/* Bundle Meta Info */}
            <div className="mb-3 flex">
              <img
                src={
                  productInformation?.images?.[0]?.display_url
                    ? productInformation.images[0].display_url
                    : FALLBACK_IMAGE_URL
                }
                style={{ width: "72px", height: "72px" }}
                alt={""}
                className="rounded object-cover"
                onClick={() =>
                  setShowImage(
                    productInformation?.images?.[0]?.display_url ??
                      FALLBACK_IMAGE_URL,
                  )
                }
              />
              <div className="ml-4 flex-1">
                <p
                  className="w-800 truncate text-lg font-semibold"
                  data-tip
                  data-for={`${productInformation.name}`}>
                  {productInformation.name}
                </p>
                <ReactTooltip id={`${productInformation.name}`}>
                  {productInformation.name}
                </ReactTooltip>
                <p className="text-sm font-normal text-lightGray">
                  SKU: {productInformation.sku}
                </p>
                {isPrepCenter && productInformation.asin && (
                  <p className="text-sm font-normal text-lightGray">
                    ASIN:{" "}
                    {buildMarketplaceHyperlink(
                      productInformation.asin,
                      "ASIN",
                      productInformation.source,
                    )}
                  </p>
                )}
              </div>
            </div>
            {/* Bundle Products */}
            <div className="min-h-329 flex w-full flex-col overflow-hidden rounded-xl border p-3">
              <div className="mb-2 flex justify-between p-1">
                <div>
                  <p className="text-base font-semibold">Bundle Info</p>
                  <p className="text-sm font-normal">{`${bundleInformation.products.length} Products`}</p>
                </div>
                <button
                  className="my-auto rounded bg-hyperlinkColor text-white"
                  style={{ width: "75px", height: "32px" }}
                  onClick={() => setEditingBundle(true)}>
                  Edit
                </button>
              </div>
              <div className="min-h-329 overflow-y-scroll">
                {bundleInformation.products.map((item, index) => (
                  <div className="mb-3 flex w-full" key={index}>
                    <img
                      src={
                        item?.itemDetails?.images?.[0]?.display_url
                          ? item.itemDetails.images[0].display_url
                          : FALLBACK_IMAGE_URL
                      }
                      style={{ width: "72px", height: "72px" }}
                      alt={""}
                      className="rounded object-cover"
                      onClick={() =>
                        setShowImage(
                          item?.itemDetails?.images?.[0]?.display_url ??
                            FALLBACK_IMAGE_URL,
                        )
                      }
                    />
                    <div className="ml-4 flex-1">
                      <p className="text-lg font-semibold">
                        {item.itemDetails?.name}
                      </p>
                      <p className="text-sm font-normal text-lightGray">
                        SKU: {item.itemDetails?.sku}
                      </p>
                      {isPrepCenter && item.asin && (
                        <p className="text-sm font-normal text-lightGray">
                          ASIN:{" "}
                          {buildMarketplaceHyperlink(
                            item.itemDetails?.asin,
                            "ASIN",
                            item.itemDetails?.source,
                          )}
                        </p>
                      )}
                      <p className="text-sm font-normal text-lightGray">
                        Quantity: {item.quantity}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </ModalV2>
      </>
    );
  }

  // Bundle Form Modal
  return (
    <ModalV2
      title={`Bundle Info`}
      noPadding={true}
      onClose={negativeAction}
      xIconClicked={negativeAction}
      minWidth={"1062px"}
      minHeight={"648px"}
      positiveAction={submitBundleChanges}
      negativeAction={negativeAction}>
      <div className="overflow-auto px-6">
        {/* Basic Info */}
        <div>
          <h2 className="text-xl font-semibold">Basic Info</h2>
          <div className="h-240 mt-2 flex w-1014 flex-col space-y-4 rounded-md border border-borderGray p-4">
            <div>
              <p className="text-sm font-medium">Select Type</p>
              <div className="mt-1 flex w-273 space-x-6">
                <div>
                  <input
                    type="radio"
                    id="bundlePack"
                    name="bundleDefinitionType"
                    value={bundleDefinitionTypes.BUNDLE}
                    checked={
                      bundleDefinitionType === bundleDefinitionTypes.BUNDLE
                    }
                    className="h-17"
                    onChange={(e) => changeBundleDefinitionType(e.target.value)}
                  />
                  <label htmlFor="bundlePack" className="ml-2">
                    Bundle Pack
                  </label>
                </div>
                <div>
                  <input
                    type="radio"
                    id="multipack"
                    name="bundleDefinitionType"
                    value={bundleDefinitionTypes.MULTIPACK}
                    checked={
                      bundleDefinitionType === bundleDefinitionTypes.MULTIPACK
                    }
                    className="h-17"
                    onChange={(e) => changeBundleDefinitionType(e.target.value)}
                  />
                  <label htmlFor="multipack" className="ml-2">
                    Multi Pack
                  </label>
                </div>
              </div>
            </div>
            <div className="space-y-2">
              <div className="h-69 w-939">
                <p className="mb-1 w-full text-sm font-medium">Name</p>
                <input
                  type="text"
                  className="placeholder:normal w-full cursor-not-allowed rounded-md border border-borderGray"
                  placeholder={`Enter ${
                    bundleInformation?.type === "BUNDLE"
                      ? "Bundle"
                      : "Multi Pack"
                  } Name`}
                  value={
                    productInformation.name ||
                    productInformation.asin ||
                    productInformation.sku
                  }
                  disabled={true}
                />
              </div>
              <div className="flex h-69 w-939 justify-start space-x-3">
                {productInformation.upc && (
                  <div className="flex h-48 w-297 flex-col">
                    <p className="mb-1 text-sm font-medium">UPC</p>
                    <input
                      type="text"
                      className="w-full cursor-not-allowed rounded-md border border-borderGray"
                      placeholder={`Enter UPC`}
                      value={productInformation.upc}
                      disabled={true}
                    />
                  </div>
                )}
                {productInformation.asin && (
                  <div className="flex h-48 w-297 flex-col">
                    <p className="mb-1 text-sm font-medium">ASIN</p>
                    <input
                      type="text"
                      className="w-full cursor-not-allowed rounded-md border border-borderGray"
                      value={productInformation.asin}
                      disabled={true}
                    />
                  </div>
                )}
                {productInformation.sku && (
                  <div className="flex h-48 w-297 flex-col">
                    <p className="mb-1 text-sm font-medium">SKU</p>
                    <input
                      type="text"
                      className="w-full cursor-not-allowed rounded-md border border-borderGray"
                      placeholder={`Enter SKU`}
                      value={productInformation.sku}
                      disabled={true}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* Products Info for Bundle */}
        <BundleProductsInfo
          selectedProduct={selectedProduct}
          bundleInformation={bundleInformation}
          bundleDefinitionType={bundleDefinitionType}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          isPrepCenter={isPrepCenter}
          setShowImage={setShowImage}
          selectedCustomer={selectedCustomer}
          selectedWarehouse={selectedWarehouse}
          upsertBundleItem={upsertBundleItem}
          removeBundleItem={removeBundleItem}
          key={bundleDefinitionType}
          setSelectedProductForUOMView={setSelectedProductForUOMView}
        />
      </div>

      {selectProductForUOMView && (
        <UOMConfigurationV2
          product={selectProductForUOMView}
          onUpdate={() => {
            refreshBundleItems();
            setSelectedProductForUOMView(null);
          }}
          onClose={() => setSelectedProductForUOMView(null)}
        />
      )}
    </ModalV2>
  );
};

const BundleProductsInfo = ({
  selectedProduct,
  bundleInformation,
  bundleDefinitionType,
  multiAccountSupportEnabled,
  isPrepCenter,
  setShowImage,
  selectedCustomer,
  selectedWarehouse,
  upsertBundleItem,
  removeBundleItem,
  setSelectedProductForUOMView,
}) => {
  const showHeaders = [
    { name: "Product Info", correspondingValue: "productInfo" },
    { name: "Channel", correspondingValue: "source" },
    {
      name: (
        <>
          Unit Type<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "formFactor",
    },
    {
      name: (
        <>
          Quantity<span className="text-trashIconRed">*</span>
        </>
      ),
      correspondingValue: "quantity",
    },
    {
      name: "Action",
      correspondingValue: "action",
    },
  ];

  const [showProductAddition, setShowProductAddition] = useState(true);
  const [showProductSearch, setShowProductSearch] = useState(
    bundleInformation.products?.length === 0,
  );

  useEffect(() => {
    if (bundleDefinitionType === bundleDefinitionTypes.MULTIPACK) {
      if (bundleInformation.products.length > 0) {
        setShowProductAddition(false);
        setShowProductSearch(false);
      } else {
        setShowProductAddition(true);
      }
    }
  }, [bundleInformation.products]);

  let restrictedChannelsForProductSearch = [
    {
      source: "Hopstack",
    },
  ];
  if (selectedProduct.source !== "Hopstack") {
    restrictedChannelsForProductSearch.push({
      source: selectedProduct.source,
      marketplace:
        selectedProduct.marketplaceAttributes?.attributes
          ?.marketplaceCountryCode ?? null,
      sellerId:
        selectedProduct.marketplaceAttributes?.attributes?.sellerId ?? null,
    });
  }

  return (
    <>
      {/* Product Info */}
      <div className="my-4">
        <div className="mb-2 flex items-center justify-between">
          <h1 className="text-xl font-semibold">
            {`${
              bundleDefinitionType === bundleDefinitionTypes.Bundle
                ? "Product"
                : "Pack"
            }`}{" "}
            Info
          </h1>
          {showProductAddition && (
            <button
              onClick={() => setShowProductSearch(true)}
              className="text-hyperlinkColor underline">
              + Add More
            </button>
          )}
        </div>
        {showProductAddition && (
          <p className="w-full text-sm font-normal">
            Easily find and add products from the Hopstack inventory. If you
            have a specific marketplace in mind, please select it for targeted
            results
          </p>
        )}
        {bundleDefinitionType === bundleDefinitionTypes.BUNDLE && (
          <p className="my-4 text-sm font-medium text-lightGray">
            Products Added: {bundleInformation.products?.length ?? 0}
          </p>
        )}
      </div>
      {/* Product Search */}
      {showProductSearch && (
        <div className="">
          <ProductSearch
            addProduct={(item) => {
              setShowProductSearch(false);
              upsertBundleItem({
                id: item.id,
                quantity: 1,
                itemDetails: item,
              });
            }}
            multiAccountSupportEnabled={multiAccountSupportEnabled}
            isPrepCenter={isPrepCenter}
            setShowImage={setShowImage}
            selectedCustomer={selectedCustomer}
            selectedWarehouse={selectedWarehouse}
            restrictedChannels={restrictedChannelsForProductSearch}
          />
        </div>
      )}
      {/* Existing Bundle Products */}
      {bundleInformation.products?.length > 0 && (
        <div className="h-full rounded-md border border-borderGray">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="sticky top-0 z-10 rounded-full bg-bgGray">
              <tr className="border-left font-montserrat text-lightGray">
                {showHeaders.map((header, idx) => (
                  <th
                    scope="col"
                    className="p-3 text-left font-medium tracking-wider"
                    key={idx}>
                    {header.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {bundleInformation.products.map((item, index) => (
                <BundleProductRow
                  key={`${item.id}+${index}`}
                  item={item}
                  itemDetails={item.itemDetails}
                  itemIdx={index}
                  setShowImage={setShowImage}
                  isPrepCenter={isPrepCenter}
                  upsertBundleItem={upsertBundleItem}
                  removeBundleItem={removeBundleItem}
                  showHeaders={showHeaders}
                  setSelectedProductForUOMView={setSelectedProductForUOMView}
                />
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

const BundleProductRow = ({
  item,
  itemDetails,
  itemIdx,
  setShowImage,
  isPrepCenter,
  upsertBundleItem,
  removeBundleItem,
  showHeaders,
  setSelectedProductForUOMView,
}) => {
  const menuItems = (item) => {
    const arr = [
      {
        title: "Remove",
        icon: TrashIcon,
        onClick: (index) => removeBundleItem(index),
        vars: "index",
      },
    ];

    return arr;
  };

  return (
    <tr key={`${item.id}+${itemIdx}`} className="bg-bgWhite">
      {showHeaders.map((header, headerIdx) => {
        const value = header.correspondingValue;
        if (value === "productInfo") {
          return (
            <td className="w-289 p-3 pr-10">
              <ProductInfo
                item={item}
                itemDetails={itemDetails}
                itemIdx={itemIdx}
                setShowImage={setShowImage}
                isPrepCenter={isPrepCenter}
              />
            </td>
          );
        }
        if (value === "source") {
          return <td className="pl-3">{item.itemDetails?.source}</td>;
        }
        if (value === "formFactor") {
          const baseUom = itemDetails.baseUom || "Each";
          const formFactorOptions = [{ name: baseUom, value: baseUom }];
          itemDetails.uomConfiguration?.forEach((uomConfig) => {
            formFactorOptions.push({
              name: uomConfig.targetUom,
              value: uomConfig.targetUom,
            });
          });

          return (
            <td className="pl-3">
              <div className="flex h-40 w-159 flex-col items-start">
                <AutocompleteDropdownV2
                  options={formFactorOptions}
                  labelKey="name"
                  valueKey="value"
                  onChange={(uom) =>
                    upsertBundleItem({
                      ...item,
                      idx: itemIdx,
                      uom: uom,
                    })
                  }
                  value={item.uom ?? "Each"}
                  placeholder=""
                  searchable={false}
                />
                <p
                  className="mt-1 cursor-pointer text-sm font-semibold text-primaryAccent underline"
                  onClick={() => setSelectedProductForUOMView(itemDetails)}>
                  Form Factor
                </p>
              </div>
            </td>
          );
        }
        if (value === "quantity") {
          return (
            <td className="pl-3">
              <div className="h-40 w-103">
                <input
                  type="number"
                  id={`item_quantity_${itemIdx}`}
                  placeholder=" "
                  onChange={(e) => {
                    upsertBundleItem({
                      ...item,
                      idx: itemIdx,
                      quantity: e.target.value,
                    });
                  }}
                  min={"0"}
                  value={item.quantity}
                  name="itemQuantity"
                  className="h-full w-full rounded border border-borderGray"
                />
              </div>
            </td>
          );
        }
        if (value === "action") {
          return (
            <td className="pl-3">
              <Menu as="div" className="text-left">
                <div>
                  <Menu.Button>
                    <DotsVerticalIcon className="h-10 w-10 rounded p-2 text-base" />
                  </Menu.Button>
                </div>
                <Menu.Items className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-primaryAccent shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                  <div className="">
                    {menuItems(item).map((menuItem, idx) => (
                      <Menu.Item key={idx}>
                        {({ active }) => (
                          <button
                            className={`relative flex w-full cursor-pointer select-none items-center border-b border-50BFC3 py-4 pl-4 pr-4 text-white hover:bg-EBEBEB hover:text-2C7695`}
                            onClick={() => {
                              menuItem["onClick"](
                                menuItem["vars"] === "index"
                                  ? itemIdx
                                  : item[menuItem["vars"]],
                              );
                            }}>
                            {menuItem.icon && (
                              <menuItem.icon className="h-8 w-8 pr-2" />
                            )}
                            {menuItem.title}
                          </button>
                        )}
                      </Menu.Item>
                    ))}
                  </div>
                </Menu.Items>
              </Menu>
            </td>
          );
        }
      })}
    </tr>
  );
};

export default BundleForm;
