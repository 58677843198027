import { useState, useMemo, useContext, useEffect } from "react";
import SlideOverModal from "#components/common/SlideOverModal";
import BatchConfirmation from "#components/orders/batching/BatchConfirmation";
import PreviewFulfillmentDetails from "#components/orders/batching/PreviewFulfillmentDetails";
import { AppStateContext } from "#contexts/appState";
import BatchPrioritisation from "#components/orders/batching/BatchPrioritisation";
import { EntityContext } from "#contexts/entity";
import _ from "lodash";
import SlideOverPanel from "#components/common/SlideOverPanel";
import BatchPrioritisationWithDetails from "./BatchPrioritisationWithDetails";
import PackPlan from "./packPlan/PackPlan";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { useQuery } from "#hooks/useQuery";
import { GET_BOX_TYPES } from "#queries/index";
import CustomNotification from "#newUiComponents/commons/CustomNotification";
import { CREATE_OUTBOUND_PLAN } from "#mutations/index";
import AskMorpheus from "./packPlan/AskMorpheus";
const ALERT_TIMEOUT_MS = 5000;
const NUMBER_OF_BOXES_TO_FETCH = 100;

const ManualBatchWithPlanning = ({
  getOrdersForPackPlan,
  negativeAction,
  manualBatchConfig,
  setManualBatchConfig,
  ordersFulfillmentDetails,
  customers,
  warehouses,
  pickers,
  stations,
  orders,
  selectedOrderIds = [],
  setSelectedOrderIds,
  tenant,
  batchSettings,
  isBackOrderEnabledForTenant,
  showBatchingPreview,
  isManualBatchExecuting,
  showBatchingPrioritisation,
  setShowBatchingPrioritisation,
  orderQuantityDetails,
  setOrderQuantityDetails,
  refetchOrderDetails,
  setRefetchOrderDetails,
  updatedOrderBatchingDetails,
  setUpdatedOrderBatchingDetails,
  createManualBatch,
  expandOrder,
  searchResults,
  enhancedSearchText,
  showEnhancedSearch,
  morpheusData,
  setMorpheusData,
  setSelectedOrders,
  selectedEnhancedOrders,
  setBatchingAttemptIds,
}) => {
  const appState = useContext(AppStateContext);
  const entity = useContext(EntityContext);
  const notify = CustomNotification();
  const boxTypesQuery = useQuery(GET_BOX_TYPES);
  const createOutboundPlanQuery = useQuery(CREATE_OUTBOUND_PLAN);
  const tenantSettings = appState?.tenant?.settings;

  const [loading, setLoading] = useState(false);
  const [orderDetailsProcessing, setOrderDetailsProcessing] = useState(false);
  const [allOrdersAreUnfulfillable, setAllOrdersAreUnfulfillable] =
    useState(false);
  const [detailedOrdersForBatching, setDetailedOrdersForBatching] = useState(
    [],
  );
  const [boxTypes, setBoxTypes] = useState([]);
  const [pickPlanDetails, setPickPlanDetails] = useState({});
  const [orderBoxes, setOrderBoxes] = useState({});
  const [morpheusScreen, setMorpheusScreen] = useState(true);
  const [selectedGroups, setSelectedGroups] = useState([]);

  // By default the fulfillable orders will be selected for batching.
  // We give option to user to select the partially fulfillable orders. hence we maintain this state and send it to createManualBatchFinal method
  const [selectedOrderIdsForBatching, setSelectedOrderIdsForBatching] =
    useState([]);

  // Orders that can be completely fulfilled
  const [fulfillableOrders, setFulfillableOrders] = useState([]);
  // Orders that are eligible for back orders
  const [partiallyFulfillableOrders, setPartiallyFulfillableOrders] = useState(
    [],
  );
  // Orders that went into exceptions
  const [exceptionOrders, setExceptionOrders] = useState([]);

  const [selectedStep, setSelectedStep] = useState(
    morpheusData?.length
      ? "00"
      : showBatchingPrioritisation
        ? "01"
        : showBatchingPreview
          ? "02"
          : "03",
  );

  /**
   * Orders that might not be eligible for back order due to client, integration level setup,
   * Orders where all items have no inventory,
   * Orders which is having any exception like sku missing.
   * Orders which can only be fulfilled by alternate warehouse.
   */
  const [unfulfillableOrders, setUnfulfillableOrders] = useState([]);
  useMemo(() => {
    if (!showBatchingPreview) {
      setSelectedOrderIdsForBatching(selectedOrderIds);
    } else {
      if (!ordersFulfillmentDetails) {
        setLoading(true);
      } else {
        setFulfillableOrders(ordersFulfillmentDetails.fulfillableOrders);
        setUnfulfillableOrders(ordersFulfillmentDetails.unfulfillableOrders);
        setPartiallyFulfillableOrders(ordersFulfillmentDetails.backOrders);
        setExceptionOrders(ordersFulfillmentDetails.exceptionOrders);
        setSelectedOrderIdsForBatching(
          ordersFulfillmentDetails.fulfillableOrders.map(
            ({ order }) => order.id,
          ),
        );
      }
      setLoading(false);
    }
  }, [ordersFulfillmentDetails]);

  useEffect(() => {
    if (morpheusScreen && morpheusData && morpheusData.length > 0) {
      setSelectedStep("00");
    } else if (
      selectedOrderIds?.length > 1 &&
      !tenantSettings?.skipBatchingPreview
    ) {
      setShowBatchingPrioritisation(true);
      setSelectedStep("01");
      // Fetch fulfillment details immediately
      createManualBatch({
        entity,
        selectedOrders: selectedOrderIds,
        fulfillmentCheck: true,
      });
    } else {
      createManualBatch({
        entity,
        selectedOrders: selectedOrderIds,
        fulfillmentCheck: true,
      });
      setShowBatchingPrioritisation(false);
      setSelectedStep(tenantSettings?.skipBatchingPreview ? "03" : "01");
    }
  }, [selectedOrderIds, morpheusScreen]);

  useEffect(() => {
    (async () => {
      const boxTypesResponse = await boxTypesQuery.fetchData({
        perPage: NUMBER_OF_BOXES_TO_FETCH,
        filters: {},
        paginated: false,
        pageNumber: 1,
        // sort: entity.sort,
      });
      if (
        boxTypesResponse.data &&
        boxTypesResponse.data?.boxTypes?.entities?.length
      ) {
        setBoxTypes([
          ...boxTypesResponse.data?.boxTypes?.entities
            ?.filter((box) => box.width && box.length && box.height)
            ?.map((item) => ({
              name: item.name,
              height: item.height,
              width: item.width,
              length: item.length,
              weight: item.weight || 0,
            })),
          { name: "Add Custom", isCustom: true },
        ]);
      }
    })();
  }, []);

  useEffect(() => {
    if (
      batchSettings &&
      Object.keys(batchSettings).length &&
      manualBatchConfig
    ) {
      setPickPlanDetails(() => {
        return {
          pickingPlan: {
            ...(manualBatchConfig?.maxOrdersInBatch && {
              batchSize: manualBatchConfig?.maxOrdersInBatch,
            }),
            picker: manualBatchConfig.picker,
          },
        };
      });
    }
  }, [batchSettings, manualBatchConfig]);

  const validateOrderBoxes = (orderId) => {
    // Get the order details
    const order = detailedOrdersForBatching.find((o) => o.orderId === orderId);
    if (!order) return { isValid: false, error: "Order not found" };

    if (orderBoxes && orderBoxes?.length) {
      // Get boxes for this order
      const orderBoxes = orderBoxes[orderId] || [];
      // if (orderBoxes.length === 0) {
      //   return { isValid: false, error: "No boxes added to the order" };
      // }

      // Check if any box is empty
      const hasEmptyBox = orderBoxes.some(
        (box) => !box.items || box.items.length === 0,
      );
      if (hasEmptyBox) {
        return {
          isValid: false,
          error: "Some boxes are empty. All boxes must contain items.",
        };
      }

      // Calculate total packed quantities for each SKU
      const packedQuantities = {};
      orderBoxes.forEach((box) => {
        box.items.forEach((item) => {
          packedQuantities[item.sku] =
            (packedQuantities[item.sku] || 0) + item.packQuantity;
        });
      });

      // Compare with required quantities
      const unpackedItems = order.orderDetails.filter((item) => {
        const packedQty = packedQuantities[item.sku] || 0;
        return packedQty < item.quantity;
      });

      if (unpackedItems.length > 0) {
        const itemsList = unpackedItems
          .map(
            (item) =>
              `${item.name} (${packedQuantities[item.sku] || 0}/${item.quantity})`,
          )
          .join(", ");
        return {
          isValid: false,
          error: `Not all items are fully packed. Remaining items: ${itemsList}`,
        };
      }
    }

    return { isValid: true };
  };

  const handleSaveOutboundPlan = (status, callback) => {
    const validationErrors = [];

    for (const order of detailedOrdersForBatching) {
      const validation = validateOrderBoxes(order.orderId);
      if (!validation.isValid) {
        validationErrors.push(`Order #${order.orderId}: ${validation.error}`);
      }
    }

    if (validationErrors.length > 0) {
      notify.error(
        "Validation Failed",
        <div>
          <p>Cannot save outbound plan due to the following issues:</p>
          <ul className="mt-2 list-disc pl-4">
            {validationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>,
      );
      return;
    }

    // If validation passes, proceed with the confirmation dialog
    appState.showNewConfirmation(
      callback ? "Save outbound plan & Create Batch" : "Save Outbound Plan",
      <div>
        <p>
          Are you sure you want to{" "}
          {status === "DRAFT" ? "save as draft" : "confirm"} this outbound plan{" "}
          {callback ? " & create Batch" : ""}?
        </p>
      </div>,
      async () => {
        const packingPlan = {
          packingStation: manualBatchConfig.packingStation,
          boxAssignments: detailedOrdersForBatching.map((order) => ({
            orderId: order.orderId,
            boxes: (orderBoxes[order.orderId] || []).map((box) => ({
              boxName: box.name,
              items: box.items.map((item) => ({
                sku: item.sku,
                quantity: item.packQuantity,
              })),
              boxDimension: {
                length: box.length,
                width: box.width,
                height: box.height,
                unit: "in",
              },
              totalWeight: parseFloat(
                (
                  box.weight +
                  box.items.reduce(
                    (sum, item) => sum + (item.weight || 0) * item.packQuantity,
                    0,
                  )
                ).toFixed(2),
              ),
              weightUnit: "lbs",
            })),
          })),
        };

        try {
          appState.setLoading();
          const createOutboundPlanPayload = {
            outboundPlanInput: {
              warehouse: detailedOrdersForBatching[0].warehouse,
              customer: detailedOrdersForBatching[0].customer,
              orders: detailedOrdersForBatching.map((order) => order.id),
              status: "DRAFT",
              pickingPlan: {
                ...pickPlanDetails.pickingPlan,
                triggerAutoBatching: status === "DRAFT" ? false : true,
              },
              packingPlan,
            },
          };

          const createOutboundPlanQueryResponse =
            await createOutboundPlanQuery.fetchData(createOutboundPlanPayload);
          appState.removeLoading();

          if (
            createOutboundPlanQueryResponse?.data?.createOutboundPlan
              ?.outboundPlan?.createdAt
          ) {
            notify.success(
              status === "DRAFT" ? "Plan Saved" : "Plan Confirmed",
              status === "DRAFT"
                ? "The outbound plan has been saved as draft."
                : "The outbound plan has been successfully created.",
            );
            if (callback) callback();
            negativeAction();

            const attemptId =
              createOutboundPlanQueryResponse?.data?.createOutboundPlan
                ?.attemptId;
            setBatchingAttemptIds((prev) => [...prev, attemptId]);
          } else {
            notify.error(
              status === "DRAFT"
                ? "Failed to save plan as draft."
                : "Failed to confirm plan.",
              "Failed to create outbound plan.",
            );
          }
        } catch (error) {
          appState.removeLoading();
          notify.error(
            "Error Saving Plan",
            "An error occurred while saving the outbound plan.",
          );
        }
      },
      appState.hideConfirmation,
      "Cancel",
      status === "DRAFT" ? "Save as Draft" : "Confirm Plan",
    );
  };
  const groupedData = useMemo(() => {
    if (!morpheusData) return [];
    return morpheusData.map((category) => ({
      categoryName: category.grouping_criteria.match_type,
      groups: category.groups.map((group, index) => ({
        id: `${category.grouping_criteria.match_type}_${category.grouping_type}_${index}`, // Changed ID format
        name: `Group ${index + 1}`,
        skus: group.matching_attributes.skus || [],
        orders: group.orders,
        count: group.orders.length,
      })),
    }));
  }, [morpheusData]);

  const handleProceed = () => {
    const selectedOrderIds = new Set();
    groupedData.forEach((category) => {
      category.groups.forEach((group) => {
        if (selectedGroups.includes(group.id)) {
          group.orders.forEach((groupOrder) => {
            // Find matching order from orders array
            const fullOrder = orders.find(
              (order) => order.orderId === groupOrder.order_id,
            );
            if (fullOrder) {
              selectedOrderIds.add(fullOrder.id); // Use the order.id instead of order_id
            }
          });
        }
      });
    });

    const entityForBatching =
      enhancedSearchText && showEnhancedSearch
        ? {
            entities: searchResults?.entities?.map(
              (entity) => entity?.orderData,
            ),
          }
        : entity;

    setSelectedOrders(Array.from(selectedOrderIds) || []);
    createManualBatch({
      entity: entityForBatching,
      selectedOrders: Array.from(selectedOrderIds),
      fulfillmentCheck: selectedEnhancedOrders.length === 1 ? true : false,
    });
    setMorpheusScreen(false);
  };

  const onCancel = () => {
    if (exceptionOrders.length > 0) {
      appState.setAlert(
        `${exceptionOrders.length} order went into exception.`,
        "error",
        ALERT_TIMEOUT_MS,
      );
    }
    negativeAction();
  };

  return (
    <SlideOverPanel
      open={true}
      setOpen={(open) => {
        if (!open && !orderDetailsProcessing) {
          onCancel();
        }
      }}
      containerStyle={"max-w-6xl"}
      title={`Create Outbound Plan ${selectedStep === "01" ? `(${selectedOrderIds.length})` : ""}`}>
      <div className="relative flex h-full w-full select-none flex-col p-6 font-inter">
        <main className="mb-10 grow">
          {loading ? (
            <div className="mt-2 flex h-full w-full flex-col items-center justify-center rounded border">
              <div className="h-10 w-10 animate-spin rounded-full border-t-4 border-solid border-hyperlinkColor"></div>
            </div>
          ) : (
            <>
              {selectedStep === "00" && (
                <div className="flex flex-col gap-3">
                  <div>
                    <h1 className="text-xl font-semibold">
                      Intelligent Order Groups Suggestions
                    </h1>
                  </div>
                  <AskMorpheus
                    // isOpen={morpheusData !== null && morpheusData !== undefined}
                    isShowIds={false}
                    setSelectedStep={setSelectedStep}
                    data={morpheusData || []}
                    customers={customers}
                    warehouses={warehouses}
                    orders={
                      enhancedSearchText && showEnhancedSearch
                        ? searchResults?.entities?.map(
                            (entity) => entity?.orderData,
                          )
                        : orders
                    }
                    onClose={() => setMorpheusScreen(false)}
                    setSelectedGroups={setSelectedGroups}
                    selectedGroups={selectedGroups}
                    groupedData={groupedData}
                    // createManualBatch={(selectedOrderIds) => {
                    //   const entityForBatching =
                    //     enhancedSearchText && showEnhancedSearch
                    //       ? {
                    //           entities: searchResults?.entities?.map(
                    //             (entity) => entity?.orderData,
                    //           ),
                    //         }
                    //       : entity;
                    //   setSelectedOrders(selectedOrderIds || []);
                    //   createManualBatch({
                    //     entity: entityForBatching,
                    //     selectedOrders: selectedOrderIds,
                    //     fulfillmentCheck:
                    //       selectedEnhancedOrders.length === 1 ? true : false,
                    //   });
                    // }}
                  />
                </div>
              )}
              {selectedStep === "01" && (
                <div className="flex flex-col gap-3">
                  <div>
                    {" "}
                    <h1 className="text-xl font-semibold">Order Plan</h1>
                  </div>
                  <BatchPrioritisationWithDetails
                    selectedOrderIds={selectedOrderIds}
                    setSelectedOrderIds={setSelectedOrderIds}
                    orders={
                      enhancedSearchText && showEnhancedSearch
                        ? searchResults?.entities?.map(
                            (entity) => entity?.orderData,
                          )
                        : orders
                    }
                    expandOrder={expandOrder}
                    // Add new props for merged functionality
                    fulfillableOrders={fulfillableOrders}
                    partiallyFulfillableOrders={partiallyFulfillableOrders}
                    unfulfillableOrders={unfulfillableOrders}
                    exceptionOrders={exceptionOrders}
                    ordersFulfillmentDetails={ordersFulfillmentDetails}
                    selectedOrderIdsForBatching={selectedOrderIdsForBatching}
                    setSelectedOrderIdsForBatching={
                      setSelectedOrderIdsForBatching
                    }
                    isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
                    refetchOrderDetails={refetchOrderDetails}
                    setRefetchOrderDetails={setRefetchOrderDetails}
                    updatedOrderBatchingDetails={updatedOrderBatchingDetails}
                    setUpdatedOrderBatchingDetails={
                      setUpdatedOrderBatchingDetails
                    }
                    orderQuantityDetails={orderQuantityDetails}
                    setOrderQuantityDetails={setOrderQuantityDetails}
                    tenant={tenant}
                    setOrderDetailsProcessing={setOrderDetailsProcessing}
                    setAllOrdersAreUnfulfillable={setAllOrdersAreUnfulfillable}
                    warehouses={warehouses}
                    customers={customers}
                  />
                </div>
              )}

              {/* {selectedStep === "02" && (
                <div className="flex flex-col gap-3">
                  <div>
                    {" "}
                    <h1 className="text-xl font-semibold">Pack Plan</h1>
                  </div>
                  <PreviewFulfillmentDetails
                    fulfillableOrders={fulfillableOrders}
                    partiallyFulfillableOrders={partiallyFulfillableOrders}
                    unfulfillableOrders={unfulfillableOrders}
                    exceptionOrders={exceptionOrders}
                    selectedOrderIds={selectedOrderIds}
                    selectedOrderIdsForBatching={selectedOrderIdsForBatching}
                    setSelectedOrderIdsForBatching={
                      setSelectedOrderIdsForBatching
                    }
                    isBackOrderEnabledForTenant={isBackOrderEnabledForTenant}
                    orderQuantityDetails={orderQuantityDetails}
                    setOrderQuantityDetails={setOrderQuantityDetails}
                    refetchOrderDetails={refetchOrderDetails}
                    setRefetchOrderDetails={setRefetchOrderDetails}
                    updatedOrderBatchingDetails={updatedOrderBatchingDetails}
                    setUpdatedOrderBatchingDetails={
                      setUpdatedOrderBatchingDetails
                    }
                    tenant={tenant}
                  />
                </div>
              )} */}

              {selectedStep === "03" && (
                <div className="flex flex-col gap-3">
                  <div>
                    {" "}
                    <h1 className="text-xl font-semibold">Pick Plan</h1>
                  </div>
                  <BatchConfirmation
                    pickers={pickers}
                    manualBatchConfig={manualBatchConfig}
                    setManualBatchConfig={setManualBatchConfig}
                    stations={stations}
                    tenant={tenant}
                    batchSettings={batchSettings}
                    orders={
                      enhancedSearchText && showEnhancedSearch
                        ? searchResults?.entities?.map(
                            (entity) => entity?.orderData,
                          )
                        : orders
                    }
                    fulfillableOrders={fulfillableOrders}
                    partiallyFulfillableOrders={partiallyFulfillableOrders}
                    selectedOrderIdsForBatching={selectedOrderIdsForBatching}
                    showBatchingPreview={showBatchingPreview}
                  />
                </div>
              )}

              {selectedStep === "04" && (
                <div className="flex flex-col gap-3">
                  <div>
                    {" "}
                    <h1 className="text-xl font-semibold">Pack Plan</h1>
                  </div>
                  <PackPlan
                    orders={
                      enhancedSearchText && showEnhancedSearch
                        ? searchResults?.entities?.map(
                            (entity) => entity?.orderData,
                          )
                        : orders
                    }
                    customers={customers}
                    warehouses={warehouses}
                    detailedOrdersForBatching={detailedOrdersForBatching}
                    boxTypes={boxTypes}
                    orderBoxes={orderBoxes}
                    setOrderBoxes={setOrderBoxes}
                    tenant={tenant}
                    setPickPlanDetails={setPickPlanDetails}
                    pickPlanDetails={pickPlanDetails}
                  />
                </div>
              )}
            </>
          )}
        </main>
        <footer className="sticky bottom-0 bg-white">
          <FooterActions
            negativeAction={onCancel}
            selectedStep={selectedStep}
            setSelectedStep={setSelectedStep}
            setDetailedOrdersForBatching={setDetailedOrdersForBatching}
            getOrdersForPackPlan={getOrdersForPackPlan}
            selectedOrderIdsForBatching={selectedOrderIdsForBatching}
            fulfillableOrders={fulfillableOrders}
            partiallyFulfillableOrders={partiallyFulfillableOrders}
            showBatchingPreview={showBatchingPreview}
            isManualBatchExecuting={isManualBatchExecuting}
            selectedOrderIds={selectedOrderIds}
            createManualBatch={createManualBatch}
            enhancedSearchText={enhancedSearchText}
            searchResults={searchResults}
            showEnhancedSearch={showEnhancedSearch}
            handleSaveOutboundPlan={handleSaveOutboundPlan}
            orderDetailsProcessing={orderDetailsProcessing}
            allOrdersAreUnfulfillable={allOrdersAreUnfulfillable}
            morpheusData={morpheusData}
            handleProceed={handleProceed}
            selectedGroups={selectedGroups}
          />
        </footer>
      </div>
    </SlideOverPanel>
  );
};

const FooterActions = ({
  negativeAction,
  selectedStep,
  setSelectedStep,
  getOrdersForPackPlan,
  selectedOrderIdsForBatching,
  setDetailedOrdersForBatching,
  handleSaveOutboundPlan,
  fulfillableOrders,
  partiallyFulfillableOrders,
  showBatchingPreview,
  isManualBatchExecuting,
  selectedOrderIds,
  createManualBatch,
  enhancedSearchText,
  searchResults,
  showEnhancedSearch,
  orderDetailsProcessing,
  allOrdersAreUnfulfillable,
  morpheusData,
  handleProceed,
  selectedGroups,
}) => {
  const entity = useContext(EntityContext);
  const appState = useContext(AppStateContext);

  return (
    <div className="mr-2 flex h-16 items-center justify-end space-x-2">
      <PrimaryButton
        height="3rem"
        width="7rem"
        variant="primary"
        onClick={negativeAction}
        disabled={orderDetailsProcessing && selectedStep !== "00"}
        className="text-base font-medium"
        danger>
        Cancel
      </PrimaryButton>

      {/* Previous button for Pick Plan and Pack Plan */}
      {(selectedStep === "03" ||
        selectedStep === "04" ||
        (selectedStep === "01" && morpheusData && morpheusData.length > 0)) && (
        <PrimaryButton
          height="3rem"
          width="7rem"
          variant="secondary"
          className="text-base font-medium"
          onClick={() =>
            setSelectedStep(
              selectedStep === "03"
                ? "01"
                : selectedStep === "01"
                  ? "00"
                  : "03",
            )
          }>
          Previous
        </PrimaryButton>
      )}

      <PrimaryButton
        height="3rem"
        minWidth="7rem"
        maxWidth="15rem"
        variant="primary"
        onClick={async () => {
          if (selectedStep === "00") {
            handleProceed();
            setSelectedStep("01");
          } else if (selectedStep === "01") {
            setSelectedStep("03");
          } else if (selectedStep === "03") {
            const ordersWithDetails = await getOrdersForPackPlan(
              selectedOrderIdsForBatching,
            );
            setDetailedOrdersForBatching(ordersWithDetails);
            setSelectedStep("04");
          } else if (selectedStep === "04") {
            handleSaveOutboundPlan("CONFIRM");
          }
        }}
        disabled={
          isManualBatchExecuting ||
          appState.loading ||
          (selectedStep === "00" && !selectedGroups.length) ||
          (selectedStep === "01" &&
            (allOrdersAreUnfulfillable || orderDetailsProcessing))
        }
        className={
          "text-base font-medium " +
          (selectedStep === "04"
            ? "bg-primaryAccent"
            : selectedOrderIdsForBatching.length > 0 && !appState.loading
              ? "bg-FC8862"
              : "cursor-not-allowed bg-gray-200 text-gray-400")
        }>
        {selectedStep === "04" ? "Confirm Plan & Batch" : "Next"}
      </PrimaryButton>
    </div>
  );
};

export default ManualBatchWithPlanning;
