export const PREP_CATEGORY_OPTIONS = [
  {
    id: "ADULT",
    label: "ADULT",
    value: "ADULT",
    description:
      "Displays potentially offensive material such as profanity or nudity.",
  },
  {
    id: "BABY",
    label: "BABY",
    value: "BABY",
    description:
      "Made for a child aged three years or younger, packaging with cutouts greater than one square inch.",
  },
  {
    id: "FC_PROVIDED",
    label: "FC_PROVIDED",
    value: "FC_PROVIDED",
    description:
      "A prep type has been defined by the Fulfillment Center. This value is provided by Amazon and cannot be used as an input.",
  },
  {
    id: "FRAGILE",
    label: "FRAGILE",
    value: "FRAGILE",
    description:
      "Glass or otherwise fragile, or a liquid in a glass container.",
  },
  {
    id: "GRANULAR",
    label: "GRANULAR",
    value: "GRANULAR",
    description: "Made of powder, pellets, or granular material.",
  },
  {
    id: "HANGER",
    label: "HANGER",
    value: "HANGER",
    description: "Made of cloth or fabric and intended to be put on a hanger.",
  },
  {
    id: "LIQUID",
    label: "LIQUID",
    value: "LIQUID",
    description: "Liquid or viscous without a double seal.",
  },
  {
    id: "PERFORATED",
    label: "PERFORATED",
    value: "PERFORATED",
    description: "In packaging that has a perforated opening.",
  },
  {
    id: "SET",
    label: "SET",
    value: "SET",
    description: "Multiple items that are sold as one unit.",
  },
  {
    id: "SHARP",
    label: "SHARP",
    value: "SHARP",
    description:
      "Sharp and easily exposed, not already contained in protective packaging.",
  },
  {
    id: "SMALL",
    label: "SMALL",
    value: "SMALL",
    description:
      "Longest side less than 2 1/8 inches (width of a credit card).",
  },
  {
    id: "TEXTILE",
    label: "TEXTILE",
    value: "TEXTILE",
    description:
      "Made of cloth or fabric that could be damaged by dirt, dust, moisture, or liquid.",
  },
  {
    id: "UNKNOWN",
    label: "UNKNOWN",
    value: "UNKNOWN",
    description:
      "An unknown prep category was found and needs to be updated. This value is provided by Amazon and cannot be used as an input.",
  },
  {
    id: "NONE",
    label: "NONE",
    value: "NONE",
    description: "Does not require prep.",
  },
];

export const PREP_TYPE_OPTIONS = [
  {
    id: "ITEM_BLACK_SHRINKWRAP",
    name: "ITEM_BLACK_SHRINKWRAP",
    description: "The item requires black shrink wrapping.",
  },
  {
    id: "ITEM_BLANKSTK",
    name: "ITEM_BLANKSTK",
    description:
      "The item requires a blank sticker to obscure a bad barcode that cannot be covered by another sticker.",
  },
  {
    id: "ITEM_BOXING",
    name: "ITEM_BOXING",
    description:
      "Products may require overboxing when there are safety concerns over sharp items, fragile items, hazardous liquids, and vinyl records. For items over 4.5 kg, use double-wall corrugated boxes.",
  },
  {
    id: "ITEM_BUBBLEWRAP",
    name: "ITEM_BUBBLEWRAP",
    description: "The item requires bubble wrapping.",
  },
  {
    id: "ITEM_CAP_SEALING",
    name: "ITEM_CAP_SEALING",
    description:
      "To prevent leakage, the product needs to have a secondary seal in one of the following types]: Induction seal, safety ring, clips, heat shrink plastic band, or boxing.",
  },
  {
    id: "ITEM_DEBUNDLE",
    name: "ITEM_DEBUNDLE",
    description:
      "The item requires taking apart a set of items labeled for individual sale. Remove tape or shrink wrap that groups multiple inventory units together.",
  },
  {
    id: "ITEM_HANG_GARMENT",
    name: "ITEM_HANG_GARMENT",
    description: "The item must be placed on a hanger.",
  },
  {
    id: "ITEM_LABELING",
    name: "ITEM_LABELING",
    description: "The FNSKU label must be applied to the item.",
  },
  {
    id: "ITEM_NO_PREP",
    name: "ITEM_NO_PREP",
    description: "The item does not require any prep.",
  },
  {
    id: "ITEM_POLYBAGGING",
    name: "ITEM_POLYBAGGING",
    description: "The item requires polybagging.",
  },
  {
    id: "ITEM_RMOVHANG",
    name: "ITEM_RMOVHANG",
    description: "The item cannot be shipped on a hanger.",
  },
  {
    id: "ITEM_SETCREAT",
    name: "ITEM_SETCREAT",
    description:
      "Units that are sets must be labeled as sets on their packaging. The barcodes on the individual items must not face outward and must not require covering.",
  },
  {
    id: "ITEM_SETSTK",
    name: "ITEM_SETSTK",
    description:
      "Products that are sets must be marked as sets on their packaging. Add a label to the unit that clearly states that the products must be received and sold as a single unit. For example, if a set of six unique toy cars is sold as one unit, the packaging for each car must indicate that it is part of the set.",
  },
  {
    id: "ITEM_SIOC",
    name: "ITEM_SIOC",
    description: "The item ships in its original product packaging.",
  },
  {
    id: "ITEM_SUFFOSTK",
    name: "ITEM_SUFFOSTK",
    description:
      "Poly bags with an opening of 12 cm or larger (measured when flat) must have a suffocation warning. This warning must be printed on the bag or attached as a label.",
  },
  {
    id: "ITEM_TAPING",
    name: "ITEM_TAPING",
    description: "Indicates that taping is required.",
  },
];
