// hooks imports
import { useState, useEffect, useContext, useRef } from "react";
import { useQuery } from "#hooks/useQuery";
// queries, mutations imports
import {
  GET_PRODUCTS,
  GET_SKU,
  GET_CATALOG,
  GET_ENTITY_TYPES,
  EXPLAIN_INVENTORY,
  GET_TAGS,
  GET_CATEGORIES,
  GET_MIN_MAX_SALES_PRICE_RANGE,
} from "#queries";
import {
  SAVE_PRODUCT,
  SAVE_ENTITY_TYPE,
  DELETE_PRODUCT,
  ADD_TAGS_TO_PRODUCTS,
  SAVE_USER,
} from "#mutations";
// contexts imports
import { AppStateContext } from "#contexts/appState";
import { EntityContext } from "#contexts/entity";
import { AuthContext } from "#contexts/auth";
// other imports
import _ from "lodash";
import PropTypes from "prop-types";

const ALERT_VISIBILITY_IN_MS = 5000; // 5 seconds
export const DEFAULT_QUERY_OPERATOR = "all";
export const INITIAL_SALES_PRICE_RANGE = {
  from: 0,
  to: 10000,
};

/**
 * Higher-Order Component that is responsible for managing,providing data for catalog management.
 *
 * @param {React.Component} WrappedComponent - The component to be wrapped. In this case it comes from catalogs.jsx
 * @returns {React.Component} - The enhanced component with props data.
 */
const withCatalogsLogic = (WrappedComponent) => {
  return (props) => {
    // query declarations
    const saveUserQuery = useQuery(SAVE_USER);
    const getCatalogQuery = useQuery(GET_CATALOG);
    const explainInventoryQuery = useQuery(EXPLAIN_INVENTORY);
    const productsQuery = useQuery(GET_PRODUCTS);
    const tagsQuery = useQuery(GET_TAGS);
    const categoriesQuery = useQuery(GET_CATEGORIES);
    const entityTypesQuery = useQuery(GET_ENTITY_TYPES);
    const saveProductQuery = useQuery(SAVE_PRODUCT);
    const deleteProductQuery = useQuery(DELETE_PRODUCT);
    const getSpecificProduct = useQuery(GET_SKU);
    const saveEntityTypeQuery = useQuery(SAVE_ENTITY_TYPE);
    const addTagsToProductsQuery = useQuery(ADD_TAGS_TO_PRODUCTS);
    const exportToCsvProductsQuery = useQuery(GET_PRODUCTS);
    const minMaxSalesPriceQuery = useQuery(GET_MIN_MAX_SALES_PRICE_RANGE);

    // context declarations
    const auth = useContext(AuthContext);
    const entity = useContext(EntityContext);
    const appState = useContext(AppStateContext);

    // state declarations
    const [manageTags, setManageTags] = useState(false);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [allProductsSelected, setAllProductsSelected] = useState(false);
    const [lastSelectedRow, setLastSelectedRow] = useState(null);
    const [inventoryExplanations, setInventoryExplanations] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [fetchedCatalog, setFetchedCatalog] = useState(null);
    const [showSingleImageView, setShowSingleImageView] = useState(null);
    const [showFilters, setShowFilters] = useState(false);

    const [entityTypes, setEntityTypes] = useState([]);
    const [entityAttributes, setEntityAttributes] = useState({});
    const [addCustomEntity, setAddCustomEntity] = useState(null);
    const [filterQueryOperator, setFilterQueryOperator] = useState(
      DEFAULT_QUERY_OPERATOR,
    );
    const [productTags, setProductTags] = useState([]);
    const [productCategories, setProductCategories] = useState([]);
    const [exportListHeaders, setExportListHeaders] = useState(null);
    const [reloadCategories, setReloadCategories] = useState(false);
    const [filteringCriteria, setFilteringCriteria] = useState([]);
    const [customerList, setCustomerList] = useState([]);
    const [salesPriceRange, setSalesPriceRange] = useState(
      INITIAL_SALES_PRICE_RANGE,
    );

    const fetchSalesPriceMinMaxValue = async () => {
      try {
        const getMinMaxSalesPrice = await minMaxSalesPriceQuery.fetchData();
        if (
          getMinMaxSalesPrice?.data?.getMinMaxSalesPrice?.salesMinMaxPrice
            ?.length > 0
        ) {
          setSalesPriceRange({
            from:
              (getMinMaxSalesPrice.data.getMinMaxSalesPrice.salesMinMaxPrice[0]
                ?.minSalesChannelTotal || INITIAL_SALES_PRICE_RANGE.from) / 100,
            to:
              (getMinMaxSalesPrice.data.getMinMaxSalesPrice.salesMinMaxPrice[0]
                ?.maxSalesChannelTotal || INITIAL_SALES_PRICE_RANGE.to) / 100,
          });
        }
      } catch (e) {}
    };

    useEffect(() => {
      setFilteringCriteria([
        {
          name: "Tags",
          options: productTags
            ? productTags.map((i) => ({
                id: i.id,
                name: i.name,
              }))
            : [],
          filterName: "tags",
        },
        {
          name: "Categories",
          options: productCategories
            ? productCategories.map((i) => ({
                id: i.id,
                name: i.name,
              }))
            : [],
          filterName: "category",
        },
        {
          name: "Customers",
          options: customerList
            ? customerList.map((i) => ({
                id: i.id,
                name: i.name,
              }))
            : [],
          filterName: "selectedCustomers",
        },
        {
          name: "Price Range",
          filterName: "salesPriceRange",
        },
      ]);
    }, [productTags, productCategories, customerList]);

    useEffect(() => {
      if (categoriesQuery.data) {
        setProductCategories(categoriesQuery.data.categories.entities);
      }

      if (categoriesQuery.error) {
        setProductCategories([]);
      }

      if (categoriesQuery.loading) appState.setLoading();
      else appState.removeLoading();
    }, [categoriesQuery.data, categoriesQuery.error, categoriesQuery.loading]);

    const handleScroll = (event) => {
      const { scrollTop, clientHeight, scrollHeight } = event.target;
      const threshold = 1;
      if (scrollTop + clientHeight + threshold >= scrollHeight) {
        checkPagination("forward", entity);
      }
    };

    useEffect(() => {
      fetchEntityTypes();
      return () => {
        entity.setFilters({});
        entity.resetEntities();
      };
    }, []);

    useEffect(() => {
      fetchSalesPriceMinMaxValue();
    }, []);

    useEffect(() => {
      if (auth?.user?.customersList?.length > 0) {
        setCustomerList(
          auth?.user?.customersList.map(({ name, id }) => {
            return {
              id,
              name,
            };
          }) ?? [],
        );
      }
    }, [auth]);

    /**
     * Perform side effects based on the state of `entityTypesQuery`.
     * @param {Object} entityTypesQuery - The object containing the query state.
     */
    useEffect(() => {
      if (entityTypesQuery.data) {
        setEntityTypes(entityTypesQuery.data.entityTypes);
      }
      if (entityTypesQuery.error) {
        setEntityTypes([]);
      }
    }, [entityTypesQuery.data, entityTypesQuery.error]);

    /**
     * Perform side effects based on the state of `entityTypes`.
     * @param {Array<Object>} entityTypes - An array of entityTypes objects
     */
    useEffect(() => {
      const attributes = entityTypes.reduce((result, obj) => {
        const entityParent = obj["entityParent"];
        if (!result[entityParent]) {
          result[entityParent] = [];
        }
        result[entityParent].push(obj);
        return result;
      }, {});

      setEntityAttributes(attributes);
    }, [entityTypes]);

    /**
     * Perform side effects based on the state of `saveEntityTypeQuery`.
     * @param {Object} saveEntityTypeQuery - The object containing the query state.
     */
    useEffect(() => {
      if (saveEntityTypeQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveEntityTypeQuery.data) {
        const filtersSet = {
          entityParent: [
            "PRODUCTSIZE",
            "PRODUCTSHAPE",
            "PRODUCTCOLOR",
            "PRODUCTCATEGORY",
            "PRODUCTTYPE",
          ],
        };
        entityTypesQuery.fetchData({ filters: filtersSet });
        setAddCustomEntity(null);
      }

      if (saveEntityTypeQuery.error) {
        appState.setDismissableAlert(
          saveEntityTypeQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      saveEntityTypeQuery.loading,
      saveEntityTypeQuery.data,
      saveEntityTypeQuery.error,
    ]);

    /**
     * Perform side effects to load first time data when auth context is updated.
     * @param {Object} auth - The object containing the auth context
     */
    useEffect(() => {
      loadFirstTimeData();
      appState.setAdminPageBgColor("bg-white");
    }, [reloadCategories]);

    /**
     * Perform side effects based on the state of `productsQuery`.
     * @param {Object} productsQuery - The object containing the query state.
     */
    useEffect(() => {
      if (productsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (productsQuery.data && productsQuery.data.products) {
        productsQuery.data.products.entities =
          productsQuery.data.products.entities.filter((i) => !i.parentProduct);
        if (productsQuery.variables?.filters) {
          const salesPriceRange = {};
          if (entity.filters?.salesPriceRange) {
            salesPriceRange["salesPriceRange"] = {
              from: entity.filters.salesPriceRange.from,
              to: entity.filters.salesPriceRange.to,
            };
          }
          productsQuery.variables.filters = {
            ...productsQuery.variables.filters,
            ...salesPriceRange,
            category: entity.filters.category || [],
            selectedCustomers: entity.filters.selectedCustomers || [],
          };
        }
        entity.setEntities({
          ...productsQuery.data.products,
          ...productsQuery.variables,
        });
      }
    }, [productsQuery.error, productsQuery.data, productsQuery.loading]);

    /**
     * Perform side effects based on the state of `exportToCsvProductsQuery`.
     * @param {Object} exportToCsvProductsQuery - The object containing the query state.
     */
    useEffect(() => {
      if (exportToCsvProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (exportToCsvProductsQuery?.data?.products?.entities) {
        const products = exportToCsvProductsQuery.data.products.entities;
        generateProductsCsv(products);
      }
    }, [
      exportToCsvProductsQuery.error,
      exportToCsvProductsQuery.data,
      exportToCsvProductsQuery.loading,
    ]);

    /**
     * Perform side effects based on the state of `deleteProductQuery`.
     * @param {Object} deleteProductQuery - The object containing the query state.
     */
    useEffect(() => {
      if (deleteProductQuery.data) {
        appState.hideConfirmation();
        appState.setDismissableAlert(
          deleteProductQuery.data.deleteProduct.message,
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: {
            ...entity.filters,
            categoryQuerySelector: filterQueryOperator,
          },
          paginated: false,
          sort: entity.sort,
        });
      }

      if (deleteProductQuery.error) {
        appState.setDismissableAlert(
          deleteProductQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        appState.hideConfirmation();
      }
    }, [
      deleteProductQuery.loading,
      deleteProductQuery.data,
      deleteProductQuery.error,
    ]);

    /**
     * Perform side effects based on the state of `getCatalogQuery`.
     * @param {Object} getCatalogQuery - The object containing the query state.
     */
    useEffect(() => {
      if (getCatalogQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (getCatalogQuery.data && getCatalogQuery.data.specificCatalog) {
        setFetchedCatalog(getCatalogQuery.data.specificCatalog);
      }

      if (getCatalogQuery.error) {
        appState.setDismissableAlert(
          getCatalogQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [getCatalogQuery.loading, getCatalogQuery.error, getCatalogQuery.data]);

    /**
     * Perform side effects based on the state of `explainInventoryQuery`.
     * @param {Object} explainInventoryQuery - The object containing the query state.
     */
    useEffect(() => {
      if (explainInventoryQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (explainInventoryQuery.data) {
        setInventoryExplanations([
          ...inventoryExplanations,
          explainInventoryQuery.data.explainInventory,
        ]);
      }

      if (explainInventoryQuery.error) {
        appState.setDismissableAlert(
          explainInventoryQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      explainInventoryQuery.loading,
      explainInventoryQuery.error,
      explainInventoryQuery.data,
    ]);

    /**
     * Perform side effects based on the state of `getSpecificProduct`.
     * @param {Object} getSpecificProduct - The object containing the query state.
     */
    useEffect(() => {
      if (
        getSpecificProduct.data &&
        getSpecificProduct.data.specificInventory
      ) {
        setSelectedProduct({
          ...getSpecificProduct.data.specificInventory,
          uomConfiguration: getSpecificProduct.data.specificInventory
            .uomConfiguration
            ? getSpecificProduct.data.specificInventory.uomConfiguration
            : [],
        });
      }

      if (getSpecificProduct.error) {
        appState.setDismissableAlert(
          getSpecificProduct.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      getSpecificProduct.loading,
      getSpecificProduct.error,
      getSpecificProduct.data,
    ]);

    /**
     * Perform side effects based on the state of `saveProductQuery`.
     * @param {Object} saveProductQuery - The object containing the query state.
     */
    useEffect(() => {
      if (saveProductQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveProductQuery.error) {
        appState.setDismissableAlert(
          saveProductQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveProductQuery.data) {
        appState.setDismissableAlert(saveProductQuery.data.saveProduct.message);
        setSelectedProduct(null);
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: {
            ...entity.filters,
            categoryQuerySelector: filterQueryOperator,
          },
          paginated: false,
          sort: entity.sort,
        });
      }
    }, [
      saveProductQuery.data,
      saveProductQuery.loading,
      saveProductQuery.error,
    ]);

    /**
     * Perform side effects based on the state of `addTagsToProductsQuery`.
     * @param {Object} addTagsToProductsQuery - The object containing the query state.
     */
    useEffect(() => {
      if (addTagsToProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (addTagsToProductsQuery.data) {
        appState.setDismissableAlert(
          addTagsToProductsQuery.data.addTagsToProducts.message,
        );
        setManageTags(false);
      }

      if (addTagsToProductsQuery.error) {
        appState.setDismissableAlert(
          addTagsToProductsQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [
      addTagsToProductsQuery.loading,
      addTagsToProductsQuery.data,
      addTagsToProductsQuery.error,
    ]);

    // fetch the list of required entityTypes using entityTypesQuery
    const fetchEntityTypes = () => {
      const filtersSet = {
        entityParent: [
          "PRODUCTSIZE",
          "PRODUCTSHAPE",
          "PRODUCTCOLOR",
          "PRODUCTCATEGORY",
          "PRODUCTTYPE",
        ],
      };
      entityTypesQuery.fetchData({ filters: filtersSet });
    };

    useEffect(() => {
      if (tagsQuery.data) {
        setProductTags(tagsQuery.data.tags.entities);
      }
    }, [tagsQuery.data]);

    // loads the first time data that are required for this page.
    /*onlyFromManageCategories = false,withFilters = false this values are used when new categories are added 
    and when products should be updated on the basis of assigning of tags, by default these values will be false */

    const loadFirstTimeData = (
      onlyFromManageCategories = false,
      withFilters = false,
    ) => {
      if (
        auth &&
        auth.user &&
        auth.user.warehousesList &&
        auth.user.customersList
      ) {
        let warehouses = auth.user.warehousesList;
        let customers = auth.user.customersList;
        if (warehouses.length === 0 || customers.length === 0) {
          return appState.setDismissableAlert(
            `You don't have necessary permission to execute this action.`,
            "error",
            ALERT_VISIBILITY_IN_MS,
          );
        }
        if (onlyFromManageCategories) {
          categoriesQuery.fetchData();
          return;
        }
        const tempFilters = withFilters
          ? {
              ...entity.filters,
              category: getFinalSelectedValues(
                entity.filters.category,
                filteringCriteria[1].options,
              ),
            }
          : {};
        productsQuery.fetchData({
          perPage: entity.perPage,
          pageNumber: entity.pageNumber,
          filters: {
            ...tempFilters,
            customer: auth.user.customers,
            categoryQuerySelector: DEFAULT_QUERY_OPERATOR,
          },
          paginated: false,
          sort: entity.sort,
        });

        tagsQuery.fetchData({
          perPage: 100,
          pageNumber: 1,
          filters: {},
        });
        categoriesQuery.fetchData();
      }
    };

    // Provide an explanation for inventory items based on the provided parameters.
    const explainInventory = (id, typeOfBreakdown, typeOfInnerBreakdown) => {
      explainInventoryQuery.fetchData({
        id,
        typeOfBreakdown,
        typeOfInnerBreakdown,
      });
    };

    // function to check whether the requested productList page can be obtained from the entityContext or need to fetch from backend
    const checkPagination = (direction, entity) => {
      if (direction === "backward") {
        return entity.paginate({ pageNumber: entity.pageNumber - 1 });
      }
      if (entity.entities.length < entity.total) {
        entity.filters = {
          ...entity.filters,
          category: getFinalSelectedValues(
            entity.filters.category,
            filteringCriteria[1].options,
          ),
          selectedCustomers: getFinalSelectedValues(
            entity.filters.selectedCustomers,
            filteringCriteria[2].options,
            "id",
          ),
        };

        const priceRangeAndCustomersFilter = {};
        if (
          entity.filters?.salesPriceRange &&
          entity.filters.salesPriceRange?.to
        ) {
          priceRangeAndCustomersFilter["salesPriceRange"] = {
            from: entity.filters.salesPriceRange.from * 100,
            to: entity.filters.salesPriceRange.to * 100,
          };
        }

        if (
          entity.filters?.selectedCustomers &&
          entity.filters.selectedCustomers.length > 0 &&
          typeof entity.filters.selectedCustomers[0] === "object" &&
          Object.keys(entity.filters.selectedCustomers[0]).length > 0
        ) {
          priceRangeAndCustomersFilter["selectedCustomers"] =
            getFinalSelectedValues(
              entity.filters?.selectedCustomers,
              filteringCriteria[2].options,
              "id",
            ) || [];
        }

        const vars = {
          perPage: entity.perPage,
          pageNumber: entity.pageNumber + 1,
          filters: {
            ...entity.filters,
            ...priceRangeAndCustomersFilter,
            categoryQuerySelector: filterQueryOperator,
          },
          paginated: true,
          sort: entity.sort,
        };
        return productsQuery.fetchData(vars);
      }
    };

    // functions to modify selected product
    const onChange = (event) => {
      const product = {
        ...selectedProduct,
      };

      if (event.target.type === "number") {
        product[event.target.name] = parseInt(event.target.value);
      } else if (
        event.target.name === "upc" ||
        event.target.name === "ean" ||
        event.target.name === "lpn"
      ) {
        product[event.target.name] = event.target.value.split(",");
      } else if (event.target.type === "attribute") {
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[event.target.name] = event.target.value;
      } else {
        product[event.target.name] = event.target.value;
      }
      setSelectedProduct(product);
    };
    const onChangeDropdown = (field, value, entity = "selectedProduct") => {
      const product = {
        ...selectedProduct,
      };
      if (field === "size") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTSIZE",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "shape") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTSHAPE",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "color") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTCOLOR",
            name: "",
          });
        }
        if (!product.attributes) {
          product.attributes = {};
        }
        product.attributes[field] = value;
        setSelectedProduct(product);
      }
      if (field === "categories") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTCATEGORY",
            name: "",
          });
        }
        product[field] = value;
        setSelectedProduct(product);
      }
      if (field === "type") {
        if (value === "Add Custom") {
          return setAddCustomEntity({
            entityParent: "PRODUCTTYPE",
            name: "",
          });
        }
        product[field] = value;
        setSelectedProduct(product);
      }
      product[field] = value;
      setSelectedProduct(product);
    };
    const AttributeValues = (name, value) => {
      const product = {
        ...selectedProduct,
      };
      if (!product.attributes) {
        product.attributes = {};
      }

      product.attributes[name] = value;
      setSelectedProduct(product);
    };
    const deleteAttributes = (name) => {
      const product = {
        ...selectedProduct,
      };
      const attributes = product.attributes;
      if (attributes[name]) delete attributes[name];
      if (Object.keys(attributes).length === 0) delete product.attributes;
      setSelectedProduct(product);
    };
    const deleteProduct = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to delete this product?",
        () => {
          deleteProductQuery.fetchData({ id });
        },
        appState.hideConfirmation,
      );
    };
    const addTagsToProducts = (selectedTags) => {
      if (selectedTags && !selectedTags.length) {
        return appState.setDismissableAlert(
          "No tags selected to modify the products",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
      addTagsToProductsQuery.fetchData({
        productIds: selectedProducts,
        tags: selectedTags,
      });
    };

    const findIndexOfProduct = (id, products) => {
      return products.findIndex((product) => product.id === id);
    };
    // functions for handling selections in the catalog list
    const selectProduct = (id, shiftKeyPressed, products) => {
      let selectedListCopy = [...selectedProducts];
      if (shiftKeyPressed) {
        if (lastSelectedRow !== null) {
          const firstIndex = Math.min(
            findIndexOfProduct(lastSelectedRow, products),
            findIndexOfProduct(id, products),
          );
          const lastIndex = Math.max(
            findIndexOfProduct(lastSelectedRow, products),
            findIndexOfProduct(id, products),
          );
          for (let i = firstIndex; i <= lastIndex; i++) {
            if (!selectedListCopy.includes(products[i].id)) {
              selectedListCopy.push(products[i].id);
            }
          }
        } else {
          setLastSelectedRow(id);
        }
      } else {
        setLastSelectedRow(id);
        const isPresent = selectedListCopy.some(
          (productId) => productId === id,
        );
        if (isPresent) {
          selectedListCopy = selectedListCopy.filter(
            (productId) => productId != id,
          );
        } else {
          selectedListCopy.push(
            products.find((product) => product.id === id).id,
          );
        }
      }

      setSelectedProducts(selectedListCopy);
    };
    const selectAllProducts = () => {
      if (allProductsSelected === true) {
        setSelectedProducts([]);
        setAllProductsSelected(false);
      } else {
        setAllProductsSelected(true);
        setSelectedProducts(entity.entities.map((product) => product.id));
      }
    };

    const deleteImage = (idx) => {
      if (selectedProduct && selectedProduct.images) {
        const images = selectedProduct.images.filter(
          (item, index) => index !== idx,
        );
        setSelectedProduct({
          ...selectedProduct,
          images,
        });
      }
    };

    const PAGE_NAME = "CATALOG_MANAGEMENT";

    const saveBookmark = (bookmarkToSave, isToBeDeleted = false) => {
      if (!auth.user.pageSavedFilters) {
        auth.user.pageSavedFilters = {};
      }

      if (!auth.user.pageSavedFilters[PAGE_NAME]) {
        auth.user.pageSavedFilters[PAGE_NAME] = [];
      }

      if (!isToBeDeleted) {
        const savedFilters = auth.user.pageSavedFilters[PAGE_NAME];
        const existingFilter = savedFilters.find(
          (filter) => filter.name === bookmarkToSave.name,
        );
        if (existingFilter) {
          existingFilter.filters = { ...entity.filters };
          existingFilter.queryOperator = filterQueryOperator;
        } else {
          bookmarkToSave.queryOperator = filterQueryOperator;
          savedFilters.push(bookmarkToSave);
        }
      } else {
        auth.user.pageSavedFilters[PAGE_NAME] = auth.user.pageSavedFilters[
          PAGE_NAME
        ].filter((filter) => filter.name !== bookmarkToSave.name);
      }
      return saveUserQuery
        .fetchData({
          ...auth.user,
        })
        .then((saveUserResponse) => saveUserResponse);
    };

    useEffect(() => {
      if (saveUserQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }

      if (saveUserQuery.data) {
        appState.setDismissableAlert(
          "Bookmark saved successfully",
          "success",
          ALERT_VISIBILITY_IN_MS,
        );
      }

      if (saveUserQuery.error) {
        appState.setDismissableAlert(
          saveUserQuery.error.message,
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
      }
    }, [saveUserQuery.loading, saveUserQuery.data, saveUserQuery.error]);

    /**
     * Generates and downloads a CSV file containing product data.
     * Displays an alert and aborts if the product list is empty.
     *
     * @param {Object[]} data - Array of product objects with properties like sku, name, asin, etc.
     */
    const generateProductsCsv = (data) => {
      if (data?.length === 0) {
        appState.setAlert(
          "Your catalog is empty",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }
      if (exportListHeaders?.length === 0) {
        appState.setAlert(
          "Please select fields to export",
          "error",
          ALERT_VISIBILITY_IN_MS,
        );
        return;
      }

      const headersMap = {
        SKU: (item) => item.sku || "-",
        "Product Name": (item) => `"${item.name || "-"}"`, // to handle commas in values
        Description: (item) => item.description || "-",
        Category: (item) =>
          item.category
            ? productCategories?.find((i) => i.id === item.category)?.name ||
              "-"
            : "-",
        Pricing: (item) =>
          item.pricing?.computedPriceFields?.totalPrice
            ? `$${item.pricing?.computedPriceFields?.totalPrice.toFixed(2)}`
            : "-",
      };

      let csvContent = exportListHeaders.join(",") + "\n";

      data.forEach((item) => {
        const row = exportListHeaders.map((field) => headersMap[field](item));
        csvContent += row.join(",") + "\n";
      });

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
      const link = document.createElement("a");
      const url = URL.createObjectURL(blob);

      link.setAttribute("href", url);
      link.setAttribute("download", `products_export.csv`);
      link.style.visibility = "hidden";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      setExportListHeaders(null);
    };

    const onChangeDropdownFields = (value) => {
      let exportListHeadersDuplicate = [...exportListHeaders];

      if (exportListHeadersDuplicate.includes(value)) {
        setExportListHeaders(
          exportListHeadersDuplicate.filter((item) => item !== value),
        );
      } else {
        setExportListHeaders([...exportListHeadersDuplicate, value]);
      }
    };

    const getFinalSelectedValues = (
      selectedValues,
      options,
      getValue = "name",
    ) => {
      const selectedIds = selectedValues.map((value) => value.id);
      const result = [];

      for (const option of options) {
        if (selectedIds.includes(option.id)) {
          result.push(option);
        }
      }

      return result.map((option) => option[getValue]);
    };

    return (
      <WrappedComponent
        {...props}
        displayProducts={entity.displayEntities}
        products={entity.entities}
        productTags={productTags}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        showSingleImageView={showSingleImageView}
        setShowSingleImageView={setShowSingleImageView}
        addCustomEntity={addCustomEntity}
        setAddCustomEntity={setAddCustomEntity}
        onSubmitCustomEntity={() =>
          saveEntityTypeQuery.fetchData({
            entityTypeInput: {
              ...addCustomEntity,
            },
          })
        }
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        AttributeValues={AttributeValues}
        deleteAttributes={deleteAttributes}
        saveProduct={() => {
          saveProductQuery.fetchData({
            ...selectedProduct,
            categories: getFinalSelectedValues(
              selectedProduct.categories,
              filteringCriteria[1].options,
            ),
            images:
              selectedProduct.images && selectedProduct.images.length > 0
                ? selectedProduct.images.map((image) => {
                    delete image.display_url;
                    return image;
                  })
                : [],
          });
        }}
        deleteProduct={deleteProduct}
        productSizes={entityAttributes?.["PRODUCTSIZE"]}
        productShapes={entityAttributes?.["PRODUCTSHAPE"]}
        productColors={entityAttributes?.["PRODUCTCOLOR"]}
        productTypes={entityAttributes?.["PRODUCTTYPE"]}
        getCatalog={(id) => {
          getCatalogQuery.fetchData({ id });
        }}
        getSpecificProduct={(id) => {
          getSpecificProduct.fetchData({ id });
        }}
        fetchedCatalog={fetchedCatalog}
        setFetchedCatalog={setFetchedCatalog}
        manageTags={manageTags}
        setManageTags={setManageTags}
        addTagsToProducts={addTagsToProducts}
        total={entity.total}
        pageNumber={entity.pageNumber}
        checkPagination={checkPagination}
        handleScroll={handleScroll}
        perPage={entity.perPage}
        setPerPage={(perPage) => {
          entity.setPerPage({ perPage });
          productsQuery.fetchData({
            perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              categoryQuerySelector: filterQueryOperator,
            },
            sort: entity.sort,
          });
        }}
        filters={entity.filters}
        setFilters={entity.setFilters}
        submitFilters={() => {
          setShowFilters(false);
          const salesPriceRange = {};
          if (
            entity.filters?.salesPriceRange &&
            entity.filters.salesPriceRange?.to
          ) {
            salesPriceRange["salesPriceRange"] = {
              from: entity.filters.salesPriceRange.from * 100,
              to: entity.filters.salesPriceRange.to * 100,
            };
          }
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              ...salesPriceRange,
              category: getFinalSelectedValues(
                entity.filters.category,
                filteringCriteria[1].options,
              ),
              selectedCustomers: getFinalSelectedValues(
                entity.filters.selectedCustomers,
                filteringCriteria[2].options,
                "id",
              ),
              categoryQuerySelector: filterQueryOperator,
            },
            sort: entity.sort,
          });
        }}
        clearKeyword={() => {
          entity.setFilters({
            ...entity.filters,
            keyword: null,
          });
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              keyword: null,
              categoryQuerySelector: filterQueryOperator,
            },
            sort: entity.sort,
          });
        }}
        onChangeFilter={(
          field,
          value,
          autoSubmit = false,
          onChangeQueryOperator = "",
        ) => {
          if (field)
            entity.setFilters({
              ...entity.filters,
              [field]: value,
            });
          //? Changing appearance of value for category to send backend
          if (field === "category" && value.length)
            value = getFinalSelectedValues(value, filteringCriteria[1].options);

          if (field === "selectedCustomers" && value.length)
            value = getFinalSelectedValues(
              value,
              filteringCriteria[2].options,
              "id",
            );

          if (autoSubmit) {
            let updatedFilters = { ...entity.filters };
            if (field) {
              updatedFilters = {
                ...entity.filters,
                [field]: value,
              };
            }
            if (updatedFilters?.customer?.length > 0) {
              const { customer, ...restFilters } = updatedFilters;
              updatedFilters = restFilters;
            }
            if (
              updatedFilters?.category &&
              typeof updatedFilters?.category[0] === "object" &&
              Object.keys(updatedFilters?.category[0]).length > 0
            ) {
              updatedFilters.category =
                getFinalSelectedValues(
                  updatedFilters.category,
                  filteringCriteria[1].options,
                ) || [];
            }
            if (
              updatedFilters?.selectedCustomers &&
              typeof updatedFilters?.selectedCustomers[0] === "object" &&
              Object.keys(updatedFilters?.selectedCustomers[0]).length > 0
            ) {
              updatedFilters.selectedCustomers =
                getFinalSelectedValues(
                  updatedFilters.selectedCustomers,
                  filteringCriteria[2].options,
                  "id",
                ) || [];
            }
            const salesPriceRange = {};
            if (
              updatedFilters?.salesPriceRange &&
              updatedFilters.salesPriceRange?.to
            ) {
              salesPriceRange["salesPriceRange"] = {
                from: updatedFilters.salesPriceRange.from * 100,
                to: updatedFilters.salesPriceRange.to * 100,
              };
            }
            if (
              appState.filterQueryOperator !== filterQueryOperator ||
              onChangeQueryOperator != "onChangeQueryOperator"
            ) {
              productsQuery.fetchData({
                perPage: entity.perPage,
                pageNumber: 1,
                filters: {
                  ...updatedFilters,
                  ...salesPriceRange,
                  categoryQuerySelector: filterQueryOperator,
                },
                sort: entity.sort,
              });
              appState.setFilterQueryOperator(filterQueryOperator);
            }
          }
        }}
        onChangeAttributes={(field, value) => {
          const entityFilters = { ...entity.filters };
          if (!entityFilters.attributes) entityFilters.attributes = {};
          entityFilters.attributes = {
            ...entityFilters.attributes,
            [field]: value,
          };
          entity.setFilters({ ...entityFilters });
        }}
        showFilters={showFilters}
        setShowFilters={setShowFilters}
        clearFilters={loadFirstTimeData}
        warehouses={auth.user?.warehousesList ? auth.user.warehousesList : []}
        customers={auth.user?.customersList ? auth.user.customersList : []}
        pageSavedFilters={
          auth?.user?.pageSavedFilters && auth.user.pageSavedFilters[PAGE_NAME]
            ? auth.user.pageSavedFilters[PAGE_NAME]
            : []
        }
        onChangeSearchKeyword={(e) =>
          entity.setFilters({
            ...entity.filters,
            keyword: e.target.value,
          })
        }
        sort={entity.sort}
        setSort={(key) => {
          const sort = entity.sort === key ? `-${key}` : key;
          entity.setSort({ sort });
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...entity.filters,
              categoryQuerySelector: filterQueryOperator,
            },
            sort,
          });
        }}
        selectedProducts={selectedProducts}
        allRowsSelected={allProductsSelected}
        selectProduct={selectProduct}
        selectAllRows={selectAllProducts}
        explainInventory={explainInventory}
        inventoryExplanations={inventoryExplanations}
        setInventoryExplanations={setInventoryExplanations}
        filterQueryOperator={filterQueryOperator}
        setFilterQueryOperator={setFilterQueryOperator}
        deleteImage={deleteImage}
        saveBookmark={saveBookmark}
        applyBookmarkFilters={(savedFilter) => {
          entity.setFilters(savedFilter.filters);
          const filters = {
            ...savedFilter.filters,
            category: savedFilter.filters?.category
              ? getFinalSelectedValues(
                  savedFilter.filters?.category,
                  filteringCriteria[1].options,
                )
              : [],
            selectedCustomers: savedFilter.filters?.selectedCustomers
              ? getFinalSelectedValues(
                  savedFilter.filters?.selectedCustomers,
                  filteringCriteria[2].options,
                  "id",
                )
              : [],
          };
          if (filters?.salesPriceRange && filters.salesPriceRange?.to) {
            filters.salesPriceRange.from *= 100;
            filters.salesPriceRange.to *= 100;
          }
          productsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: 1,
            filters: {
              ...filters,
              categoryQuerySelector:
                savedFilter.queryOperator || filterQueryOperator,
            },
            sort: entity.sort,
          });
          setFilterQueryOperator(
            savedFilter.queryOperator || filterQueryOperator,
          );
        }}
        filteringCriteria={filteringCriteria}
        productCategories={productCategories}
        setProductCategories={setProductCategories}
        downloadProductsList={() => {
          exportToCsvProductsQuery.fetchData({
            perPage: entity.perPage,
            pageNumber: entity.pageNumber,
            filters: entity.filters,
            paginated: false,
            sort: entity.sort,
            queryOperator: filterQueryOperator,
          });
        }}
        exportListHeaders={exportListHeaders}
        setExportListHeaders={setExportListHeaders}
        onChangeDropdownFields={onChangeDropdownFields}
        setReloadCategories={setReloadCategories}
        reloadCategories={reloadCategories}
        loadFirstTimeData={loadFirstTimeData}
        parentProducts={entity.parentProducts}
        customerList={customerList}
        salesPriceRange={salesPriceRange}
        fetchSalesPriceMinMaxValue={fetchSalesPriceMinMaxValue}
      />
    );
  };
};

withCatalogsLogic.propTypes = {
  // The component to be wrapped
  WrappedComponent: PropTypes.elementType.isRequired,
};

export default withCatalogsLogic;
