import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {
  ChevronDownIcon,
  SearchIcon,
  ExclamationCircleIcon,
  XIcon,
} from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { twMerge } from "tailwind-merge";

export default function AutoCompleteSingleSelect({
  options,
  value,
  onChange,
  labelKey = "label",
  valueKey = "value",
  placeholder,
  onKeyDown,
  secondaryLabelKey,
  disabled = false,
  searchable = true,
  restrictOptionsWidth = true,
  labelText,
  required = false,
  error = "",
  parentClasses = "",
  labelClasses = "text-gray-700",
  displayValueClasses = "",
  shouldBeHighLighted = false,
  shouldDeselect = true,
  infoText = "",
  isSort = true,
  defaultValue = "",
  inputClasses,
  CustomOptionRender = null, // Added CustomOptionRender
  ...props
}) {
  const [text, setText] = useState(defaultValue);
  const [showOptions, setShowOptions] = useState(false);
  const [cursor, setCursor] = useState(-1);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);

  const select = (option) => {
    if (onChange) {
      onChange(option);
    }
    setShowOptions(false);
  };

  const handleChange = (text) => {
    setText(text);
    if (onKeyDown) {
      onKeyDown(text);
    }
    setCursor(-1);
    if (!showOptions) {
      setShowOptions(true);
    }
  };

  const clearSelection = () => {
    if (onChange) {
      onChange(null);
    }
    setText("");
  };

  let filteredOptions = options
    ? options.filter((option) =>
        option[labelKey]?.toLowerCase().includes(text?.toLowerCase()),
      )
    : [];
  if (isSort) {
    filteredOptions = filteredOptions.sort(
      (a, b) =>
        b.frequency - a.frequency || a[labelKey].localeCompare(b[labelKey]),
    );
  }

  useEffect(() => {
    function handleOutsideClick(event) {
      if (
        !dropdownRef ||
        !dropdownRef.current ||
        !dropdownRef.current.contains(event.target)
      ) {
        setShowOptions(false);
        setCursor(-1);
        setText("");
      }
    }

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  const selectedOption = _.find(options, (e) => e[valueKey] === value);

  return (
    <div className={`relative ${parentClasses}`} ref={dropdownRef}>
      {labelText && (
        <div className="mb-2 flex items-center">
          <div className={labelClasses}>
            {labelText}
            {required && <span className="text-red-500">*</span>}
            {infoText && (
              <Tooltip title={infoText}>
                <ExclamationCircleIcon className="ml-2 inline-block h-4 w-4 text-gray-400" />
              </Tooltip>
            )}
          </div>
        </div>
      )}
      <div className="relative">
        <input
          type="text"
          placeholder={!selectedOption ? placeholder : ""}
          readOnly
          disabled={disabled}
          onClick={() => !disabled && setShowOptions((prev) => !prev)}
          className={twMerge(
            `block w-full rounded border bg-white p-4 text-gray-700 transition-colors duration-300 focus:border-primaryAccent focus:ring-primaryAccent ${
              shouldBeHighLighted && error
                ? "border-red-500"
                : "border-gray-400"
            } ${disabled ? "pointer-events-none bg-gray-200 opacity-50" : "cursor-pointer"} ${inputClasses}`,
          )}
        />
        {selectedOption && (
          <div
            className={twMerge(
              `pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2 ${displayValueClasses}`,
            )}
            onClick={() => !disabled && setShowOptions((prev) => !prev)}>
            {selectedOption[labelKey]}
          </div>
        )}
        <div className="absolute inset-y-0 right-0 flex items-center pr-3">
          {selectedOption && shouldDeselect && !disabled ? (
            <XIcon
              className="h-5 w-5 cursor-pointer text-gray-500"
              onClick={disabled ? () => {} : clearSelection}
            />
          ) : (
            <ChevronDownIcon
              className="h-5 w-5 text-gray-500"
              onClick={() => !disabled && setShowOptions((prev) => !prev)}
            />
          )}
        </div>
      </div>

      {showOptions && (
        <div className="z-8 relative mt-2 w-full transition duration-300 ease-in-out">
          <ul
            className={`absolute ${
              restrictOptionsWidth ? "w-full" : "w-max"
            } z-50 max-h-96 overflow-auto rounded-lg rounded-t-none border border-gray-300 bg-white shadow-lg`}>
            {searchable && (
              <li className="sticky top-0 z-10 bg-white p-2">
                <div className="relative">
                  <input
                    type="search"
                    ref={inputRef}
                    value={text}
                    id="default-search"
                    className="w-full rounded border border-gray-300 px-3 py-2 text-sm focus:border-primaryAccent focus:outline-none focus:ring-1 focus:ring-primaryAccent"
                    placeholder="Search..."
                    onChange={(e) => handleChange(e.target.value)}
                  />
                </div>
              </li>
            )}

            {filteredOptions.length > 0 ? (
              filteredOptions.map((option, i, arr) => {
                let className = `text-gray-900 text-md cursor-pointer select-none relative py-4 pl-4 pr-4 border-b hover:bg-gray-100 `;

                if (i === arr.length - 1) className += "rounded-b-lg";
                if (cursor === i) {
                  className += " bg-gray-200";
                }

                return (
                  <li
                    className={className}
                    key={i}
                    onClick={() => select(option[valueKey])}>
                    {option["htmlContent"]
                      ? option["htmlContent"]
                      : option[labelKey]}
                    {secondaryLabelKey && option[secondaryLabelKey]}
                  </li>
                );
              })
            ) : (
              <li className="text-md relative cursor-default select-none border-b py-4 pl-4 pr-4 text-gray-900">
                No results
              </li>
            )}
          </ul>
        </div>
      )}
      {error && (
        <div className="mt-1 text-sm text-red-600">
          {error || "This field is required."}
        </div>
      )}
    </div>
  );
}

AutoCompleteSingleSelect.propTypes = {
  options: PropTypes.array.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  labelKey: PropTypes.string.isRequired,
  valueKey: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  onKeyDown: PropTypes.func,
  secondaryLabelKey: PropTypes.string,
  disabled: PropTypes.bool,
  searchable: PropTypes.bool,
  restrictOptionsWidth: PropTypes.bool,
  labelText: PropTypes.string,
  required: PropTypes.bool,
  error: PropTypes.string,
  parentClasses: PropTypes.string,
  labelClasses: PropTypes.string,
  shouldBeHighLighted: PropTypes.bool,
  shouldDeselect: PropTypes.bool,
  infoText: PropTypes.string,
  isSort: PropTypes.bool,
  defaultValue: PropTypes.string,
  inputClasses: PropTypes.string,
  CustomOptionRender: PropTypes.func,
};

AutoCompleteSingleSelect.defaultProps = {
  placeholder: "",
  secondaryLabelKey: "",
  disabled: false,
  searchable: true,
  restrictOptionsWidth: true,
  labelText: "",
  required: false,
  error: "",
  parentClasses: "",
  labelClasses: "text-gray-700",
  shouldBeHighLighted: false,
  shouldDeselect: true,
  infoText: "",
  isSort: true,
  defaultValue: "",
  inputClasses: "",
};
