export const buildHierarchicalInventoryTracking = (
  parentQuantity,
  parentFormFactor,
  uomConfig,
  parentId = "",
  baseIndex = 1,
  trackingLevels = {}, // Parameter to control which levels to track
) => {
  const items = [];
  const conversionMatrix = uomConfig?.currentConversionMatrix || {};

  // Get current level conversions
  const currentLevelConversions = conversionMatrix[parentFormFactor] || {};

  // Check if current level should be tracked
  const shouldTrackCurrentLevel = trackingLevels[parentFormFactor] !== false;

  // If this level isn't tracked, don't even look for next level
  if (!shouldTrackCurrentLevel) {
    return [];
  }

  // Find next form factor in hierarchy
  const nextFormFactor = Object.entries(currentLevelConversions)
    .filter(
      ([formFactor, value]) => value > 1 && formFactor !== parentFormFactor,
    )
    .sort(([, a], [, b]) => a - b) // Sort ascending to find next level
    .map(([formFactor]) => formFactor)[0];

  // Create parent level items
  for (let i = 0; i < parentQuantity; i++) {
    const index = i + 1;
    const uniqueId = parentId
      ? `${parentId}_${parentFormFactor}_${index}`
      : `${parentFormFactor}_${index}`;

    const item = {
      id: uniqueId,
      index: index,
      formFactor: parentFormFactor,
      code: "",
      children: [],
    };

    // Only add children if next level exists AND is tracked
    if (nextFormFactor && trackingLevels[nextFormFactor] !== false) {
      const childQuantity = currentLevelConversions[nextFormFactor];
      item.children = buildHierarchicalInventoryTracking(
        childQuantity,
        nextFormFactor,
        uomConfig,
        uniqueId,
        1,
        trackingLevels,
      );
    }

    items.push(item);
  }

  return items;
};

export const getAllPossibleLevels = (uomConfig, startingFormFactor) => {
  const levels = [startingFormFactor];
  const conversionMatrix = uomConfig?.currentConversionMatrix || {};

  let currentFormFactor = startingFormFactor;
  while (true) {
    const currentLevelConversions = conversionMatrix[currentFormFactor] || {};
    const nextFormFactor = Object.entries(currentLevelConversions)
      .filter(
        ([formFactor, value]) => value > 1 && formFactor !== currentFormFactor,
      )
      .sort(([, a], [, b]) => a - b)
      .map(([formFactor]) => formFactor)[0];

    if (!nextFormFactor) break;

    levels.push(nextFormFactor);
    currentFormFactor = nextFormFactor;
  }

  return levels;
};
