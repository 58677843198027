import { useState, useEffect } from "react";
import _ from "lodash";
import AutoCompleteMultiSelect from "#newUiComponents/commons/AutoCompleteMultiSelect";

const SelectedSalesChannel = ({
  customerList,
  indx,
  selectedProduct,
  salesData,
  onChange,
}) => {
  const getSelecteChannel =
    selectedProduct?.salesPrice?.[indx]?.salesChannel?.map((value) => {
      return {
        id: value,
        name:
          customerList?.find((customerObject) => customerObject?.id === value)
            ?.name || "",
      };
    }) || [];

  const [selectedValues, setSelectedValues] = useState(getSelecteChannel);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    if (!_.isEqual(selectedValues, getSelecteChannel)) {
      const currency = {};
      if (selectedValues.length > 0) {
        if (!salesData?.currency) {
          currency["currency"] = "USD-$";
        }
      }
      onChange({
        target: {
          name: indx,
          value: {
            ...selectedProduct?.salesPrice?.[indx],
            salesChannel: [...selectedValues].map(
              (selectedObject) => selectedObject?.id,
            ),
            valueObj: {
              ...salesData,
              ...currency,
            },
          },
          type: "salesPrice",
        },
      });
    }
  }, [selectedValues]);

  return (
    <AutoCompleteMultiSelect
      options={customerList}
      selectedValues={selectedValues}
      setSelectedValues={setSelectedValues}
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      placeholderDropdown="relative w-[274px]"
      placeholderText="text-gray-900"
    />
  );
};

export default SelectedSalesChannel;
