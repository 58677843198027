import { useState, useEffect } from "react";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import AutoCompleteMultiSelect from "#newUiComponents/commons/AutoCompleteMultiSelect";
import {
  OPERATORS_ENUM,
  CONSTANT_ENUM,
} from "#newUiComponents/notificationV2/ConsumerNotifications";
import { SEARCH_PRODUCTS, GET_PRODUCTS } from "#queries";
import { useQuery } from "#hooks/useQuery";
import { TrashIcon } from "@heroicons/react/outline";
import { twMerge } from "tailwind-merge";

export const criteriasList = [
  { name: "AND", value: "AND" },
  { name: "OR", value: "OR" },
];

export const ConsumerNotificationConditions = ({
  index,
  condition,
  handleChange,
  handleDeleteConditions,
  disabled,
  fieldsList,
  rowIndex,
  id,
  customerList,
  productSources,
  clearAllConditions,
  setClearAllConditions,
}) => {
  const [valueOptions, setValueOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);
  const [operatorsList, setOperatorsList] = useState([]);
  const searchProductsQuery = useQuery(SEARCH_PRODUCTS);
  const productsQuery = useQuery(GET_PRODUCTS);
  useEffect(() => {
    if (fieldsList && fieldsList?.length !== 0 && id) {
      onChangeKeyFieldsHandler(condition?.field);
      if (condition?.field === CONSTANT_ENUM?.productId) {
        const OrderSKUIDs = condition?.value?.length
          ? condition.value.map((item) => item.id)
          : null;
        if (OrderSKUIDs) {
          (async () => {
            const orderSKUSelectedOptions = OrderSKUIDs
              ? await getProductsByIDS(OrderSKUIDs)
              : [];
            setSelectedValues(orderSKUSelectedOptions);
            setValueOptions(orderSKUSelectedOptions);
            handleChange(
              {
                target: {
                  name: "value",
                  value: orderSKUSelectedOptions,
                  type: CONSTANT_ENUM?.condition,
                },
              },
              index,
            );
          })();
        }
      } else {
        if (Array.isArray(condition?.value)) {
          if (condition?.field === CONSTANT_ENUM?.customer) {
            const selectedValues = condition?.value?.map((client) => {
              const matchedClient = customerList?.find(
                (matchClient) =>
                  matchClient?.uniqueIdentifier === client?.uniqueIdentifier,
              );
              return {
                ...client,
                name: matchedClient ? matchedClient?.name : client?.name,
              };
            });
            setSelectedValues(selectedValues);
          } else {
            setSelectedValues(condition?.value);
          }
        }
      }
    }
  }, [fieldsList, id]);

  useEffect(() => {
    if (fieldsList && fieldsList.length !== 0) {
      if (selectedValues && selectedValues?.length !== 0) {
        handleChange(
          {
            target: {
              name: "value",
              value: selectedValues,
              type: CONSTANT_ENUM?.condition,
            },
          },
          index,
        );
      } else {
        handleChange(
          {
            target: {
              name: "value",
              value: null,
              type: CONSTANT_ENUM?.condition,
            },
          },
          index,
        );
      }
    }
  }, [selectedValues]);

  useEffect(() => {
    if (clearAllConditions) {
      setSelectedValues([]);
      setClearAllConditions(false);
    }
  }, [clearAllConditions]);

  const onChangeKeyFieldsHandler = (fieldValue) => {
    const operatorsList =
      fieldsList?.find((entity) => entity.key === fieldValue)?.operator || [];

    setOperatorsList(
      operatorsList.map((entity) => ({
        name: OPERATORS_ENUM[entity]?.label,
        value: entity,
      })),
    );

    switch (fieldValue) {
      case CONSTANT_ENUM?.customer:
        setValueOptions([...customerList]);
        break;
      case CONSTANT_ENUM?.productId:
        setValueOptions([]);
        break;
      case CONSTANT_ENUM?.source:
        setValueOptions([...productSources]);
        break;
      default:
        break;
    }
  };

  const fetchEnhancedSearchResults = (keyword) => {
    const payload = {
      keyword,
      field: "sku",
      perPage: 20,
      filters: {
        customers: [],
        sources: [],
        typeOfProduct: "",
      },
    };
    return searchProductsQuery.fetchData(payload);
  };

  const transformData = (response) => {
    const entities = response?.data?.searchProducts?.entities;

    if (!Array.isArray(entities)) return [];

    const responseData = entities.map(({ productData: { sku, id } = {} }) => ({
      id,
      name: sku,
      uniqueIdentifier: sku,
    }));

    const selectedDropDownValue = selectedValues?.length ? selectedValues : [];
    const selectedIds = new Set(selectedDropDownValue.map((item) => item.id));

    const filteredResponseData = responseData.filter(
      ({ id }) => !selectedIds.has(id),
    );

    setValueOptions([...selectedDropDownValue, ...filteredResponseData]);

    return [];
  };

  const getProductsByIDS = async (ids) => {
    if (!ids) return [];

    try {
      const productResponse = await productsQuery.fetchData({
        filters: { id: ids },
      });
      const products = productResponse?.data?.products?.entities || [];

      return products.map(({ sku, id }) => ({
        name: sku,
        id,
        uniqueIdentifier: sku,
      }));
    } catch (error) {
      return [];
    }
  };

  return (
    <div className="flex w-full gap-4 py-2" key={"condition" + index}>
      <div className="w-1/4 space-y-1">
        <AutocompleteDropdownV2
          options={criteriasList}
          labelKey="name"
          valueKey="value"
          onChange={(criteria) => {
            if (criteria) {
              handleChange(
                {
                  target: {
                    name: "criteria",
                    value: criteria || null,
                    type: CONSTANT_ENUM?.condition,
                  },
                },
                index,
              );
            }
          }}
          value={condition?.criteria || ""}
          placeholder="Criteria"
          id={`criteriaID${index}`}
          disabled={rowIndex === 0}
        />
      </div>
      <div className="w-1/4 space-y-1">
        <div className="flex items-center justify-between space-x-3">
          <span className="">When</span>
          <AutocompleteDropdownV2
            options={fieldsList}
            labelKey="name"
            valueKey="key"
            onChange={(fieldValue) => {
              if (fieldValue) {
                onChangeKeyFieldsHandler(fieldValue);
                if (condition?.fieldType === CONSTANT_ENUM?.dropdown) {
                  setSelectedValues([]);
                }
                handleChange(
                  {
                    target: {
                      name: "field",
                      value: fieldValue || null,
                      type: CONSTANT_ENUM?.condition,
                    },
                  },
                  index,
                );
                handleChange(
                  {
                    target: {
                      name: "value",
                      value: "",
                      type: CONSTANT_ENUM?.condition,
                    },
                  },
                  index,
                );
              }
            }}
            value={condition?.field || ""}
            placeholder="Select Key"
            id={`fieldID${index}`}
          />
        </div>
      </div>
      <div className="w-1/4 space-y-1">
        <div className="flex items-center justify-between space-x-3">
          <span className="">Is</span>
          <AutocompleteDropdownV2
            options={operatorsList}
            labelKey="name"
            valueKey="value"
            onChange={(operator) => {
              if (operator) {
                handleChange(
                  {
                    target: {
                      name: "operator",
                      value: operator || null,
                      type: CONSTANT_ENUM?.condition,
                    },
                  },
                  index,
                );
              }
            }}
            value={condition?.operator || ""}
            placeholder="Operator"
            id={`operatorID${index}`}
          />
        </div>
      </div>
      <div className="w-1/4 space-y-1">
        <div className="flex items-center justify-between space-x-3">
          {condition?.fieldType === CONSTANT_ENUM?.dropdown ? (
            <AutoCompleteMultiSelect
              label=""
              dropdownOpen={dropdownOpen}
              setDropdownOpen={setDropdownOpen}
              options={valueOptions}
              selectedValues={condition?.value || []}
              setSelectedValues={setSelectedValues}
              placeholder="Select Value"
              optionClasses="w-full"
              multiSelect="count"
              maxTagCount={2}
              placeholderDropdown="comboDropdown undefined"
              placeholderBackground="bg-white"
              placeholderText="text-gray-700 text-base"
              fetchOptions={
                condition?.field === CONSTANT_ENUM?.productId
                  ? fetchEnhancedSearchResults
                  : null
              }
              transformData={
                condition?.field === CONSTANT_ENUM?.productId
                  ? transformData
                  : null
              }
              noOptionsFoundText={
                condition?.field === CONSTANT_ENUM?.productId
                  ? "Search For options"
                  : "No options found"
              }
            />
          ) : condition?.fieldType === CONSTANT_ENUM?.text ? (
            <input
              type="text"
              name="valueInput"
              id={`valueID${index}`}
              autoComplete="off"
              className="block w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-[#224E73] focus:outline-none focus:ring-[#224E73] sm:text-sm"
              value={condition?.value || ""}
              onChange={(e) => {
                handleChange(
                  {
                    target: {
                      name: "value",
                      value: e.target.value || null,
                      type: CONSTANT_ENUM?.condition,
                    },
                  },
                  index,
                );
              }}
              placeholder="Enter value"
            />
          ) : null}
          <span
            onClick={() => handleDeleteConditions(index)}
            className={twMerge(
              `${disabled && index === 0 ? "pointer-events-none cursor-not-allowed" : "cursor-pointer"}`,
            )}>
            <div className="rounded-lg bg-red-50 p-2">
              <TrashIcon
                className={twMerge(
                  `h-5 w-5 ${disabled && index === 0 ? "text-gray-400" : "bg-red-50 text-red-500"}`,
                )}
              />
            </div>
          </span>
        </div>
      </div>
    </div>
  );
};

export const ConsumerNotificationGroupConditions = ({
  groupIndex,
  condition,
  handleChange,
  handleDeleteGroupConditions,
  handleAddGroupConditions,
  handleDeleteConditions,
  disabled,
  fieldsList,
  rowIndex,
  id,
  customerList,
  productSources,
  clearAllConditions,
  setClearAllConditions,
}) => {
  return (
    <div
      className="mb-3 flex w-full flex-col"
      key={"Groupcondition" + groupIndex}>
      <div className="absolute flex w-full flex-row items-center justify-between">
        <div
          className={twMerge(`ml-4 w-56 ${rowIndex === 0 ? "bg-white" : ""}`)}>
          <AutocompleteDropdownV2
            options={criteriasList}
            labelKey="name"
            valueKey="value"
            onChange={(criteria) => {
              if (criteria) {
                handleChange(
                  {
                    target: {
                      name: "criteria",
                      value: criteria || null,
                      type: CONSTANT_ENUM?.condition,
                    },
                  },
                  groupIndex,
                );
              }
            }}
            value={condition?.criteria || ""}
            placeholder="Criteria"
            id={`criteriaIDG${groupIndex}`}
            disabled={rowIndex === 0}
          />
        </div>
        <div
          className={twMerge(
            `mr-12 w-2 ${disabled ? "pointer-events-none cursor-not-allowed" : "cursor-pointer"}`,
          )}
          style={{ marginRight: "3rem", marginBottom: "0.5rem" }}>
          <span
            onClick={() => handleDeleteConditions(groupIndex)}
            className="cursor-pointer">
            <div className="rounded-lg bg-red-50 p-2">
              <TrashIcon
                className={twMerge(
                  `h-5 w-5 ${disabled ? "text-gray-400" : "bg-red-50 text-red-500"}`,
                )}
              />
            </div>
          </span>
        </div>
      </div>
      <div
        className="mt-5 flex w-full flex-col items-center rounded-lg border border-gray-300 px-3 py-5"
        key={"GroupconditionSub" + groupIndex}>
        {condition &&
          condition?.conditions &&
          condition?.conditions.map((groupCondition, index) => (
            <ConsumerNotificationConditions
              index={index}
              condition={groupCondition}
              handleChange={(event, index) =>
                handleChange(event, index, groupIndex)
              }
              handleDeleteConditions={(index) =>
                handleDeleteGroupConditions(groupIndex, index)
              }
              disabled={disabled}
              fieldsList={fieldsList}
              rowIndex={rowIndex === 0 ? null : rowIndex}
              id={id}
              customerList={customerList}
              productSources={productSources}
              clearAllConditions={clearAllConditions}
              setClearAllConditions={setClearAllConditions}
            />
          ))}
        <div className="mt-1 flex w-full items-center justify-start">
          <div className="space-x-4">
            <button
              className="p-0 text-sm font-semibold text-primaryAccent underline"
              onClick={() => handleAddGroupConditions(groupIndex)}>
              + Add Group Condition
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
