import { useState } from "react";
import withProductsLogic from "#components/HOC/withProductsLogic";
import PageTitle from "#components/utils/PageTitle";
import AddButton from "#components/utils/AddButton";
import { InformationCircleIcon, UserAddIcon } from "@heroicons/react/outline";
import ProductsList from "#components/products/ProductsList";
import ExpandedSku from "#components/inventory/ExpandedSku";
import Pagination from "#components/common/Pagination";
import ParentProductForm from "#components/products/ParentProductForm";
import ProductForm from "#components/products/ProductForm";
import BundleAsProductForm from "#components/products/BundleAsProductForm";
import ProductFilters from "#components/products/ProductFilters";
import UploadBulk from "../components/bulkUpload/UploadBulk";
import CustomEntityForm from "#components/common/CustomEntityForm";
import Modal from "#components/utils/Modal";
import Accordian from "#components/utils/Accordian";
import { QuestionMarkCircleIcon } from "@heroicons/react/solid";
import { Tooltip } from "antd";
import FnSkuLabelOptions from "#components/orders/FnSkuLabelOptions";
import Popover from "#components/utils/Popover";
import { Disclosure, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import BulkUploadSlideOverModal from "#newUiComponents/bulkUploadV2/BulkUploadSlideOverModal";
import { BULK_UPLOAD_ENTITY_TYPES } from "#newUiComponents/bulkUploadV2/useReducer";
import ExportBanner, {
  BANNER_TYPE_ENUM,
} from "#newUiComponents/commons/ExportBanner";
import { useFeatureFlags } from "#contexts/featureFlags";
import CustomMultiSelect from "#newUiComponents/commons/CustomMultiSelect";
import AutoCompleteSingleSelect from "#newUiComponents/commons/AutoCompleteSingleSelect";
import {
  PREP_CATEGORY_OPTIONS,
  PREP_TYPE_OPTIONS,
} from "#constants/productConstants";

const Products = ({
  displayProducts,
  fetchedSku,
  setFetchedSku,
  saveProduct,
  getSku,
  total,
  pageNumber,
  productSizes,
  productShapes,
  productColors,
  productCategories,
  productsTypes,
  AttributeValues,
  deleteAttributes,
  perPage,
  setPerPage,
  onSubmitCustomEntity,
  checkPagination,
  sort,
  setSort,
  filters,
  onChangeSearchKeyword,
  submitFilters,
  clearKeyword,
  showFilters,
  setShowFilters,
  clearFilters,
  getSpecificProduct,
  addCustomEntity,
  setAddCustomEntity,
  customers,
  warehouses,
  onChangeFilter,
  onChangeAttributes,
  addNewProduct,
  selectedProduct,
  setSelectedProduct,
  allVariantComponents,
  setAllVariantComponents,
  getProductVariantComponents,
  onChange,
  onChangeDropdown,
  writable,
  deleteProduct,
  productDashboardFields,
  saveBulkUpload,
  errorMessage,
  successMessage,
  validate,
  validationResult,
  filterQueryOperator,
  setFilterQueryOperator,
  deleteImage,
  showProductForm,
  setShowProductForm,
  showBundleAsProductForm,
  setShowBundleAsProductForm,
  addBundleProduct,
  removeBundleProduct,
  availableInventory,
  onChangeInventorySearch,
  getMarketplaceProducts,
  customAttributes,
  setCustomAttributes,
  multiAccountSupportEnabled,
  getMarketplaces,
  sellerIds,
  fnSkuOptions,
  setFnSkuOptions,
  submitFnSkuOptions,
  downloadFnSkuLabels,
  printFnSkuLabels,
  isPrintLabel,
  createProductIdentifiers,
  productHasInventory,
  addNewCategory,
  setAddNewCategory,
  submitCategoryForm,
  tenant,
  convertToBundle,
  fetchedSkuAvailable,
  setFetchedSkuAvailable,
  forDownloadingLabel,
  setForDownloadingLabel,
  newParentProduct,
  setNewParentProduct,
  parentProducts,
  selectedParentProduct,
  setSelectedParentProduct,
  getParentSku,
  getParentSkuForEdit,
  deleteParentProduct,
  actionTypes,
  handleManageActionClick,
  showBulkUpload,
  setShowBulkUpload,
  selectedActionType,
  setSelectedActionType,
  pastUploadsList,
  showBanner,
  typeOfBanner,
  bulkuploadFileName,
  closeBanner,
  productDashboardFieldsV2,
  bulkUploadDuplicateValidation,
  bulkUploadDuplicateRowValidation,
  searchFilters,
  fetchEnhancedSearchResults,
  searchProductsLoading,
  searchResults,
  setSearchResults,
  currentPage,
  setCurrentPage,
  customerList,
  showPrepInstructionsModal,
  setShowPrepInstructionsModal,
  updateProductPrepInstructions,
}) => {
  const { warehouseBulkUpload: bulkUploadV2Enabled } = useFeatureFlags();
  const [openManageActionsOptions, setOpenManageActionsOptions] = useState(0);

  const isBrand = tenant?.typeOfCustomer?.includes("Brand");

  const [showNewAdditionModal, setShowNewAdditionModal] = useState(false);
  const newAdditionModalDetails = [
    {
      title: "Product",
      isActive: true,
      single: {
        text: "Create Single Product",
        onClick: () =>
          addNewProduct({ typeOfProduct: "STANDALONE", active: true }),
      },
      bulk: {
        dashboardFields: { ...productDashboardFields },
        saveBulkUpload: (rows) => saveBulkUpload(rows),
        errorMessage: errorMessage,
        successMessage: successMessage,
        validate: (rows) => validate(rows),
        validationResult: validationResult,
        uploadBttnText: "Bulk Upload Product Variants",
        templateLink:
          "https://templates-onboarding.s3.amazonaws.com/prod/Products.xlsx",
      },
    },
    {
      title: "Bundle / Kit",
      single: {
        text: "Create Single Bundle",
        onClick: () => addNewProduct({ typeOfProduct: "BUNDLE", active: true }),
      },
    },
  ];

  if (isBrand) {
    newAdditionModalDetails.push({
      title: "Parent Product",
      isActive: false,
      single: {
        text: "Create Parent Product",
        onClick: () =>
          setNewParentProduct({ active: true, source: "Hopstack" }),
      },
    });
  }

  const ProductManagementActions = () => {
    return (
      <>
        {writable && (
          <div className="flex items-center justify-end space-x-2">
            <AddButton
              disabled={false}
              text={"Add New Product / Bundle / Kit"}
              onClick={() => setShowNewAdditionModal(true)}
              icon={UserAddIcon}
            />

            {filters &&
              filters["customer"] &&
              filters["customer"].length === 1 && (
                <AddButton
                  disabled={false}
                  text="Sync Products"
                  onClick={getMarketplaceProducts}
                />
              )}
          </div>
        )}
      </>
    );
  };

  return (
    <div className="w-full p-5">
      <div className="mb-4 flex items-center">
        <div className="flex-1">
          <PageTitle>Product Management</PageTitle>
        </div>

        {!bulkUploadV2Enabled && <ProductManagementActions />}
        {bulkUploadV2Enabled && (
          <div className="mb-4 flex items-center justify-end space-x-2">
            {actionTypes && (
              <Popover
                title={"Manage Actions"}
                showChevron={true}
                disabled={!writable}
                panelClassName="mt-2 bg-bgWhite z-10 
                overflow-auto rounded-lg p-1 
                border border-borderGray w-[18rem]"
                showOverlay={true}>
                <div className="divide-y rounded-xl">
                  {actionTypes.map((action, index) => {
                    return (
                      <div className="space-y-4 p-1" key={action.name}>
                        <Disclosure
                          as="div"
                          key={index}
                          defaultOpen={openManageActionsOptions === index}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                onClick={() =>
                                  setOpenManageActionsOptions(
                                    openManageActionsOptions === index
                                      ? null
                                      : index,
                                  )
                                }
                                className="flex w-full justify-between px-4 py-2 text-left font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                                <span>{action.name}</span>
                                <ChevronDownIcon
                                  className={`${
                                    openManageActionsOptions === index
                                      ? "rotate-180 transform"
                                      : ""
                                  } h-5 w-5`}
                                />
                              </Disclosure.Button>
                              {openManageActionsOptions === index && (
                                <ul className="px-4">
                                  {action?.options &&
                                    action?.options?.length !== 0 &&
                                    action?.options?.map((option) => {
                                      return (
                                        <li
                                          key={option?.name}
                                          className="cursor-pointer rounded p-2 font-medium text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                                          onClick={() =>
                                            handleManageActionClick(
                                              option?.value,
                                              action?.value,
                                            )
                                          }>
                                          {option?.name}
                                        </li>
                                      );
                                    })}
                                </ul>
                              )}
                            </>
                          )}
                        </Disclosure>
                      </div>
                    );
                  })}
                </div>
              </Popover>
            )}
          </div>
        )}
      </div>

      {showBanner && (
        <ExportBanner
          content={
            typeOfBanner === BANNER_TYPE_ENUM?.INITIATED ? (
              <span className="pb-2">
                Upload Scheduled! Your file {bulkuploadFileName} has been
                scheduled for upload. You will be notified when it is ready.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.COMPLETED ? (
              <span className="pb-2">
                Upload Successful! Your file {bulkuploadFileName} has been
                uploaded. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClick("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.FAILED ? (
              <span className="pb-2">
                Upload Failed! Your file {bulkuploadFileName} couldn't be
                uploaded. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClick("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : typeOfBanner === BANNER_TYPE_ENUM?.PARTIAL_COMPLETE ? (
              <span className="pb-2">
                Uploaded Partially! Your file {bulkuploadFileName} has been
                uploaded partially. You can check{" "}
                <span
                  className="cursor-pointer underline"
                  onClick={(e) => {
                    e.preventDefault();
                    handleManageActionClick("PAST_UPLOADS");
                  }}>
                  Past Uploads
                </span>{" "}
                for further information.
              </span>
            ) : null
          }
          typeOfBanner={typeOfBanner}
          onClose={closeBanner}
        />
      )}

      <ProductsList
        products={displayProducts}
        noValuesText="No Products found"
        onChangeSearchKeyword={onChangeSearchKeyword}
        submitFilters={submitFilters}
        clearKeyword={clearKeyword}
        setShowFilters={setShowFilters}
        clearFilters={clearFilters}
        sort={sort}
        setSort={setSort}
        filters={filters}
        getSku={getSku}
        fetchedSkuAvailable={fetchedSkuAvailable}
        setFetchedSkuAvailable={setFetchedSkuAvailable}
        getSpecificProduct={getSpecificProduct}
        customers={customers}
        onChangeFilter={onChangeFilter}
        writable={writable}
        deleteProduct={deleteProduct}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
        warehouses={warehouses}
        printFnSkuLabels={printFnSkuLabels}
        downloadFnSkuLabels={downloadFnSkuLabels}
        productCategories={productCategories}
        convertToBundle={convertToBundle}
        tenant={tenant}
        perPage={perPage}
        parentProducts={parentProducts}
        selectedParentProduct={selectedParentProduct}
        setSelectedParentProduct={setSelectedParentProduct}
        getParentSku={getParentSku}
        getParentSkuForEdit={getParentSkuForEdit}
        deleteParentProduct={deleteParentProduct}
        searchFilters={searchFilters}
        fetchEnhancedSearchResults={fetchEnhancedSearchResults}
        searchProductsLoading={searchProductsLoading}
        searchResults={searchResults}
        setSearchResults={setSearchResults}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        setShowPrepInstructionsModal={setShowPrepInstructionsModal}
      />

      <Pagination
        showingLhs={total > 0 ? (pageNumber - 1) * perPage + 1 : 0}
        showingRhs={Math.min((pageNumber - 1) * perPage + perPage, total)}
        showingTotal={total}
        perPage={perPage}
        setPerPage={setPerPage}
        pageNumber={pageNumber}
        checkPagination={checkPagination}
        searchEnabled={true}
      />

      {showFilters && (
        <ProductFilters
          negativeAction={() => setShowFilters(false)}
          onChangeFilter={onChangeFilter}
          filters={filters}
          onSubmit={submitFilters}
          customers={customers}
          productSize={productSizes}
          productShape={productShapes}
          productColor={productColors}
          productCategory={productCategories}
          productType={productsTypes}
          onChangeAttributes={onChangeAttributes}
          filterQueryOperator={filterQueryOperator}
          setFilterQueryOperator={setFilterQueryOperator}
        />
      )}

      {showProductForm && !showPrepInstructionsModal && (
        <ProductForm
          selectedProduct={selectedProduct}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={selectedProduct?.id ? "Edit Product" : "Add Product"}
          onClose={() => {
            setShowProductForm(false);
            setSelectedProduct(null);
          }}
          onSubmit={saveProduct}
          customers={customers}
          warehouses={warehouses}
          setSelectedProduct={setSelectedProduct}
          allVariantComponents={allVariantComponents}
          setAllVariantComponents={setAllVariantComponents}
          getProductVariantComponents={getProductVariantComponents}
          productSizes={productSizes}
          productShapes={productShapes}
          productColors={productColors}
          productCategories={productCategories}
          productsTypes={productsTypes}
          AttributeValues={AttributeValues}
          deleteAttributes={deleteAttributes}
          deleteImage={deleteImage}
          customAttributes={customAttributes}
          setCustomAttributes={setCustomAttributes}
          multiAccountSupportEnabled={multiAccountSupportEnabled}
          getMarketplaces={getMarketplaces}
          sellerIds={sellerIds}
          productHasInventory={productHasInventory}
          addNewCategory={addNewCategory}
          setAddNewCategory={setAddNewCategory}
          submitCategoryForm={submitCategoryForm}
          setAddCustomEntity={setAddCustomEntity}
          addCustomEntity={addCustomEntity}
          customerList={customerList}
        />
      )}

      {showBundleAsProductForm && (
        <BundleAsProductForm
          selectedProduct={selectedProduct}
          onChange={onChange}
          onChangeDropdown={onChangeDropdown}
          title={selectedProduct.id ? "Edit Bundle" : "Add Bundle"}
          onClose={() => {
            setShowBundleAsProductForm(false);
            setSelectedProduct(null);
          }}
          onSubmit={saveProduct}
          customers={customers}
          warehouses={warehouses}
          setSelectedProduct={setSelectedProduct}
          productSizes={productSizes}
          productShapes={productShapes}
          productColors={productColors}
          productCategories={productCategories}
          productsTypes={productsTypes}
          deleteImage={deleteImage}
          addBundleProduct={addBundleProduct}
          removeBundleProduct={removeBundleProduct}
          availableInventory={availableInventory}
          onChangeInventorySearch={onChangeInventorySearch}
          getMarketplaceProducts={getMarketplaceProducts}
          customAttributes={customAttributes}
          setCustomAttributes={setCustomAttributes}
          productHasInventory={productHasInventory}
          tenant={tenant}
        />
      )}

      {addCustomEntity && (
        <CustomEntityForm
          addCustomEntity={addCustomEntity}
          setAddCustomEntity={setAddCustomEntity}
          onSubmit={onSubmitCustomEntity}
          showCode={false}
        />
      )}

      {fetchedSkuAvailable && !forDownloadingLabel && (
        <ExpandedSku
          fetchedSku={fetchedSku}
          negativeAction={() => {
            setFetchedSku(null);
            setFetchedSkuAvailable(false);
          }}
          customers={customers}
        />
      )}

      {fnSkuOptions && isPrintLabel && fetchedSku && (
        <FnSkuLabelOptions
          fnSkuLabelOptions={fnSkuOptions}
          setFnSkuLabelOptions={setFnSkuOptions}
          setForDownloadingLabel={setForDownloadingLabel}
          setFetchedSkuAvailable={setFetchedSkuAvailable}
          positiveAction={submitFnSkuOptions}
          isSelectLabel={true}
          productIdentifiers={createProductIdentifiers(fetchedSku)}
        />
      )}

      {showNewAdditionModal && (
        <Modal
          title={`Add New Product / Bundle / Kit`}
          negativeAction={() => setShowNewAdditionModal(false)}
          minWidth={"450px"}>
          {newAdditionModalDetails.map((details) => (
            <Accordian title={details.title} isActive={details.isActive}>
              <div className="flex flex-col items-center">
                <AddButton
                  text={details.single.text}
                  onClick={details.single.onClick}
                  styles={["w-1/2", "mb-2"]}
                />
                {details.bulk && (
                  <div className="flex w-full justify-center">
                    <UploadBulk
                      dashboardFields={details.bulk.dashboardFields}
                      saveBulkUpload={details.bulk.saveBulkUpload}
                      errorMessage={details.bulk.errorMessage}
                      successMessage={details.bulk.successMessage}
                      validate={details.bulk.validate}
                      validationResult={details.bulk.validationResult}
                      uploadBttnText={details.bulk.uploadBttnText}
                      uploadBttnStyles={["ml-10", "w-1/2"]}
                    />
                    <Tooltip
                      title={
                        <span>
                          Click{" "}
                          <a
                            href={details.bulk.templateLink}
                            style={{ textDecoration: "underline" }}>
                            here
                          </a>{" "}
                          to download template
                        </span>
                      }>
                      <QuestionMarkCircleIcon className="ml-2 h-8 w-8" />
                    </Tooltip>
                  </div>
                )}
              </div>
            </Accordian>
          ))}
        </Modal>
      )}
      {newParentProduct && (
        <ParentProductForm
          selectedProduct={newParentProduct}
          setSelectedProduct={setNewParentProduct}
          onClose={() => setNewParentProduct(null)}
          setAddCustomEntity={setAddCustomEntity}
          addCustomEntity={addCustomEntity}
          customers={customers}
          onChangeDropdown={onChangeDropdown}
        />
      )}

      {showBulkUpload && (
        <BulkUploadSlideOverModal
          dashboardFields={productDashboardFieldsV2}
          showBulkUpload={showBulkUpload}
          setShowBulkUpload={setShowBulkUpload}
          selectedActionType={selectedActionType}
          setSelectedActionType={setSelectedActionType}
          pastUploadsList={pastUploadsList}
          bulkUploadDuplicateValidation={bulkUploadDuplicateValidation}
          bulkUploadDuplicateRowValidation={bulkUploadDuplicateRowValidation}
        />
      )}

      {showPrepInstructionsModal && selectedProduct && (
        <Modal
          title={`Prep Instructions: ${selectedProduct?.sku}`}
          positiveAction={() => {
            updateProductPrepInstructions(selectedProduct);
            setShowProductForm(false);
          }}
          negativeAction={() => {
            setSelectedProduct(null);
            setShowProductForm(false);
            setShowPrepInstructionsModal(false);
          }}
          minWidth={"750px"}>
          <div className="flex flex-col gap-4">
            <AutoCompleteSingleSelect
              labelText={`Prep Category`}
              options={PREP_CATEGORY_OPTIONS}
              value={selectedProduct?.prepInstructions?.[0]?.PrepCategory}
              onChange={(newValue) =>
                newValue &&
                newValue !== "UNKNOWN" &&
                setSelectedProduct((prev) => ({
                  ...prev,
                  prepInstructions: [
                    {
                      ...prev.prepInstructions?.[0],
                      PrepCategory: newValue,
                    },
                  ],
                }))
              }
              isOptionDisabled={(option) => option.value === "UNKNOWN"}
              required={true}
              labelKey="label"
              valueKey="value"
              placeholder="Select Category"
              labelClasses="text-base text-gray-500"
              CustomOptionRender={({ option }) => (
                <div
                  className={`flex items-center gap-2 ${option.value === "UNKNOWN" ? "cursor-not-allowed opacity-50" : ""}`}>
                  <span>{option.label}</span>
                  <Tooltip title={option.description}>
                    <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700" />
                  </Tooltip>
                </div>
              )}
            />
            <div>
              <div className="mb-2 flex items-center">
                <div className="text-base text-gray-500">Prep Types</div>
              </div>
              <CustomMultiSelect
                options={PREP_TYPE_OPTIONS}
                selectedValues={
                  selectedProduct?.prepInstructions?.[0]?.PrepTypes || []
                }
                onChange={(newValues) => {
                  if (newValues.length >= 1) {
                    setSelectedProduct((prev) => ({
                      ...prev,
                      prepInstructions: [
                        {
                          ...prev.prepInstructions?.[0],
                          PrepTypes: newValues.map((val) => val.id),
                        },
                      ],
                    }));
                  }
                }}
                required={true}
                CustomOptionRender={({ option }) => (
                  <div className="flex items-center gap-2">
                    <span>{option.name}</span>
                    <Tooltip title={option.description}>
                      <InformationCircleIcon className="h-5 w-5 cursor-pointer text-gray-500 hover:text-gray-700" />
                    </Tooltip>
                  </div>
                )}
                placeholder="Select Types"
              />
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default withProductsLogic(Products);
