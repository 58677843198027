import React, { useState, useMemo } from "react";
import NewModal from "#newUiComponents/commons/NewModal";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/outline";

const AskMorpheus = ({
  isOpen,
  onClose,
  data,
  isShowIds,
  createManualBatch,
}) => {
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [expandedGroups, setExpandedGroups] = useState([]);

  const groupsData = useMemo(() => {
    if (!data?.data) return [];
    return Object.entries(data.data).map(([key, value]) => ({
      name: key,
      orders: value,
      count: value.length,
    }));
  }, [data]);

  const handleSelectAll = () => {
    if (selectedGroups.length === groupsData.length) {
      setSelectedGroups([]); // Deselect all
    } else {
      setSelectedGroups(groupsData.map((group) => group.name)); // Select all
    }
  };

  const toggleGroup = (groupName) => {
    if (!isShowIds) return;
    setExpandedGroups((prev) =>
      prev.includes(groupName)
        ? prev.filter((name) => name !== groupName)
        : [...prev, groupName],
    );
  };

  const toggleGroupSelection = (groupName) => {
    setSelectedGroups((prev) =>
      prev.includes(groupName)
        ? prev.filter((name) => name !== groupName)
        : [...prev, groupName],
    );
  };

  const handleProceed = () => {
    const selectedOrderIds = new Set();
    selectedGroups.forEach((groupName) => {
      const group = data.data[groupName];
      group.forEach((orderId) => selectedOrderIds.add(orderId));
    });

    createManualBatch(Array.from(selectedOrderIds));
    setSelectedGroups([]);
    onClose();
  };

  const totals = useMemo(() => {
    const totalGroups = groupsData.length;
    let totalOrderSet = new Set();
    groupsData.forEach((group) => {
      group.orders.forEach((orderId) => totalOrderSet.add(orderId));
    });
    return { totalGroups, totalOrders: Array.from(totalOrderSet).length };
  }, [groupsData]);

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title="Select Order Groups for Batching"
      maxWidth="740px">
      <div className="max-h-[60vh] overflow-y-auto p-4 font-inter">
        {/* Select All Checkbox */}
        <div className="mb-4 flex items-center gap-4 border-b border-gray-200 pb-4">
          <input
            type="checkbox"
            checked={
              selectedGroups.length === groupsData.length &&
              groupsData.length > 0
            }
            onChange={handleSelectAll}
            className="h-4 w-4 cursor-pointer rounded border-gray-300 text-primaryAccent ring-0 checked:bg-primaryAccent"
          />
          <span className="font-medium">
            Select All Groups{" "}
            <span className="rounded-full bg-gray-200 px-2 py-1 text-sm">
              {totals.totalGroups} groups
            </span>
            <span className="ml-2 rounded-full bg-gray-200 px-2 py-1 text-sm">
              {totals.totalOrders} total orders
            </span>
          </span>
        </div>

        {/* Group List */}
        {groupsData.map(({ name, orders, count }) => (
          <div key={name} className="mb-4 rounded-lg border border-gray-200">
            <div className="flex items-center justify-between bg-gray-50 p-4">
              <div className="flex items-center gap-4">
                <input
                  type="checkbox"
                  checked={selectedGroups.includes(name)}
                  onChange={() => toggleGroupSelection(name)}
                  className="h-4 w-4 cursor-pointer rounded border-gray-300 text-primaryAccent ring-0 checked:bg-primaryAccent"
                />
                <div
                  className={`flex items-center gap-2 ${isShowIds ? "cursor-pointer" : ""}`}
                  onClick={() => toggleGroup(name)}>
                  {isShowIds &&
                    (expandedGroups.includes(name) ? (
                      <ChevronDownIcon className="h-5 w-5 text-gray-500" />
                    ) : (
                      <ChevronRightIcon className="h-5 w-5 text-gray-500" />
                    ))}
                  <span className="font-medium">
                    {name}{" "}
                    <span className="text-wrap rounded-full bg-gray-200 px-2 py-1 text-sm">
                      {count} orders
                    </span>
                  </span>
                </div>
              </div>
            </div>
            {isShowIds && expandedGroups.includes(name) && (
              <div className="border-t border-gray-200 p-4">
                <div className="grid grid-cols-2 gap-4">
                  {orders.map((orderId) => (
                    <div key={orderId} className="text-sm text-gray-600">
                      {orderId}
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      <div className="flex justify-end gap-4 border-t border-gray-200 p-4">
        <PrimaryButton
          variant="secondary"
          height="3rem"
          minWidth="7rem"
          className="mt-2 text-base font-medium"
          onClick={() => {
            setSelectedGroups([]);
            onClose();
          }}>
          Cancel
        </PrimaryButton>
        <PrimaryButton
          height="3rem"
          minWidth="7rem"
          variant="primary"
          className="mt-2 text-base font-medium"
          onClick={handleProceed}
          disabled={selectedGroups.length === 0}>
          Proceed with Batching
        </PrimaryButton>
      </div>
    </NewModal>
  );
};

export default AskMorpheus;
