import { useState, useEffect, useContext } from "react";
import { GET_NOTIFICATIONS_TEMPLATES } from "#queries";
import { SAVE_CONSUMER_NOTIFICATION } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import { AppStateContext } from "#contexts/appState";
import AutocompleteDropdownV2 from "#components/utils/AutocompleteDropdownV2";
import {
  ConsumerNotificationConditions,
  ConsumerNotificationGroupConditions,
} from "#newUiComponents/notificationV2/ConsumerNotificationConditions";
import RuleViewer from "#newUiComponents/notificationV2/RuleViewer";
import {
  InformationCircleIcon,
  MailIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import { Tooltip } from "antd";
import { twMerge } from "tailwind-merge";

export const ALERT_VISIBILITY_IN_5000_MS = 5000;
export const OPERATORS_ENUM = {
  eq: { label: "Equals To", format: "equals" },
  gt: { label: "Greater Than", format: "greater_than" },
  lt: { label: "Less Than", format: "less_than" },
  gte: { label: "Greater Than Or Equal To", format: "greater_than_equals" },
  lte: { label: "Less Than Or Equal To", format: "less_than_equals" },
  nteq: { label: "Not Equal To", format: "not_equal_to" },
  in: { label: "In", format: "in" },
  notin: { label: "Not In", format: "not_in" },
};
export const CONSTANT_ENUM = {
  group: "group",
  condition: "condition",
  text: "text",
  field: "field",
  and: "AND",
  or: "OR",
  dropdown: "dropdown",
  customer: "customer",
  productId: "productId",
  source: "source",
  orderCompleted: "Order Completed",
  orderCreated: "Order Created",
  orderCancelled: "Order Cancelled",
};

const createSubjectEnum = (selectedTemplate) => {
  return {
    orderId: selectedTemplate?.orderId || 639087,
    consignmentId: selectedTemplate?.consignmentId || 639087,
  };
};

export const ConsumerNotifications = ({
  onCancel,
  selectedNotification,
  customerList,
  productSources,
  fetchNotificationsEvents,
}) => {
  const appState = useContext(AppStateContext);
  const submitConsumerNotificationsRulesQuery = useQuery(
    SAVE_CONSUMER_NOTIFICATION,
  );
  const getTemplatesListQuery = useQuery(GET_NOTIFICATIONS_TEMPLATES);
  const [notificationTypeList, setNotificationTypeList] = useState(null);
  const [templateList, setTemplateList] = useState(null);
  const [templateOptions, setTemplateOptions] = useState(null);
  const [selectedConsumer, setSelectedConsumer] = useState(null);
  const [isValidateConditions, setIsValidateConditions] = useState(true);
  const [fieldsList, setFieldsList] = useState([]);
  const [conditionsRulePreview, setConditionsRulePreview] = useState(null);
  const [clearAllConditions, setClearAllConditions] = useState(false);

  const handleAddGroupOrConditions = (type) => {
    const validateCondition = validateConditions(selectedConsumer?.conditions);
    if (validateCondition?.hasInvalidFields) {
      appState.setAlert(
        "Please enter condition details",
        "error",
        ALERT_VISIBILITY_IN_5000_MS,
      );
      return false;
    }
    if (validateCondition?.hasDuplicate) {
      appState.setAlert(
        "Duplicate key selected",
        "error",
        ALERT_VISIBILITY_IN_5000_MS,
      );
      return false;
    }
    if (type === CONSTANT_ENUM?.condition) {
      setSelectedConsumer((prev) => ({
        ...prev,
        conditions: [
          ...prev.conditions,
          {
            type: CONSTANT_ENUM?.condition,
            criteria: null,
            field: null,
            operator: null,
            value: null,
            conditions: [],
            fieldType: CONSTANT_ENUM?.text,
          },
        ],
      }));
    } else if (type === CONSTANT_ENUM?.group) {
      setSelectedConsumer((prev) => ({
        ...prev,
        conditions: [
          ...prev.conditions,
          {
            type: CONSTANT_ENUM?.group,
            criteria: null,
            conditions: [
              {
                type: CONSTANT_ENUM?.condition,
                criteria: null,
                field: null,
                operator: null,
                value: null,
                conditions: [],
                fieldType: CONSTANT_ENUM?.text,
              },
            ],
          },
        ],
      }));
    }
  };

  const handleAddGroupConditions = (index) => {
    const validatGroupConditions = [...selectedConsumer?.conditions];
    const validateCondition = validateConditions(
      validatGroupConditions[index] &&
        validatGroupConditions[index]["conditions"]
        ? validatGroupConditions[index]["conditions"]
        : [],
    );
    if (validateCondition?.hasInvalidFields) {
      appState.setAlert(
        "Please enter condition details",
        "error",
        ALERT_VISIBILITY_IN_5000_MS,
      );
      return false;
    }
    if (validateCondition?.hasDuplicate) {
      appState.setAlert(
        "Duplicate key selected",
        "error",
        ALERT_VISIBILITY_IN_5000_MS,
      );
      return false;
    }
    if (!validatGroupConditions[index]["criteria"]) {
      appState.setAlert(
        "Please enter condition values",
        "error",
        ALERT_VISIBILITY_IN_5000_MS,
      );
      return false;
    }
    setSelectedConsumer((prev) => {
      const updatedConditions = [...prev.conditions];
      const targetGroup = updatedConditions[index];
      if (targetGroup && targetGroup.type === CONSTANT_ENUM?.group) {
        targetGroup.conditions.push({
          type: CONSTANT_ENUM?.condition,
          criteria: null,
          field: null,
          operator: null,
          value: null,
          conditions: [],
          fieldType: CONSTANT_ENUM?.text,
        });
      }
      return {
        ...prev,
        conditions: updatedConditions,
      };
    });
  };

  const handleDeleteConditions = (indexToDelete) => {
    setSelectedConsumer((prev) => {
      let updatedConditions = [...prev.conditions];
      updatedConditions.splice(indexToDelete, 1);
      updatedConditions = updatedConditions.map((item, index) =>
        index === 0 ? { ...item, criteria: CONSTANT_ENUM?.and } : item,
      );
      return {
        ...prev,
        conditions: updatedConditions,
      };
    });
  };
  const handleDeleteGroupConditions = (groupIndex, index) => {
    setSelectedConsumer((prev) => {
      const updatedConditions = [...prev.conditions];
      const targetGroup = updatedConditions[groupIndex];
      if (targetGroup && targetGroup.type === CONSTANT_ENUM?.group) {
        const targetGroupConditions = [...targetGroup.conditions];
        targetGroupConditions.splice(index, 1);
        targetGroup.conditions = targetGroupConditions;
        if (targetGroupConditions?.length === 0) {
          updatedConditions.splice(groupIndex, 1);
        }
      }
      return {
        ...prev,
        conditions: updatedConditions,
      };
    });
  };

  const resetConditions = () => {
    setSelectedConsumer((prev) => ({
      ...prev,
      conditions: [
        {
          type: CONSTANT_ENUM?.condition,
          criteria: CONSTANT_ENUM?.and,
          field: null,
          operator: null,
          value: null,
          conditions: [],
          fieldType: CONSTANT_ENUM?.text,
        },
      ],
    }));
    setClearAllConditions(true);
  };

  const handleChange = (event, conditionIndex = null, groupIndex = null) => {
    const { name, value } = event.target;

    let updatedFieldType = null;
    if (name === CONSTANT_ENUM?.field) {
      updatedFieldType =
        fieldsList && fieldsList?.length !== 0
          ? fieldsList.find((entity) => entity.key === value)?.fieldType
          : null;
    }

    setSelectedConsumer((prev) => {
      const updatedConditions = [...prev.conditions];

      if (groupIndex === null) {
        updatedConditions[conditionIndex] = {
          ...updatedConditions[conditionIndex],
          [name]: value,
          ...(name === CONSTANT_ENUM?.field &&
            updatedFieldType !== null && { fieldType: updatedFieldType }),
        };
      } else {
        const targetGroup = updatedConditions[groupIndex];
        if (targetGroup && targetGroup.type === CONSTANT_ENUM?.group) {
          const groupConditions = [...targetGroup.conditions];
          groupConditions[conditionIndex] = {
            ...groupConditions[conditionIndex],
            [name]: value,
            ...(name === CONSTANT_ENUM?.field &&
              updatedFieldType !== null && { fieldType: updatedFieldType }),
          };
          targetGroup.conditions = groupConditions;
        }
      }

      return {
        ...prev,
        conditions: updatedConditions,
      };
    });
  };

  const validateConditions = (conditions) => {
    let hasInvalidFields = false;
    let hasDuplicate = false;

    const isConditionInvalid = (condition) => {
      return (
        !condition.criteria ||
        !condition.field ||
        !condition.operator ||
        !condition.value ||
        condition.value.length === 0
      );
    };

    const collectFieldsAndValidate = (conditions, fields = new Map()) => {
      conditions.forEach((condition) => {
        if (condition.type === CONSTANT_ENUM?.condition) {
          if (isConditionInvalid(condition)) {
            hasInvalidFields = true;
          }
          if (condition.field) {
            fields.set(condition.field, (fields.get(condition.field) || 0) + 1);
            if (fields.get(condition.field) > 1) {
              hasDuplicate = true;
            }
          }
        } else if (condition.type === CONSTANT_ENUM?.group) {
          if (!condition.criteria) {
            hasInvalidFields = true;
          }
          collectFieldsAndValidate(condition.conditions, new Map());
        }
      });
    };

    if (conditions.length === 1) {
      const firstCondition = conditions[0];

      if (firstCondition.type === CONSTANT_ENUM?.condition) {
        const hasAnyFieldFilled =
          firstCondition.field ||
          firstCondition.operator ||
          (firstCondition.value && firstCondition.value.length > 0);

        if (hasAnyFieldFilled && isConditionInvalid(firstCondition)) {
          hasInvalidFields = true;
        }

        return { hasInvalidFields, hasDuplicate };
      }

      if (
        firstCondition.type === CONSTANT_ENUM?.group &&
        firstCondition.conditions?.length <= 1
      ) {
        const nestedCondition = firstCondition.conditions[0];

        const hasAnyFieldFilled =
          nestedCondition.criteria ||
          nestedCondition.field ||
          nestedCondition.operator ||
          (nestedCondition.value && nestedCondition.value.length > 0);

        if (hasAnyFieldFilled && isConditionInvalid(nestedCondition)) {
          hasInvalidFields = true;
        }

        return { hasInvalidFields, hasDuplicate };
      }
    }

    collectFieldsAndValidate(conditions);
    return {
      hasInvalidFields,
      hasDuplicate,
    };
  };

  const handleConfirmSubmitConfirmationRequest = () => {
    const event =
      getEventType(notificationTypeList, selectedConsumer?.eventId) || null;

    const formattedConditionsInput = transformConditionsToAPIModalData(
      selectedConsumer?.conditions,
    );
    const consumerNotificationsRulesInput = {
      eventId: selectedConsumer?.eventId,
      eventType: event ? event?.key : null,
      templateId: selectedConsumer?.templateId,
      conditions: formattedConditionsInput,
      channels: selectedConsumer?.channels,
      rulePreview: conditionsRulePreview,
    };
    if (selectedConsumer?.id) {
      consumerNotificationsRulesInput["id"] = selectedConsumer?.id;
    }
    const conditions = [
      {
        field: event ? event?.name : "",
        operator: null,
        value: null,
      },
      ...selectedConsumer?.conditions,
    ];
    appState.showNewConfirmation(
      selectedConsumer?.id ? "Edit Notification" : "Create Notification",
      <div className="w-[50rem]" style={{ width: "50rem" }}>
        <span>
          {`Are you sure you want to ${selectedConsumer?.id ? "edit" : "create"} the selected notification ?`}
        </span>
        <div className="mt-4 flex w-full flex-col items-center">
          <div className="mb-2 flex w-full items-center text-gray-700">
            Email Trigger Conditions
            <Tooltip title="Email Trigger Conditions">
              <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
            </Tooltip>
          </div>
          {formattedConditionsInput &&
          formattedConditionsInput?.length !== 0 ? (
            <RuleViewer rules={conditions} eventsList={fieldsList || []} />
          ) : (
            <div className="max-h-60 w-full overflow-y-auto rounded-lg border border-gray-300 p-4">
              <div className="flex flex-wrap items-center space-x-2">
                <div className="rounded border border-green-300 bg-green-100 px-2 py-1 text-sm text-[#00ff00]">
                  {event?.name || ""}
                </div>
                <span>+</span>
                <div className="rounded border border-green-300 bg-green-100 px-2 py-1 text-sm text-[#00ff00]">
                  All Conditions
                </div>
              </div>
            </div>
          )}
        </div>
      </div>,
      () => {
        saveConsumerNotificationsRules(consumerNotificationsRulesInput);
      },
      appState.hideConfirmation,
    );
  };

  const transformConditionsToAPIModalData = (conditionsInput) => {
    const conditionsOutput = [];
    let groupId = 1;
    let groupConditions = 0;

    const processConditions = (conditions, groupGate, groupParser) => {
      conditions.forEach((condition) => {
        if (condition.type === CONSTANT_ENUM?.condition) {
          if (
            !condition.field ||
            !condition.operator ||
            !condition.value ||
            (Array.isArray(condition.value) && condition.value.length === 0)
          ) {
            return;
          }

          const formattedCondition = {
            field: condition.field,
            operator: condition.operator,
            value: Array.isArray(condition.value)
              ? condition.value.map((v) => v.id || v)
              : condition.value,
            logicGate: condition.criteria,
            groupId: groupId,
            groupGate: groupParser ? groupGate : condition?.criteria,
            groupParser: groupParser,
          };

          conditionsOutput.push(formattedCondition);
          if (groupConditions === 0) {
            groupId++;
          }
          if (groupConditions > 0) {
            groupConditions = groupConditions - 1;
            if (groupConditions === 0) {
              groupId++;
            }
          }
        } else if (condition.type === CONSTANT_ENUM?.group) {
          groupConditions = condition.conditions?.length;
          processConditions(condition.conditions, condition.criteria, true);
        }
      });
    };

    processConditions(conditionsInput, CONSTANT_ENUM?.and, false);

    return conditionsOutput;
  };

  const transformConditionsFormModalData = (conditions) => {
    const mapValues = (values) =>
      (Array.isArray(values) ? values : [values]).map((val) => ({
        name: val,
        id: val,
        uniqueIdentifier: val,
      }));

    const groupedData = {};

    conditions.forEach((item) => {
      if (item.groupParser) {
        if (!groupedData[item.groupId]) {
          groupedData[item.groupId] = {
            type: CONSTANT_ENUM.group,
            criteria: item.groupGate,
            conditions: [],
          };
        }
        groupedData[item.groupId].conditions.push({
          type: CONSTANT_ENUM.condition,
          criteria: item.logicGate,
          field: item.field,
          operator: item.operator,
          value: Array.isArray(item.value) ? mapValues(item.value) : item.value,
          fieldType: Array.isArray(item.value)
            ? CONSTANT_ENUM.dropdown
            : CONSTANT_ENUM.text,
          conditions: [],
        });
      } else {
        groupedData[item.groupId] = {
          type: CONSTANT_ENUM.condition,
          criteria: item.logicGate,
          field: item.field,
          operator: item.operator,
          value: Array.isArray(item.value) ? mapValues(item.value) : item.value,
          fieldType: Array.isArray(item.value)
            ? CONSTANT_ENUM.dropdown
            : CONSTANT_ENUM.text,
          conditions: [],
        };
      }
    });

    return Object.values(groupedData);
  };

  const handlePreviewTemplate = () => {
    if (
      selectedConsumer?.templateId &&
      templateList &&
      templateList.length !== 0
    ) {
      const selectedTemplate = templateList.find(
        (template) => template["id"] === selectedConsumer?.templateId,
      );

      if (selectedTemplate && selectedTemplate?.subject) {
        const SUBJECT_ENUM = createSubjectEnum(selectedTemplate);
        selectedTemplate["subject"] =
          selectedTemplate?.subject?.replace(/#{(\w+)}/g, (_, placeholder) => {
            return SUBJECT_ENUM[placeholder]
              ? `#${SUBJECT_ENUM[placeholder]}`
              : placeholder;
          }) || null;
      }

      if (selectedTemplate?.templateContentUrl) {
        appState.showNewConfirmation(
          "Preview Template",
          <div className="w-full space-y-6">
            {selectedTemplate?.subject && (
              <div className="flex w-full flex-col items-start justify-start">
                <div className="mb-2 flex w-full items-center font-semibold text-gray-700">
                  Subject
                  <Tooltip title="Subject">
                    <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                  </Tooltip>
                </div>
                <p className="text-sm text-gray-500">
                  {selectedTemplate?.subject}
                </p>
              </div>
            )}
            <div className="flex w-full flex-row items-center justify-center">
              <iframe
                src={selectedTemplate?.templateContentUrl + "#toolbar=0"}
                title="PDF Viewer"
                width="100%"
                height="400px"
                style={{ border: "none" }}
              />
            </div>
          </div>,
          () => {},
          appState.hideConfirmation,
          "Cancel",
          "Confirm",
          false,
        );
      }
    }
  };

  const getTemplatesList = async (eventId) => {
    if (eventId) {
      appState.setLoading();
      const getTemplatesListResponse = await getTemplatesListQuery.fetchData({
        eventId: eventId,
      });
      appState.removeLoading();
      if (getTemplatesListResponse.error) {
        setTemplateList(null);
        setTemplateOptions(null);
      } else if (getTemplatesListResponse.data) {
        if (
          getTemplatesListResponse?.data?.listTemplates?.entities &&
          getTemplatesListResponse?.data?.listTemplates?.entities?.length !== 0
        ) {
          setTemplateList(
            getTemplatesListResponse?.data?.listTemplates?.entities || [],
          );
          setTemplateOptions(
            getTemplatesListResponse?.data?.listTemplates?.entities || [],
          );
        } else {
          setTemplateList(null);
          setTemplateOptions(null);
        }
      }
    }
  };

  const saveConsumerNotificationsRules = async (
    requestNotificationRulesInput,
  ) => {
    if (requestNotificationRulesInput) {
      appState.setLoading();
      const submitConsumerNotificationRulesResponse =
        await submitConsumerNotificationsRulesQuery.fetchData({
          upsertNotificationRulesInput: requestNotificationRulesInput,
        });
      appState.removeLoading();
      if (submitConsumerNotificationRulesResponse.error) {
        if (
          submitConsumerNotificationRulesResponse?.error &&
          submitConsumerNotificationRulesResponse?.error?.message
        ) {
          appState.setAlert(
            submitConsumerNotificationRulesResponse?.error?.message,
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          return false;
        }
      } else if (submitConsumerNotificationRulesResponse.data) {
        appState.hideConfirmation();
        appState.setAlert(
          submitConsumerNotificationRulesResponse.data?.upsertNotificationRules
            .message,
          "success",
          ALERT_VISIBILITY_IN_5000_MS,
        );
        onCancel("success");
      }
    }
  };

  useEffect(() => {
    if (
      selectedConsumer?.channels &&
      templateList &&
      templateList.length != 0
    ) {
      setSelectedConsumer((prev) => ({
        ...prev,
        templateId: null,
      }));
      const templateOption = templateList.filter(
        (template) =>
          template["deliveryChannel"].toLowerCase() ===
          selectedConsumer?.channels,
      );
      setTemplateOptions(templateOption);
    }
  }, [selectedConsumer?.channels]);

  useEffect(() => {
    if (selectedConsumer && selectedConsumer?.conditions) {
      const isValidConditions = validateConditions(
        selectedConsumer?.conditions,
      );
      setIsValidateConditions(
        isValidConditions?.hasInvalidFields || isValidConditions?.hasDuplicate,
      );
      const outputRulesReviewConditions = formattedRulePreviewConditions([
        ...(selectedConsumer?.conditions || []),
      ]);
      setConditionsRulePreview(outputRulesReviewConditions);
    }
  }, [selectedConsumer]);

  useEffect(() => {
    if (selectedConsumer?.eventId) {
      fieldsConditionsHandler(selectedConsumer?.eventId, notificationTypeList);
    }
  }, [selectedConsumer?.eventId]);

  useEffect(async () => {
    try {
      appState.setLoading();
      const payload = {
        filters: {
          key: "subCategory",
          value: "Orders",
        },
        type: "events",
      };
      const getNotificationsTypesResponse =
        await fetchNotificationsEvents(payload);
      appState.removeLoading();
      if (getNotificationsTypesResponse.error) {
        setNotificationTypeList(null);
      } else if (getNotificationsTypesResponse.data) {
        if (
          getNotificationsTypesResponse?.data?.listNotificationEvents
            ?.entities &&
          getNotificationsTypesResponse?.data?.listNotificationEvents?.entities
            ?.length !== 0
        ) {
          setNotificationTypeList(
            getNotificationsTypesResponse?.data?.listNotificationEvents?.entities
              ?.map(({ key, ...rest }) => ({
                ...rest,
                name: key || null,
                key,
              }))
              ?.filter(({ key }) =>
                [
                  CONSTANT_ENUM.orderCreated,
                  CONSTANT_ENUM.orderCompleted,
                  CONSTANT_ENUM.orderCancelled,
                ].includes(key),
              ),
          );

          if (selectedNotification?.id) {
            const formattedConditionsModal = transformConditionsFormModalData(
              selectedNotification?.conditions,
            );
            setSelectedConsumer((prev) => ({
              ...prev,
              ...selectedNotification,
              conditions:
                formattedConditionsModal &&
                formattedConditionsModal?.length !== 0
                  ? formattedConditionsModal
                  : [
                      {
                        type: CONSTANT_ENUM?.condition,
                        criteria: CONSTANT_ENUM?.and,
                        field: null,
                        operator: null,
                        value: null,
                        conditions: [],
                        fieldType: CONSTANT_ENUM?.text,
                      },
                    ],
            }));

            if (selectedNotification?.eventId) {
              getTemplatesList(selectedNotification?.eventId);
              fieldsConditionsHandler(
                selectedNotification?.eventId,
                getNotificationsTypesResponse?.data?.listNotificationEvents
                  ?.entities,
              );
            }
          } else {
            setSelectedConsumer((prev) => ({
              ...prev,
              channels: "email",
              conditions: [
                {
                  type: CONSTANT_ENUM?.condition,
                  criteria: CONSTANT_ENUM?.and,
                  field: null,
                  operator: null,
                  value: null,
                  conditions: [],
                  fieldType: CONSTANT_ENUM?.text,
                },
              ],
            }));
          }
        } else {
          setNotificationTypeList(null);
        }
      }
    } catch (error) {
      setNotificationTypeList(null);
    }
  }, []);

  const fieldsConditionsHandler = (eventId, notificationTypeListData) => {
    if (eventId) {
      const selectedNotificationEvent = notificationTypeListData
        ? notificationTypeListData?.filter(
            (condition) => condition?.id === eventId,
          )
        : [];
      if (selectedNotificationEvent?.length !== 0) {
        const updatedFieldsTypeList =
          selectedNotificationEvent[0]?.conditionKeys;
        setFieldsList(
          updatedFieldsTypeList && updatedFieldsTypeList?.length !== 0
            ? updatedFieldsTypeList.map((entity) => {
                return {
                  ...entity,
                  key: entity.payloadKey,
                  name: entity.label,
                };
              })
            : [],
        );
      }
    }
  };

  const parseRulePreviewConditions = (conditions) => {
    return conditions
      .map((condition, index) => {
        let rulePreviewConditions = "";
        if (condition.type === CONSTANT_ENUM?.condition) {
          const eventType = getFieldType(fieldsList || [], condition.field);
          rulePreviewConditions += `${eventType?.name || ""} ${
            condition.operator ? OPERATORS_ENUM[condition.operator]?.format : ""
          } ${
            condition.value && condition.value.length !== 0
              ? Array.isArray(condition.value)
                ? condition.value.map((v) => v.name).join(", ")
                : condition.value
              : ""
          }`;
          const nextCondition = conditions[index + 1];
          if (nextCondition) {
            rulePreviewConditions += ` ${nextCondition.criteria || ""}`;
          }
        }
        if (condition.type === CONSTANT_ENUM?.group) {
          rulePreviewConditions += `(${parseRulePreviewConditions(condition.conditions)})`;
          const nextCondition = conditions[index + 1];
          if (nextCondition) {
            rulePreviewConditions += ` ${nextCondition.criteria || ""}`;
          }
        }
        return rulePreviewConditions;
      })
      .join(" ");
  };

  const formattedRulePreviewConditions = (conditions) => {
    const eventType = getEventType(
      notificationTypeList || [],
      selectedConsumer?.eventId,
    );
    const rulesPreview = parseRulePreviewConditions(conditions)?.trim();
    return eventType
      ? `(${eventType?.name} AND ${rulesPreview || "All Conditions"})`
      : "";
  };

  const getEventType = (notifications, eventId) =>
    notifications.find(({ id }) => id === eventId);
  const getFieldType = (events, key) =>
    events.find(({ payloadKey }) => payloadKey === key);

  return (
    <div className="relative flex h-full flex-col overflow-y-auto overflow-x-hidden bg-white p-0 font-inter">
      <main>
        <div className="mb-12 mt-2 h-full space-y-1">
          <div className="flex h-full flex-col">
            <div className="flex gap-4 py-2">
              <div className="w-1/2 space-y-1">
                <div
                  style={{ display: "inline-block", width: "100%" }}
                  className="space-y-1">
                  <div className="flex w-full flex-col items-center">
                    <div className="mb-2 flex w-full items-center text-gray-700">
                      Notification Type <span className="text-red-500">*</span>
                      <Tooltip title="Notification Type">
                        <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                      </Tooltip>
                    </div>
                    <div className="flex w-full items-center">
                      <AutocompleteDropdownV2
                        options={notificationTypeList || []}
                        labelKey="name"
                        valueKey="id"
                        onChange={(notification) => {
                          setSelectedConsumer((prev) => ({
                            ...prev,
                            eventId: notification || null,
                            conditions: [
                              {
                                type: CONSTANT_ENUM?.condition,
                                criteria: CONSTANT_ENUM?.and,
                                field: null,
                                operator: null,
                                value: null,
                                conditions: [],
                                fieldType: CONSTANT_ENUM?.text,
                              },
                            ],
                          }));
                          if (notification) {
                            getTemplatesList(notification);
                          }
                        }}
                        value={selectedConsumer?.eventId || ""}
                        placeholder="Select Notification Type"
                        id="NotificationType"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 space-y-1"></div>
            </div>
            <div className="flex gap-4 py-2">
              <div className="w-1/2 space-y-1">
                <div
                  style={{ display: "inline-block", width: "100%" }}
                  className="space-y-1">
                  <div className="flex w-full flex-col items-center">
                    <div className="mb-2 flex w-full items-center text-gray-700">
                      Delivery Channel <span className="text-red-500">*</span>
                      <Tooltip title="Delivery Channel">
                        <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                      </Tooltip>
                    </div>
                    <div className="flex w-full gap-4">
                      <button
                        className={twMerge(
                          `flex w-1/2 cursor-pointer items-center justify-center space-y-1 rounded-md border px-4 py-2 ${
                            selectedConsumer?.channels === "email"
                              ? "border-primaryAccent bg-blue-50"
                              : "border-gray-300"
                          }`,
                        )}
                        onClick={() =>
                          setSelectedConsumer((prev) => ({
                            ...prev,
                            channels: "email",
                          }))
                        }>
                        <MailIcon className="mr-2 h-6 w-6 text-primaryAccent" />
                        Email
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-1/2 space-y-1"></div>
            </div>
            <div className="flex gap-4 py-2">
              <div className="w-full space-y-1">
                <div
                  style={{ display: "inline-block", width: "100%" }}
                  className="space-y-1">
                  <div className="ml-1 flex w-full flex-col items-center pr-2">
                    <div className="mb-2 flex w-full items-center text-gray-700">
                      <div className="flex flex-col items-start">
                        <div>
                          Conditions
                          <Tooltip title="Conditions">
                            <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                          </Tooltip>
                        </div>
                        <span className="!text-sm !font-light">
                          Conditions are logical operators used to trigger the
                          email.
                        </span>
                      </div>
                    </div>
                    <div
                      className="flex w-full flex-col items-center rounded-lg border border-gray-300 px-3 py-3"
                      key="conditionsIDs">
                      {selectedConsumer &&
                      selectedConsumer?.conditions &&
                      selectedConsumer?.conditions?.length !== 0 ? (
                        selectedConsumer?.conditions.map((condition, index) => (
                          <>
                            {condition.type === CONSTANT_ENUM?.condition ? (
                              <ConsumerNotificationConditions
                                index={index}
                                condition={condition}
                                handleChange={handleChange}
                                handleDeleteConditions={handleDeleteConditions}
                                disabled={
                                  selectedConsumer?.conditions?.length === 1
                                }
                                fieldsList={fieldsList}
                                rowIndex={index}
                                id={selectedConsumer?.id}
                                customerList={customerList}
                                productSources={productSources}
                                clearAllConditions={clearAllConditions}
                                setClearAllConditions={setClearAllConditions}
                              />
                            ) : (
                              <ConsumerNotificationGroupConditions
                                key={"Group" + index}
                                groupIndex={index}
                                type={condition.type}
                                condition={condition}
                                handleChange={handleChange}
                                handleDeleteConditions={handleDeleteConditions}
                                handleDeleteGroupConditions={
                                  handleDeleteGroupConditions
                                }
                                handleAddGroupConditions={
                                  handleAddGroupConditions
                                }
                                disabled={
                                  selectedConsumer?.conditions?.length === 1
                                }
                                fieldsList={fieldsList}
                                rowIndex={index}
                                id={selectedConsumer?.id}
                                customerList={customerList}
                                productSources={productSources}
                                clearAllConditions={clearAllConditions}
                                setClearAllConditions={setClearAllConditions}
                              />
                            )}
                          </>
                        ))
                      ) : (
                        <div
                          className="flex w-full items-center justify-center py-2"
                          key={"conditionEmptyID"}>
                          <span className="!text-base !font-normal">
                            No Conditions created.
                          </span>
                        </div>
                      )}
                      <div className="mt-1 flex w-full items-center justify-between">
                        <div className="space-x-4">
                          <Tooltip title="Reset All Conditions">
                            <button
                              className="p-0 text-sm font-semibold text-red-500 underline"
                              onClick={resetConditions}>
                              Reset All
                            </button>
                          </Tooltip>
                        </div>
                        <div className="space-x-4">
                          <button
                            className="p-0 text-sm font-semibold text-primaryAccent underline"
                            onClick={() =>
                              handleAddGroupOrConditions(CONSTANT_ENUM?.group)
                            }>
                            + Add Group
                          </button>
                          <button
                            className="p-0 text-sm font-semibold text-primaryAccent underline"
                            onClick={() =>
                              handleAddGroupOrConditions(
                                CONSTANT_ENUM?.condition,
                              )
                            }>
                            + Add Condition
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {conditionsRulePreview && (
              <div className="flex gap-4 py-2">
                <div className="w-full space-y-1">
                  <div
                    style={{ display: "inline-block", width: "100%" }}
                    className="space-y-1">
                    <div className="ml-1 flex w-full flex-col items-center pr-2">
                      <div className="mb-2 flex w-full items-center text-gray-700">
                        <div className="flex flex-col items-start">
                          <div>
                            Rule Preview
                            <Tooltip title="Rule Preview">
                              <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                            </Tooltip>
                          </div>
                        </div>
                      </div>
                      <div
                        className="flex w-full flex-col items-center rounded-lg border border-gray-300 bg-gray-50 px-3 py-3 text-sm"
                        key="ReviewRules">
                        {conditionsRulePreview}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="flex gap-4 py-2">
              <div className="w-1/2 space-y-1">
                <div
                  style={{ display: "inline-block", width: "100%" }}
                  className="space-y-1">
                  <div className="flex w-full flex-col items-center">
                    <div className="mb-2 flex w-full items-center justify-between">
                      <div className="text-gray-700">
                        Template <span className="text-red-500">*</span>
                        <Tooltip title="Template">
                          <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                        </Tooltip>
                      </div>
                    </div>
                    <div className="flex w-full items-center">
                      <AutocompleteDropdownV2
                        options={templateOptions}
                        labelKey="templateName"
                        valueKey="id"
                        onChange={(templateValue) => {
                          setSelectedConsumer((prev) => ({
                            ...prev,
                            templateId: templateValue || null,
                          }));
                        }}
                        value={selectedConsumer?.templateId || ""}
                        placeholder="Select Template"
                        id="NotificationType"
                      />
                    </div>
                    {selectedConsumer?.templateId && (
                      <div className="mb-2 flex w-full items-center justify-start">
                        <button
                          className="flex items-center p-0 text-sm font-semibold text-primaryAccent underline"
                          onClick={handlePreviewTemplate}>
                          <EyeIcon className="mr-2 h-5 w-5 text-primaryAccent" />
                          <span>Preview</span>
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="w-1/2 space-y-1"></div>
            </div>
          </div>
        </div>
      </main>
      <footer className="mt-auto w-full flex-shrink-0 px-4">
        <div className="mt-4 flex w-full items-center justify-end gap-4 space-x-4 bg-white">
          <button
            onClick={() => {
              onCancel();
            }}
            className={`cursor-pointer rounded-md border border-primaryAccent px-4 py-2 text-base font-semibold text-primaryAccent`}>
            Cancel
          </button>
          <button
            onClick={handleConfirmSubmitConfirmationRequest}
            disabled={
              isValidateConditions ||
              !(
                selectedConsumer?.channels &&
                selectedConsumer?.eventId &&
                selectedConsumer?.templateId
              )
            }
            className={twMerge(
              `rounded-md px-4 py-2 text-base font-semibold text-white ${
                isValidateConditions ||
                !(
                  selectedConsumer?.channels &&
                  selectedConsumer?.eventId &&
                  selectedConsumer?.templateId
                )
                  ? "cursor-not-allowed border bg-gray-400"
                  : "cursor-pointer bg-primaryAccent"
              }`,
            )}>
            {selectedNotification?.id ? "Update" : "Create"}
          </button>
        </div>
      </footer>
    </div>
  );
};
