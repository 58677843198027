import React, { useState, Fragment, useEffect } from "react";
import { SystemNotificationEventsUserRoles } from "#newUiComponents/notificationV2/SystemNotificationEventsUserRoles";
import { SystemNotificationUserRolesMultiSelect } from "#newUiComponents/notificationV2/SystemNotificationUserRolesMultiSelect";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/solid";
import CustomSwitch from "#newUiComponents/commons/CustomSwitch";
import { InformationCircleIcon } from "@heroicons/react/outline";
import { Transition } from "@headlessui/react";
import { Tooltip } from "antd";
import { twMerge } from "tailwind-merge";

export const FORM_ENUM = {
  USER_ROLE_IDS: "userRoleIds",
  outbound_notifications: "Outbound Notifications",
};
const SystemNotifications = ({
  selectedTab,
  entityUserRolesWithCount,
  systemNotificationsEvents,
  updateEventEntities,
}) => {
  const [selectedAppNotification, setSelectedAppNotification] = useState(null);
  const [expandedNotificatons, setExpandedNotificatons] = useState(null);
  const [autoIsEnable, setAutoIsEnable] = useState(false);
  const [selectedNotificationData, setSelectedNotificationData] =
    useState(null);

  const handleChange = (event, index) => {
    const { name, value } = event.target;

    setSelectedAppNotification((prev) => {
      const updatedEvents = [...prev.events];
      const eventDetail = updatedEvents[index] || {};

      if (name === FORM_ENUM?.USER_ROLE_IDS) {
        const removedRoleIdsBackup = eventDetail.removedRoleIdsBackup || [];
        const updatedUserRoleIds = value?.map((role) => role.id) || [];
        const removedRoleIds = removedRoleIdsBackup.filter(
          (id) => !updatedUserRoleIds.includes(id),
        );

        updatedEvents[index] = {
          ...eventDetail,
          [name]: value,
          removedRoleIds,
        };
      } else {
        updatedEvents[index] = {
          ...eventDetail,
          [name]: value,
        };
      }

      return {
        ...prev,
        events: updatedEvents,
      };
    });
  };

  useEffect(() => {
    if (selectedTab) {
      setSelectedAppNotification((prev) => ({
        ...prev,
        ...selectedTab,
        events: [...systemNotificationsEvents],
      }));
      setSelectedNotificationData((prev) => ({
        ...prev,
        ...selectedTab,
        events: [...systemNotificationsEvents],
      }));
    }
  }, [selectedTab]);

  const toggleComponents = (id) =>
    setExpandedNotificatons(expandedNotificatons === id ? null : id);

  const handleSubmitRequest = (index) => {
    const selectedEvent = selectedAppNotification?.events?.[index] ?? null;
    if (selectedEvent) {
      const payload = {
        event: {
          eventId: selectedEvent?.id,
          isEnabled: selectedEvent?.isEnabled,
        },
        userRoleIds: selectedEvent?.userRoleIds?.map((role) => role?.id),
        removedRoleIds: selectedEvent?.removedRoleIds,
      };
      updateEventEntities(payload);
    }
  };

  const validateSystemNotificationEvents = (index) => {
    const selectedEvent = selectedAppNotification?.events[index];
    const backupEvent = selectedNotificationData?.events[index];

    if (selectedEvent?.userRoleIds?.length > 0) {
      return true;
    }

    return (
      selectedEvent?.userRoleIds?.length === 0 &&
      backupEvent?.userRoleIds?.length > 0 &&
      !selectedEvent?.isEnabled
    );
  };

  return (
    <div className="w-full space-y-4 font-inter">
      {selectedAppNotification?.events &&
        selectedAppNotification?.events?.map((eventData, rootIndex) => (
          <div key={eventData?.key} className="w-4/5 rounded border px-4 py-4">
            <div
              onClick={() => toggleComponents(eventData?.key)}
              className="flex cursor-pointer items-center justify-between font-semibold text-black">
              <span className="text-base">{eventData?.key}</span>
              <div className="flex items-center justify-center">
                <div
                  onClick={(event) => {
                    if (expandedNotificatons === eventData?.key) {
                      event.stopPropagation();
                    }
                  }}>
                  <CustomSwitch
                    leftSideText=""
                    rightSideText="Enable Notification"
                    checked={eventData?.isEnabled}
                    onChange={(value) => {
                      handleChange(
                        {
                          target: {
                            name: "isEnabled",
                            value: value,
                            type: "checkbox",
                          },
                        },
                        rootIndex,
                      );
                    }}
                    labelClasses="text-gray-700 font-medium mr-2"
                    switchClasses="w-10 h-6"
                    handleClasses="w-4 h-4"
                    rightColor="bg-primaryAccent"
                  />
                </div>
                {expandedNotificatons === eventData?.key ? (
                  <ChevronUpIcon className="mr-1 h-5 w-5" />
                ) : (
                  <ChevronDownIcon className="mr-1 h-5 w-5" />
                )}
              </div>
            </div>
            <Transition
              as={Fragment}
              show={expandedNotificatons === eventData?.key}
              enter="transition ease-out duration-200 transform"
              enterFrom="-translate-y-10 opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition ease-in duration-150 transform"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="-translate-y-10 opacity-0">
              <div className="mt-2 w-full border-t px-0 pb-0 pt-2 text-gray-600">
                <Fragment key={rootIndex}>
                  <div className="flex flex-col items-start justify-start space-y-4">
                    <div className="space-y-1">
                      <div className="mb-2 flex w-full items-center text-gray-700">
                        User Roles
                        <Tooltip title=" User Roles">
                          <InformationCircleIcon className="ml-2 inline-block h-4 w-4" />
                        </Tooltip>
                      </div>
                      <SystemNotificationUserRolesMultiSelect
                        onChange={(value) => {
                          handleChange(
                            {
                              target: {
                                name: "userRoleIds",
                                value: value,
                              },
                            },
                            rootIndex,
                          );
                          if (value && value?.length !== 0 && autoIsEnable) {
                            handleChange(
                              {
                                target: {
                                  name: "isEnabled",
                                  value: true,
                                  type: "checkbox",
                                },
                              },
                              rootIndex,
                            );
                          }
                        }}
                        options={entityUserRolesWithCount}
                        values={eventData?.userRoleIds}
                        setAutoIsEnable={setAutoIsEnable}
                      />
                    </div>
                  </div>
                  <SystemNotificationEventsUserRoles
                    userRoleIds={eventData?.userRoleIds}
                    entityUserRolesWithCount={entityUserRolesWithCount}
                  />

                  <div className="flex w-full items-center justify-end border-t p-2 text-gray-600">
                    <button
                      onClick={() => handleSubmitRequest(rootIndex)}
                      className={twMerge(
                        `mt-2 rounded-md px-4 py-2 text-sm font-medium text-white ${
                          validateSystemNotificationEvents(rootIndex)
                            ? "cursor-pointer border border-primaryAccent bg-primaryAccent"
                            : "pointer-events-none cursor-not-allowed border border-gray-500 bg-gray-500"
                        }`,
                      )}>
                      Save Changes
                    </button>
                  </div>
                </Fragment>
              </div>
            </Transition>
          </div>
        ))}
    </div>
  );
};

export default SystemNotifications;
