import React, { useState, useEffect } from "react";
import { formatCount } from "#utils/helper-functions";
import AutoCompleteMultiSelect from "#newUiComponents/commons/AutoCompleteMultiSelect";

export const SystemNotificationUserRolesMultiSelect = ({
  onChange,
  options,
  values,
  setAutoIsEnable,
}) => {
  const [dropDownOptions, setDropDownOptions] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [selectedValues, setSelectedValues] = useState([]);

  useEffect(() => {
    if (selectedValues && selectedValues?.length !== 0) {
      onChange(selectedValues);
    } else {
      onChange([]);
    }
  }, [selectedValues]);

  useEffect(() => {
    if (options) {
      const dropDwonOptions =
        options?.map((option) => ({
          ...option,
          name: (
            <div className="flex w-full items-center justify-between">
              <span className={`text-left`}>{option?.label}</span>
              <span className={`text-right`}>
                {formatCount(option?.userCount)} Users
              </span>
            </div>
          ),
          id: option?.id,
          uniqueIdentifier: option?.label || option?.name,
        })) || [];
      setDropDownOptions(dropDwonOptions);
    }
  }, [options]);

  useEffect(() => {
    if (values && values?.length !== 0 && selectedValues?.length === 0) {
      setSelectedValues(values);
    }
  }, [values]);

  useEffect(() => {
    if (dropdownOpen) {
      setAutoIsEnable(true);
    }
  }, [dropdownOpen]);
  return (
    <AutoCompleteMultiSelect
      label=""
      dropdownOpen={dropdownOpen}
      setDropdownOpen={setDropdownOpen}
      options={dropDownOptions}
      selectedValues={values || []}
      setSelectedValues={setSelectedValues}
      placeholder="Select User Roles"
      optionClasses="w-full"
      multiSelect="count"
      maxTagCount={2}
      placeholderDropdown="comboDropdown undefined"
      placeholderBackground="bg-white"
      placeholderText="text-gray-700 text-base"
    />
  );
};
