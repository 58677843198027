import { useEffect, useRef } from "react";
import LoadingIndicator from "../../components/utils/LoadingIndicator";
import AutoCompleteMultiSelect from "./AutoCompleteMultiSelect";
import AutoCompleteSingleSelect from "./AutoCompleteSingleSelect";
import { getTotalCount } from "../../utils/searchPanelUtils";

const SearchPanel = ({
  filters,
  onChangeFilter,
  dataObject,
  rowRender,
  emptySearchText,
  totalResults,
  isLoading,
  setCurrentPage,
  nextCursor,
  tabs,
  activeTab,
  setActiveTab,
}) => {
  const infiniteLoader = useRef(null);

  const getFilterComponent = (filter) => {
    switch (filter.typeOfField) {
      case "select":
        return (
          <AutoCompleteMultiSelect
            {...filter}
            options={filter?.data}
            onChange={(value) =>
              onChangeFilter({ filterName: filter.id, filterValue: value })
            }
          />
        );
      case "single-select":
        return (
          <AutoCompleteSingleSelect
            {...filter}
            options={filter?.data?.map((item) => ({
              id: item.id,
              label: item.name,
            }))}
            labelKey="label"
            valueKey="id"
            labelText={filter.label}
            labelClasses="text-xs font-medium text-primaryAccent"
            onChange={(value) =>
              onChangeFilter({ filterName: filter.id, filterValue: value })
            }
            inputClasses="border-gray-300"
          />
        );
      default:
        return null;
    }
  };

  const attachObserver = () => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && nextCursor && !isLoading) {
          setCurrentPage((page) => page + 1);
        }
      },
      {
        threshold: 0.5,
      },
    );

    if (infiniteLoader.current) {
      observer.observe(infiniteLoader.current);
    }

    return observer;
  };

  useEffect(() => {
    const observer = attachObserver();

    return () => {
      if (infiniteLoader.current) {
        observer.unobserve(infiniteLoader.current);
      }
    };
  }, [nextCursor]);

  return (
    <div className="h-full overflow-y-hidden">
      <div className="flex gap-8 border-b border-gray-300">
        {tabs.map((item) => (
          <span
            key={item.id}
            className={`${item.id === activeTab ? "border-b-4 border-primaryAccent pb-3 font-semibold text-primaryAccent" : "text-gray-600"}`}
            role="button"
            onClick={() => setActiveTab(item.id)}>
            {item.name}
          </span>
        ))}
      </div>

      <div className="flex h-full overflow-y-scroll">
        {dataObject.nonExactMatches.length > 0 ||
        dataObject.exactMatches.length > 0 ||
        nextCursor ||
        isLoading ? (
          <div className="w-3/4 border-r border-gray-300 pr-4">
            {totalResults > 0 && (
              <p className="my-4 text-gray-400">
                Total results: {getTotalCount(totalResults)}
              </p>
            )}

            <div
              className="h-full overflow-y-auto"
              style={{ maxHeight: "500px" }}>
              <div className="flex flex-col gap-4">
                {dataObject.exactMatches?.map((item) => rowRender(item))}
              </div>

              {dataObject.exactMatches.length &&
              dataObject.nonExactMatches.length ? (
                <div className={"my-8 w-full border-t-2 border-gray-900"}>
                  {/* separator */}
                </div>
              ) : null}

              <p className="my-4 text-gray-700">
                {dataObject.exactMatches.length === 0 &&
                dataObject.nonExactMatches.length > 0
                  ? "We couldn't find the exact match but here are some related matches:"
                  : dataObject.exactMatches.length > 0 &&
                      dataObject.nonExactMatches.length > 0
                    ? "Here are some related matches:"
                    : ""}
              </p>

              <div className="flex flex-col gap-4">
                {dataObject.nonExactMatches?.map((item) => rowRender(item))}
              </div>

              <div ref={infiniteLoader}>
                {isLoading ? (
                  <LoadingIndicator shouldShowOnPage={false} />
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex w-3/4 flex-col items-center justify-between border-r border-gray-300 pr-4">
            <img
              src="https://assets.prod.hopstack.io/platform-placeholders/search-result-null.png"
              alt="No results"
              height={270}
              width={340}
              className="mb-4"
            />
            <p className="grow text-center">{emptySearchText}</p>
          </div>
        )}

        <div className="w-1/4 p-4">
          {filters
            .filter((filter) => filter.typeOfField !== "tab")
            .map((filter) => (
              <div key={filter.id}>{getFilterComponent(filter)}</div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default SearchPanel;
