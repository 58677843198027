import { useState, useEffect, useContext, useCallback } from "react";
import { useQuery } from "#hooks/useQuery";
import {
  GET_INTEGRATIONS,
  GET_INTEGRATION_PROVIDERS,
  TEST_INTEGRATION,
  GET_INTEGRATION_PRODUCTS,
} from "#queries";
import { SAVE_INTEGRATION, DELETE_INTEGRATION } from "#mutations";
import _ from "lodash";
import { AppStateContext } from "#contexts/appState";
import { AuthContext } from "#contexts/auth";
import { MasterDataContext } from "#contexts/masterData";
import Cookies from "universal-cookie";
import qs from "qs";
import { useHistory } from "react-router-dom";

/**
 * Sets a cookie with the necessary information for tenant and selected integration.
 *
 * @param {Object} params - The parameters for setting the cookie.
 * @param {Object} params.appState - The application state containing the tenant information.
 * @param {Object} params.selectedIntegration - The selected integration object.
 */
const setCookie = ({ appState, ...selectedIntegration }) => {
  const cookies = new Cookies();
  const expires = new Date();
  expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000); // 1 month
  cookies.set(
    "hsTenantInfo",
    encodeURI(
      `${appState.tenant.apiGateway}|${window.location.origin}|${
        appState.tenant.id
      }|${JSON.stringify(selectedIntegration)}`,
    ),
    {
      path: "/",
      expires,
      domain: ".hopstack.io",
      secure: true,
    },
  );
};

const withGlobalIntegrationsLogic = (WrappedComponent) => {
  return (props) => {
    const history = useHistory();
    const [testResults, setTestResults] = useState(null);
    const [selectedIntegration, setSelectedIntegration] = useState(null);
    const [selectedIntegrationToManage, setSelectedIntegrationToManage] =
      useState(null);
    const [selectedIntegrationToConfigure, setSelectedIntegrationToConfigure] =
      useState(null);

    const appState = useContext(AppStateContext);
    const auth = useContext(AuthContext);
    const masterData = useContext(MasterDataContext);

    const integrationsQuery = useQuery(GET_INTEGRATIONS);
    const providersQuery = useQuery(GET_INTEGRATION_PROVIDERS);
    const saveIntegrationQuery = useQuery(SAVE_INTEGRATION);
    const deleteIntegrationQuery = useQuery(DELETE_INTEGRATION);
    const testIntegrationQuery = useQuery(TEST_INTEGRATION);
    const getProductsQuery = useQuery(GET_INTEGRATION_PRODUCTS);
    const [multiAccountSupportEnabled, setMultiAccountSupportEnabled] =
      useState(false);

    useEffect(() => {
      loadData();
    }, []);

    useEffect(() => {
      const tenantSettings = appState?.tenant?.settings;
      if (
        tenantSettings?.multiAccountIntegrationSupportEnabled &&
        !multiAccountSupportEnabled
      ) {
        setMultiAccountSupportEnabled(true);
      }
    }, [appState]);

    const onPageLoad = () => {
      const queryParams = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      });
      if (queryParams?.res && queryParams?.state) {
        const splitState = decodeURIComponent(queryParams.state).split("|");
        const {
          spapi_oauth_code: spOAuthCode,
          code,
          selling_partner_id: amazonSellerId,
        } = JSON.parse(queryParams.res);

        if (!splitState || splitState.length < 4) {
          return appState.setAlert(
            "Something went wrong. Please try again.",
            "error",
            5000,
          );
        }

        const integrationBody = {
          ...JSON.parse(splitState[3]),
          spOAuthCode,
          authCode: code,
          amazonSellerId,
        };

        console.log(
          "integrationBody.integrationType ",
          integrationBody.integrationType,
        );
        if (integrationBody.integrationType !== "UPS") {
          setSelectedIntegration(integrationBody);
        }

        saveIntegrationQuery.fetchData(integrationBody);
      }
      history.replace({
        search: "",
      });
    };

    useEffect(() => {
      loadFirstTimeData();
    }, []);

    const loadFirstTimeData = () => {
      if (auth && auth.user) {
        onPageLoad();
      }
    };

    useEffect(() => {
      if (getProductsQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (getProductsQuery.data) {
        appState.setAlert(getProductsQuery.data.integrationProducts.message);
      }
    }, [
      getProductsQuery.loading,
      getProductsQuery.error,
      getProductsQuery.data,
    ]);

    const loadData = () => {
      integrationsQuery.fetchData({
        filters: { customer: null, warehouse: null },
      });
      providersQuery.fetchData();
    };

    const globalIntegrations = integrationsQuery.data
      ? integrationsQuery.data.integrations.filter(
          (integration) =>
            integration.customer === null && integration.warehouse === null,
        )
      : [];

    const globalProviders = providersQuery.data
      ? providersQuery.data.integrationProviders.filter(
          (provider) => provider.scope === "GLOBAL",
        )
      : [];

    const connectIntegration = (integrationType) => {
      setSelectedIntegration({
        integrationType,
        customer: null,
        warehouse: null,
        username: "",
        password: "",
        storeName: "",
        marketplaces: [],
        region: null,
      });
    };

    const onChange = (e) => {
      let integration = { ...selectedIntegration };
      const nestedkey = e?.target?.attributes?.getNamedItem("nestedkey")?.value;

      if (nestedkey != null) {
        integration = {
          ...integration,
          [nestedkey]: {
            ...integration[nestedkey],
            [e?.target?.name]: e?.target?.value,
          },
        };
      } else {
        integration[e.target.name] = e.target.value;
      }
      setSelectedIntegration(integration);
    };

    const onChangeDropdown = (field, value) => {
      const integration = { ...selectedIntegration };
      integration[field] = value;
      setSelectedIntegration(integration);
    };

    const saveIntegration = () => {
      if (selectedIntegration.integrationType === "UPS") {
        if (selectedIntegration) {
          if (!selectedIntegration?.configurations?.upsAccountNumber) {
            appState.setAlert(
              `UPS account number is required. Please provide a valid account number.`,
              "error",
              5000,
            );
            return false;
          }

          if (
            selectedIntegration?.configurations?.upsAccountNumber !==
            selectedIntegration?.configurations?.confirmUpsAccountNumber
          ) {
            appState.setAlert(
              `UPS account numbers do not match. Please check and try again.`,
              "error",
              5000,
            );
            return false;
          }
        }
        setCookie({
          appState,
          ...selectedIntegration,
        });
        window.location.href = `https://onlinetools.ups.com/security/v1/oauth/authorize?client_id=uXt5WyXgomqAXJwSk8rZlmtJqys0RPAIpLQUTQ5A19OwDRBv&redirect_uri=https%3A%2F%2Foauth.hopstack.io&response_type=code&state=string&scope=string`;
      } else {
        saveIntegrationQuery.fetchData({ ...selectedIntegration });
      }
    };

    useEffect(() => {
      if (saveIntegrationQuery.data) {
        appState.setAlert(
          saveIntegrationQuery.data.saveIntegration.message,
          "success",
          5000,
        );
        setSelectedIntegration(null);
        setSelectedIntegrationToConfigure(null);
        loadData();
      }
      if (saveIntegrationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
      if (saveIntegrationQuery.error) {
        console.log(saveIntegrationQuery.error);
        appState.setAlert(saveIntegrationQuery.error.message, "error", 5000);
      }
    }, [
      saveIntegrationQuery.loading,
      saveIntegrationQuery.data,
      saveIntegrationQuery.error,
    ]);

    useEffect(() => {
      if (deleteIntegrationQuery.error) {
        appState.setAlert(
          `Could not logout of integration. Please contact support.`,
          "error",
          5000,
        );
      }
      if (deleteIntegrationQuery.data) {
        appState.setAlert(`Successfully logged out`, "success", 5000);
        loadData();
      }

      if (deleteIntegrationQuery.loading) {
        appState.setLoading();
      } else {
        appState.removeLoading();
      }
    }, [
      deleteIntegrationQuery.loading,
      deleteIntegrationQuery.error,
      deleteIntegrationQuery.data,
    ]);

    const logoutIntegration = (id) => {
      appState.showConfirmation(
        "Confirm",
        "Are you sure you want to logout?",
        () => {
          deleteIntegrationQuery.fetchData({ id });
          appState.hideConfirmation();
        },
        appState.hideConfirmation,
      );
    };

    useEffect(() => {
      if (testIntegrationQuery.data) {
        appState.setAlert(
          testIntegrationQuery.data.testIntegration.message,
          "success",
          5000,
        );
        setTestResults(testIntegrationQuery.data.testIntegration.testData);
        appState.removeLoading();
      }
      if (testIntegrationQuery.error) {
        appState.setAlert(testIntegrationQuery.error.message, "error", 5000);
        setTestResults(null);
        appState.removeLoading();
      }
    }, [
      testIntegrationQuery.loading,
      testIntegrationQuery.error,
      testIntegrationQuery.data,
    ]);

    const testIntegration = (id) => {
      appState.setLoading();
      appState.setAlert(`Testing connection`, "success", 3000);
      testIntegrationQuery.fetchData({ id });
    };

    const getProducts = (id) => {
      // Implement getProducts logic here
      getProductsQuery.fetchData({ id });
    };

    const configureIntegrationSubmit = () => {
      if (selectedIntegrationToConfigure) {
        saveIntegrationQuery.fetchData({
          ...selectedIntegrationToConfigure,
        });
      }
    };

    return (
      <WrappedComponent
        integrationProviders={globalProviders}
        getProducts={getProducts}
        connectIntegration={connectIntegration}
        integrations={globalIntegrations}
        selectedIntegration={selectedIntegration}
        setSelectedIntegration={setSelectedIntegration}
        selectedIntegrationToManage={selectedIntegrationToManage}
        setSelectedIntegrationToManage={setSelectedIntegrationToManage}
        selectedIntegrationToConfigure={selectedIntegrationToConfigure}
        setSelectedIntegrationToConfigure={setSelectedIntegrationToConfigure}
        configureIntegrationSubmit={configureIntegrationSubmit}
        onChange={onChange}
        onChangeDropdown={onChangeDropdown}
        saveIntegration={saveIntegration}
        logoutIntegration={logoutIntegration}
        testIntegration={testIntegration}
        testResults={testResults}
        setTestResults={setTestResults}
        writable={props.writable}
        multiAccountSupportEnabled={multiAccountSupportEnabled}
      />
    );
  };
};

export default withGlobalIntegrationsLogic;
