import NewModal from "#newUiComponents/commons/NewModal";
import PrimaryButton from "#newUiComponents/commons/PrimaryButton";
import { useState } from "react";

const BoxDuplicationModal = ({ isOpen, onClose, onConfirm, maxDuplicates }) => {
  const [quantity, setQuantity] = useState(1);
  const [inputValue, setInputValue] = useState("1");

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    if (value !== "") {
      const numValue = parseInt(value);
      const boundedValue = Math.min(maxDuplicates, Math.max(1, numValue || 1));
      setQuantity(boundedValue);
    } else {
      setQuantity(1);
    }
  };

  const handleBlur = () => {
    const displayValue = quantity.toString();
    setInputValue(displayValue);
  };

  return (
    <NewModal
      isOpen={isOpen}
      onClose={onClose}
      title="Duplicate Box"
      maxHeight="300px">
      <div className="py-4 font-inter">
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">
            How many boxes do you want to duplicate? (Max: {maxDuplicates})
          </label>
          <input
            type="number"
            min="1"
            max={maxDuplicates}
            value={inputValue}
            onChange={handleInputChange}
            onBlur={handleBlur}
            className="mt-1 block w-36 rounded-md border-gray-300 shadow-sm focus:border-primaryAccent focus:ring focus:ring-primaryAccent focus:ring-opacity-50"
          />
        </div>
        <div className="flex justify-end gap-4">
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="secondary"
            onClick={onClose}>
            Cancel
          </PrimaryButton>
          <PrimaryButton
            height="2.5rem"
            width="7rem"
            variant="primary"
            onClick={() => onConfirm(quantity)}>
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </NewModal>
  );
};

export default BoxDuplicationModal;
