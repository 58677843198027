import { useContext, useState } from "react";
import Modal from "#components/utils/Modal";
import _ from "lodash";
import { buildMarketplaceHyperlink } from "../../utils/buildMarketplaceHyperlink";
import BundleProductsDetail from "#components/bundles/BundleProductsDetail";
import TableFieldButton from "#components/utils/TableFieldButton";
import { PencilIcon } from "@heroicons/react/outline";
import TextField from "#components/utils/TextField";
import Dropdown from "#components/utils/Dropdown";
import { AppStateContext } from "#contexts/appState";

const ExpandedEligibleBundles = ({
  eligibleBundles,
  setEligibleBundles,
  selectedBundleForAllocation,
  showBundleAllocationForm,
  onSubmitBundleAllocation,
  handleBundleSelection,
}) => {
  const appState = useContext(AppStateContext);
  const isPrepCenter = appState.isPrepCenter();

  const headers = [
    "SKU",
    "ASIN",
    "Name",
    "Source",
    isPrepCenter ? "FNSKU" : null,
    "Products",
    "Action",
  ];
  const [bundleProductsDetail, setBundleProductsDetail] = useState(null);
  const [bundleAllocationDetail, setBundleAllocationDetail] = useState({});

  if (bundleProductsDetail) {
    return (
      <BundleProductsDetail
        products={bundleProductsDetail.map((product) => {
          return { ...product, quantity: product.unallocatedQuantity };
        })}
        onClose={() => setBundleProductsDetail(null)}
      />
    );
  }

  if (showBundleAllocationForm) {
    return (
      <Modal
        title={"Allocate Bundle"}
        negativeAction={() => handleBundleSelection(null)}
        positiveAction={() => onSubmitBundleAllocation(bundleAllocationDetail)}>
        <div className="space-y-4">
          <div className="flex-1">
            <div className="space-y-4 pb-4 pl-1">
              <table className="divide-y divide-gray-200 border border-gray-400 px-2">
                <thead className="rounded-full bg-primaryAccent p-4 px-12">
                  <tr className="border-left px-12 font-montserrat text-textWhite">
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                      Form Factor
                    </th>
                    <th
                      scope="col"
                      className="px-2 py-3 pl-4 text-left font-medium tracking-wider">
                      Allocable Quantity
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {selectedBundleForAllocation?.maximumAllocableQuantities
                    ?.length > 0 ? (
                    selectedBundleForAllocation?.maximumAllocableQuantities.map(
                      (allocableQuantity, index) => (
                        <tr
                          key={index}
                          className={`${
                            index % 2 === 0 ? "bg-white" : "bg-gray-50"
                          }`}>
                          <td
                            className={`tracking-widerrounded-tl rounded-bl p-5 text-left font-semibold text-primaryAccent`}>
                            {allocableQuantity.uom.toUpperCase()}
                          </td>

                          <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                            {allocableQuantity.quantity}
                          </td>
                        </tr>
                      ),
                    )
                  ) : (
                    <tr className="bg-white">
                      <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                        No Data Available.
                      </td>

                      <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B"></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div>
              <TextField
                type="number"
                id="quantity"
                label="Quantity"
                placeholder=" "
                onChange={(e) =>
                  setBundleAllocationDetail({
                    ...bundleAllocationDetail,
                    allocatedQuantity:
                      isNaN(e.target.value) === false &&
                      parseInt(e.target.value) > 0
                        ? parseInt(e.target.value)
                        : null,
                  })
                }
                value={setBundleAllocationDetail.allocatedQuantity}
                min="0"
              />
            </div>
          </div>

          <div className="mt-3 flex-1">
            <div>
              <Dropdown
                placeholder={"Form Factor"}
                list={
                  selectedBundleForAllocation?.maximumAllocableQuantities?.map(
                    (allocableQuantity) => {
                      return { name: allocableQuantity.uom };
                    },
                  ) ?? []
                }
                labelKey="name"
                valueKey="name"
                name="formFactor"
                setSelected={(e) =>
                  setBundleAllocationDetail({
                    ...bundleAllocationDetail,
                    formFactor: e,
                  })
                }
                selectedValue={bundleAllocationDetail.formFactor}
              />
            </div>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      negativeAction={() => setEligibleBundles(null)}
      title={`Eligible Bundles`}
      noPadding={true}>
      <div className="p-4">
        <div className="overflow-y-auto" style={{ maxHeight: 500 }}>
          <table className="mr-8 min-w-full divide-y divide-gray-200 px-2">
            <thead className="sticky left-0 top-0 bg-primaryAccent p-4">
              <tr className="border-left font-montserrat text-white">
                {headers.map((header, headerIdx) =>
                  headerIdx === 0 ? (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header}
                    </th>
                  ) : (
                    <th
                      scope="col"
                      className="px-1 py-3 pl-4 text-left font-medium tracking-wider"
                      key={headerIdx}>
                      {header}
                    </th>
                  ),
                )}
              </tr>
            </thead>
            <tbody>
              {eligibleBundles.map((bundle, index) => (
                <tr
                  key={bundle.id}
                  className={`${index % 2 === 0 ? "bg-white" : "bg-gray-50"}`}>
                  <td
                    className={`tracking-widerrounded-tl rounded-bl border-l-8 p-5 text-left font-medium text-5F666B ${
                      index % 2 === 0 ? "border-F4C261" : "border-primaryAccent"
                    }`}>
                    {bundle.sku}
                  </td>

                  <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {buildMarketplaceHyperlink(
                      bundle.asin,
                      "ASIN",
                      bundle.source,
                    )}
                  </td>

                  <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {truncate(bundle.name)}
                  </td>

                  <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    {bundle.source}
                  </td>

                  {isPrepCenter ? (
                    <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                      {bundle.fnSku || ""}
                    </td>
                  ) : null}

                  <td className="cursor-pointer rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-primaryAccent">
                    <span
                      onClick={() => setBundleProductsDetail(bundle.products)}>
                      view
                    </span>
                  </td>

                  <td className="rounded-br rounded-tr px-2 py-1 pl-4 text-left font-medium tracking-wider text-5F666B">
                    <TableFieldButton
                      onClick={() => handleBundleSelection(bundle)}
                      text={<PencilIcon className="h-6 w-6" />}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

export default ExpandedEligibleBundles;

const truncate = (str) => {
  return str.length > 40 ? str.substring(0, 40) + "..." : str;
};
