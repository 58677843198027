import { useState, useEffect, useContext } from "react";
import SystemNotifications from "#newUiComponents/notificationV2/SystemNotifications";
import SlideOverModal from "#components/common/SlideOverModal";
import { ALERT_VISIBILITY_IN_5000_MS } from "#newUiComponents/notificationV2/ConsumerNotifications";
import {
  formatTwoDigits,
  capitalizeLabel,
  formatCount,
} from "#utils/helper-functions";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import { GET_ENTITY_USERS_COUNTS_LIST } from "#queries";
import { UPDATE_EVENT_ENTITIES } from "#mutations";
import { useQuery } from "#hooks/useQuery";
import { Tabs } from "antd";
import { twMerge } from "tailwind-merge";
import { ChevronRightIcon } from "@heroicons/react/outline";
import NoNotificationsDataAvailable from "#newUiComponents/notificationV2/NoNotificationsDataAvailable";
import { FORM_ENUM } from "#newUiComponents/notificationV2/SystemNotifications";

const SystemNotificationsDashboard = ({ fetchNotificationsEvents }) => {
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const getEntityUserRolesWithCountQuery = useQuery(
    GET_ENTITY_USERS_COUNTS_LIST,
  );
  const updateEventEntitiesQuery = useQuery(UPDATE_EVENT_ENTITIES);
  const [systemNotificationsCategories, setSystemNotificationsCategories] =
    useState(null);
  const [
    systemNotificationsSubCategories,
    setSystemNotificationsSubCategories,
  ] = useState(null);
  const [systemNotificationsEvents, setSystemNotificationsEvents] = useState(
    [],
  );
  const [entityUserRolesWithCount, setEntityUserRolesWithCount] =
    useState(null);
  const [openSelectedNotification, setopenSelectedNotification] =
    useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [selectedReportTab, setSelectedReportTab] = useState(null);

  const getEntityUserRolesWithCount = async () => {
    const getEntityUserRolesWithCountResponse =
      await getEntityUserRolesWithCountQuery.fetchData();
    if (getEntityUserRolesWithCountResponse.error) {
      setEntityUserRolesWithCount(null);
    } else if (getEntityUserRolesWithCountResponse.data) {
      setEntityUserRolesWithCount(
        getEntityUserRolesWithCountResponse?.data?.listEntityWithUsersCount?.entities?.map(
          (user) => ({
            id: user?.entityId,
            name: user?.name,
            label: user?.name,
            uniqueIdentifier: user?.name,
            userCount: user?.userCount,
          }),
        ) ?? [],
      );
    }
  };

  const fetchNotifications = async (payload, onSuccess, onError) => {
    try {
      appState.setLoading();
      const notificationEventResponse = await fetchNotificationsEvents(payload);
      appState.removeLoading();

      if (notificationEventResponse.error) {
        onError();
      } else if (notificationEventResponse.data) {
        onSuccess(notificationEventResponse.data);
      }
    } catch (error) {
      appState.removeLoading();
      onError();
    }
  };

  const getSystemNotificationCategories = async () => {
    const payload = {
      type: "category",
      notificationType: "system",
    };

    fetchNotifications(
      payload,
      (data) => {
        setSystemNotificationsCategories(
          data?.listNotificationEvents?.entities?.filter(
            (event) => event?.category !== FORM_ENUM?.outbound_notifications,
          ) ?? [],
        );
      },
      () => {
        setSystemNotificationsCategories(null);
      },
    );
  };

  const getSystemNotificationSubCategories = async (notification) => {
    if (!notification?.category) return;

    const payload = {
      filters: {
        key: "category",
        value: notification.category,
      },
      type: "subCategory",
    };

    fetchNotifications(
      payload,
      (data) => {
        setSelectedNotification(notification);

        const subCategoriesTabsList =
          data?.listNotificationEvents?.entities
            ?.filter((item) => item?.subCategory !== null)
            .map((item) => ({
              category: notification.category,
              subCategory: item.subCategory,
              key: item.subCategory,
              label: capitalizeLabel(item.subCategory),
              events: [],
            })) ?? [];

        setSystemNotificationsSubCategories(subCategoriesTabsList);
        setSelectedReportTab(
          subCategoriesTabsList.length > 0 ? subCategoriesTabsList[0].key : "",
        );

        if (subCategoriesTabsList.length > 0) {
          getSystemNotificationSubCategoriesEvents(
            subCategoriesTabsList[0].key,
          );
        }
      },
      () => {
        setSystemNotificationsSubCategories(null);
        setSelectedNotification(null);
      },
    );
  };

  const getSystemNotificationSubCategoriesEvents = async (subCategory) => {
    if (!subCategory) return;

    const payload = {
      filters: {
        key: "subCategory",
        value: subCategory,
      },
      type: "events",
      notificationType: "system",
    };

    fetchNotifications(
      payload,
      (data) => {
        appState.removeLoading();
        const userRolesEvents = data?.listNotificationEvents?.entities ?? [];
        const updatedUserRolesEventsData =
          userRolesEvents?.map((eventData) => {
            const updatedUserRoleIds = eventData?.userRoleIds
              ?.map((roleId) => {
                const userRole = entityUserRolesWithCount?.find((role) => {
                  return role?.id === roleId;
                });
                return userRole
                  ? {
                      name: (
                        <div className="flex w-full items-center justify-between">
                          <span className="text-left">
                            {userRole?.label || userRole?.name}
                          </span>
                          <span className="text-right">
                            {formatCount(userRole?.userCount)} Users
                          </span>
                        </div>
                      ),
                      id: userRole?.id,
                      uniqueIdentifier: userRole?.label || userRole?.name,
                    }
                  : null;
              })
              .filter(Boolean);
            return {
              ...eventData,
              removedRoleIdsBackup: eventData?.userRoleIds,
              removedRoleIds: [],
              userRoleIds: updatedUserRoleIds,
            };
          }) ?? [];
        setSystemNotificationsEvents(updatedUserRolesEventsData ?? []);
        setopenSelectedNotification(true);
      },
      () => {
        appState.removeLoading();
        setSystemNotificationsEvents([]);
        setopenSelectedNotification(false);
      },
    );
  };

  const updateEventEntities = async (requestEventEntitiesInput) => {
    if (requestEventEntitiesInput) {
      appState.setLoading();
      const updateEventEntitiesResponse =
        await updateEventEntitiesQuery.fetchData({
          updateEventEntitiesInput: requestEventEntitiesInput,
        });
      appState.removeLoading();
      if (updateEventEntitiesResponse.error) {
        if (
          updateEventEntitiesResponse?.error &&
          updateEventEntitiesResponse?.error?.message
        ) {
          appState.setAlert(
            updateEventEntitiesResponse?.error?.message,
            "error",
            ALERT_VISIBILITY_IN_5000_MS,
          );
          return false;
        }
      } else if (updateEventEntitiesResponse.data) {
        appState.setAlert(
          updateEventEntitiesResponse.data?.updateEventEntities.message,
          "success",
          ALERT_VISIBILITY_IN_5000_MS,
        );
      }
    }
  };

  useEffect(() => {
    getEntityUserRolesWithCount();
    getSystemNotificationCategories();
  }, []);

  const tabContents =
    systemNotificationsSubCategories?.map((tab) => ({
      key: tab.key,
      label: (
        <span
          className={twMerge(
            `text-sm transition-colors duration-300 hover:text-primaryAccent ${
              selectedReportTab === tab.key
                ? "font-medium text-primaryAccent"
                : "font-light text-gray-500"
            }`,
          )}>
          {tab.label}
        </span>
      ),
      children: (
        <SystemNotifications
          key={tab.key + JSON.stringify(systemNotificationsEvents)}
          selectedTab={tab}
          entityUserRolesWithCount={entityUserRolesWithCount}
          systemNotificationsEvents={systemNotificationsEvents}
          updateEventEntities={updateEventEntities}
        />
      ),
    })) ?? [];

  if (
    !systemNotificationsCategories ||
    systemNotificationsCategories?.length === 0
  ) {
    return (
      <NoNotificationsDataAvailable
        width="25%"
        message="No system notifications are available."
      />
    );
  }

  return (
    <div className="space- h-full w-full space-y-4 px-8 font-inter">
      {systemNotificationsCategories &&
        systemNotificationsCategories.map((notification, index) => (
          <div
            className="flex w-full flex-1 flex-row gap-4 px-0"
            key={"Notify" + index}>
            <div
              onClick={() => {
                getSystemNotificationSubCategories(notification);
              }}
              className="flex w-4/5 cursor-pointer items-center rounded-lg border px-6 py-6 shadow-sm transition hover:shadow-md">
              <div className="flex flex-1 items-center justify-between">
                <div>
                  <h3 className="text-lg font-semibold">
                    {notification?.category}
                  </h3>
                  <p className="text-sm text-gray-500">
                    {notification?.categoryDescription}
                  </p>
                </div>
                <div className="flex items-center justify-center space-x-2">
                  <svg
                    width={14}
                    height={14}
                    viewBox="0 0 16 16"
                    xmlns="http://www.w3.org/2000/svg"
                    className={twMerge(
                      `${notification?.activeCount > 0 ? "text-green-600" : "text-red-600"}`,
                    )}>
                    <circle cx="8" cy="8" r="6" className="fill-current" />
                  </svg>
                  <span
                    className={twMerge(
                      `${notification?.activeCount > 0 ? "text-green-600" : "text-red-600"}`,
                    )}>
                    {`${formatTwoDigits(notification?.activeCount)} Active Notifications`}
                  </span>
                </div>
              </div>
              <div className="ml-4 font-semibold">
                <ChevronRightIcon className="h-6 w-6" />
              </div>
            </div>
          </div>
        ))}

      <SlideOverModal
        open={openSelectedNotification}
        onClose={() => {
          setopenSelectedNotification(false);
          setSelectedNotification(null);
          getSystemNotificationCategories();
        }}
        fontFamilyClass="font-inter"
        title={<Titles selectedNotification={selectedNotification} />}
        subtitle={<SubTitles selectedNotification={selectedNotification} />}>
        <Tabs
          size="large"
          tabBarStyle={{ borderBottom: "0px solid #224E73" }}
          onChange={(tabKey) => {
            setSelectedReportTab(tabKey);
            appState.setLoading();
            setSystemNotificationsEvents([]);
            getSystemNotificationSubCategoriesEvents(tabKey);
          }}
          activeKey={selectedReportTab}
          items={tabContents}
        />
      </SlideOverModal>
    </div>
  );
};

export const Titles = ({ selectedNotification }) => {
  if (selectedNotification) {
    return (
      <span className="text-2xl font-semibold">
        {selectedNotification?.category}
      </span>
    );
  }
  return null;
};
export const SubTitles = ({ selectedNotification }) => {
  if (selectedNotification) {
    return (
      <div className="!text-base !font-light !text-gray-400">
        {selectedNotification?.description}
      </div>
    );
  }
  return null;
};

export default SystemNotificationsDashboard;
