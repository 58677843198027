import { useState, useContext } from "react";
import { AuthContext } from "#contexts/auth";
import { AppStateContext } from "#contexts/appState";
import NotificationIcon from "../static/images/notificationIcon.png";
import HeaderWithArrow from "#newUiComponents/commons/HeaderWithArrow";
import ConsumerNotificationsDashboard from "#newUiComponents/notificationV2/ConsumerNotificationsDashboard";
import SystemNotificationsDashboard from "#newUiComponents/notificationV2/SystemNotificationsDashboard";
import NoNotificationsDataAvailable from "#newUiComponents/notificationV2/NoNotificationsDataAvailable";
import { GET_NOTIFICATIONS_TYPE } from "#queries";
import { useQuery } from "#hooks/useQuery";
import { ChevronRightIcon } from "@heroicons/react/outline";

const NOTIFICATIONS_MODES = {
  CONSUMER_NOTIFICATION: "CONSUMER_NOTIFICATION",
  SYSTEM_NOTIFICATION: "SYSTEM_NOTIFICATION",
  HEADER_NOTIFICATION: "HEADER_NOTIFICATION",
};

const notificationManagementsTypes = [
  {
    key: "CONSUMER_NOTIFICATION",
    title: "Consumer Notification",
    subTitle:
      "Create, customize, and track all notifications sent to your customers, ensuring seamless communication",
  },
  {
    key: "SYSTEM_NOTIFICATION",
    title: "System Notification",
    subTitle:
      "Manage alerts and updates for internal teams to keep operations aligned and informed",
  },
];

const USER_ROLE_ENUM = {
  ADMIN: "ADMIN",
};

const NotificationsV2 = () => {
  const auth = useContext(AuthContext);
  const appState = useContext(AppStateContext);
  const notificationsTypesQuery = useQuery(GET_NOTIFICATIONS_TYPE);
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);

  const onSelectedNotificationType = (notificationType) => {
    if (notificationType) {
      const selectedNotificationType = notificationManagementsTypes
        ? notificationManagementsTypes?.filter(
            (notificationT) => notificationT?.key === notificationType,
          )
        : [];
      setSelectedNotificationType(
        selectedNotificationType?.length !== 0
          ? selectedNotificationType[0]
          : null,
      );
    }
  };

  const fetchNotificationsEvents = async (payload) => {
    if (!payload) {
      return { data: [], error: null };
    }

    try {
      appState.setLoading();
      const getNotificationsTypesResponse =
        await notificationsTypesQuery.fetchData(payload);
      appState.removeLoading();
      return { ...getNotificationsTypesResponse };
    } catch (error) {
      appState.removeLoading();
      return { data: [], error };
    }
  };

  return (
    <div className="min-h-screen bg-white font-inter">
      <div className="mb-4 px-8 pt-8 font-inter">
        <div className="flex items-center font-inter">
          <div className="flex-1">
            <HeaderWithArrow
              headerTitle={`${selectedNotificationType ? selectedNotificationType?.title : "Notification Management"}`}
              description={`${selectedNotificationType ? selectedNotificationType?.subTitle : "Effortlessly manage and streamline all consumer and internal notifications from one central hub"}`}
              isArrow={selectedNotificationType ? true : false}
              arrowAction={() => setSelectedNotificationType(null)}
              isLearnMore={false}
            />
          </div>
          <div className="mb-4 flex items-center justify-end space-x-2"></div>
        </div>
      </div>
      {!selectedNotificationType &&
      auth?.user?.role === USER_ROLE_ENUM?.ADMIN ? (
        <div className="grid grid-cols-2 gap-4 px-8">
          <div
            onClick={() =>
              onSelectedNotificationType(
                NOTIFICATIONS_MODES?.CONSUMER_NOTIFICATION,
              )
            }
            className="flex cursor-pointer items-center rounded-lg border p-6 shadow-sm transition hover:shadow-md">
            <div className="mr-4 flex items-center justify-center">
              <img src={NotificationIcon} alt="Icon" className="h-20 w-20" />
            </div>

            <div className="flex-1">
              <h3 className="text-lg font-semibold">Consumer Notifications</h3>
              <p className="text-sm text-gray-500">
                Create, customize, and track all notifications sent to your
                customers, ensuring seamless communication
              </p>
            </div>

            <div className="ml-4 font-semibold">
              <ChevronRightIcon className="h-6 w-6" />
            </div>
          </div>
          <div
            onClick={() =>
              onSelectedNotificationType(
                NOTIFICATIONS_MODES?.SYSTEM_NOTIFICATION,
              )
            }
            className="flex cursor-pointer items-center rounded-lg border p-6 shadow-sm transition hover:shadow-md">
            <div className="mr-4 flex items-center justify-center">
              <img src={NotificationIcon} alt="Icon" className="h-20 w-20" />
            </div>

            <div className="flex-1">
              <h3 className="text-lg font-semibold">System Notifications</h3>
              <p className="text-sm text-gray-500">
                Manage alerts and updates for internal teams to keep operations
                aligned and informed
              </p>
            </div>

            <div className="ml-4 font-semibold">
              <ChevronRightIcon className="h-6 w-6" />
            </div>
          </div>
        </div>
      ) : (
        !selectedNotificationType && (
          <NoNotificationsDataAvailable
            width="25%"
            message="No notifications to be shown"
          />
        )
      )}
      {selectedNotificationType &&
        selectedNotificationType?.key ===
          NOTIFICATIONS_MODES?.CONSUMER_NOTIFICATION && (
          <ConsumerNotificationsDashboard
            fetchNotificationsEvents={fetchNotificationsEvents}
          />
        )}
      {selectedNotificationType &&
        selectedNotificationType?.key ===
          NOTIFICATIONS_MODES?.SYSTEM_NOTIFICATION && (
          <SystemNotificationsDashboard
            fetchNotificationsEvents={fetchNotificationsEvents}
          />
        )}
    </div>
  );
};

export default NotificationsV2;
