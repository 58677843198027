import WarehouseStorageSections from "../../components/storageManagement/WarehouseStorageSections";

const StorageManagementHome = ({
  warehouses,
  selectedStorageSection,
  setSelectedStorageSection,
  INITIAL_STORAGE_SECTION_STATE,
  setLocationTree,
  storageSections,
  setStorageSections,
  locationTypes,
  deleteStorageSection,
  storageSectionStatusUpdate,
  setStorageSectionStatusUpdate,
  submitStorageSectionStatusUpdate,
  setLoading,
  buildLocationTreeFromStorageSection,
  showAlert,
  storageSectionToDelete,
  setStorageSectionToDelete,
  getCurrentHierarchyArray,
  printBarcodesForStorageSection,
  printSelectedBarcodes,
  selectedPrints,
  setSelectedPrints,
}) => {
  return (
    <>
      <div className="min-h-screen bg-white px-3 py-5">
        <div className="mt-2">
          <WarehouseStorageSections
            storageSections={storageSections}
            setStorageSections={setStorageSections}
            warehouses={warehouses}
            selectedStorageSection={selectedStorageSection}
            setSelectedStorageSection={setSelectedStorageSection}
            INITIAL_STORAGE_SECTION_STATE={INITIAL_STORAGE_SECTION_STATE}
            setLocationTree={setLocationTree}
            locationTypes={locationTypes}
            deleteStorageSection={deleteStorageSection}
            storageSectionStatusUpdate={storageSectionStatusUpdate}
            setStorageSectionStatusUpdate={setStorageSectionStatusUpdate}
            submitStorageSectionStatusUpdate={submitStorageSectionStatusUpdate}
            setLoading={setLoading}
            buildLocationTreeFromStorageSection={
              buildLocationTreeFromStorageSection
            }
            showAlert={showAlert}
            storageSectionToDelete={storageSectionToDelete}
            setStorageSectionToDelete={setStorageSectionToDelete}
            getCurrentHierarchyArray={getCurrentHierarchyArray}
            printBarcodesForStorageSection={printBarcodesForStorageSection}
            printSelectedBarcodes={printSelectedBarcodes}
            selectedPrints={selectedPrints}
            setSelectedPrints={setSelectedPrints}
          />
        </div>
      </div>
    </>
  );
};

export default StorageManagementHome;
