import { Route, Switch } from "react-router-dom";

import PrivateRoute from "#config/PrivateRoute";

// pages import
import NotFound from "#pages/404";
import Login from "#pages/login";
import PrivacyPolicy from "#pages/privacyPolicy";
import TermsOfUse from "#pages/termsOfUse";
import Disclaimer from "#pages/disclaimer";
import Warehouses from "#pages/warehouses";
import Customers from "#pages/customers";
import Users from "#pages/users";
import Devices from "#pages/devices";
import Stations from "#pages/stations";
import Orders from "#pages/orders";
import SalesManagement from "#pages/salesManagement";
import OutboundExceptions from "#pages/outboundExceptions";
import Outbound from "#pages/outbound";
import Inbound from "#pages/inbound";
import InventoryOperations from "#pages/inventoryOperations";
import InventoryOverview from "#pages/inventoryOverview";
import InventoryLedger from "#pages/inventoryLedger";
import Bundler from "#pages/bundler";
import AddUpdateSkuBundle from "#pages/addUpdateSkuBundle";
import Forecasts from "#pages/forecasts";
import Rules from "#pages/rules";
import Integrations from "#pages/integrations";
import Consignments from "#pages/consignments";
import Stats from "#pages/stats";
import Receiver from "#pages/receiver";
import Putaway from "#pages/putaway";
import ConsignmentPutaway from "#pages/consignmentPutaway";
import BundlePutaway from "#pages/bundlePutaway";
import ReceivedSkus from "#pages/receivedSkus";
import Dashboard from "#pages/dashboard";
import AddGroup from "#pages/addGroup";
import AddRule from "#pages/addRule";
import Picker from "#pages/picker";
import UoM from "#pages/uom";
import OrderPicker from "#pages/orderPicker";
import BundlePicker from "#pages/bundlePicker";
import Packer from "#pages/packer";
import Prepper from "#pages/prepper";
import SkuBinMappings from "#pages/skuBinMappings";
import Products from "#pages/products";
import QualityChecker from "#pages/qualityChecker";
import Totes from "#pages/totes";
import Locations from "#pages/locations";
import Workflows from "#pages/workflows";
import BoxTypes from "#pages/boxTypes";
import SkuBoxMappings from "#pages/skuBoxMappings";
import Notifications from "#pages/notifications";
import Usage from "#pages/usage";
import BillingProfiles from "#pages/billing-profiles";
import Invoicing from "#pages/invoicing";
import Materials from "#pages/materials";
import CycleCountPlans from "#pages/cycleCountPlans";
import SetPassword from "#pages/setPassword";
import ResetPassword from "#pages/resetPassword";
import EmailVerification from "#pages/emailVerification";
import StockTransfer from "#pages/stockTransfer";
import VendorManagement from "#pages/vendorManagement";
import Reports from "#pages/reports";
import MyProfile from "#pages/myProfile";
import CatalogV2 from "#pages/catalogV2";
import Shippers from "#pages/shippers";
import Shipper from "#pages/shipper";
import { AuthContext } from "#contexts/auth";
import { useContext } from "react";
import ForbiddenPage from "../pages/ForbiddenError";
import PurchaseOrderListPage from "../pages/newUiPages/PurchaseOrderListPage";
import storageManagement from "../pages/storageManagement";
import AccountSuspended from "#pages/AccountSuspended";
import FbaInboundPage from "../pages/newUiPages/FbaInboundPage";
import { useFeatureFlags } from "#contexts/featureFlags";
import FeatureFlagsList from "#pages/flags";
import NotificationsV2 from "#pages/notificationsV2";
import { notificationPermissionAccess } from "#utils/helper-functions";
import OutboundPlan from "#newUiComponents/orderManagement/outboundPlan/OutboundPlan";

const Routes = () => {
  const { isBundlingWorkflowEnabled, salesPortalEnabled, isBranded } =
    useFeatureFlags();

  const { notificationV2Enabled } = useFeatureFlags();

  const auth = useContext(AuthContext);
  const hasNotificationAccess = notificationPermissionAccess(auth?.user);
  return (
    <>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/account-suspended" component={AccountSuspended} />

        <PrivateRoute
          exact
          path="/customers"
          component={Customers}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/warehouses"
          component={Warehouses}
          adminRoute={true}
        />
        <PrivateRoute exact path="/users" component={Users} adminRoute={true} />
        <PrivateRoute
          exact
          path="/users/:userName"
          component={Users}
          adminRoute={true}
        />
        <PrivateRoute exact path="/devices" component={Devices} />
        <PrivateRoute exact path="/totes" component={Totes} />
        <PrivateRoute exact path="/boxTypes" component={BoxTypes} />
        <PrivateRoute exact path="/locations" component={Locations} />
        <PrivateRoute exact path="/uom" component={UoM} />
        <PrivateRoute exact path="/stations" component={Stations} />
        <PrivateRoute
          exact
          path="/orders"
          component={Orders}
          adminRoute={true}
        />
        <PrivateRoute
          path="/orders/:orderId"
          component={Orders}
          adminRoute={true}
        />
        <PrivateRoute exact path="/fbaInbound" component={FbaInboundPage} />
        <PrivateRoute path="/fbaInbound/:planId" component={FbaInboundPage} />
        <PrivateRoute
          exact
          path="/outboundExceptions"
          component={OutboundExceptions}
        />

        {salesPortalEnabled && (
          <PrivateRoute
            exact
            path="/salesManagement"
            component={SalesManagement}
          />
        )}
        <PrivateRoute
          exact
          path="/outboundSortingOverview"
          component={Orders}
        />
        <PrivateRoute exact path="/inboundSortingOverview" component={Orders} />
        <PrivateRoute exact path="/outbound" component={Outbound} />
        <PrivateRoute exact path="/inbound" component={Inbound} />
        <PrivateRoute
          exact
          path="/inventoryOperations"
          component={InventoryOperations}
        />
        <PrivateRoute
          exact
          path="/consignments"
          component={Consignments}
          adminRoute={true}
        />
        <PrivateRoute
          path="/consignments/:orderId"
          component={Consignments}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/outboundPlans"
          component={OutboundPlan}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/purchaseOrders"
          component={PurchaseOrderListPage}
        />
        <PrivateRoute
          path="/purchaseOrders/:poId"
          component={PurchaseOrderListPage}
        />

        <PrivateRoute
          exact
          path="/inventoryOverview"
          component={InventoryOverview}
          adminRoute={true}
        />
        {isBundlingWorkflowEnabled && (
          <PrivateRoute exact path="/bundler" component={Bundler} />
        )}
        {isBundlingWorkflowEnabled && (
          <PrivateRoute exact path="/picker/order" component={OrderPicker} />
        )}
        {isBundlingWorkflowEnabled && (
          <PrivateRoute exact path="/picker/bundle" component={BundlePicker} />
        )}
        {isBundlingWorkflowEnabled && (
          <PrivateRoute
            exact
            path="/putaway/order"
            component={ConsignmentPutaway}
          />
        )}
        {isBundlingWorkflowEnabled && (
          <PrivateRoute
            exact
            path="/putaway/bundle"
            component={BundlePutaway}
          />
        )}
        <PrivateRoute
          exact
          path="/addUpdateSkuBundle"
          component={AddUpdateSkuBundle}
        />
        <PrivateRoute
          exact
          path="/inventoryLedger"
          component={InventoryLedger}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/products"
          component={Products}
          adminRoute={true}
        />
        {isBranded && (
          <PrivateRoute
            exact
            path="/catalogs"
            component={CatalogV2}
            adminRoute={true}
          />
        )}
        <PrivateRoute exact path="/forecasts" component={Forecasts} />
        <PrivateRoute exact path="/workflows" component={Workflows} />
        <PrivateRoute exact path="/rules" component={Rules} />
        <PrivateRoute
          exact
          path="/reports"
          component={
            auth.user?.role && auth.user?.role.toLowerCase() === "admin"
              ? Reports
              : ForbiddenPage
          }
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/integrations"
          component={Integrations}
          adminRoute={true}
        />
        <PrivateRoute exact path="/stats" component={Stats} />
        <PrivateRoute exact path="/receivedSkus" component={ReceivedSkus} />
        <PrivateRoute
          exact
          path="/dashboard"
          component={Dashboard}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/flags"
          component={FeatureFlagsList}
          adminRoute={true}
        />
        <PrivateRoute exact path="/group" component={AddGroup} />
        <PrivateRoute exact path="/rule" component={AddRule} />
        <PrivateRoute exact path="/picker" component={Picker} />
        <PrivateRoute exact path="/packer" component={Packer} />
        <PrivateRoute exact path="/prepper" component={Prepper} />
        <PrivateRoute exact path="/receiver" component={Receiver} />
        <PrivateRoute exact path="/putaway" component={Putaway} />
        <PrivateRoute exact path="/shipper" component={Shipper} />
        <PrivateRoute exact path="/qualityChecker" component={QualityChecker} />
        <PrivateRoute exact path="/skuBinMappings" component={SkuBinMappings} />
        <PrivateRoute
          exact
          path="/storage-management"
          component={storageManagement}
        />
        <PrivateRoute exact path="/skuBoxMappings" component={SkuBoxMappings} />
        <PrivateRoute
          exact
          path="/notificationsv2"
          component={
            notificationV2Enabled && hasNotificationAccess
              ? NotificationsV2
              : ForbiddenPage
          }
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/notifications"
          component={
            hasNotificationAccess && !notificationV2Enabled
              ? Notifications
              : ForbiddenPage
          }
          adminRoute={true}
        />
        <PrivateRoute exact path="/usage" component={Usage} />
        <PrivateRoute
          exact
          path="/invoicing"
          component={Invoicing}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/billing-profiles"
          component={BillingProfiles}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/binToBinTransfer"
          component={StockTransfer}
          adminRoute={true}
        />
        <PrivateRoute exact path="/materials" component={Materials} />
        <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
        <Route exact path="/termsOfUse" component={TermsOfUse} />
        <Route exact path="/disclaimer" component={Disclaimer} />
        <PrivateRoute
          exact
          path="/vendors"
          component={VendorManagement}
          adminRoute={true}
        />
        <PrivateRoute
          exact
          path="/cycle-count-plans"
          component={CycleCountPlans}
        />
        <PrivateRoute exact path="/my-profile" component={MyProfile} />
        <PrivateRoute exact path="/shippers" component={Shippers} />

        <Route exact path="/:token/set-password" component={SetPassword} />
        <Route exact path="/:token/reset-password" component={ResetPassword} />
        <Route
          exact
          path="/:token/email-verification"
          component={EmailVerification}
        />
        <Route component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
