import promiseState from "#redux/ReduxUtils/reduxReducerHelper";

import { createSlice } from "@reduxjs/toolkit";
import {
  startAmazonInboundPlanCreation,
  getErrorsInInboundPlan,
  getPackingOptions,
  setPackingInformation,
  confirmPackingOptions,
  cancelAmazonInboundPlan,
  getBoxTypes,
  scanBarcode,
  getPlacementsOptions,
  getTransportationOptions,
  getDeliveryWindowOptions,
  confirmPlacementOption,
  confirmTransportationOptions,
  confirmDeliveryWindowOptions,
  listShipments,
  regenerateTransportationOptions,
  confirmPackingCompleted,
  confirmPackItem,
  updateShipmentTrackingInformation,
  packItems,
  unPackItems,
  setPackerBoxes,
  unPackBox,
  duplicateBox,
  dropItems,
} from "./fbaPackingActions";

import { addPromiseHandlers } from "#redux/ReduxUtils/graphqlApi";
import { startFbaInboundShipmentPlan } from "#redux/FbaInbound/fbaInboundActions";

const stateKeysEnums = {
  AMAZON_INBOUND_PLAN_CREATION_RESULT: "amazonInboundPlanCreationResult",
  ERROR_IN_INBOUND_PLAN_RESULT: "errorsInInboundPlanResult",
  PACKING_OPTIONS_RESULT: "packingOptionsResult",
  PACKING_INFORMATION_RESULT: "packingInformationResult",
  CONFIRM_PACKING_OPTIONS_RESULT: "confirmPackingOptionsResult",
  CANCEL_AMAZON_INBOUND_PLAN_RESULT: "cancelAmazonInboundPlanResult",
  GET_BOX_TYPES_RESULT: "getBoxTypesResult",
  SCAN_BARCODE_RESULT: "scanBarcodeResult",
  GET_PLACMENT_OPTIONS_RESULT: "getPlacementOptionsResult",
  GET_TRANSPORTATION_OPTIONS_RESULT: "getTransportationsOptionsResult",
  GET_DELIVERY_WINDOW_OPTIONS_RESULT: "getDeliverWindowOptionsResult",
  CONFIRM_PLACEMENT_OPTION_RESULT: "confirmPlacementOptionResult",
  CONFIRM_TRANSPORTATION_OPTIONS_RESULT: "confirmTransportationsOptionsResult",
  CONFIRM_DELIVERY_WINDOW_OPTIONS_RESULT: "confirmDeliveryWindowOptionsResult",
  LIST_SHIPMENTS_RESULT: "listShipmentsResult",
  REGENERATE_TRANSPORTATION_OPTIONS_RESULT:
    "regenerateTransportationOptionsResult",
  CONFIRM_PACKING_COMPLETED_RESULT: "confirmPackingCompletedResult",
  CONFIRM_PACK_ITEM_RESULT: "confirmPackItemResult",
  UPDATE_SHIPMENT_TRACKING_INFORMATION_RESULT:
    "updateShipmentTrackingInformationResult",
  PACK_ITEMS_RESULT: "packItemsResult",
  UNPACK_ITEM_RESULT: "unpackItemsResult",
  SET_PACKER_BOXES_RESULT: "setPackerBoxesResult",
  UNPACK_BOX_RESULT: "unpackBoxResult",
  DUPLICATE_BOX_RESULT: "duplicateBoxResult",
  START_FBA_INBOUND_SHIPMENT_PLAN_RESULT: "startFbaInboundShipmentPlanResult",
  DROP_ITEMS_RESULT: "dropItemsResult",
};

const initialState = {
  [stateKeysEnums.AMAZON_INBOUND_PLAN_CREATION_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.ERROR_IN_INBOUND_PLAN_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.PACKING_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.PACKING_INFORMATION_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.CONFIRM_PACKING_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.CANCEL_AMAZON_INBOUND_PLAN_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.GET_BOX_TYPES_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.SCAN_BARCODE_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.GET_PLACMENT_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.GET_TRANSPORTATION_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.CONFIRM_DELIVERY_WINDOW_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.CONFIRM_PLACEMENT_OPTION_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.CONFIRM_TRANSPORTATION_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.GET_DELIVERY_WINDOW_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.LIST_SHIPMENTS_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.REGENERATE_TRANSPORTATION_OPTIONS_RESULT]: {
    ...promiseState(false, false, false, []),
  },
  [stateKeysEnums.CONFIRM_PACKING_COMPLETED_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.CONFIRM_PACK_ITEM_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
  [stateKeysEnums.UPDATE_SHIPMENT_TRACKING_INFORMATION_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
  [stateKeysEnums.PACK_ITEMS_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
  [stateKeysEnums.UNPACK_ITEM_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
  [stateKeysEnums.SET_PACKER_BOXES_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
  [stateKeysEnums.UNPACK_BOX_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
  [stateKeysEnums.DUPLICATE_BOX_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
  [stateKeysEnums.START_FBA_INBOUND_SHIPMENT_PLAN_RESULT]: {
    ...promiseState(false, false, false, {}),
  },
  [stateKeysEnums.DROP_ITEMS_RESULT]: {
    ...promiseState(true, true, true, {}),
  },
};

const fbaPackingSlice = createSlice({
  name: "fbaPacking",
  initialState,
  reducers: {
    resetFbaPackingState: (state) => {
      // Reset each key in the state to its initial value
      Object.keys(initialState).forEach((key) => {
        state[key] = { ...initialState[key] };
      });
    },
    resetFbaPackingAmazonInboundPlanCreationResult: (state) => {
      state[stateKeysEnums.AMAZON_INBOUND_PLAN_CREATION_RESULT] = {
        ...promiseState(false, false, false, {}),
      };
    },
    resetErrorInInboundPlanResult: (state) => {
      state[stateKeysEnums.ERROR_IN_INBOUND_PLAN_RESULT] = {
        ...promiseState(false, false, false, []),
      };
    },
    resetPackingOptionsResult: (state) => {
      state[stateKeysEnums.PACKING_OPTIONS_RESULT] = {
        ...promiseState(false, false, false, []),
      };
    },
    resetPackingInformationResult: (state) => {
      state[stateKeysEnums.PACKING_INFORMATION_RESULT] = {
        ...promiseState(false, false, false, {}),
      };
    },
    resetConfirmPackingOptionsResult: (state) => {
      state[stateKeysEnums.CONFIRM_PACKING_OPTIONS_RESULT] = {
        ...promiseState(false, false, false, {}),
      };
    },
    resetPlacementOptionsResult: (state) => {
      state[stateKeysEnums.GET_PLACMENT_OPTIONS_RESULT] = {
        ...promiseState(false, false, false, []),
      };
    },
    resetDeliveryWindowOptionsResult: (state) => {
      state[stateKeysEnums.GET_DELIVERY_WINDOW_OPTIONS_RESULT] = {
        ...promiseState(false, false, false, []),
      };
    },
  },
  extraReducers: (builder) => {
    addPromiseHandlers(
      builder,
      startAmazonInboundPlanCreation,
      stateKeysEnums.AMAZON_INBOUND_PLAN_CREATION_RESULT,
    );
    addPromiseHandlers(
      builder,
      getErrorsInInboundPlan,
      stateKeysEnums.ERROR_IN_INBOUND_PLAN_RESULT,
    );
    addPromiseHandlers(
      builder,
      getPackingOptions,
      stateKeysEnums.PACKING_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      setPackingInformation,
      stateKeysEnums.PACKING_INFORMATION_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmPackingOptions,
      stateKeysEnums.CONFIRM_PACKING_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      cancelAmazonInboundPlan,
      stateKeysEnums.CANCEL_AMAZON_INBOUND_PLAN_RESULT,
    );
    addPromiseHandlers(
      builder,
      getBoxTypes,
      stateKeysEnums.GET_BOX_TYPES_RESULT,
    );
    addPromiseHandlers(
      builder,
      scanBarcode,
      stateKeysEnums.SCAN_BARCODE_RESULT,
    );
    addPromiseHandlers(
      builder,
      getPlacementsOptions,
      stateKeysEnums.GET_PLACMENT_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      getTransportationOptions,
      stateKeysEnums.GET_TRANSPORTATION_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      getDeliveryWindowOptions,
      stateKeysEnums.GET_DELIVERY_WINDOW_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmPlacementOption,
      stateKeysEnums.CONFIRM_PLACEMENT_OPTION_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmTransportationOptions,
      stateKeysEnums.CONFIRM_TRANSPORTATION_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmDeliveryWindowOptions,
      stateKeysEnums.CONFIRM_DELIVERY_WINDOW_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      listShipments,
      stateKeysEnums.LIST_SHIPMENTS_RESULT,
    );
    addPromiseHandlers(
      builder,
      regenerateTransportationOptions,
      stateKeysEnums.REGENERATE_TRANSPORTATION_OPTIONS_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmPackingCompleted,
      stateKeysEnums.CONFIRM_PACKING_COMPLETED_RESULT,
    );
    addPromiseHandlers(
      builder,
      confirmPackItem,
      stateKeysEnums.CONFIRM_PACK_ITEM_RESULT,
    );
    addPromiseHandlers(
      builder,
      updateShipmentTrackingInformation,
      stateKeysEnums.UPDATE_SHIPMENT_TRACKING_INFORMATION_RESULT,
    );
    addPromiseHandlers(builder, packItems, stateKeysEnums.PACK_ITEMS_RESULT);
    addPromiseHandlers(builder, unPackItems, stateKeysEnums.UNPACK_ITEM_RESULT);
    addPromiseHandlers(
      builder,
      setPackerBoxes,
      stateKeysEnums.SET_PACKER_BOXES_RESULT,
    );
    addPromiseHandlers(builder, unPackBox, stateKeysEnums.UNPACK_BOX_RESULT);
    addPromiseHandlers(
      builder,
      duplicateBox,
      stateKeysEnums.DUPLICATE_BOX_RESULT,
    );
    addPromiseHandlers(
      builder,
      startFbaInboundShipmentPlan,
      stateKeysEnums.START_FBA_INBOUND_SHIPMENT_PLAN_RESULT,
    );
    addPromiseHandlers(builder, dropItems, stateKeysEnums.DROP_ITEMS_RESULT);
  },
});

export const {
  resetConfirmPackingOptionsResult,
  resetErrorInInboundPlanResult,
  resetFbaPackingAmazonInboundPlanCreationResult,
  resetFbaPackingState,
  resetPackingInformationResult,
  resetPackingOptionsResult,
} = fbaPackingSlice.actions;

export default fbaPackingSlice.reducer;
