import React from "react";
import { Tooltip } from "antd";
import {
  CONSTANT_ENUM,
  OPERATORS_ENUM,
} from "#newUiComponents/notificationV2/ConsumerNotifications";

import { twMerge } from "tailwind-merge";
import { ChevronRightIcon } from "@heroicons/react/outline";

const RuleViewer = ({ rules, eventsList }) => {
  const getOperatorLabel = (operator) => {
    return OPERATORS_ENUM[operator]?.label || operator;
  };

  const renderCondition = (condition) => {
    if (!condition || !condition.field) return null;

    const getFieldType = (events, key) =>
      events.find(({ payloadKey }) => payloadKey === key)?.name || "";

    return (
      <div className="flex items-center space-x-2 py-1">
        <div className="rounded border border-gray-200 bg-gray-50 px-2 py-1 text-sm">
          {getFieldType(eventsList, condition.field)}
        </div>
        {condition.operator && (
          <div className="h-4 w-4 text-gray-400">
            <ChevronRightIcon className="h-4 w-4" />
          </div>
        )}
        {condition.operator && (
          <div className="rounded border border-blue-200 bg-blue-50 px-2 py-1 text-sm text-[#224E73]">
            {getOperatorLabel(condition.operator)}
          </div>
        )}
        {condition.operator && (
          <div className="h-4 w-4 text-gray-400">
            <ChevronRightIcon className="h-4 w-4" />
          </div>
        )}
        {condition.operator && condition.value && (
          <div className="w-40 truncate rounded border border-gray-200 bg-gray-50 px-2 py-1 text-sm">
            <Tooltip
              placement="topLeft"
              title={
                Array.isArray(condition.value)
                  ? condition.value.map((val) => val.name).join(", ")
                  : condition.value
              }>
              {Array.isArray(condition.value)
                ? condition.value.map((val) => val.name).join(", ")
                : condition.value}
            </Tooltip>
          </div>
        )}
      </div>
    );
  };

  const renderGroup = (group, level = 0) => {
    if (!group || !Array.isArray(group.conditions)) return null;

    return (
      <div
        className={twMerge(`ml-2 border-l-4 border-gray-100 pl-${level * 4}`)}>
        {group.conditions.map((condition, index) => (
          <div key={index} className="mb-2 ml-1">
            {index > 0 && condition.criteria && (
              <div className="flex items-center py-1">
                <div
                  className={twMerge(
                    `rounded px-2 py-1 text-xs ${condition.criteria === CONSTANT_ENUM?.and ? "border-green-300 bg-green-100 text-[#00ff00]" : condition.criteria === CONSTANT_ENUM?.or ? "border-red-300 bg-red-100 text-[#FF0000]" : "border-gray-300 bg-gray-100 text-black"}`,
                  )}>
                  {condition.criteria}
                </div>
              </div>
            )}
            {condition.type === CONSTANT_ENUM?.group
              ? renderGroup(condition, level + 1)
              : renderCondition(condition)}
          </div>
        ))}
      </div>
    );
  };

  const renderRule = (rule) => {
    if (rule.operator) {
      return renderCondition(rule);
    }
    return (
      <div className="flex flex-wrap">
        <div className="rounded border border-gray-200 bg-gray-50 px-2 py-1 text-sm">
          {rule.field}
        </div>
      </div>
    );
  };

  if (!Array.isArray(rules) || rules.length === 0) {
    return (
      <div className="w-full max-w-2xl rounded bg-white p-6 shadow-md">
        <div className="text-center text-gray-500">No rules defined</div>
      </div>
    );
  }

  return (
    <div className="max-h-64 w-full overflow-hidden rounded-lg border border-gray-300 pr-px">
      <div className="max-h-64 w-full overflow-y-auto p-4">
        {rules.map((rule, index) => (
          <div key={index} className="mb-4">
            {index > 0 && rule.criteria && (
              <div className="flex items-center py-1">
                <div
                  className={twMerge(
                    `rounded px-2 py-1 text-xs ${rule.criteria === CONSTANT_ENUM?.and ? "border-green-300 bg-green-100 text-[#00ff00]" : rule.criteria === CONSTANT_ENUM?.or ? "border-red-300 bg-red-100 text-[#FF0000]" : "border-gray-300 bg-gray-100 text-black"}`,
                  )}>
                  {rule.criteria}
                </div>
              </div>
            )}
            {rule.type === CONSTANT_ENUM?.group
              ? renderGroup(rule)
              : renderRule(rule)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default RuleViewer;
