import { Popover } from "antd";

const BoxTypePopover = ({
  onSelectBox,
  trigger,
  isOpen,
  setIsOpen,
  boxTypes = [],
}) => {
  console.log(boxTypes, "boxTypes from popover...");
  const content = (
    <div className="h-96 w-64 overflow-auto">
      {(boxTypes || [])?.map((type) => (
        <div
          key={type.name}
          className="cursor-pointer p-2 hover:bg-gray-100"
          onClick={() => {
            onSelectBox(type);
            setIsOpen(false);
          }}>
          <p>{type.isCustom ? type.name : `${type.name}`}</p>
          {!type.isCustom && (
            <p className="mt-2">{`${type.length} * ${type.width} * ${type.height} (in), ${type.weight} lbs`}</p>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <Popover
      placement="bottomLeft"
      content={content}
      trigger="click"
      open={isOpen}
      onOpenChange={setIsOpen}>
      {trigger}
    </Popover>
  );
};

export default BoxTypePopover;
