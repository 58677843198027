import {
  InformationCircleIcon,
  DotsVerticalIcon,
} from "@heroicons/react/solid";
import HollowButton from "#utils/hollowButton";
import FilledButton from "#utils/filledButton";
import CustomTable from "#newUiComponents/commons/CustomTable";
import ReactTooltip from "react-tooltip";
import PrintSettingsModal from "#components/common/PrintSettingsModal";
import { useState } from "react";
import { Dropdown } from "antd";
import NewModal from "#newUiComponents/commons/NewModal";
import { useQuery } from "#hooks/useQuery";
import { SEARCH_LOCATIONS } from "../../queries";
import { DELETE_LOCATION } from "../../mutations";
import { useEffect } from "react";
import AutoCompleteMultiSelect from "#newUiComponents/commons/AutoCompleteMultiSelect";
import CheckBox from "#newUiComponents/commons/CheckBox";
import CustomBadge from "#newUiComponents/commons/CustomBadge";

const BarcodeView = ({
  warehouses,
  storageSectionsMap,
  locationTypes,
  queueSinglePrint,
  selectedPrints,
  printSelectedBarcodes,
  setSelectedPrints,
  LocationStatusEnum,
  showAlert,
}) => {
  const ALERT_VISIBILITY_IN_MS = 5_000;
  const SHORT_ALERT_VISIBILITY_IN_MS = 2_000;
  const [locationToDelete, setLocationToDelete] = useState(null);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [showWarehouseDropdown, setShowWarehouseDropdown] = useState(false);

  const deleteLocationQuery = useQuery(DELETE_LOCATION);
  const searchLocationsForBarcodeViewQuery = useQuery(SEARCH_LOCATIONS);
  const [tableData, setTableData] = useState([]);
  const [totalDataCount, setTotalDataCount] = useState(0);

  // Pagination and Search States
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [searchTerm, setSearchTerm] = useState("");

  // States for filters
  const [filters, setFilters] = useState({
    warehouses: warehouses.map((wh) => wh.id),
    statuses: [LocationStatusEnum.AVAILABLE, LocationStatusEnum.UNAVAILABLE],
  });

  // Fetch locations whenever currentPage, pageSize, or searchTerm changes
  useEffect(() => {
    searchLocations();
  }, [currentPage, pageSize, searchTerm]);

  useEffect(() => {
    if (searchLocationsForBarcodeViewQuery.data) {
      const newData =
        searchLocationsForBarcodeViewQuery.data.searchLocations.data.locations;
      const fetchedTotalCount =
        searchLocationsForBarcodeViewQuery.data.searchLocations.data.total; // use 'total' key
      setTotalDataCount(fetchedTotalCount);

      if (currentPage === 1) {
        setTableData(newData);
      } else {
        setTableData((prevData) => [...prevData, ...newData]);
      }
    } else if (searchLocationsForBarcodeViewQuery.error) {
      showAlert(
        "Error fetching locations. Please try again",
        "error",
        ALERT_VISIBILITY_IN_MS,
      );
    }
  }, [
    searchLocationsForBarcodeViewQuery.loading,
    searchLocationsForBarcodeViewQuery.error,
    searchLocationsForBarcodeViewQuery.data,
  ]);

  const submitFiltersForBarcodeView = () => {
    searchLocations(filters, searchTerm);
  };

  const searchLocations = () => {
    const query = {
      pagination: {
        limit: pageSize,
        offset: (currentPage - 1) * pageSize,
      },
    };

    if (searchTerm) {
      query.code = searchTerm;
    }

    if (filters?.warehouses) {
      query.warehouses = filters.warehouses;
    }

    if (filters?.statuses) {
      if (!filters.statuses.includes("UNAVAILABLE")) {
        query.excludeInactive = true;
      }
      if (!filters.statuses.includes("AVAILABLE")) {
        query.excludeActive = true;
      }
    }
    searchLocationsForBarcodeViewQuery.fetchData(query);
  };

  const onChangeSearchTerm = (term) => {
    // Reset to first page whenever search changes
    setCurrentPage(1);
    setSearchTerm(term);
  };

  const handleSingleLocationDelete = async (locationId) => {
    try {
      const deleteLocationResponse = await deleteLocationQuery.fetchData({
        locationId,
      });
      const error =
        deleteLocationResponse?.error?.message ||
        deleteLocationResponse?.data?.deleteLocation?.error;
      if (error) {
        throw new Error(`Cannot delete location : ${error}`);
      }

      showAlert(
        "Location deleted successfully",
        "success",
        SHORT_ALERT_VISIBILITY_IN_MS,
      );
    } catch (e) {
      console.error(e);
      showAlert(e.message, "error", ALERT_VISIBILITY_IN_MS);
      return;
    }
  };

  const GreenBadge = ({ text }) => {
    return (
      <div className="me-2 h-8 w-max rounded-full bg-green-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-green-800">
        {text}
      </div>
    );
  };

  const RedBadge = ({ text }) => {
    return (
      <div className="me-2 h-8 w-max rounded-full bg-red-100 px-2.5 py-0.5 pt-1.5 text-sm font-medium text-red-800">
        {text}
      </div>
    );
  };

  const StorageSectionColorBadge = ({ status, reason }) => {
    if (!status) {
      return null;
    }
    let modifiedStatusToDisplay = status.replace(/_/g, " ");
    modifiedStatusToDisplay = modifiedStatusToDisplay
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    switch (status) {
      case LocationStatusEnum.AVAILABLE:
        return <GreenBadge text={modifiedStatusToDisplay} />;
      case LocationStatusEnum.UNAVAILABLE:
        return (
          <div className="flex items-center">
            <RedBadge text={modifiedStatusToDisplay} />
            {reason && (
              <div data-tip={reason}>
                <InformationCircleIcon className="ml-2 h-5 w-5 text-red-800" />
              </div>
            )}
            <ReactTooltip place="top" type="dark" effect="solid" />
          </div>
        );
      default:
        return null;
    }
  };

  const menuItems = (id) => {
    return [
      {
        title: "Print Barcode",
        onClick: () => {
          const code = tableData.find((data) => data.id === id)?.code;
          queueSinglePrint(code);
        },
      },

      {
        title: "Delete Hierarchy",
        onClick: () => {
          setLocationToDelete(id);
        },
      },
    ];
  };

  const handleApplyFilters = () => {
    // Implement your filtering logic here
    // For now, just close the modal
    setShowFilterModal(false);
    submitFiltersForBarcodeView();
  };

  const handleResetFilters = () => {
    setFilters({
      warehouses: warehouses.map((wh) => wh.id),
      statuses: [LocationStatusEnum.AVAILABLE, LocationStatusEnum.UNAVAILABLE],
    });
  };

  const handleWarehouseSelection = (selected) => {
    if (!selected) return;

    if (filters.warehouses.includes(selected.id)) {
      setFilters({
        ...filters,
        warehouses: filters.warehouses.filter((id) => id !== selected.id),
      });
    } else {
      setFilters({
        ...filters,
        warehouses: [...filters.warehouses, selected.id],
      });
    }
  };

  return (
    <>
      <div className="mt-2 w-full overflow-hidden transition-all duration-500">
        <CustomTable
          columns={[
            {
              key: "warehouse",
              title: "Warehouse",
              render: (warehouse) => {
                const warehouseName = warehouses.find(
                  (wh) => wh.id === warehouse,
                )?.name;
                return <span>{warehouseName}</span>;
              },
            },
            {
              key: "storageSection",
              title: "Storage Section",
              render: (storageSection) => {
                const storageSectionName =
                  storageSectionsMap[storageSection]?.name || "";
                return <span>{storageSectionName}</span>;
              },
            },
            { key: "code", title: "Barcode" },
            {
              key: "type",
              title: "Bin Type",
              render: (type) => {
                const typeName = locationTypes.find(
                  (lt) => lt.id === type,
                )?.name;
                return <span>{typeName}</span>;
              },
            },
            {
              key: "activationStatus",
              title: "Status",
              render: (activationStatus) => (
                <StorageSectionColorBadge
                  status={
                    activationStatus
                      ? LocationStatusEnum.AVAILABLE
                      : LocationStatusEnum.UNAVAILABLE
                  }
                />
              ),
            },
            {
              key: "id",
              title: "Action",
              render: (id) => (
                <Dropdown
                  trigger={["hover"]}
                  menu={{
                    items: menuItems(id),
                  }}
                  overlayClassName="z-5"
                  dropdownRender={({ props }) => {
                    return (
                      <div className="w-auto rounded-md border bg-white p-2 text-sm text-gray-600 shadow-md">
                        {props.items.map((item) => (
                          <div
                            onClick={() => item["onClick"]()}
                            key={item.title}
                            className="cursor-pointer p-2 hover:bg-hoverHighlight hover:text-primaryAccent">
                            {item.title}
                          </div>
                        ))}
                      </div>
                    );
                  }}
                  placement="topRight"
                  arrow>
                  <DotsVerticalIcon className="h-6 w-6 cursor-pointer text-gray-400" />
                </Dropdown>
              ),
            },
          ]}
          data={tableData}
          isSearchable={true}
          searchPlaceholder={"Search by code"}
          onChangeSearchTerm={onChangeSearchTerm}
          isFilters={true}
          onClickFilters={() => setShowFilterModal(true)}
          isPagination={false}
          isInfiniteScroll={true}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
          pageSize={pageSize}
          setPageSize={setPageSize}
          totalDataCount={totalDataCount}
        />
      </div>

      {locationToDelete && (
        <NewModal
          isOpen={!!locationToDelete}
          onClose={() => setLocationToDelete(null)}
          title="Confirm">
          <div className="text-left text-base font-normal leading-6 tracking-normal">
            Are you sure you want to delete the selected hierarchy?
          </div>
          <div className="mt-4 flex w-full">
            <HollowButton
              text={"Cancel"}
              colour={"primaryAccent"}
              onClick={() => {
                setLocationToDelete(null);
              }}
              styles="ml-auto"
            />
            <FilledButton
              text={"Delete"}
              colour={"primaryAccent"}
              onClick={() => {
                handleSingleLocationDelete(locationToDelete);
                setLocationToDelete(null);
              }}
              className="ml-4"
            />
          </div>
        </NewModal>
      )}

      {selectedPrints && (
        <PrintSettingsModal
          setSelectedPrints={setSelectedPrints}
          selectedPrints={selectedPrints}
          printSelectedBarcodes={printSelectedBarcodes}
          disablePreview={selectedPrints.barcodes.length > 5}
        />
      )}

      {/* Updated Filter Modal */}
      {showFilterModal && (
        <NewModal
          isOpen={showFilterModal}
          onClose={() => setShowFilterModal(false)}
          title="Filters"
          resetModal={
            <span
              onClick={handleResetFilters}
              className="mr-4 cursor-pointer font-inter font-medium text-red-500">
              Reset Filter
            </span>
          }>
          <div className="bg-white p-4">
            {/* Display selected filters as tags (if any) */}
            {/* <div className="mb-6 space-y-2">
              {filters.statuses && filters.statuses.length > 0 && (
                <>
                  <div className="text-lg font-semibold">Selected Filters</div>
                  <div className="flex flex-wrap gap-2">
                    {filters.statuses.map((statusVal) => (
                      <span
                        key={statusVal}
                        className="inline-flex items-center gap-2 rounded-full border border-solid border-gray-300 bg-white px-3 py-1 font-inter text-xs font-medium">
                        <CustomBadge
                          label={statusVal}
                          color={
                            statusVal === LocationStatusEnum.AVAILABLE
                              ? "green"
                              : "red"
                          }
                          className="text-xs"
                        />
                        <XIcon
                          className="h-4 w-4 cursor-pointer text-gray-500"
                          onClick={() =>
                            setFilters({
                              ...filters,
                              statuses: filters.statuses.filter(
                                (s) => s !== statusVal,
                              ),
                            })
                          }
                        />
                      </span>
                    ))}
                  </div>
                </>
              )}
            </div> */}

            {/* Filter controls */}
            <div className="mb-8 space-y-6">
              {/* Warehouse Filter */}
              <div>
                <AutoCompleteMultiSelect
                  label="Warehouse"
                  options={warehouses}
                  selectedValues={warehouses.filter((w) =>
                    filters.warehouses.includes(w.id),
                  )}
                  setSelectedValues={handleWarehouseSelection}
                  placeholder="Select warehouses"
                  dropdownOpen={showWarehouseDropdown}
                  setDropdownOpen={setShowWarehouseDropdown}
                  multiSelect="tag"
                  onChange={handleWarehouseSelection}
                />
              </div>

              {/* Status Filter */}
              <div>
                <label className="mb-2 block font-medium">Status</label>
                <div className="flex flex-col space-y-4">
                  <CheckBox
                    checked={filters.statuses.includes(
                      LocationStatusEnum.AVAILABLE,
                    )}
                    onChange={() => {
                      const statuses = filters.statuses.includes(
                        LocationStatusEnum.AVAILABLE,
                      )
                        ? filters.statuses.filter(
                            (s) => s !== LocationStatusEnum.AVAILABLE,
                          )
                        : [...filters.statuses, LocationStatusEnum.AVAILABLE];
                      setFilters({ ...filters, statuses });
                    }}>
                    <CustomBadge
                      label="AVAILABLE"
                      color="green"
                      className="ml-2 text-xs"
                    />
                  </CheckBox>

                  <CheckBox
                    checked={filters.statuses.includes(
                      LocationStatusEnum.UNAVAILABLE,
                    )}
                    onChange={() => {
                      const statuses = filters.statuses.includes(
                        LocationStatusEnum.UNAVAILABLE,
                      )
                        ? filters.statuses.filter(
                            (s) => s !== LocationStatusEnum.UNAVAILABLE,
                          )
                        : [...filters.statuses, LocationStatusEnum.UNAVAILABLE];
                      setFilters({ ...filters, statuses });
                    }}>
                    <CustomBadge
                      label="UNAVAILABLE"
                      color="red"
                      className="ml-2 text-xs"
                    />
                  </CheckBox>
                </div>
              </div>
            </div>

            {/* Modal Actions */}
            <div className="flex items-end justify-end space-x-2">
              <button
                className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
                onClick={() => setShowFilterModal(false)}>
                Cancel
              </button>
              <button
                className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
                onClick={handleApplyFilters}>
                Apply Filter
              </button>
            </div>
          </div>
        </NewModal>
      )}
    </>
  );
};

export default BarcodeView;
