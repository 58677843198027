import { useEffect, useState } from "react";
import _ from "lodash";
import { useQuery } from "#hooks/useQuery";
import CatalogDefaultIcon from "#static/images/catalogplaceholder.png";
import Checkbox from "#components/utils/Checkbox";
import CatalogProductActions from "./CatalogProductActions";
import { GET_PRODUCT_VARIANT_COMPONENT } from "#queries";

export default function GridViewProducts({
  products,
  selectProduct,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  getCatalog,
  actionsOnProduct,
  isRemoveSelectAllCheckBox,
  showSalesAndCostPrice,
}) {
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);
  const [variantComponentIds, setVariantComponentIds] = useState([]);
  const [allVariantComponents, setAllVariantComponents] = useState([]);

  const productComponentQuery = useQuery(GET_PRODUCT_VARIANT_COMPONENT);

  const fetchProductVariantComponent = async (variantIds) => {
    try {
      const productComponentResponse = await productComponentQuery.fetchData({
        perPage: variantIds.length,
        pageNumber: 1,
        filters: {
          componentId: variantIds,
        },
      });

      if (
        productComponentResponse?.data?.getProductVariantComponent?.entities
          ?.length > 0
      ) {
        setAllVariantComponents(
          productComponentResponse.data.getProductVariantComponent.entities,
        );
      }
    } catch (e) {}
  };

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(async () => {
    if (showSalesAndCostPrice && products?.length > 0) {
      let allVariantComponents = products.reduce((initArray, nxtObject) => {
        if (nxtObject?.costPrice) {
          const allVariantComponentsId = _.keys(
            nxtObject?.costPrice?.[0],
          ).filter((key) => _.isObject(nxtObject?.costPrice?.[0]?.[key]));
          if (allVariantComponentsId.length > 0) {
            return [...initArray, ...allVariantComponentsId];
          }
        }
        return initArray;
      }, []);
      if (allVariantComponents.length > 0) {
        allVariantComponents = allVariantComponents.filter(
          (id, index) => index === allVariantComponents.indexOf(id),
        );
        if (!_.isEqual(variantComponentIds, allVariantComponents)) {
          setVariantComponentIds(allVariantComponents);
        }
      }
    }
  }, [products, variantComponentIds]);

  useEffect(() => {
    if (variantComponentIds.length > 0) {
      fetchProductVariantComponent(variantComponentIds);
    }
  }, [variantComponentIds]);

  return (
    <>
      <div className="flex space-x-2 p-2">
        {!isRemoveSelectAllCheckBox && (
          <>
            <Checkbox
              role="checkbox"
              onChange={selectAllRows}
              name="selectAllRows"
              checked={allRowsSelected}
            />
            <div className="text-[#717679 ] text-lg">Select All</div>
          </>
        )}
      </div>
      <ul
        role="list"
        className="grid h-[51vh] grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
        {products.map((product, rowIndex) => {
          const costPrice = {};
          if (showSalesAndCostPrice && product?.costPrice?.length === 1) {
            for (const costPriceKey in product.costPrice[0]) {
              if (costPriceKey !== "currency") {
                let componentPrice;
                if (!_.isObject(product.costPrice[0][costPriceKey])) {
                  componentPrice = Number(product.costPrice[0][costPriceKey]);
                  if (costPriceKey !== "discount" && costPriceKey !== "total") {
                    costPrice[costPriceKey] = componentPrice / 100;
                  } else costPrice[costPriceKey] = componentPrice;
                } else {
                  const selectedComponent = allVariantComponents.find(
                    (variantObject) => variantObject.id === costPriceKey,
                  );
                  componentPrice =
                    (Number(selectedComponent?.price || "") /
                      Number(selectedComponent?.quantity || "")) *
                    Number(product.costPrice[0][costPriceKey].quantity || "");
                  costPrice[selectedComponent?.componentName] =
                    componentPrice / 100;
                }
                if (costPriceKey !== "discount") {
                  if (costPriceKey !== "total") {
                    costPrice["total"] =
                      (costPrice["total"] || 0) + componentPrice;
                  }
                }
              } else
                costPrice[costPriceKey] = product.costPrice[0][costPriceKey];
            }
            if (costPrice?.discount) {
              costPrice.total =
                (costPrice.total - (costPrice?.discount || 0)) / 100;
              costPrice.discount = costPrice?.discount / 100;
            } else costPrice.total = Number(costPrice.total || 0) / 100;
          }
          return (
            <li
              key={product.id}
              className="relative col-span-1 flex flex-col divide-y divide-gray-200 rounded-lg border border-[#DDDFE0] bg-white text-center">
              <div className="absolute p-4">
                <Checkbox
                  role="checkbox"
                  onChange={(_) => {
                    selectProduct(product.id, false, products);
                  }}
                  name="product"
                  value={product.id}
                  checked={selectedProducts?.includes(product.id)}
                />
              </div>
              <div
                className="cursor-pointer"
                onClick={() => getCatalog(product.id)}>
                <div className="flex flex-1 flex-col">
                  <img
                    className="mx-auto h-52 w-full shrink-0"
                    src={product.images?.[0]?.url || CatalogDefaultIcon}
                    alt=""
                  />
                </div>
                <div className="flex items-start justify-start truncate text-left capitalize">
                  <div className="p-2">
                    <div className="flex flex-col gap-1">
                      <h3 className="w-auto max-w-[200px] truncate text-sm text-lightGray">
                        SKU: {product.sku}
                      </h3>
                      <p className="w-auto max-w-[200px] truncate text-base font-medium text-[#111827]">
                        {truncate(product.name)}
                      </p>
                    </div>
                    <div>
                      {costPrice?.total ? (
                        <>
                          <span className="text-xs text-[#111827]">
                            {costPrice.currency}
                          </span>
                          <span className="text-lg font-semibold text-[#111827]">
                            {` ${costPrice.total}`}
                          </span>
                        </>
                      ) : (
                        "-"
                      )}
                      {/* {product.pricing?.computedPriceFields?.totalPrice > 0
                      ? `$${product.pricing?.computedPriceFields?.totalPrice.toFixed(
                          2,
                        )}`
                      : "-"} */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="absolute -top-2 right-0 pr-4">
                <CatalogProductActions
                  actionsOnProduct={actionsOnProduct}
                  product={product}
                />
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
}

function truncate(str) {
  return str?.length > 20 ? str.substring(0, 20) + "..." : str;
}
