import React from "react";
import { Timeline } from "antd";
import dayjs from "dayjs";
import PropTypes from "prop-types";
import CustomBadge from "./CustomBadge";

const CustomAuditTrail = ({
  title = "Audit Trail",
  stages,
  showLatestFirst = false,
  statusConfig = {
    CANCELLED: {
      dotColor: "red",
      badgeColor: "red",
      bgColor: "#FEE2E2",
      textColor: "#991B1B",
    },
    DRAFT: {
      dotColor: "gray",
      badgeColor: "gray",
      bgColor: "#F3F4F6",
      textColor: "#374151",
    },
    ACTIVE: {
      dotColor: "blue",
      badgeColor: "blue",
      bgColor: "#DBEAFE",
      textColor: "#1E40AF",
    },
    ELIGIBILITY_CHECK_COMPLETED: {
      dotColor: "green",
      badgeColor: "green",
      bgColor: "#DCFCE7",
      textColor: "#166534",
    },
  },
}) => {
  const formatTime = (timestamp) => {
    return dayjs(timestamp).format("DD MMM YYYY, hh:mm A");
  };

  const getDotColor = (status) => {
    return statusConfig[status]?.dotColor || "gray";
  };

  const formatNotes = (notes) => {
    if (!notes?.length) return "";
    const actionNote = notes.find((note) => !note.startsWith("User:"));
    return actionNote || notes[0];
  };

  // Get ordered stages based on showLatestFirst prop
  const orderedStages = showLatestFirst ? [...stages].reverse() : stages;

  // Get last updated time based on ordering
  const lastUpdatedTime = showLatestFirst
    ? orderedStages[0]?.createdAt
    : orderedStages[orderedStages.length - 1]?.createdAt;

  return (
    <div className="p-6 font-inter">
      <div className="mb-6">
        {title && (
          <h2 className="text-2xl font-semibold text-gray-800">{title}</h2>
        )}
        <p className="mt-2 text-sm text-gray-500">
          Last updated on: {formatTime(lastUpdatedTime)}
        </p>
      </div>
      <Timeline
        mode="left"
        items={orderedStages.map((stage, index) => ({
          color: getDotColor(stage.status),
          children: (
            <div className="mb-6">
              <div className="flex items-start gap-3">
                <span className="text-sm font-medium">
                  {stage.notes?.[stage.notes.length - 1]?.split("User: ")[1] ||
                    "System"}
                </span>
                <span className="text-sm text-gray-500">
                  {formatTime(stage.createdAt)}
                </span>
                <CustomBadge
                  label={stage.status?.toLowerCase().replace(/_/g, " ")}
                  bgColor={statusConfig[stage.status]?.bgColor}
                  textColor={statusConfig[stage.status]?.textColor}
                  textSize="text-xs"
                  className="px-2 py-1"
                />
              </div>
              {formatNotes(stage.notes) && (
                <div
                  className="mt-2 rounded p-2"
                  style={{
                    backgroundColor:
                      statusConfig[stage.status]?.bgColor || "#F3F4F6",
                  }}>
                  <p
                    className="text-sm"
                    style={{ color: statusConfig[stage.status]?.textColor }}>
                    {formatNotes(stage.notes)}
                  </p>
                </div>
              )}
            </div>
          ),
        }))}
      />
    </div>
  );
};

CustomAuditTrail.propTypes = {
  stages: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string.isRequired,
      createdAt: PropTypes.number.isRequired,
      notes: PropTypes.arrayOf(PropTypes.string),
      userId: PropTypes.string,
    }),
  ).isRequired,
  statusConfig: PropTypes.objectOf(
    PropTypes.shape({
      dotColor: PropTypes.string,
      badgeColor: PropTypes.string,
      bgColor: PropTypes.string,
      textColor: PropTypes.string,
    }),
  ),
  showLatestFirst: PropTypes.bool,
  title: PropTypes.string,
};

export default CustomAuditTrail;
