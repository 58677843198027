import { useState, useEffect, useContext } from "react";
import { Tooltip } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  PlusIcon,
  MinusIcon,
  CheckCircleIcon,
  ExclamationIcon,
  InformationCircleIcon,
  XIcon,
  SearchIcon,
  ViewGridIcon,
  TableIcon,
} from "@heroicons/react/outline";
import { ALERT_TYPES } from "#components/common/ContainerWithLoaderAndAlert";
import AutoCompleteMultiSelect from "#newUiComponents/commons/AutoCompleteMultiSelect";
import { AppStateContext } from "#contexts/appState";
import useDebouncedEffect from "#hooks/useDebouncedEffect";
import ManageCatalogProducts from "./ManageCatalogProducts";

const ManageCatalogForm = ({
  catalog,
  setCatalog,
  submitCatalog,
  productCategories,
  componentAlert,
  setComponentAlert,
  fetchCatalogProducts,
  setSelectedCategories,
  selectedCategories,
  isProductsLoading,
  productQueryPageNumber,
  setProductQueryPageNumber,
  customerList,
}) => {
  const [showListView, setShowListView] = useState(true);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordForDebounce, setKeywordForDebounce] = useState("");
  const appState = useContext(AppStateContext);

  const categoryNames = [...selectedCategories].map(
    (categoryObject) => categoryObject.name,
  );

  let getAllUnSelectedProduct = [...(catalog.displayProducts || [])];

  if (catalog?.products?.length > 0) {
    catalog.products.forEach((productObject) => {
      const productIndx = getAllUnSelectedProduct.findIndex(
        (unSelectedProductObject) =>
          unSelectedProductObject.id === productObject.id,
      );
      if (productIndx !== -1) {
        getAllUnSelectedProduct.splice(productIndx, 1);
      }
    });
  }

  const renderAlert = (showAlert) => {
    let icon, bgColor, borderColor, textColor;

    switch (showAlert.alertType) {
      case ALERT_TYPES.SUCCESS:
        icon = <CheckCircleIcon className="h-8 w-8" />;
        bgColor = "#D7FAE3";
        borderColor = "#14804A";
        textColor = "#14804A";
        break;
      case ALERT_TYPES.ERROR:
        icon = <ExclamationIcon className="h-8 w-8" />;
        bgColor = "#FFF4F3";
        borderColor = "#CA3A31";
        textColor = "#CA3A31";
        break;
      case ALERT_TYPES.INFO:
        icon = <InformationCircleIcon className="h-8 w-8" />;
        bgColor = "#F1F8FF";
        borderColor = "primaryAccent";
        textColor = "primaryAccent";
        break;
      default:
        return null;
    }

    return (
      <div
        className={`bg-[${bgColor}] text-[${textColor}] flex items-center rounded-md p-4 border-[${borderColor}] mb-2 border-2`}>
        <span className="mr-2 flex flex-1 items-center space-x-2 text-lg">
          {icon}
          <div>{showAlert.msg}</div>
        </span>
        <button
          className="float-right flex items-center justify-center font-bold text-[#717679]"
          onClick={() => setComponentAlert(null)}>
          <XIcon className="h-6 w-6 font-bold" />
        </button>
      </div>
    );
  };
  const actionsOnProduct = (product) => {
    if (catalog?.products?.includes(product.id)) {
      return [
        {
          name: "Remove from Catalog",
          value: "removeFromCatalog",
          icon: <MinusIcon className="h-6 w-6" />,
          onClick: () => {
            const allSelectedProducts = [...(catalog?.products || [])];
            const productIndx = allSelectedProducts.findIndex(
              (productObject) => productObject?.id === product.id,
            );
            if (productIndx !== -1) {
              allSelectedProducts.splice(productIndx, 1);

              setCatalog({
                ...catalog,
                products: allSelectedProducts,
              });
              setComponentAlert({
                msg: "Product removed from catalog",
                alertType: "success",
              });
            }
          },
        },
      ];
    }
    return [
      {
        name: "Add to Catalog",
        value: "addToCatalog",
        icon: <PlusIcon className="h-6 w-6" />,
        onClick: () => {
          const allSelectedCatalogProducts = [...(catalog?.products || [])];
          allSelectedCatalogProducts.unshift(product);

          setCatalog({
            ...catalog,
            products: allSelectedCatalogProducts,
          });
          setComponentAlert({
            msg: "Product added to catalog",
            alertType: "success",
          });
        },
      },
    ];
  };

  const addRemoveProduct = (productId) => {
    const catalogData = { ...catalog };
    catalogData.products = [...(catalogData?.products || [])];
    const productIndx = catalogData.products.findIndex(
      (productObject) => productObject?.id === productId,
    );
    if (productIndx !== -1) {
      if (
        catalogData.displayProducts.findIndex(
          (product) => product.id === productId,
        ) === -1
      ) {
        catalogData.displayProducts.unshift({
          ...catalogData.products[productIndx],
        });
      }

      catalogData.products.splice(productIndx, 1);
    } else {
      catalogData.products.unshift(
        catalogData.displayProducts.find(
          (productObject) => productObject.id === productId,
        ),
      );
    }
    setCatalog({
      ...catalogData,
    });
  };

  const selectAllHandler = () => {
    if (catalog?.displayProducts?.length > 0) {
      if (
        _.isEqual(
          _.orderBy(catalog.displayProducts, ["id"]),
          _.orderBy(catalog?.products || [], ["id"]),
        ) ||
        (catalog?.products?.length > 0 && getAllUnSelectedProduct.length === 0)
      ) {
        let displayProducts = [...catalog.displayProducts];
        const productsIsNotInDisplayProducts = catalog.products.filter(
          (productObject) =>
            catalog.displayProducts.findIndex(
              (selectedProductObject) =>
                selectedProductObject.id === productObject.id,
            ) === -1,
        );
        if (productsIsNotInDisplayProducts.length > 0) {
          displayProducts = [
            ...displayProducts,
            ...productsIsNotInDisplayProducts,
          ];
        }
        setCatalog({
          ...catalog,
          products: [],
          displayProducts,
        });
      } else {
        let selectedProducts = [...catalog.displayProducts];
        if (catalog.products.length > 0) {
          const filteredSelectedProducts = catalog.displayProducts.filter(
            (productObject) =>
              catalog.products.findIndex(
                (selectedProductObject) =>
                  selectedProductObject.id === productObject.id,
              ) === -1,
          );
          selectedProducts = [...filteredSelectedProducts, ...catalog.products];
        }
        setCatalog({
          ...catalog,
          products: selectedProducts,
        });
      }
    }
  };

  const onChangeProductSearch = (searchKeyword) => {
    setKeyword(searchKeyword);
  };

  const onListScroll = (event) => {
    const { scrollTop, clientHeight, scrollHeight } = event.target;
    const threshold = 1;
    if (scrollTop + clientHeight + threshold >= scrollHeight) {
      if (catalog?.displayProducts?.length < catalog?.total) {
        setProductQueryPageNumber(productQueryPageNumber + 1);
        fetchCatalogProducts(
          productQueryPageNumber + 1,
          [],
          categoryNames,
          keyword,
          [],
        );
      }
    }
  };

  useDebouncedEffect(() => setKeywordForDebounce(keyword), 1000, [keyword]);

  useEffect(() => {
    if (productQueryPageNumber !== 1) setProductQueryPageNumber(1);
    if (catalog?.displayProducts && catalog.displayProducts?.length > 0) {
      setCatalog({
        ...catalog,
        displayProducts: [],
      });
    }
  }, [selectedCategories, keywordForDebounce]);

  useEffect(() => {
    if (
      (!catalog?.displayProducts || catalog.displayProducts?.length === 0) &&
      (selectedCategories.length > 0 || keywordForDebounce)
    ) {
      fetchCatalogProducts(1, [], categoryNames, keywordForDebounce, []);
    }
  }, [
    JSON.stringify(catalog?.displayProducts),
    selectedCategories,
    keywordForDebounce,
  ]);

  useEffect(() => {
    return () => {
      setSelectedCategories([]);
      setKeyword("");
      setKeywordForDebounce("");
      setComponentAlert(null);
    };
  }, []);

  return (
    <>
      <div className="relative p-6">
        {componentAlert &&
          componentAlert?.alertType !== "success" &&
          renderAlert(componentAlert)}
        <div className="text-2xl font-semibold">Basic Info</div>
        <div className="flex">
          <div className="catalog-textfield-wrapper">
            <div className="flex flex-col">
              <span className="mb-1 text-sm font-medium text-lightGray">
                Catalog Name<span className="text-lg text-red-500">*</span>{" "}
                <Tooltip title="Catalog Name">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>
              <input
                type="text"
                className="rounded border border-gray-300 font-inter text-base text-lightGray focus:border-primaryAccent focus:outline-none focus:ring-primaryAccent"
                onChange={(e) =>
                  setCatalog({ ...catalog, name: e.target.value })
                }
                value={catalog.name}
                name="catalogName"
                placeholder="Enter Catalog Name"
              />
            </div>
          </div>
          <div className="catalog-textfield-wrapper"></div>
        </div>
        <div className="mt-2 text-2xl font-semibold">Add Products</div>
        <div className="flex flex-col">
          <div className="catalog-textfield-wrapper">
            <div className="flex flex-col">
              <span className="mb-1 text-sm font-medium text-lightGray">
                Product Category<span className="text-lg text-red-500">*</span>{" "}
                <Tooltip title="Add Product Category">
                  <InfoCircleOutlined />
                </Tooltip>
              </span>

              <AutoCompleteMultiSelect
                label=""
                options={productCategories}
                placeholderDropdown="relative mb-0 w-[619px] text-base font-inter"
                selectClasses={"text-base text-[#111827] font-inter"}
                selectedValues={selectedCategories}
                setSelectedValues={setSelectedCategories}
                dropdownOpen={dropdownOpen}
                setDropdownOpen={setDropdownOpen}
                multiSelect="count"
              />
            </div>
          </div>
          {!isProductsLoading &&
            (catalog?.products?.length > 0 ||
              catalog?.displayProducts?.length > 0) && (
              <div className="flex justify-between font-inter text-sm text-lightGray">
                <div className="mb-4 flex gap-1">
                  <input
                    className="h-4 w-4 rounded border-gray-400"
                    checked={
                      catalog?.displayProducts?.length > 0 &&
                      (_.isEqual(
                        _.orderBy(catalog.displayProducts, ["id"]),
                        _.orderBy(catalog?.products || [], ["id"]),
                      ) ||
                        (catalog?.products?.length > 0 &&
                          getAllUnSelectedProduct.length === 0))
                    }
                    onChange={selectAllHandler}
                    type="checkbox"
                  />
                  <span>Select All</span>
                </div>
                <div>
                  <span>Selected Products: {catalog?.products?.length}</span>
                </div>
              </div>
            )}
          <div className="flex gap-4">
            <div className="w-11/12">
              <div className="relative flex-1 rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <SearchIcon
                    className="h-5 w-5 text-lightGray"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="search"
                  name="search"
                  id="search"
                  className="block w-full rounded-md border-0 py-2 pl-10 text-sm text-lightGray ring-1 ring-inset ring-gray-300 placeholder:text-lightGray focus:ring-2 focus:ring-inset focus:ring-primaryAccent"
                  placeholder="Search Products"
                  onChange={(e) => {
                    onChangeProductSearch(e.target.value);
                  }}
                  value={keyword}
                />
              </div>
            </div>

            <span className="isolate inline-flex h-8 rounded-md shadow-sm">
              <button
                type="button"
                className={`hs-catalog-topbar-action-group-left rounded-l-md p-2 ${
                  showListView && "hs-catalog-topbar-action-group-active"
                }`}
                onClick={() => setShowListView(true)}>
                <TableIcon className="h-5 w-5" aria-hidden="true" />
              </button>
              <button
                type="button"
                className={`hs-catalog-topbar-action-group-right rounded-r-md p-2 ${
                  !showListView && "hs-catalog-topbar-action-group-active"
                }`}
                onClick={() => setShowListView(false)}>
                <ViewGridIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </span>
          </div>
        </div>

        <ManageCatalogProducts
          onListScroll={onListScroll}
          showListView={showListView}
          selectedCatalogProducts={catalog?.products || []}
          getAllUnSelectedProduct={getAllUnSelectedProduct}
          addRemoveProduct={addRemoveProduct}
          actionsOnProduct={actionsOnProduct}
          productCategories={productCategories}
          isProductsLoading={isProductsLoading}
          keyword={keyword}
          selectedCategories={selectedCategories}
          catalog={catalog}
          customerList={customerList}
        />

        <div className="sticky bottom-0 z-10 flex w-full flex-1 justify-end bg-white">
          <div className="flex space-x-4 py-6">
            <button
              className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent p-2 px-6 font-semibold text-primaryAccent"
              onClick={() => {
                setCatalog(null);
              }}>
              Cancel
            </button>
            <button
              className="cursor-pointer whitespace-nowrap rounded-lg border-2 border-primaryAccent bg-primaryAccent p-2 px-6 font-semibold text-white"
              onClick={submitCatalog}>
              {catalog?.id ? "Update" : "Create"}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCatalogForm;
