import _ from "lodash";
import JSPM from "jsprintmanager";
import { jsPDF } from "jspdf";
import JsBarcode from "jsbarcode";
import { toCanvas } from "qrcode";

// Add LPN_LABEL_CONSTANTS
const LPN_LABEL_CONSTANTS = {
  PAGE: {
    WIDTH: 6,
    HEIGHT: 4,
  },
  QR_CODE: {
    WIDTH: 100,
    HEIGHT: 100,
    SIZE_IN_INCHES: 1,
    Y_POSITION: 0.3,
  },
  TEXT_POSITIONS: {
    CODE_Y: 1.8,
    NESTED_FORM_FACTOR_Y: 2.1,
    SKU_Y: 2.4,
    DESCRIPTION_START_Y: 2.8,
    DESCRIPTION_LINE_HEIGHT: 0.35,
    CUSTOMER_Y: 3.4,
    DETAILS_START_Y: 3.6,
    DETAILS_LINE_HEIGHT: 0.2,
  },
  FONT_SIZES: {
    CODE: 14,
    SKU: 18,
    DESCRIPTION: 18,
    CUSTOMER: 14,
    DETAILS: 10,
  },
  MAX_WIDTHS: {
    DESCRIPTION: 5,
    DETAILS: 5,
  },
  MAX_LINES: {
    DESCRIPTION: 2,
    DETAILS: 3,
  },
};

/**
 * Generates a PDF with multiple pages, each containing a barcode for a given code.
 *
 * @param {Array<string>} codes - Array of codes to generate barcodes for.
 * @param {number} noOfCopies - Number of copies per code.
 * @param {string} message - Message to display during printing.
 * @param {string|null} printer - Specific printer to use. If null, default printer is used.
 * @param {string} dimensions - Dimensions of the label.
 * @param {Object|null} appState - Application state for setting alerts and loading indicators.
 * @param {boolean} print - Whether to send to printer or save as PDF.
 * @param {string} text1 - Additional text to display below the barcode.
 * @param {string} text2 - Additional text to display below the first text.
 */
export const printMultiPageLpn = async (
  codes,
  noOfCopies = 1,
  message = "Printing labels",
  printer = null,
  dimensions = "2x1",
  appState = null,
  print = true,
  text1,
  text2,
) => {
  if (!codes || !Array.isArray(codes) || codes.length === 0) {
    return (
      appState &&
      appState.setAlert("No codes provided for printing.", "error", 5000)
    );
  }

  const doc = new jsPDF({
    orientation: "landscape",
    unit: "in",
    format: [LPN_LABEL_CONSTANTS.PAGE.WIDTH, LPN_LABEL_CONSTANTS.PAGE.HEIGHT],
  });

  for (let codeIndex = 0; codeIndex < codes.length; codeIndex++) {
    const codeObj = codes[codeIndex];
    const code = codeObj.code;
    if (!code) continue;

    if (codeIndex > 0) {
      doc.addPage();
    }

    try {
      // Create a canvas element for the QR code
      const canvas = document.createElement("canvas");

      // Generate QR code on the canvas
      await toCanvas(canvas, code, {
        width: LPN_LABEL_CONSTANTS.QR_CODE.WIDTH,
        height: LPN_LABEL_CONSTANTS.QR_CODE.HEIGHT,
        margin: 1,
        errorCorrectionLevel: "M",
      });

      // Convert canvas to data URL and add to PDF
      const qrCodeDataUrl = canvas.toDataURL("image/png");

      // Calculate x position for true center
      const xPos =
        (LPN_LABEL_CONSTANTS.PAGE.WIDTH -
          LPN_LABEL_CONSTANTS.QR_CODE.SIZE_IN_INCHES) /
        2;

      // Add the QR code to the PDF
      doc.addImage(
        qrCodeDataUrl,
        "PNG",
        xPos,
        LPN_LABEL_CONSTANTS.QR_CODE.Y_POSITION,
        LPN_LABEL_CONSTANTS.QR_CODE.SIZE_IN_INCHES,
        LPN_LABEL_CONSTANTS.QR_CODE.SIZE_IN_INCHES,
      );

      // Add text elements
      doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.CODE);
      doc.text(
        code,
        LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
        LPN_LABEL_CONSTANTS.TEXT_POSITIONS.CODE_Y,
        { align: "center" },
      );

      if (codeObj?.formFactor) {
        doc.text(
          `${codeObj.formFactor} ${codeObj.children?.length > 0 ? `(${codeObj.children.length} ${codeObj.children[0].formFactor})` : ""}`,
          LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
          LPN_LABEL_CONSTANTS.TEXT_POSITIONS.NESTED_FORM_FACTOR_Y,
          { align: "center" },
        );
      }

      if (codeObj?.nestedFormFactor) {
        doc.text(
          codeObj.nestedFormFactor,
          LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
          LPN_LABEL_CONSTANTS.TEXT_POSITIONS.NESTED_FORM_FACTOR_Y,
          { align: "center" },
        );
      }

      if (text1?.includeTrackingDetails) {
        // SKU
        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.SKU);
        doc.setFont("arial", "bold");
        if (text1.sku) {
          doc.text(
            text1.sku,
            LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
            LPN_LABEL_CONSTANTS.TEXT_POSITIONS.SKU_Y,
            { align: "center" },
          );
        }

        // Description
        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.DESCRIPTION);
        if (text1.name) {
          const lines = doc.splitTextToSize(
            text1.name,
            LPN_LABEL_CONSTANTS.MAX_WIDTHS.DESCRIPTION,
          );
          const textLines = lines.slice(
            0,
            LPN_LABEL_CONSTANTS.MAX_LINES.DESCRIPTION,
          );
          textLines.forEach((line, index) => {
            doc.text(
              line,
              LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
              LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DESCRIPTION_START_Y +
                index *
                  LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DESCRIPTION_LINE_HEIGHT,
              { align: "center" },
            );
          });
        }

        // Customer
        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.CUSTOMER);
        doc.setFont("arial", "normal");
        if (text1.customer) {
          doc.text(
            text1.customer,
            LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
            LPN_LABEL_CONSTANTS.TEXT_POSITIONS.CUSTOMER_Y,
            { align: "center" },
          );
        }

        // Additional details
        doc.setFontSize(LPN_LABEL_CONSTANTS.FONT_SIZES.DETAILS);
        const stringToDisplay = [];
        if (text1.lotId) stringToDisplay.push(`Lot ID: ${text1.lotId}`);
        if (text1.bestByDate)
          stringToDisplay.push(`Expiry Date: ${text1.bestByDate}`);
        if (text1.serialNumber)
          stringToDisplay.push(`Serial #: ${text1.serialNumber}`);

        if (stringToDisplay.length > 0) {
          const lines = doc.splitTextToSize(
            stringToDisplay.join(" | "),
            LPN_LABEL_CONSTANTS.MAX_WIDTHS.DETAILS,
          );
          const textLines = lines.slice(
            0,
            LPN_LABEL_CONSTANTS.MAX_LINES.DETAILS,
          );
          textLines.forEach((line, index) => {
            doc.text(
              line,
              LPN_LABEL_CONSTANTS.PAGE.WIDTH / 2,
              LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DETAILS_START_Y +
                index * LPN_LABEL_CONSTANTS.TEXT_POSITIONS.DETAILS_LINE_HEIGHT,
              { align: "center" },
            );
          });
        }
      }
    } catch (error) {
      console.error("LPN label generation error:", error);
      appState?.setAlert(
        `Error generating QR code: ${error.message}`,
        "error",
        5000,
      );
      continue;
    }
  }

  // Handle print or save
  if (print === false) {
    doc.save(`${codes[0].code}.pdf`);
    return appState?.removeLoading();
  } else {
    const out = doc.output("datauristring");
    const cpj = new JSPM.ClientPrintJob();
    const myPrinter = printer
      ? new JSPM.InstalledPrinter(printer)
      : new JSPM.DefaultPrinter();
    cpj.clientPrinter = myPrinter;

    for (let i = 0; i < noOfCopies; i++) {
      const myFile = new JSPM.PrintFilePDF(
        out,
        JSPM.FileSourceType.URL,
        `${codes[0].code}_${i}.pdf`,
        1,
      );
      cpj.files.push(myFile);
    }
    cpj.sendToClient();
    return appState?.removeLoading();
  }
};
