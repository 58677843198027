import { useState, useEffect, useContext } from "react";
import {
  ViewGridIcon,
  TableIcon,
  PlusIcon,
  TagIcon,
  PencilIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import _ from "lodash";
import GridViewProducts from "./GridViewProducts";
import ListViewProducts from "./ListViewProducts";
import Popover from "#components/utils/Popover";
import SlideOverPanel from "../../common/SlideOverPanel";
import CatalogManageTags from "../CatalogManageTags";
import { useQuery } from "../../../hooks/useQuery";
import { GET_SKU, GET_PRODUCTS } from "#queries";
import { SAVE_PRODUCT, DELETE_PRODUCT } from "#mutations";
import CatalogEditProduct from "./CatalogEditProduct";
import { AppStateContext } from "#contexts/appState";
import CatalogEnhancedSearch from "./CatalogEnhancedSearch";
import PurchaseOrderMain from "#newUiComponents/purchaseOrder/PurchaseOrderMain";
import {
  GET_WAREHOUSES,
  GET_VENDORS,
  GET_PRODUCT_VARIANT_COMPONENT,
} from "#queries/index";

const ALERT_TIMEOUT_IN_MS = 5000;

//temp function to add flag for po creation
export const checkURL = () => {
  const currentURL = window.location.href;
  const valuesToCheck = [
    "delmartest",
    "localhost",
    "uat",
    "delmaruat",
    "demo",
    "uatsharath",
  ];
  return valuesToCheck.some((value) => currentURL.includes(value));
};

function CatalogMainContent({
  products,
  total,
  filters,
  submitFilters,
  onChangeFilter,
  clearFilters,
  loadFirstTimeData,
  selectProduct,
  showLeftSidebar,
  selectedProducts,
  selectAllRows,
  allRowsSelected,
  getCatalog,
  productCategories,
  selectAllDisabled,
  setExportListHeaders,
  handleCreateCatalogClick,
  handleScroll,
  getSpecificProduct,
  customers,
  parentProducts,
  customerList,
  fetchSalesPriceMinMaxValue,
}) {
  const appState = useContext(AppStateContext);
  const [selectedProductForTags, setSelectedProductForTags] = useState(null);
  const [editingProduct, setEditingProduct] = useState(null);
  const [productForPurchase, setProductForPurchase] = useState(null);
  const [parentProductForPurchase, setParentProductForPurchase] =
    useState(null);
  const [showPurchaseOrderSlide, setShowPurchaseOrderSlide] = useState(false);
  const [showListView, setShowListView] = useState(true);
  const [showAddTags, setShowAddTags] = useState(false);
  const [showManageTags, setShowManageTags] = useState(false);
  const [warehouses, setWarehouses] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [allVariantComponents, setAllVariantComponents] = useState([]);
  const [pastAddedCostPrice, setPastAddedCostPrice] = useState();
  const [pastAddedSalesPrice, setPastAddedSalesPrice] = useState();
  const getSkuQuery = useQuery(GET_SKU);
  const saveProductQuery = useQuery(SAVE_PRODUCT);
  const deleteProductQuery = useQuery(DELETE_PRODUCT);
  const getProductsQuery = useQuery(GET_PRODUCTS);
  const warehousesQuery = useQuery(GET_WAREHOUSES);
  const vendorsQuery = useQuery(GET_VENDORS);
  const productComponentQuery = useQuery(GET_PRODUCT_VARIANT_COMPONENT);

  if (parentProducts?.length > 0) {
    products = [
      ...parentProducts.map((i) => ({ ...i, isParentProduct: true })),
      ...products,
    ];
  }

  useEffect(() => {
    if (getSkuQuery.loading || deleteProductQuery.loading)
      appState.setLoading();
    else appState.removeLoading();
  }, [getSkuQuery.loading, deleteProductQuery.loading]);

  useEffect(() => {
    if (!showPurchaseOrderSlide) {
      setParentProductForPurchase(null);
      setProductForPurchase([]);
    }
  }, [showPurchaseOrderSlide]);

  const fetchCatalogProduct = async (
    ids,
    successCallBack = () => {},
    errorCallBack = () => {},
  ) => {
    appState.setLoading();
    const productsQueryResponse = await getProductsQuery.fetchData({
      filters: { id: [...ids] },
    });
    appState.removeLoading();
    if (productsQueryResponse?.data?.products?.entities.length) {
      const parentProducts =
        productsQueryResponse?.data?.products?.parentProducts || [];
      setProductForPurchase(
        productsQueryResponse?.data?.products.entities.map((product) => {
          if (parentProducts.some((i) => i.id === product.parentProduct)) {
            return {
              ...product,
              parentProductDetails: parentProducts.find(
                (i) => i.id === product.parentProduct,
              ),
            };
          } else return product;
        }),
      );
      successCallBack(productsQueryResponse?.data?.products.entities);
      setShowPurchaseOrderSlide(true);
      setParentProductForPurchase(null);
    } else if (productsQueryResponse?.error) {
      errorCallBack(
        productsQueryResponse?.error?.message || "Error fetching products!",
      );
    }
  };

  const deleteProduct = (id) => {
    // ASK FOR CONFIRMATION
    appState.showConfirmation(
      "Remove Product",
      "Are you sure you want to remove this product?",
      () => confirmDeleteProduct(id),
      appState.hideConfirmation,
    );
  };

  const confirmDeleteProduct = async (id) => {
    const response = await deleteProductQuery.fetchData({ id });
    if (response.error) {
      appState.setDismissableAlert(
        response.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
      appState.hideConfirmation();
    }

    if (response.data) {
      appState.setDismissableAlert(
        response.data.deleteProduct.message,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      appState.hideConfirmation();
      submitFilters();
    }
  };

  const fetchProductForTags = async (product) => {
    const response = await getSkuQuery.fetchData({ id: product.id });

    if (response?.data?.specificInventory) {
      setSelectedProductForTags(response.data.specificInventory);
      setShowAddTags(true);
    }
  };

  const getProductVariantComponents = async (searchKeyword) => {
    const allVariantComponent = await productComponentQuery.fetchData({
      perPage: 25,
      pageNumber: 1,
      filters: {
        keyword: searchKeyword,
      },
    });

    if (
      allVariantComponent?.data?.getProductVariantComponent?.entities?.length >
      0
    ) {
      const filteredVariantComponents =
        allVariantComponent?.data?.getProductVariantComponent?.entities.filter(
          (receivedVariantObject) =>
            allVariantComponents.findIndex(
              (variantObj) => variantObj.id === receivedVariantObject.id,
            ) === -1,
        );
      setAllVariantComponents([
        ...allVariantComponents,
        ...filteredVariantComponents,
      ]);
    }
  };

  const fetchProductForEdit = async (product) => {
    const response = await getSkuQuery.fetchData({ id: product.id });

    if (response?.data?.specificInventory) {
      const specificInventory = { ...response?.data?.specificInventory };
      const typeOfProduct = specificInventory.typeOfProduct || "STANDALONE";
      if (typeOfProduct !== "BUNDLE") {
        if (
          specificInventory?.costPrice &&
          specificInventory?.costPrice?.length === 1
        ) {
          setPastAddedCostPrice(specificInventory.costPrice);
          const allVariantComponentsId = _.keys(
            specificInventory.costPrice[0],
          ).filter((key) => _.isObject(specificInventory.costPrice[0]?.[key]));
          let fatchedVariantComponent = [];
          if (allVariantComponentsId.length > 0) {
            const productComponentResponse =
              await productComponentQuery.fetchData({
                perPage: allVariantComponentsId.length,
                pageNumber: 1,
                filters: {
                  componentId: allVariantComponentsId,
                },
              });
            if (
              productComponentResponse?.data?.getProductVariantComponent
                ?.entities?.length > 0
            ) {
              fatchedVariantComponent =
                productComponentResponse.data.getProductVariantComponent
                  ?.entities;
            }
          }
          const costPrice = { ...specificInventory.costPrice[0] };
          for (const key in costPrice) {
            if (key !== "currency") {
              if (_.isObject(costPrice[key]) && null !== costPrice[key]) {
                const selectedVariantComponent =
                  fatchedVariantComponent.find(
                    (variantObject) => variantObject?.id === key,
                  ) || "test name";
                const selectedQuantity = Number(costPrice[key]?.quantity || "");
                costPrice[key] = {
                  ...costPrice[key],
                  componentName: selectedVariantComponent?.componentName,
                  uom: selectedVariantComponent?.uom,
                  quantity: selectedVariantComponent?.quantity,
                  selectedQuantity,
                  selectedPrice:
                    ((Number(selectedVariantComponent?.price || "") /
                      Number(selectedVariantComponent?.quantity || "")) *
                      Number(selectedQuantity || "")) /
                    100,
                };
              } else costPrice[key] = Number(costPrice[key]) / 100;
            }
          }

          specificInventory["costPrice"] = costPrice;
        }

        if (
          specificInventory?.salesPrice &&
          specificInventory?.salesPrice?.length > 0
        ) {
          setPastAddedSalesPrice(specificInventory.salesPrice);
          let mappedSalesPrices = [...specificInventory.salesPrice].reduce(
            (salesPriceArray, newSalesPriceObject) => {
              const existSalesPriceIndex = salesPriceArray.findIndex(
                (existSalesPriceObject) =>
                  _.isEqual(
                    existSalesPriceObject?.valueObj,
                    _.omit(newSalesPriceObject, [
                      "salesChannel",
                      "salesChannelTotal",
                    ]),
                  ),
              );
              if (existSalesPriceIndex !== -1) {
                salesPriceArray[existSalesPriceIndex].salesChannel.push(
                  newSalesPriceObject.salesChannel,
                );
              } else {
                salesPriceArray.push({
                  valueObj: _.omit(newSalesPriceObject, [
                    "salesChannel",
                    "salesChannelTotal",
                  ]),
                  salesChannel: [newSalesPriceObject.salesChannel],
                });
              }
              return salesPriceArray;
            },
            [],
          );

          mappedSalesPrices = mappedSalesPrices.map((salesPriceObject) => {
            const breakdownValues = {};
            for (const key in salesPriceObject.valueObj) {
              if (key !== "currency") {
                breakdownValues[key] =
                  Number(salesPriceObject.valueObj[key]) / 100;
              } else breakdownValues[key] = salesPriceObject.valueObj[key];
            }
            return {
              ...salesPriceObject,
              valueObj: breakdownValues,
            };
          });
          specificInventory["salesPrice"] = mappedSalesPrices;
        }
      }
      setEditingProduct(specificInventory);
    }
  };

  const actionsOnPopover = [
    {
      name: "Add Catalog",
      value: "add_catalog",
      icon: <PlusIcon className="h-6 w-6" />,
      onClick: () => handleCreateCatalogClick(),
    },
    {
      name: "Manage Tags",
      value: "add_tags",
      icon: <TagIcon className="h-6 w-6" />,
      // disabled: !selectedProducts?.length,
      // disabledMessage: "Please select a product to add tags",
      onClick: () => setShowManageTags(true),
    },
    {
      name: "Export Catalog",
      value: "export_catalog",
      icon: <PlusIcon className="h-6 w-6" />,
      onClick: () => setExportListHeaders(["SKU", "Product Name"]),
    },
  ];

  const actionsOnProduct = (product) => {
    if (product.isParentProduct) {
      return [
        {
          name: "Create Purchase Order",
          value: "purchaseOrder",
          icon: <PlusIcon className="h-6 w-6" />,
          onClick: () => {
            setShowPurchaseOrderSlide(true);
            setParentProductForPurchase(product);
          },
        },
      ];
    }

    return checkURL()
      ? [
          {
            name: "Edit Product Details",
            value: "edit",
            icon: <PencilIcon className="h-6 w-6" />,
            onClick: () => fetchProductForEdit(product),
          },
          {
            name: "Create Purchase Order",
            value: "purchaseOrder",
            icon: <PlusIcon className="h-6 w-6" />,
            onClick: () => {
              fetchCatalogProduct([product.id]);
            },
          },
          {
            name: "Add Tags",
            value: "tags",
            icon: <TagIcon className="h-6 w-6" />,
            onClick: () => fetchProductForTags(product),
          },
          {
            name: "Remove",
            value: "delete",
            icon: <TrashIcon className="h-6 w-6" />,
            onClick: () => deleteProduct(product.id),
          },
        ]
      : [
          {
            name: "Edit Product Details",
            value: "edit",
            icon: <PencilIcon className="h-6 w-6" />,
            onClick: () => fetchProductForEdit(product),
          },
          {
            name: "Add Tags",
            value: "tags",
            icon: <TagIcon className="h-6 w-6" />,
            onClick: () => fetchProductForTags(product),
          },
          {
            name: "Remove",
            value: "delete",
            icon: <TrashIcon className="h-6 w-6" />,
            onClick: () => deleteProduct(product.id),
          },
        ];
  };

  const [shiftKeyPressed, setShiftKeyPressed] = useState(false);

  const getFinalSelectedValues = (selectedValues, options) => {
    const selectedIds = selectedValues.map((value) => value.id);
    const result = [];

    for (const option of options) {
      if (selectedIds.includes(option.id)) {
        result.push({
          id: option.id,
          name: option.name,
          description: option.description,
        });
      }
    }

    return result;
  };

  const mapSalesPricesSalesChannelWise = (
    salesChannels = [],
    breakdownSalesPrices,
  ) => {
    const mappedSalesChannels = salesChannels?.map((saleChannel) => {
      if (_.keys(breakdownSalesPrices).length > 0) {
        let salesChannelTotal = _.values(
          _.omit(breakdownSalesPrices, [
            "salesChannelTotal",
            "currency",
            "discount",
          ]),
        ).reduce((accumulator, value) => accumulator + value, 0);

        if (breakdownSalesPrices?.discount) {
          salesChannelTotal =
            salesChannelTotal - (breakdownSalesPrices.discount || 0);
        }
        breakdownSalesPrices = { ...breakdownSalesPrices, salesChannelTotal };
        return {
          ...breakdownSalesPrices,
          salesChannel: _.isObject(saleChannel) ? saleChannel.id : saleChannel,
        };
      }
    });

    return mappedSalesChannels;
  };

  const saveProduct = async (media) => {
    let editingProductData = { ...editingProduct };

    if (editingProductData?.costPrice) {
      if (
        _.values(_.omit(editingProductData.costPrice, ["currency"])).filter(
          (val) => !!val,
        ).length > 0
      ) {
        let breakdownCost = _.omit(editingProductData.costPrice, [
          "total",
          "currency",
        ]);
        if (_.keys(breakdownCost).length > 0) {
          _.keys(breakdownCost).forEach((key) => {
            if (_.isObject(breakdownCost[key])) {
              if (editingProductData.costPrice.currency)
                breakdownCost[key].currency =
                  editingProductData.costPrice.currency;
              breakdownCost[key].quantity = Number(
                breakdownCost[key].selectedQuantity,
              );
              breakdownCost[key] = _.omit(breakdownCost[key], [
                "selectedQuantity",
                "selectedPrice",
              ]);
            } else breakdownCost[key] = Number(breakdownCost[key]) * 100;
          });
          if (!_.isEmpty(_.omit(breakdownCost, ["discount"]))) {
            editingProductData.costPrice = [
              {
                ..._.omit(editingProductData.costPrice, ["total"]),
                ...breakdownCost,
              },
            ];
          } else
            editingProductData.costPrice = [
              {
                ...editingProductData.costPrice,
                ...breakdownCost,
                total: Number(editingProductData.costPrice.total) * 100,
              },
            ];
        } else {
          const total = Number(editingProductData.costPrice.total) * 100;
          editingProductData.costPrice = [
            { ...editingProductData.costPrice, total },
          ];
        }
        if (_.isEqual(editingProductData?.costPrice, pastAddedCostPrice))
          editingProductData["isCpModified"] = false;
        else editingProductData["isCpModified"] = true;
      } else {
        if (pastAddedCostPrice && pastAddedCostPrice?.length > 0) {
          editingProductData.costPrice = [];
          editingProductData["isCpModified"] = false;
        } else {
          const { costPrice, ...restObjects } = editingProductData;
          editingProductData = restObjects;
        }
      }
    }
    if (editingProductData?.salesPrice) {
      if (editingProductData?.salesPrice.length > 0) {
        const channelWiseSalesPrice = editingProductData?.salesPrice?.reduce(
          (initVal, nxtVal) => {
            const currency = nxtVal?.valueObj?.currency;
            let breakdownSalesPrices = _.omit(nxtVal?.valueObj, [
              "total",
              "currency",
            ]);

            if (_.keys(breakdownSalesPrices).length > 0) {
              _.keys(breakdownSalesPrices).forEach((key) => {
                breakdownSalesPrices[key] =
                  Number(breakdownSalesPrices[key]) * 100;
              });

              if (
                _.keys(_.omit(breakdownSalesPrices, ["discount"])).length > 0
              ) {
                const discountIndx =
                  _.keys(breakdownSalesPrices).indexOf("discount");
                breakdownSalesPrices = {
                  ...breakdownSalesPrices,
                  currency,
                };
              } else {
                const total = Number(nxtVal?.valueObj?.total) * 100;
                breakdownSalesPrices = {
                  ...breakdownSalesPrices,
                  total,
                  currency,
                };
              }
            } else if (nxtVal?.valueObj?.total) {
              const total = Number(nxtVal?.valueObj?.total) * 100;
              breakdownSalesPrices = { total, currency };
            }

            const saleChannel = mapSalesPricesSalesChannelWise(
              nxtVal?.salesChannel?.length > 0 ||
                editingProductData.salesPrice.length > 1
                ? nxtVal?.salesChannel
                : customerList,
              breakdownSalesPrices,
            );

            return [...initVal, ...saleChannel];
          },
          [],
        );
        editingProductData.salesPrice = channelWiseSalesPrice;
        if (_.isEqual(editingProductData?.salesPrice, pastAddedSalesPrice))
          editingProductData["isSpModified"] = false;
        else editingProductData["isSpModified"] = true;
      } else {
        if (pastAddedSalesPrice && pastAddedSalesPrice?.length > 0) {
          editingProductData["isSpModified"] = false;
        } else {
          const { salesPrice, ...restObjects } = editingProductData;
          editingProductData = restObjects;
        }
      }
    }

    const response = await saveProductQuery.fetchData({
      ...editingProductData,
      images: media ? media : editingProductData.images,
      categories:
        editingProductData.categories?.length > 0
          ? getFinalSelectedValues(
              editingProductData.categories,
              productCategories,
            )
          : [],
    });
    if (response?.data?.saveProduct) {
      setEditingProduct(null);
      appState.setDismissableAlert(
        response.data.saveProduct.message,
        "success",
        ALERT_TIMEOUT_IN_MS,
      );
      submitFilters();
      fetchSalesPriceMinMaxValue();
    }

    if (response?.error) {
      setEditingProduct(null);
      appState.setDismissableAlert(
        response.error.message,
        "error",
        ALERT_TIMEOUT_IN_MS,
      );
    }
  };

  const onChange = (event) => {
    if (
      event.target.type === "costPrice" ||
      event.target.type === "salesPrice"
    ) {
      let product = {
        ...editingProduct,
      };
      if (!product[event.target.type]) {
        if (event.target.type === "salesPrice") {
          product[event.target.type] = [];
        } else {
          product[event.target.type] = {
            currency: "USD-$",
          };
        }
      }
      if (event.target.value !== "delete") {
        product[event.target.type][event.target.name] = event.target.value;
      } else {
        if (event.target.type === "salesPrice") {
          product[event.target.type].splice(event.target.name, 1);
        } else
          product[event.target.type] = _.omit(product[event.target.type], [
            event.target.name,
          ]);
      }
      setEditingProduct(product);
    }
  };

  const validateCostPriseSalesPrice = () => {
    if (editingProduct?.costPrice) {
      if (
        _.values(_.omit(editingProduct?.costPrice, ["currency"])).filter(
          (val) => val && !_.isObject(val) && Number(val) <= 0,
        ).length > 0
      ) {
        appState.setAlert(
          "You need to enter valid price in cost price section",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return false;
      }

      if (
        (editingProduct?.costPrice?.total ||
          editingProduct?.costPrice?.discount) &&
        _.isEmpty(
          _.omit(editingProduct?.costPrice, ["total", "currency", "discount"]),
        )
      ) {
        if (
          !editingProduct?.costPrice?.total &&
          Number(editingProduct?.costPrice?.discount) > 0
        ) {
          appState.setAlert(
            "You need to enter valid cost price",
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return false;
        } else if (
          Number(editingProduct?.costPrice?.total) <=
          Number(editingProduct?.costPrice?.discount)
        ) {
          appState.setAlert(
            "cost price discount should not be equal to or greater than its price",
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return false;
        }
      }

      if (
        _.values(
          _.omit(editingProduct?.costPrice, ["discount", "currency", "total"]),
        ).length > 0 &&
        _.values(
          _.omit(editingProduct?.costPrice, ["discount", "currency", "total"]),
        ).reduce(
          (existCost, newCost) =>
            (existCost += Number(
              _.isObject(newCost) ? newCost.selectedPrice : newCost && newCost,
            )),
          0,
        ) <= Number(editingProduct?.costPrice?.discount)
      ) {
        appState.setAlert(
          "cost price discount should not be equal to or greater than its price",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return false;
      }
      if (
        _.values(_.omit(editingProduct?.costPrice, ["currency"])).filter(
          (val) => val && _.isObject(val) && !Number(val.selectedQuantity) > 0,
        ).length > 0
      ) {
        appState.setAlert(
          "You need to enter valid quantity for variant component",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return false;
      }
    }

    if (editingProduct?.costPrice || editingProduct?.salesPrice?.length > 0) {
      const selectedCurrency =
        editingProduct?.salesPrice?.filter(
          (salesPriceObject, index) =>
            index ===
            editingProduct.salesPrice.findIndex(
              (object) =>
                object?.valueObj?.currency &&
                salesPriceObject?.valueObj?.currency &&
                object?.valueObj?.currency ===
                  salesPriceObject?.valueObj?.currency,
            ),
        ) || [];

      if (
        !_.isEmpty(_.omit(editingProduct?.costPrice, ["currency", "discount"]))
      ) {
        if (
          selectedCurrency.length > 0 &&
          selectedCurrency.findIndex(
            (salesPriceObject) =>
              salesPriceObject?.valueObj?.currency !==
              editingProduct.costPrice?.currency,
          ) !== -1
        ) {
          appState.setAlert(
            "Cost Price and Sales Price currency should be same",
            "error",
            ALERT_TIMEOUT_IN_MS,
          );
          return false;
        }
      }
      if (selectedCurrency.length > 1) {
        appState.setAlert(
          "All Sales Price currency should be equal",
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return false;
      }
    }

    if (editingProduct?.salesPrice && editingProduct?.salesPrice.length > 0) {
      const salesPrice = editingProduct.salesPrice;
      let allSalesChannels = [];
      for (let i = 0; i < salesPrice.length; i++) {
        if (salesPrice[i]) {
          if (
            _.values(
              _.omit(salesPrice[i]?.valueObj, ["discount", "currency"]),
            ).filter((val) => !!Number(val) && Number(val) > 0).length === 0
          ) {
            appState.setAlert(
              `You need to enter valid price for sales price section ${i + 1}`,
              "error",
              ALERT_TIMEOUT_IN_MS,
            );
            return false;
          } else {
            if (
              (salesPrice[i]?.valueObj?.total &&
                Number(salesPrice[i]?.valueObj?.total) <=
                  Number(salesPrice[i]?.valueObj?.discount)) ||
              (_.values(
                _.omit(salesPrice[i]?.valueObj, [
                  "discount",
                  "currency",
                  "total",
                ]),
              ).length > 0 &&
                _.values(
                  _.omit(salesPrice[i]?.valueObj, [
                    "discount",
                    "currency",
                    "total",
                  ]),
                ).reduce(
                  (existCost, newCost) =>
                    (existCost += newCost && Number(newCost)),
                  0,
                ) <= Number(salesPrice[i]?.valueObj?.discount))
            ) {
              appState.setAlert(
                "Discount should not be equal or greater than sales price",
                "error",
                ALERT_TIMEOUT_IN_MS,
              );
              return false;
            }
            if (
              salesPrice?.length > 1 &&
              (!salesPrice[i]?.salesChannel ||
                salesPrice[i]?.salesChannel?.length === 0)
            ) {
              appState.setAlert(
                `Select Sales channel for sales price section ${i + 1}`,
                "error",
                ALERT_TIMEOUT_IN_MS,
              );
              return false;
            }
          }
          allSalesChannels = [
            ...allSalesChannels,
            ...(salesPrice[i]?.salesChannel || []),
          ];
        }
      }

      if (
        allSalesChannels.filter(
          (value, indx) => allSalesChannels.indexOf(value) !== indx,
        ).length > 0
      ) {
        appState.setAlert(
          `You can't use same sale channel for multiple sales price`,
          "error",
          ALERT_TIMEOUT_IN_MS,
        );
        return false;
      }
    }
    saveProduct();
  };

  useEffect(() => {
    if (saveProductQuery.loading) appState.setLoading();
    else appState.removeLoading();
  }, [saveProductQuery.loading]);

  useEffect(() => {
    function handleKeyDown(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(true);
      }
    }

    function handleKeyUp(event) {
      if (event.key === "Shift") {
        setShiftKeyPressed(false);
      }
    }

    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const warehousesData = await warehousesQuery.fetchData();
        const vendorsData = await vendorsQuery.fetchData({
          filters: {
            classifications: ["Internal", "Third Party"],
          },
        });
        if (warehousesData.data?.warehouses?.entities?.length > 0)
          setWarehouses(warehousesData.data.warehouses.entities);
        if (vendorsData.data?.vendors?.entities?.length > 0)
          setVendors(vendorsData.data.vendors.entities);
      } catch (e) {
        console.log("Error fetching warehouses", e);
      }
    })();
  }, []);

  return (
    <>
      {showAddTags && (
        <SlideOverPanel
          open={showAddTags}
          setOpen={setShowAddTags}
          title="Add/Remove Tags"
          containerStyle={"max-w-3xl"}
          subTitle="Manage or add tags to products">
          <CatalogManageTags
            setShowAddTags={setShowAddTags}
            product={selectedProductForTags}
            loadFirstTimeData={loadFirstTimeData}
            fetchProductForTags={fetchProductForTags}
          />
        </SlideOverPanel>
      )}
      {showManageTags && (
        <SlideOverPanel
          open={showManageTags}
          setOpen={setShowManageTags}
          title="Manage Tags"
          containerStyle={"max-w-3xl"}
          subTitle="Manage or create new tags.">
          <CatalogManageTags
            loadFirstTimeData={loadFirstTimeData}
            showManageTags={showManageTags}
            setShowManageTags={setShowManageTags}
          />
        </SlideOverPanel>
      )}
      <SlideOverPanel
        open={showPurchaseOrderSlide}
        setOpen={setShowPurchaseOrderSlide}
        title="Add Purchase Order"
        containerStyle={"max-w-7xl"}
        subTitle="In here we will give context on creating new Purchase Order, Learn More">
        <PurchaseOrderMain
          parentProductForPurchase={parentProductForPurchase}
          productForPurchase={productForPurchase}
          fetchCatalogProduct={fetchCatalogProduct}
          toView={false}
          toCreate={true}
          setShowPurchaseOrderSlide={setShowPurchaseOrderSlide}
          warehouses={warehouses}
          vendors={vendors}
        />
      </SlideOverPanel>
      {editingProduct && (
        <SlideOverPanel
          open={true}
          setOpen={setEditingProduct}
          title="Edit Product Details"
          containerStyle={"max-w-7xl"}
          subTitle="Manage product attributes, identifiers, images etc">
          <CatalogEditProduct
            product={editingProduct}
            setProduct={setEditingProduct}
            productCategories={productCategories}
            saveProduct={saveProduct}
            customers={customers}
            customerList={customerList}
            onChange={onChange}
            validateCostPriseSalesPrice={validateCostPriseSalesPrice}
            getProductVariantComponents={getProductVariantComponents}
            allVariantComponents={allVariantComponents}
            setAllVariantComponents={setAllVariantComponents}
          />
        </SlideOverPanel>
      )}
      <div
        className={`hs-catalog-main-content-${showLeftSidebar} w-full px-4 sm:px-6 lg:px-8`}>
        <div className="item-center mt-2 flex justify-center space-x-4">
          <CatalogEnhancedSearch
            products={products}
            selectProduct={selectProduct}
            selectedProducts={selectedProducts}
            allRowsSelected={allRowsSelected}
            selectAllRows={selectAllRows}
            getCatalog={getCatalog}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={shiftKeyPressed}
            parentProducts={parentProducts}
            editingProduct={editingProduct}
            customerList={customerList}
          />

          {/* 
          for now this should be hidden as feature is yet to be implemented.
          <button className="hs-catalog-topbar-action">
            <AdjustmentsIcon className="h-6 w-6" />
          </button> 
          */}
          <span className="isolate inline-flex rounded-md shadow-sm">
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-left ${
                showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(true)}>
              <TableIcon className="h-6 w-6" aria-hidden="true" />
            </button>
            <button
              type="button"
              className={`hs-catalog-topbar-action-group-right ${
                !showListView && "hs-catalog-topbar-action-group-active"
              }`}
              onClick={() => setShowListView(false)}>
              <ViewGridIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </span>
          <Popover
            title={"Actions"}
            showChevron={true}
            panelClassName={
              "mt-2 bg-bgWhite z-10 overflow-auto rounded-lg p-1 border border-borderGray"
            }
            showOverlay={true}
            customClassName={"py-4"}>
            {actionsOnPopover.map((action, idx) => {
              return (
                <div
                  className={`cursor-pointer whitespace-nowrap rounded-lg p-3 font-medium ${
                    action.disabled
                      ? "cursor-not-allowed text-gray-200"
                      : "text-unselectedTextGray hover:bg-hoverHighlight hover:text-primaryAccent"
                  }`}
                  onClick={
                    action.onClick && !action.disabled
                      ? action.onClick
                      : () =>
                          action.disabledMessage
                            ? alert(action.disabledMessage)
                            : console.log(action.value, action.name)
                  }
                  key={idx}>
                  {action.name}
                </div>
              );
            })}
          </Popover>
        </div>

        <div className="mt-2 sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-lg leading-6 text-[#8A8B8E]">
              Products: {total}
            </h1>
          </div>
        </div>
        <div onScroll={handleScroll} className="mt-2 flow-root overflow-x-auto">
          <div className="-mx-4 -my-2 overflow-visible sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full overflow-y-visible py-2 align-middle sm:px-6 lg:px-8">
              {showListView ? (
                <ListViewProducts
                  products={products}
                  selectProduct={selectProduct}
                  selectedProducts={selectedProducts}
                  allRowsSelected={allRowsSelected}
                  selectAllRows={selectAllRows}
                  getCatalog={getCatalog}
                  actionsOnProduct={actionsOnProduct}
                  productCategories={productCategories}
                  shiftKeyPressed={shiftKeyPressed}
                  selectAllDisabled={selectAllDisabled}
                  parentProducts={parentProducts}
                  showSalesAndCostPrice={true}
                  customerList={customerList}
                />
              ) : (
                <GridViewProducts
                  products={products}
                  selectProduct={selectProduct}
                  selectedProducts={selectedProducts}
                  allRowsSelected={allRowsSelected}
                  selectAllRows={selectAllRows}
                  getCatalog={getCatalog}
                  actionsOnProduct={actionsOnProduct}
                  parentProducts={parentProducts}
                  showSalesAndCostPrice={true}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default CatalogMainContent;
