import { useState, useEffect } from "react";
import LoadingIndicator from "#components/utils/LoadingIndicator";
import ListViewProducts from "#components/catalogs/catalogTabs/ListViewProducts";
import GridViewProducts from "#components/catalogs/catalogTabs/GridViewProducts";
import SectionTabs from "./catalogTabs/SectionTabs";
import noFilterIcon from "#static/images/nofilter.png";

const ManageCatalogProducts = ({
  onListScroll,
  showListView,
  selectedCatalogProducts,
  getAllUnSelectedProduct,
  addRemoveProduct,
  actionsOnProduct,
  productCategories,
  isProductsLoading,
  keyword,
  selectedCategories,
  catalog,
  customerList,
}) => {
  const productStatusTab = [
    { name: "Selected Products", current: false },
    { name: "All Products", current: true },
  ];
  const [tabs, setTabs] = useState(productStatusTab);

  const renderTabContent = () => {
    const currentTab = tabs.find((tab) => tab.current).name;
    const currentTabInfo = {};
    if (currentTab === "Selected Products") {
      currentTabInfo.onListScroll = null;
      currentTabInfo.products = selectedCatalogProducts;
      currentTabInfo.selectedProducts = selectedCatalogProducts?.map(
        (productObj) => productObj.id,
      );
    } else {
      currentTabInfo.onListScroll = onListScroll;
      currentTabInfo.products = getAllUnSelectedProduct;
      currentTabInfo.selectedProducts = [];
    }
    return currentTabInfo.products.length > 0 ? (
      <div
        onScroll={currentTabInfo.onListScroll}
        className="h-96 overflow-y-scroll">
        {showListView ? (
          <ListViewProducts
            products={currentTabInfo.products}
            selectProduct={addRemoveProduct}
            selectedProducts={currentTabInfo.selectedProducts}
            allRowsSelected={false}
            selectAllRows={() => {}}
            getCatalog={() => {}}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={false}
            selectAllDisabled={false}
            isRemoveSelectAllCheckBox={true}
            showSalesAndCostPrice={true}
            customerList={customerList}
          />
        ) : (
          <GridViewProducts
            products={currentTabInfo.products}
            selectProduct={addRemoveProduct}
            selectedProducts={currentTabInfo.selectedProducts}
            allRowsSelected={false}
            selectAllRows={() => {}}
            getCatalog={() => {}}
            actionsOnProduct={actionsOnProduct}
            productCategories={productCategories}
            shiftKeyPressed={false}
            selectAllDisabled={true}
            isRemoveSelectAllCheckBox={true}
            showSalesAndCostPrice={true}
          />
        )}
      </div>
    ) : (
      <>
        {isProductsLoading && (
          <div className="m-auto h-full w-full flex-col items-center justify-center pb-10 pt-8 text-center">
            <LoadingIndicator shouldShowOnPage={false} />
          </div>
        )}
        <div className="flex-col items-center justify-center p-16 text-center">
          <img src={noFilterIcon} className="mx-auto w-64" />
          <div className="mt-4 text-center font-inter text-2xl font-semibold text-black">
            {currentTab === "Selected Products"
              ? "There is no any selected product"
              : "No products available"}
          </div>
          <div className="mt-4 text-center font-inter text-2xl font-normal text-[#717679]">
            {currentTab === "Selected Products"
              ? "Select product from all products tab"
              : "Add products by using the search and product category above"}
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (!tabs.find((tabObject) => tabObject.name === "All Products")?.current) {
      setTabs(
        [...tabs].map((t) => {
          t.current = t.name === "All Products";
          return t;
        }),
      );
    }
  }, [keyword, selectedCategories]);

  useEffect(() => {
    if (catalog?.id && catalog?.products?.length > 0) {
      if (
        !tabs.find((tabObject) => tabObject.name === "Selected Products")
          ?.current
      ) {
        setTabs(
          [...tabs].map((t) => {
            t.current = t.name === "Selected Products";
            return t;
          }),
        );
      }
    }
  }, [catalog?.id]);

  return (
    <div className="mt-2 h-full flex-col">
      <SectionTabs tabs={tabs} setTabs={setTabs} />
      <div className="h-full">{renderTabContent()}</div>
    </div>
  );
};

export default ManageCatalogProducts;
